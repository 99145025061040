import React from 'react';
import { ROUTE_PLAN_COMPONENT } from '../../constants/constants';
import './XAxis.css';
import { round, sToMin } from "../../utils/units";

const XAxis = ({ values, parentComponent }) => {
  const formatLabel = (label) => {
    if (typeof label === 'string' || label instanceof String) {
      return label;
    }

    const roundedMins = round(sToMin(label), 0);

    return `${roundedMins > 0 ? "+" : ""}${roundedMins}mins`;
  };

  return (
    <div className="x-axis-container">
      <div className="x-axis">
        {values.map((value, index) => (
          <div key={index} className="x-axis-marker" style={{ left: `${value.position}%` }}>
            {formatLabel(value.label)}
          </div>
        ))}
      </div>
      <div className="x-axis-line"></div>
      <div className="x-axis-arrow"></div>
      { parentComponent === ROUTE_PLAN_COMPONENT &&
        <div className="text-end mt-n1">Estimated Elapsed Time (EET) including uncertainty</div>
      }
      { parentComponent !== ROUTE_PLAN_COMPONENT &&
        <div className="text-end mt-n1">Flight time (GMT) including uncertainty</div>
      }
    </div>
  );
};

export default XAxis;
