import { mapActions } from '../../reducers/map/map.reducer';

export const setFlightOrigin = flightOrigin => (dispatch) => {
  dispatch(mapActions.setFlightOrigin({ flightOrigin }));
};

export const setReservations = reservations => dispatch => {
  dispatch(mapActions.setReservations({ reservations }));
};

export const setFlightPath = flightPath => dispatch => {
  dispatch(mapActions.setFlightPath({ flightPath }));
};

export const setFlightPlans = flightPlans => dispatch => {
  dispatch(mapActions.setFlightPlans({ flightPlans }));
};

export const setFlightAndReservation = ({ flightPath, reservations, flightPlans }) => dispatch => {
  dispatch(mapActions.setFlightAndReservation({ flightPath, reservations, flightPlans }));
};

export const setIsdrawingEnabled = isDrawingEnabled => dispatch => {
  dispatch(mapActions.setIsdrawingEnabled({ isDrawingEnabled }));
};

export const setHighlightedRoute = (highlightedRoute = null) => dispatch => {
  dispatch(mapActions.setHighlightedRoute({ highlightedRoute }));
};

export const setTemplateSignals = templateSignals => dispatch => {
  dispatch(mapActions.setTemplateSignals({ templateSignals }));
};

export const toggleCoverageForSiteUuid = siteUuid => dispatch => {
  dispatch(mapActions.toggleCoverageForSiteUuid({ siteUuid }));
};

export const toggleSiteCoverageMetric = () => dispatch => {
  dispatch(mapActions.toggleSiteCoverageMetric());
};

export const clearMap = () => dispatch => {
  dispatch(mapActions.clear());
};
