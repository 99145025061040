import axios from "axios";
import moment from "moment-timezone";
import history from "./history";

export const AURA_ID_TOKEN = 'aura_id_token';
export const AURA_ACCESS_TOKEN = 'aura_access_token';
export const AURA_EXPIRES_AT = 'aura_expires_at';
export const ORG_ID = 'organization_id';
const API_LAST_CALLED_WITH_TOKEN = 'api-call-time';

export const ID_TOKEN_HEADER = 'id-token';
export const ACCESS_TOKEN_HEADER = 'access-token';

const EXPIRING_EVENT_MS_BEFORE_EXPIRY = 60000;

const START_OK_RESPONSE = 200;
const END_OK_RESPONSE = 300;
const AUTHENTICATION_ERROR = 403;

export class SessionClass {
  constructor (apiUrl, sessionTimeoutMins) {
    this.apiUrl = apiUrl;
    // NOTE. We *could* include a buffer here, to help avoid edge cases where the time we have
    // suggests that the session hasn't expired yet, when in reality it has. We have chosen not
    // to however, as if we lose the race, the worst that will happen is that a 401 will be
    // returned in which case the user should be shown the logged out page.
    this.sessionTimeoutMs = sessionTimeoutMins * 60 * 1000;
  }

  /**
 * Returns true when response is valid
 * @param {AxiosResponse} response to validate
 * @return {boolean} is a valid response
 */
  validationResponse = response => {
    if (response?.status === AUTHENTICATION_ERROR) {
      history.push('/');
    } else return !!(response?.status >= START_OK_RESPONSE && response?.status < END_OK_RESPONSE);
  };

  getApiLastCalledWithTokenTime = () => localStorage.getItem(API_LAST_CALLED_WITH_TOKEN);

  getMillisecondsToExpiry = () => {
    const apiLastCalledWithTokenTime = this.getApiLastCalledWithTokenTime();

    if (!apiLastCalledWithTokenTime) {
      return 0;
    }
    return (
      this.sessionTimeoutMs -
      moment().diff(
        moment(this.getApiLastCalledWithTokenTime()),
        'milliseconds',
        true
      )
    );
  };

  isTokenExpired = () => Math.floor(new Date().getTime() / 1000) > parseInt(localStorage.getItem(AURA_EXPIRES_AT));

  isTokenExpiringShortly = () => {
    const expiryTimestamp = parseInt(localStorage.getItem(AURA_EXPIRES_AT));
    if (isNaN(expiryTimestamp)) return false;

    const now = Math.floor(new Date().getTime());
    const timeLeft = expiryTimestamp - now / 1000;
    return timeLeft <= EXPIRING_EVENT_MS_BEFORE_EXPIRY / 1000;
  };

  isUserRecentlyActive = () => {
    const lastApiCallTimestamp = this.getApiLastCalledWithTokenTime();
    if (!lastApiCallTimestamp) return false;

    const currentTime = new Date().getTime();
    const lastActivityTime = new Date(lastApiCallTimestamp).getTime();
    return (currentTime - lastActivityTime) < window._env_.REACT_APP_SESSION_TIMEOUT_MINS * 60 * 1000;
  };

  getSecondsToExpiry = () => Math.round(this.getMillisecondsToExpiry() / 1000);

  isExpired = () => this.getMillisecondsToExpiry() <= 0;

  isExpiringShortly = () => {
    const milliSecondsToExpiry = this.getMillisecondsToExpiry();

    return milliSecondsToExpiry > 0 && milliSecondsToExpiry <= EXPIRING_EVENT_MS_BEFORE_EXPIRY;
  };

  isSessionCounterExpired = () => {
    const milliSecondsToExpiry = this.getMillisecondsToExpiry();
    return milliSecondsToExpiry <= -EXPIRING_EVENT_MS_BEFORE_EXPIRY;
  };

  isAuthenticated = () => !!(localStorage.getItem(AURA_ID_TOKEN) && localStorage.getItem(AURA_ACCESS_TOKEN) && !this.isTokenExpired());

  onLoginSuccess = ({ id_token, access_token, expires_in, expires_at, org_id }) => {
    localStorage.setItem(AURA_ID_TOKEN, id_token);
    localStorage.setItem(AURA_ACCESS_TOKEN, access_token);
    localStorage.setItem(AURA_EXPIRES_AT, expires_at);
    localStorage.setItem(ORG_ID, org_id);
  };

  clear = () => {
    localStorage.removeItem(AURA_ID_TOKEN);
    localStorage.removeItem(AURA_ACCESS_TOKEN);
    localStorage.removeItem(API_LAST_CALLED_WITH_TOKEN);
    localStorage.removeItem(AURA_EXPIRES_AT);
  };

  headers = () => {
    const headers = {};

    if (localStorage.getItem(AURA_ACCESS_TOKEN)) {
      headers[ACCESS_TOKEN_HEADER] = localStorage.getItem(AURA_ACCESS_TOKEN);
    }
    return headers;
  };

  requestConfig = (config = null) => {
    const that = this;

    return {
      ...config,
      headers: that.headers(),
    };
  };

  #onLogout = async () => {
    if (this.isAuthenticated()) {
      await axios.post(`${this.apiUrl}/session/invalidate`, null, this.requestConfig());
    }
  };

  onLogout = async () => {
    try {
      await this.#onLogout();
    } finally {
      // If session invalidation fails, we'll just ignore it and clear the tokens regardless
      this.clear();
    }
  };

  #isApiCalledWithToken = ({ config: { headers = null } = {} } = {}) =>
    headers &&
    headers[ACCESS_TOKEN_HEADER] === localStorage.getItem(AURA_ACCESS_TOKEN);

  #onApiLastCalledWithTokenNow = () => {
    localStorage.setItem(API_LAST_CALLED_WITH_TOKEN, moment.utc().toISOString());
  };

  setApiLastCalledWithTokenTimeNow = () => {
    this.#onApiLastCalledWithTokenNow();
  };

  onRequestFulfilled = (response) => {
    if (this.#isApiCalledWithToken(response)) {
      this.#onApiLastCalledWithTokenNow();
    }
    return response;
  };

  #isSecurityError = ({ response: { status = null } = {} } = {}) =>
    status && [401, 403].includes(status);

  onRequestRejected = (error) => {
    // If the server is unavailable, error.response will be undefined, therefore
    // this.#isApiCalledWithToken(error?.response) will return false and we will
    // *correctly* not update the last called with token time.
    if (!this.#isSecurityError(error) && this.#isApiCalledWithToken(error?.response)) {
      this.#onApiLastCalledWithTokenNow();
    }
    return Promise.reject(error);
  };
}

const sessionClass = new SessionClass(
  window._env_.REACT_APP_FLIGHT_PLANNING_API_URL_V2,
  window._env_.REACT_APP_SESSION_TIMEOUT_MINS
);

export default sessionClass;
