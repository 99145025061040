import { toggleSiteDetailsModal } from "../../../user/store/actions/panels.action";
import { adminActions } from "../reducers";

export const setSiteDetails = (siteDetails, parentComponent = null) => dispatch => {
  const coverageBoundsToShowBySiteId = siteDetails?.coverage_bounds_uuids?.length ? { [siteDetails.uuid]: siteDetails.coverage_bounds_uuids[0].coverage_bounds_uuid } : {};
  const initialSiteId = siteDetails?.uuid;
  dispatch(adminActions.siteDetails.setSiteDetails({ siteDetails, parentComponent, coverageBoundsToShowBySiteId, initialSiteId }));
  dispatch(toggleSiteDetailsModal(true));
};

export const clearSiteDetails = () => dispatch => {
  dispatch(adminActions.siteDetails.clear());
};

export const setInitialSiteId = initialSiteId => dispatch => {
  dispatch(adminActions.siteDetails.setInitialSiteId({ initialSiteId }));
};

export const setCoverageBoundsToShowBySiteId = coverageBoundsToShowBySiteId => dispatch => {
  dispatch(adminActions.siteDetails.setCoverageBoundsToShowBySiteId({ coverageBoundsToShowBySiteId }));
};
