import React, { useState } from "react";
import PropTypes from "prop-types";

import Autosuggest from "react-autosuggest";
import axios from "axios";
import Session from "../../utils/session";
import { metersToFeet, msToKnots, round } from "../../utils/units";

const API_URL = window._env_.REACT_APP_FLIGHT_PLANNING_API_URL_V2;

const AutoSuggestWaypoint = ({ routes, onSuggestionSelected }) => {
  const [icaoCode, setIcaoCode] = useState('');
  const [waypointSuggestions, setWaypointSuggestions] = useState([]);

  const getWaypointSuggestions = ({ value }) => {
    const lowerCaseValue = value.toLowerCase();

    const routeSuggestions = (routes || [])
      .flatMap((r) => {
        const departure = {
          route_name: r.name,
          name: r.departure.name,
          location: r.departure.location,
          icao_description: r.departure.icao_code,
        };

        const waypoints = r.waypoints.map((w) => ({
          route_name: r.name,
          name: w.name,
          location: w.location,
          icao_description: w.icao_description,
          altitude_m: w.altitude_m,
          height_agl_m: w.height_agl_m,
          true_airspeed_ms: w.true_airspeed_ms,
        }));

        const destination = {
          route_name: r.name,
          name: r.destination.name,
          location: r.destination.location,
          icao_description: r.destination.icao_code,
        };
        return [departure].concat(waypoints).concat([destination]);
      })
      .filter(r => r.route_name.toLowerCase().includes(lowerCaseValue) ||
        (r.name && r.name.toLowerCase().includes(lowerCaseValue)) ||
        (r.icao_description && r.icao_description.toLowerCase().includes(lowerCaseValue)));

    const backend_calls = [
      axios.get(`${API_URL}/lookup/navaid/autocomplete?per_page=5&icao_code=${value.toUpperCase()}`, Session.requestConfig()),
      axios.get(`${API_URL}/lookup/airport/autocomplete?per_page=5&icao_code=${value}`, Session.requestConfig())
    ];

    if (/\d/.test(value)) { backend_calls.push(axios.post(`${API_URL}/lookup/navaid/parse?icao_description=${value.toUpperCase()}`, {}, Session.requestConfig())); }

    axios.all(backend_calls)
      .then((responses) => {
        const suggestedNavaids = responses[0].data.map(x => {
          return {
            ...x,
            location: {
              type: 'Point',
              coordinates: [x.longitude, x.latitude]
            },
            icao_description: `${x.icao_code} (${x.name})`
          };
        });

        const suggestedAirports = responses[1].data.map(x => {
          return {
            ...x,
            location: {
              type: 'Point',
              coordinates: [x.longitude, x.latitude]
            },
            icao_description: `${x.icao_code} (${x.name})`
          };
        });

        const parsedNavaidPoint = backend_calls.length > 2 && responses[2].data.icao_description ? [responses[2].data] : [];

        setWaypointSuggestions(parsedNavaidPoint.concat(suggestedNavaids).concat(suggestedAirports).concat(routeSuggestions));
      })
      .catch((e) => {
        if (value) {
          setWaypointSuggestions(routeSuggestions);
        }
      });
  };

  const clearWaypointSuggestions = () => setWaypointSuggestions([]);

  const getWaypointSuggestionValue = (suggestion) => {
    let value = `${suggestion.icao_description || suggestion.name}: (${round(suggestion.location.coordinates[1], 6)}, ${round(suggestion.location.coordinates[0], 6)})`;

    if (suggestion.altitude_m) {
      value += ` @ ${round(metersToFeet(suggestion.altitude_m), 0)}ft AMSL`;
    }

    if (suggestion.true_airspeed_ms) {
      value += ` @ ${round(msToKnots(suggestion.true_airspeed_ms), 0)}kts`;
    }
    return value;
  };

  const renderWaypointSuggestion = (suggestion) => (
    <div>
      {`${
        suggestion.route_name
          ? `[${suggestion.route_name}] `
          : ''}`}{getWaypointSuggestionValue(suggestion)
      }
    </div>
  );

  const handleSuggestionSelected = (event, { suggestion }) => {
    onSuggestionSelected(suggestion);
    setIcaoCode('');
  };

  const handleInputChange = (event, { newValue }) => setIcaoCode(newValue);

  return (
    <Autosuggest
      suggestions={waypointSuggestions}
      onSuggestionsFetchRequested={getWaypointSuggestions}
      onSuggestionsClearRequested={clearWaypointSuggestions}
      getSuggestionValue={getWaypointSuggestionValue}
      renderSuggestion={renderWaypointSuggestion}
      highlightFirstSuggestion={true}
      alwaysRenderSuggestions={true}
      onSuggestionSelected={handleSuggestionSelected}
      inputProps={{
        className: "form-control rounded-0 mt-3",
        placeholder: 'Search by Name or ICAO Waypoint',
        value: icaoCode,
        onChange: handleInputChange,
      }}
    />
  );
};

AutoSuggestWaypoint.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
};

export default AutoSuggestWaypoint;
