import React, { useEffect, useState, Fragment } from 'react';
import * as d3 from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { adminActions } from '../store/reducers';
import { toggleResourceSchedulePanel } from '../../user/store/actions/panels.action';
import { Form as FinalForm, Field } from 'react-final-form';
import Form from "react-bootstrap/Form";
import FormSelect from '../../../components/FormSelectV2/FormSelect';
import DatePicker from "react-datepicker";
import { getSiteResourceSchedules, getSites } from "../store/actions";
import FormHidden from "../../../components/FormHidden/FormHidden";
import {
  dateToString,
  coerceDateIntoUtc,
  round
} from '../../../utils/units';
import { ResourceScheduleVizGrid } from '../../../components/ResourceGrid/ResourceScheduleVizGrid';
import '../../../styles.css';
import '../../../components/MapVisualization/MapVisualization.css';
import HelpToolTip from '../../../components/HelpToolTip/HelpToolTip';
import { Row, Col } from 'react-bootstrap';
import Slider from "rc-slider";
import 'rc-slider/assets/index.css';
import './ResourceScheduleViz.css';
import _ from "lodash";
import ResourceGridLegend from '../../../components/ResourceGrid/ResourceGridLegend';

// ...

const ResourceScheduleViz = () => {
  const dispatch = useDispatch();
  const sites = useSelector(({ sites }) => sites.sites);
  const sitesByUuid = useSelector(({ sites }) => sites.sitesByUuid);
  const resourceGridByUuid = useSelector(({ resourceGrid }) => resourceGrid.resourceGridByUuid);
  const siteResourceSchedules = useSelector(({ siteResourceSchedules }) => siteResourceSchedules.resourceSchedules);
  const { reservations, snapshots, resourceBlockAllocationLookup, resourceSchedules } = siteResourceSchedules;
  const [sliderValue, setSliderValue] = useState([0, 100]);
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedDates, setSelectedDates] = useState({});
  const [a2g, setA2g] = useState([]);
  const [g2a, setG2a] = useState([]);
  const snapshotTimes = snapshots.map(snapshot => new Date(snapshot.snapshot_time_utc).getTime());
  const minDate = Math.min(...snapshotTimes);
  const maxDate = Math.max(...snapshotTimes);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date(minDate));

  const [isHovered, setIsHovered] = useState(false);
  const [hoveredFlightUuid, setHoveredFlightUuid] = useState('');

  useEffect(() => {
    setSliderValue([minDate, maxDate]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (siteResourceSchedules.snapshots?.length > 0) {
      const snapshot_data = _.cloneDeep(snapshots);
      setA2g(snapshot_data[0].a2g_resource_block_allocations);
      setG2a(snapshot_data[0].g2a_resource_block_allocations);
      setCurrentDate(new Date(snapshot_data[0].snapshot_time_utc));
    }
  }, [siteResourceSchedules, snapshots]);

  useEffect(() => {
    dispatch(getSites());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(adminActions.siteResourceSchedules.clear());
    };
  }, [dispatch]);

  const handleClose = () => {
    dispatch(toggleResourceSchedulePanel());
    dispatch(adminActions.siteResourceSchedules.clear());
  };

  const handleDepartDatesChange = ([[startDate, endDate]], state, utils) => {
    utils.changeValue(state, 'departAfter', () => startDate);
    utils.changeValue(state, 'departBefore', () => endDate);
    if (startDate && endDate) {
      setSelectedDates({ startDate, endDate });
      handleSave(null, startDate, endDate);
    }
  };

  const handleSelectSite = (siteUuid) => {
    setSelectedSite(siteUuid[0]);
    handleSave(siteUuid[0]);
  };

  const handleSave = (siteUuid = null, startDate = null, endDate = null) => {
    if (!siteUuid) {
      siteUuid = selectedSite;
    }
    if (!startDate) {
      startDate = selectedDates.startDate;
    }

    if (!endDate) {
      endDate = selectedDates.endDate;
    }

    if (siteUuid && startDate && endDate) {
      if (startDate.getTime() === endDate.getTime()) {
        endDate.setHours(endDate.getHours() + 24);
      }

      // Calculate the actual difference in days
      const differenceInDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));

      if (differenceInDays <= 7) {
        dispatch(getSiteResourceSchedules(siteUuid, round(coerceDateIntoUtc(startDate).getTime() / 1000, 0), round(coerceDateIntoUtc(endDate).getTime() / 1000, 0)));
        setErrorMessage("");
      } else {
        setErrorMessage("Specify a Time range within 7 days");
        dispatch(adminActions.siteResourceSchedules.clear());
      }
    }
  };

  const snapshotIndex = snapshots?.map((snapshot, i) => {
    return { index: i, time: new Date(snapshot.snapshot_time_utc).getTime() };
  });

  const snapshotIndexForSliderPosition = snapshotIndex.reduce((acc, curr) => {
    const position = Math.round(((curr.time - minDate) / (maxDate - minDate)) * 100);
    acc[position] = curr.index;
    return acc;
  }, {});

  const dateSliderOnChange = (value) => {
    const roundedValue = Math.round(value);
    const snapshotIndex = snapshotIndexForSliderPosition[value];
    const snapshot = _.cloneDeep(snapshots[snapshotIndex]);
    if (snapshot) {
      setA2g(snapshot.a2g_resource_block_allocations);
      setG2a(snapshot.g2a_resource_block_allocations);
    }
    setSliderValue(roundedValue);
    setCurrentDate(new Date(snapshots[snapshotIndex].snapshot_time_utc));
  };

  // reservation gantt chart

  const timeScale = d3.scaleTime()
    .domain([minDate, maxDate])
    .range([0, 100]);

  const reservationsNormalized = resourceSchedules?.map(r => {
    const matchedReservation = reservations.find(res => res.uuid === r.reservation_uuid);
    const flight_uuid = matchedReservation ? matchedReservation.flight_uuid : null;
    return {
      ...r,
      flight_uuid,
      legs: r.legs.map(l => ({
        ...l,
        time_range_utc: {
          start: timeScale(new Date(l.time_range_utc.start)),
          end: timeScale(new Date(l.time_range_utc.end))
        },
        actual_time_stamp: {
          start: l.time_range_utc.start,
          end: l.time_range_utc.end
        }
      }))
    };
  }).sort((a, b) => a.legs[0].time_range_utc.start - b.legs[0].time_range_utc.start);

  const setSliderValueForHover = () => {
    let temp_slider = 0;
    if (Array.isArray(sliderValue) && sliderValue.length > 0) {
      temp_slider = 0;
    } else {
      temp_slider = sliderValue;
    }
    dateSliderOnChange(temp_slider);
  };

  return (
    <Fragment>
      <div className="panel">
        <div className="panel-header">
          <h2>Resource Schedules</h2>
          <HelpToolTip content="Query which Resource Schedules are have been allocated in a given time range for a given Site." />
          <a className="close-button" onClick={handleClose}>
            <i className="fa-solid fa-circle-xmark" />
          </a>
        </div>

        <div className="panel-body">
        <FinalForm
        initialValues={{
          departBefore: "",
          departAfter: ""
        }}
          mutators={{
            selectSite: handleSelectSite,
            selectDepartDates: handleDepartDatesChange,
          }}
          onSubmit={handleSave}
        >
          {({ form, handleSubmit }) => {
            const {
              mutators: {
                selectSite
              },
            } = form;
            const { mutators: { selectDepartDates } } = form;
            const { values: { departBefore, departAfter } } = form.getState();
            return (
              <>
                <Fragment>
                  <Row className="align-items-left mt-4">
                    <Col sm={4} md={4}>
                      <Field
                        name="site"
                        label="Site *"
                        component={FormSelect}
                        data-testid="site-name"
                        onChange={(event) => selectSite(event.target.value)}
                      >
                        <option>Select</option>
                        {sites.map((site) => (
                          <option key={site.uuid} value={site.uuid}>
                            {site.name} ({site.callsign})
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col xs={6}>
                      <div className="input-group">
                        <Form.Label>Time Range *</Form.Label>
                        <DatePicker
                          className="form-control"
                          selected={departBefore}
                          startDate={departAfter}
                          endDate={departBefore}
                          onChange={selectDepartDates}
                          dateFormat="MM/dd/yyyy"
                          selectsRange
                        />
                        <Field type="hidden" name="departBefore" component={FormHidden}/>
                        <Field type="hidden" name="departAfter" component={FormHidden}/>
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              </>
            );
          }}
        </FinalForm>
          { errorMessage && <p className="error-message">{ errorMessage }</p> }
          { a2g.length > 0 && <Row>
            <h4>Air To Ground at {dateToString(currentDate)}</h4>
            <div className={ "d-flex" }>
              <div className='resource-grid-container'>
                <ResourceScheduleVizGrid data={ a2g }
                                         allocationLookup={ resourceBlockAllocationLookup }
                                         isHovered = {isHovered}
                                         hoveredFlightUuid={hoveredFlightUuid}
                                         resourceGrid={resourceGridByUuid[sitesByUuid[selectedSite].resourceGridUuid]}
                                         is_g2a={false}
                />
              </div>
            </div>
          </Row> }
          { g2a.length > 0 && <Row>
            <h4>Ground to Air at {dateToString(currentDate)}</h4>
            <div className={ "d-flex" }>
              <div className='resource-grid-container'>
                <ResourceScheduleVizGrid data={ g2a }
                                         allocationLookup={ resourceBlockAllocationLookup }
                                         isHovered = {isHovered}
                                         hoveredFlightUuid={hoveredFlightUuid}
                                         resourceGrid={resourceGridByUuid[sitesByUuid[selectedSite].resourceGridUuid]}
                                         is_g2a={true}
                />
              </div>
            </div>
            <ResourceGridLegend showFlightLegendBlock={ true }/>
          </Row> }
        {reservations.length > 0 &&
          <Row>
            <h4>Timeline</h4>
              {reservationsNormalized.map((reservation, i) => {
                const sortedLegs = reservation.legs.sort((a, b) => a.time_range_utc.start - b.time_range_utc.start);
                let previousEnd = 0; // track end of previous leg

                return (
                <div key={i} style={{ display: 'flex', flexDirection: 'row' }}>
                  {sortedLegs.filter(leg => leg.site_uuid === selectedSite).map((leg, j) => {
                    let marginLeft = j === 0 ? leg.time_range_utc.start : leg.time_range_utc.start - previousEnd;
                    if (marginLeft < 0) {
                      marginLeft = 0;
                    }
                    previousEnd = leg.time_range_utc.end;
                    return (
                      <div
                        key={j}
                        title={reservation.flight_uuid.substring(0, 8)}
                        className="reservation-ribbons"
                        style={{
                          width: `${(leg.time_range_utc.end - leg.time_range_utc.start)}%`,
                          marginLeft: `${marginLeft}%`,
                        }}
                        onMouseEnter={() => {
                          setIsHovered(true);
                          setHoveredFlightUuid(reservation.flight_uuid);
                          setSliderValueForHover();
                        }}
                        onMouseLeave={() => {
                          setIsHovered(false);
                          setHoveredFlightUuid('');
                          setSliderValueForHover();
                        }}
                      >
                        <span className='ribbon-desc'>Flight {i + 1}</span>
                      </div>
                    );
                  })}
                </div>
                );
              })}
          </Row>}
        {reservations.length > 0 &&
          <Row>
          <div className="horizontal-slider-container">
            <Slider
                min={0}
                max={100}
                step={ null }
                marks = { snapshotIndex.reduce((acc, curr, index) => {
                  const position = Math.round(((curr.time - minDate) / (maxDate - minDate)) * 100);
                  if (index === 0 || index === snapshots.length - 1) {
                    acc[position] = dateToString(new Date(curr.time));
                  } else {
                    acc[position] = "";
                  }
                  return acc;
                }, {})}
                value={sliderValue}
                onChange={dateSliderOnChange}
              />
          </div>
          <p> Showing resource allocation at: {dateToString(currentDate)}</p>
          </Row>
        }
        </div>
      </div>
    </Fragment>
  );
};

export default ResourceScheduleViz;
