// ConfirmationPopup for Flight Details
import { Button, Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import '../../styles.css';

function ConfirmationPopup ({ message, message_2, onConfirm, show, handleClose, isLoading, confirmButtonVariant }) {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <>
        <Modal show={show}>
        <Modal.Body>
        <div className='panel-header'>
        <a className="close-button" onClick={handleClose}>
            <i className="fa-solid fa-circle-xmark" />
            </a>
        </div>
            <div>
                {message}
            </div>
            <div>
                {message_2}
            </div>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="unconfirm" onClick={handleClose}>
                <i className="fa-solid fa-backward"/>
                No, go back
            </Button>
            <Button variant={confirmButtonVariant} onClick={handleConfirm}>
            {isLoading && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />}
                Yes, continue
                <i className="fa-solid fa-forward"/>
            </Button>
        </Modal.Footer>
        </Modal>
    </>
  );
}

export default ConfirmationPopup;
