import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "../../../../admin/store/records/general.record";

export const GroundRadioRecord = new ImmutableRecord({
  uuid: '',
  organization: '',
  groundRadioModelUuid: '',
  metadata: new MetadataRecord({})
});

export const GroundRadiosRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  groundRadios: [new GroundRadioRecord({})]
});
