// length
export const metersToFeet = (x) => x * 3.28084;
export const feetToMeters = (x) => x / 3.28084;

// velocity
export const kmhTofts = x => x * 0.911344;
export const ftsTokmh = x => x / 0.911344;

export const kmhToftmin = x => x * 54.68066;
export const ftminTokmh = x => x / 54.68066;

export const kmhToknots = x => x * 0.539957;
export const knotsTokmh = x => x / 0.539957;
export const knotsToms = x => x / 1.94384;
export const msToKnots = x => x * 1.94384;

export const sToMin = x => x / 60.0;

// frequency
export const mHzToHz = x => x * 1000000;
export const hzTomhz = x => x / 1000000;

// general rounding etc.
export const round = (num, digits) => +(Math.round(parseFloat(num + "e+" + digits)) + "e-" + digits);
export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const coerceDateIntoUtc = (date) => {
  // change the 'timezone' of a Date object without altering any other values
  // - intended to compensate for ReactDatePicker always using user's local time
  return new Date(date.toString().substr(0, 24) + " GMT+0000");
};

export const coerceDateIntoUsersTimeZone = (date) => {
  // change the 'timezone' of a Date object without altering any other fields
  // - intended to compensate for ReactDatePicker always using user's local time
  return new Date(date.getTime());
};

export const convertDateIntoUsersTimeZone = (date) => {
  // change the 'timezone' of a Date object without altering any other fields
  // - intended to compensate for ReactDatePicker always using user's local time
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};

export const roundDateBackToMidnight = (date) => {
  const result = new Date(date.getTime());
  result.setHours(0, 0, 0, 0);
  return result;
};

export const roundDateForwardToMidnight = (date) => {
  const result = new Date(date.getTime());
  result.setHours(24, 0, 0, 0);
  return result;
};

export const getDatePlusDays = (days) => {
  const result = new Date();
  result.setDate(result.getDate() + days);
  return result;
};

export const roundDateToMinutes = (date, minutes) => {
  const factor = 1000 * 60 * minutes;
  return new Date(Math.round(date.getTime() / factor) * factor);
};

export const dateToString = (date) => {
  return `${zeroPad(date.getUTCMonth() + 1, 2)}/${zeroPad(date.getUTCDate(), 2)}/${date.getUTCFullYear()} ${zeroPad(date.getUTCHours(), 2)}:${zeroPad(date.getUTCMinutes(), 2)}`;
};

export const range = (start, end, step = 1) => {
  const output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export const frequecyToString = (frequency) => {
  return `${frequency.toLocaleString("en-US")} Hz`;
};

export const channelToString = (channel, index) => {
  let result = channel.name || `Subchannel ${index}`;
  result += ` (${frequecyToString(channel.lower_hz)} ${String.fromCharCode(8212)} ${frequecyToString(channel.upper_hz)})`;
  return result;
};
