import axios from 'axios';
import {
  FLIGHT_PLANNING_API_URL_V2, FLIGHTS_ENDPOINT, FLIGHTS_CLEANUP, ORPHANED_FLIGHT_CLEANUP
} from '../../../../constants/api.endpoints';
import Session from '../../../../utils/session';
import { flightsActions } from '../reducers';
import {
  coerceDateIntoUtc,
  roundDateBackToMidnight,
  roundDateForwardToMidnight
} from "../../../../utils/units";
import { setFlightPlans } from './map/map.action';
import { convertToRouteDataRecord } from '../utils/common';
import { convertToFlightOriginState } from '../utils/routes/route.plan.util';

const getFlights = (sitesByUuid, filters, pagination) => async dispatch => {
  try {
    dispatch(flightsActions.flights.isFetching());
    const { departAfter, departBefore, departureAirport, userUuid, aircraftUuid, organization } = filters;
    const { page, perPage, lastUuid, firstUuid } = pagination;
    const urlParams = [];
    urlParams.push(`page=${page}`);
    urlParams.push(`per_page=${perPage}`);
    if (lastUuid && lastUuid !== "") {
      urlParams.push(`last_uuid=${lastUuid}`);
    }
    if (firstUuid && firstUuid !== "") {
      urlParams.push(`first_uuid=${firstUuid}`);
    }
    if (aircraftUuid && aircraftUuid !== "") {
      urlParams.push(`aircraft_tail_number=${aircraftUuid}`);
    }

    if (departAfter && departAfter !== "") {
      urlParams.push(`depart_after=${
        coerceDateIntoUtc(roundDateBackToMidnight(departAfter)).getTime() / 1000
      }`);
    }

    if (departBefore && departBefore !== "") {
      urlParams.push(`depart_before=${
        coerceDateIntoUtc(roundDateForwardToMidnight(departBefore)).getTime() / 1000
      }`);
    }

    if (departureAirport && departureAirport !== "") {
      urlParams.push(`departure_airport_code=${departureAirport}`);
    }

    if (organization && organization !== "") {
      urlParams.push(`organization=${organization}`);
    }

    if (userUuid && userUuid !== "") {
      urlParams.push(`user_uuid=${userUuid}`);
    }
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${FLIGHTS_ENDPOINT}?${urlParams.join("&")}`,
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      const flightBundles = response.data.records;
      const flightPlans = [];
      const reservations = [];
      flightBundles.forEach(bundle => {
        const routeRecord = convertToRouteDataRecord(bundle.route);
        flightPlans.push(convertToFlightOriginState(routeRecord?.flightPlan));

        if (bundle?.reservation) {
          for (const leg of bundle.reservation.legs) {
            // decorate with site details we already know for convenient display
            if (leg.site_uuid) {
              leg.site_name = sitesByUuid[leg.site_uuid].name;
              leg.site_callsign = sitesByUuid[leg.site_uuid].callsign;
            }
          }
        }
        reservations.push(bundle.reservation);
      });

      dispatch(flightsActions.flights.isFetched({
        paginationDetails: {
          firstUuid: response?.data?.pagination_details?.first_uuid || null,
          lastUuid: response?.data?.pagination_details?.last_uuid || null,
          page: page || 0,
          perPage: response?.data?.pagination_details?.per_page || 5
        },
        flightBundles
      }));
      dispatch(setFlightPlans(flightPlans)); // TODO - remove flight path
    } else {
      dispatch(flightsActions.flights.isError());
    }
  } catch (err) {
    dispatch(flightsActions.flights.isError());
  }
};

export const clearFlightState = () => dispatch => {
  dispatch(flightsActions.flights.clear());
};

export const clearFlightPagination = () => dispatch => {
  dispatch(flightsActions.flights.clearFlightPagination());
};

export const setHighlightedFlightIndex = (idx = null) => dispatch => {
  dispatch(flightsActions.flights.setHighlightedFlightIndex({ idx }));
};

export const clearSuccessMessage = () => dispatch => {
  dispatch(flightsActions.flights.clearSuccessMessage());
};

export const cleanUpOldFlights = () => {
  return async (dispatch) => {
    try {
      dispatch(flightsActions.flights.isFetching());
      const response = await axios.delete(
        `${FLIGHT_PLANNING_API_URL_V2}${FLIGHTS_CLEANUP}`,
        Session.requestConfig()
      );

      if (Session.validationResponse(response)) {
        if (response.status === 200) {
          dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'No Flights to Clean up' }));
        } else {
          dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'Old flights cleaned up successfully.' }));
        }
      } else {
        dispatch(flightsActions.flights.isError());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'Internal Server Error, please try again later.' }));
      } else if (error.response) {
        dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'An error occurred, please try again.' }));
      }
      dispatch(flightsActions.flights.isError());
    }
  };
};

export const cleanUpOrphanedFlightData = () => {
  return async (dispatch) => {
    dispatch(flightsActions.flights.isFetching());
    try {
      const response = await axios.delete(
        `${FLIGHT_PLANNING_API_URL_V2}${ORPHANED_FLIGHT_CLEANUP}`,
        Session.requestConfig()
      );

      if (Session.validationResponse(response)) {
        if (response.status === 200) {
          dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'No Flight data to Clean up' }));
        } else {
          dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'Orphaned flight data cleaned up successfully.' }));
        }
      } else {
        dispatch(flightsActions.flights.isError());
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'Internal Server Error, please try again later.' }));
      } else if (error.response) {
        dispatch(flightsActions.flights.setSuccessMessage({ successMessage: 'An error occurred, please try again.' }));
      }
      dispatch(flightsActions.flights.isError());
    }
  };
};

export default getFlights;
