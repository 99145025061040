import axios from 'axios';
import { List as ImmutableList } from 'immutable';
import { FLIGHT_PLANNING_API_URL_V2, RESOURCE_GRIDS_ENDPOINT } from "../../../../constants/api.endpoints";
import Session from '../../../../utils/session';
import { adminActions } from "../reducers";
import { PaginationDetailRecord, ResourceGridRecord } from '../records/resourcegrid.record';
import { MetadataRecord } from '../records/general.record';

const getResourceGridSets = () => async dispatch => {
  try {
    dispatch(adminActions.resourceGrid.isFetching);
    const response = await axios.get(`${FLIGHT_PLANNING_API_URL_V2}${RESOURCE_GRIDS_ENDPOINT}`, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const resourceGrid = response.data.records;
      const resourceGridByUuid = {};
      for (const block of response.data.records) {
        resourceGridByUuid[block.uuid] = block;
      }
      const paginationDetails = new PaginationDetailRecord({
        page: response.data.pagination_details.page,
        perPage: response.data.pagination_details.per_page,
      });
      const records = response?.data?.records?.reduce((newRec, rec) => {
        return newRec.push(
          new ResourceGridRecord({
            metadata: new MetadataRecord({
              created: rec.metadata.created,
              createdBy: rec.metadata.created_by
            }),
            name: rec.name,
            numSubCarriers: rec.num_sub_carriers,
            numTimeSlots: rec.num_time_slots,
            minFrequencyHz: rec.min_frequency_hz,
            subChannelWidthHz: rec.sub_channel_width_hz,
            siteUuids: rec.site_uuids,
            uuid: rec.uuid
          })
        );
      }, new ImmutableList());
      dispatch(adminActions.resourceGrid.isFetched({ paginationDetails, records, resourceGridByUuid, resourceGrid }));
    } else {
      // todo => show generic failure message on UI, create error boundary to show error message
      dispatch(adminActions.resourceGrid.isError);
    }
  } catch (err) {
    dispatch(adminActions.resourceGrid.isError);
    console.log('error is', err);
  }
};

export default getResourceGridSets;
