import { createSlice } from "@reduxjs/toolkit";
import { AntennaRecord, AntennasRecord } from "../../records/inventory/antenna.record";

const initialState = new AntennasRecord({});

const antenna = createSlice({
  name: 'antenna',
  initialState,
  reducers: {
    isFetching: (antennaState) => {
      return antennaState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (antennaState, action) => {
      return antennaState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        antennas: action.payload.antennas,
      });
    },
    isError: (antennaState) => {
      return antennaState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (antennaState) => {
      return antennaState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        antennas: [new AntennaRecord({})]
      });
    }
  }
});

export default antenna;
