import React, { useState } from "react";
import PropTypes from "prop-types";

import Autosuggest from "react-autosuggest";
import axios from "axios";
import Session from "../../utils/session";

const API_URL = window._env_.REACT_APP_FLIGHT_PLANNING_API_URL_V2;

const AutoSuggestAlternate = ({ routes, onSuggestionSelected }) => {
  const [airportCode, setAirportCode] = useState('');
  const [alternateSuggestions, setAlternateSuggestions] = useState([]);

  const getAlternateSuggestions = ({ value }) => {
    const lowerCaseValue = value.toLowerCase();

    const routeSuggestions = (routes || [])
      .flatMap((r) => {
        const departure = {
          route_name: r.name,
          icao_code: r.departure.airport_code,
          name: r.departure.name,
          latitude: r.departure.location.coordinates[1],
          longitude: r.departure.location.coordinates[0],
        };

        const alternates = r.alternates.map((w) => ({
          route_name: r.name,
          icao_code: w.icao_description,
          name: w.name,
          latitude: w.location.coordinates[1],
          longitude: w.location.coordinates[0],
        }));

        const destination = {
          route_name: r.name,
          icao_code: r.destination.airport_code,
          name: r.destination.name,
          latitude: r.destination.location.coordinates[1],
          longitude: r.destination.location.coordinates[0],
        };
        return [departure].concat(alternates).concat([destination]);
      })
      .filter(r => r.route_name.toLowerCase().includes(lowerCaseValue) ||
        (r.icao_code && r.icao_code.toLowerCase().includes(lowerCaseValue)) ||
        (r.name && r.name.toLowerCase().includes(lowerCaseValue)));

    axios.get(`${API_URL}/lookup/airport/autocomplete?per_page=10&icao_code=${value}&name=${value}`, Session.requestConfig())
      .then((response) => setAlternateSuggestions((response.data || []).concat(routeSuggestions)))
      .catch((error) => {
        console.log(error);
        setAlternateSuggestions(routeSuggestions);
      });
  };

  const clearAlternateSuggestions = () => setAlternateSuggestions([]);

  const getAlternateSuggestionValue = (suggestion) =>
    `${suggestion.name}${suggestion.icao_code ? ` (${suggestion.icao_code})` : ''}`;

  const renderAlternateSuggestion = (suggestion) => (
    <div>
      {`${
        suggestion.route_name
          ? `[${suggestion.route_name}] `
          : ''}`}{`${suggestion.icao_code} (${suggestion.name})`
      }
    </div>
  );

  const handleSuggestionSelected = (event, { suggestion }) => {
    onSuggestionSelected(suggestion);
    setAirportCode('');
  };

  const handleInputChange = (event, { newValue }) => setAirportCode(newValue);

  return (
    <Autosuggest
      suggestions={alternateSuggestions}
      onSuggestionsFetchRequested={getAlternateSuggestions}
      onSuggestionsClearRequested={clearAlternateSuggestions}
      getSuggestionValue={getAlternateSuggestionValue}
      renderSuggestion={renderAlternateSuggestion}
      highlightFirstSuggestion={true}
      alwaysRenderSuggestions={true}
      onSuggestionSelected={handleSuggestionSelected}
      inputProps={{
        className: "form-control rounded-0 mt-3",
        placeholder: 'Search by Name or ICAO Airport Code',
        value: airportCode,
        onChange: handleInputChange,
      }}
    />
  );
};

AutoSuggestAlternate.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
};

export default AutoSuggestAlternate;
