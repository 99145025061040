import _ from "lodash";

export const convertToInitialFormValues = flightPlan => {
  return {
    route: flightPlan?.uuid || '',
    departureTime: flightPlan?.route?.departureTime || ''
  };
};

export const convertToFlightPlanDto = (route, departureTime, inventory) => {
  const aircraftModel = _.find(inventory.aircraftModels.aircraftModels, { name: route?.aircraftModel?.name })?.uuid;
  const antennaModel = _.find(inventory.antennaModels.antennaModels, { name: route?.antennaModel?.name })?.uuid;
  const airborneRadioModel = _.find(inventory.airborneRadioModels.airborneRadioModels, { name: route?.airborneRadioModel?.name })?.uuid;
  return {
    estimated_time_departure_utc: new Date(departureTime),
    route_uuid: route.uuid,
    aircraft_model_uuid: aircraftModel,
    airborne_radio_model_uuid: airborneRadioModel,
    antenna_model_uuid: antennaModel,
  };
};

export const flightPlanValidation = (values) => {
  const errors = {};

  if (!values?.route) {
    errors.route = 'Required';
  }

  if (!values?.departureTime) {
    errors.departureTime = 'Required';
  }

  return errors;
};
