import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import '../../styles.css';

const FormField = ({
  input,
  type,
  label,
  placeholder,
  readOnly,
  groupClassName,
  labelClassName,
  fieldClassName,
  'data-testid': dataTestId,
  meta: { touched, error },
}) => (
  <Form.Group
      controlId={input.name}
      data-testid={dataTestId}
      className={groupClassName}
    >
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <Form.Control
        {...input}
        type={input.type || type}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={readOnly}
        data-testid={dataTestId}
        className={`no-spinner ${fieldClassName}`}
      />
    {!readOnly && touched && error && (
      <div className="error-message m-0">{error}</div>
    )}
  </Form.Group>
);

FormField.propTypes = {
  input: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  'data-testid': PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
};

FormField.defaultProps = {
  type: 'text',
  label: null,
  placeholder: null,
  readOnly: false,
  groupClassName: null,
  labelClassName: null,
  fieldClassName: null,
  'data-testid': null,
  meta: {
    error: '',
  },
};

export default FormField;
