import React, { Fragment, useState, useEffect } from "react";

import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";

import { Field, Form as FinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";

import FormField from "../../../components/FormField/FormField";
import FormSelect from "../../../components/FormSelect/FormSelect";
import ResourceGrid from "../../../components/ResourceGrid/ResourceGrid";
import ResourceGridLegend from "../../../components/ResourceGrid/ResourceGridLegend";
import { getGroundAntennaModels } from "../../user/store/actions/inventory/antenna.model.action";
import { v4 as uuidv4 } from "uuid";

import { isAura } from "../../../utils/identity";
import { round } from "../../../utils/units";

import siteApi from "./api";
import validate from "./validate";

import "./../../../styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getSites } from "../store/actions";
import { COVERAGE_BOUNDS_COMPONENT, SITE_COMPONENT } from "../../../constants/api.endpoints";
import { toggleCoverageBoundsPanel, toggleSiteListPanel } from "../../user/store/actions/panels.action";
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import { getErrorMessage } from "../../user/store/utils/common";
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";
import Alert from "react-bootstrap/Alert";

const SiteDetails = () => {
  const dispatch = useDispatch();
  const identity = useSelector(({ identity }) => identity.identity);
  const resourceGridByUuid = useSelector(({ resourceGrid }) => resourceGrid.resourceGridByUuid);
  const siteDetailsRecord = useSelector(({ siteDetails }) => siteDetails);
  const inventory = useSelector(({ inventory }) => inventory);
  const { siteDetails, parentComponent } = siteDetailsRecord;

  const [redirectToSiteList, setRedirectToSiteList] = useState(false);
  const [resourceGrida2g, setResourceGrida2g] = useState(() => {
    return siteDetails?.a2gResourceBlockConfig;
  });
  const [resourceGridg2a, setResourceGridg2a] = useState(() => {
    return siteDetails?.g2aResourceBlockConfig;
  });

  const [haveResourceGridsChanged, setHaveResourceGridsChanged] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [saveError, setSaveError] = useState("");

  const { groundRadios, antennas } = inventory;

  useEffect(() => {
    if (isAura(identity)) {
      dispatch(getGroundAntennaModels());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const groundAntennaModels = useSelector(state => state.inventory.antennaModels);

  const handleSelectResourceGrid = (event, state, utils) => {
    const resourceGridId = event[0].target.value;
    const newResourceGrid = resourceGridByUuid[resourceGridId];

    const newResourceGridA2G = Array.from({ length: newResourceGrid.num_time_slots * newResourceGrid.num_subcarriers },
      (e, i) => (Math.floor(i / 2) % 2) === 1 ? 3 : 0);
    const newResourceGridG2A = Array.from({ length: newResourceGrid.num_time_slots * newResourceGrid.num_subcarriers },
      (e, i) => (Math.floor(i / 2) % 2) === 0 ? 3 : 0);

    setResourceGrida2g(newResourceGridA2G);
    setResourceGridg2a(newResourceGridG2A);
  };

  const AntennaModelMap = {};
  groundAntennaModels?.groundAntennaModels.forEach(model => {
    AntennaModelMap[model.uuid] = { name: model.name, type: model.type };
  });

  // Unfortunately react table doesn't work well with react final form, therefore we
  // are unable to use the <Field /> control inside the react table, hence the need
  // for this handler. React table doesn't appear to provide huge benefits, therefore
  // it may be better to get rid of react table instead, in the future.

  const handleSave = (values) => {
    const siteDetailsToSubmit = {
      uuid: uuidv4(),
      type: parseInt(values.type),
      callsign: values.callsign,
      name: values.name,
      ground_radio_uuid: values.groundRadioUuid,
      antenna_uuid: values.antennaUuid,
      location: {
        type: 'Point',
        coordinates: [
          round(values.location.coordinates[0], 6),
          round(values.location.coordinates[1], 6),
        ],
      },
      max_eirp_dbm: parseInt(values.maxEirpDbm),
      coverage_bounds_uuids: values.coverageBoundsUuids ? values.coverageBoundsUuids : [],
      height_agl_m: parseFloat(values.heightAglM),
      altitude_m: parseFloat(values.altitudeM),
      antenna_azimuth_deg: parseFloat(values.antennaAzimuthDeg),
      antenna_bearing_deg: parseFloat(values.antennaBearingDeg),
      a2g_resource_block_config: [...resourceGrida2g],
      g2a_resource_block_config: [...resourceGridg2a],
      resource_grid_uuid: values.resourceGridUuid,
      g2g_oob_interference_dbm: parseFloat(values.g2gOobInterferenceDbm)
    };
    if (!siteDetails) {
      return siteApi.create(siteDetailsToSubmit)
        .then(() => dispatch(getSites(true)))
        .catch((error) => {
          setSaveError(getErrorMessage(error));
        });
    }
    return siteApi.update(siteDetails.uuid, siteDetailsToSubmit)
      .then(() => dispatch(getSites(true)))
      .catch((error) => {
        setSaveError(getErrorMessage(error));
      });
  };

  const handleConfirmPopupClose = () => {
    setShowConfirmPopup(false);
  };

  const handleDelete = () => {
    return siteApi.delete_(siteDetails.uuid)
      .then(() => dispatch(getSites(true)))
      .catch((error) => {
        setSaveError(getErrorMessage(error));
      })
      .finally(() => setShowConfirmPopup(false));
  };

  const a2gtoggleBlock = (value) => {
    const blockInput = [...resourceGrida2g];
    blockInput[value] = (blockInput[value] + 1) % 4;
    setResourceGrida2g(blockInput);
    if (!haveResourceGridsChanged) {
      setHaveResourceGridsChanged(true);
    }
  };

  const g2atoggleBlock = (value) => {
    const blockInput = [...resourceGridg2a];
    blockInput[value] = (blockInput[value] + 1) % 4;
    setResourceGridg2a(blockInput);
    if (!haveResourceGridsChanged) {
      setHaveResourceGridsChanged(true);
    }
  };

  if (redirectToSiteList) {
    if (parentComponent === SITE_COMPONENT) {
      dispatch(toggleSiteListPanel(true));
    } else if (parentComponent === COVERAGE_BOUNDS_COMPONENT) {
      dispatch(toggleCoverageBoundsPanel(true));
    }
  }

  if (!isAura(identity)) {
    return;
  }

  const handleClose = () => {
    setRedirectToSiteList(true);
  };

  return (
    <Fragment>
      <div className="panel">
        <div className="panel-header">
          {siteDetails && <h2>{siteDetails.name} ({siteDetails.callsign})</h2>}
          {!siteDetails && <h2>New Site</h2>}
          <a className="close-button" onClick={handleClose}><i className="fa-solid fa-circle-xmark"/></a>
        </div>
        <div className="panel-body">
          <Container>
            <h3>Properties</h3>
            <FinalForm
              initialValues={ {
                ...siteDetails?.toJS(),
                coverageBoundsId:
                  (siteDetails?.coverageBoundsUuids || [])
                    .map((cb) => cb.coverageBoundsUuids)
                    .find(() => true),
                maxEirpDbm: siteDetails ? siteDetails.maxEirpDbm : 44,
                antennaAzimuthDeg: siteDetails ? siteDetails.antennaAzimuthDeg : 90,
                antennaBearingDeg: siteDetails ? siteDetails.antennaBearingDeg : 0,
                altitudeM: siteDetails ? siteDetails.altitudeM : null
              }}
              initialValuesEqual={() => true}
              mutators={{
                ...arrayMutators,
                selectResourceGrid: handleSelectResourceGrid
              }}
              validate={validate}
              onSubmit={handleSave}
            >
              {({ form, handleSubmit }) => {
                const {
                  submitting,
                  submitErrors,
                  hasValidationErrors,
                  values: { resourceGridUuid },
                } = form.getState();

                const {
                  mutators: { selectResourceGrid },
                } = form;

                return (
                  <>
                    {submitErrors && <p className="text-danger">{submitErrors}</p>}
                    <Row className={"mb-0 mt-2"}>
                      <Col xs={4}>
                        <Field type="text" name="name" label="Name *" component={FormField} />
                      </Col>
                      <Col xs={4}>
                        <Field type="text" name="callsign" label="Callsign *" component={FormField} />
                      </Col>
                      <Col xs={4}>
                        <Field name="type" label="Type *" component={FormSelect}>
                          <option value="">Select</option>
                          <option value="0">Macro Cell</option>
                          <option value="1">Micro Cell</option>
                          <option value="2">Go Kit</option>
                        </Field>
                      </Col>
                    </Row>
                    <Row className={"mb-0"}>
                      <Col xs={4}>
                        <Field type="number" name="location.coordinates[0]" label="Longitude *" component={FormField} />
                      </Col>
                      <Col xs={4}>
                        <Field type="number" name="location.coordinates[1]" label="Latitude *" component={FormField} />
                      </Col>
                    </Row>
                    <Row className={"mb-0"}>
                      <Col xs={4}>
                        <Field type="number" name="heightAglM" label="Height AGL (m) *" component={FormField} />
                      </Col>
                      <Col xs={4}>
                        <Field type="text" name="altitudeM" label="Altitude (m)" component={FormField} readOnly={true}/>
                      </Col>
                    </Row>
                    <Row className={"mb-0"}>
                      <Col xs={4}>
                      <Field type="text" name="antennaUuid" label="Antenna UUID *" component={FormSelect}>
                        <option value="">Select</option>
                        {antennas?.antennas?.map(antenna => {
                          const antennaModel = AntennaModelMap[antenna.antennaModelUuid];
                          if (antennaModel?.type === 'AntennaType.SITE') {
                            return (
                              <option key={antenna.uuid} value={antenna.uuid}>
                                {antennaModel.name} - {antenna.uuid.substring(0, 8)}...
                              </option>
                            );
                          }
                          return null;
                        })}
                      </Field>
                      </Col>
                      <Col xs={4}>
                        <Field type="number" name="antennaAzimuthDeg" label="Antenna Azimuth (&deg;) *" component={FormField}/>
                      </Col>
                      <Col xs={4}>
                        <Field type="number" name="antennaBearingDeg" label="Antenna Bearing (&deg;) *" component={FormField}/>
                      </Col>
                    </Row>
                    <Row className={"mb-0"}>
                      <Col xs={4}>
                        <Field type="type" name="groundRadioUuid" label="Ground Radio UUID * &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" component={FormSelect}>
                          <option value="">Select</option>
                          {groundRadios?.groundRadios?.map(groundRadio => (
                            <option key={groundRadio.uuid} value={groundRadio.uuid}>
                              {groundRadio.uuid}
                            </option>
                          ))}
                        </Field>
                      </Col>
                      <Col xs={4}>
                        <Field type="number" name="maxEirpDbm" label="Tx Power (if radio supports) (dBm) *" component={FormField} />
                      </Col>
                      <Col xs={4}>
                        <Field type="number" name="g2gOobInterferenceDbm" label="Aggregate g2g OOB Interference (dBm)" placeholder="-118" component={FormField}/>
                      </Col>
                    </Row>
                    <Row>
                      <h2>Resource Configuration</h2>
                      <Col md={12}>
                        <Field
                          name="resourceGridUuid"
                          label="Resource Grid *"
                          onChange={selectResourceGrid}
                          component={FormSelect}
                        >
                          <option key={-100} value="">Select</option>
                          {Object.values(resourceGridByUuid).map((rbs) => (
                            <option key={rbs.uuid} value={rbs.uuid}>{rbs.name}</option>
                          ))}
                        </Field>
                        { resourceGridUuid && siteDetails &&
                          <Form.Group className="input-group input-group-100 mb-0">
                            <div>
                              <h4>Air to Ground <HelpToolTip content="The grid below shows how each radio resource is configured for this Site.
                            Each grid represents a single 80ms frame. Each element of the grid represents a single
                            Resource Element, i.e. one subchannel for one timeslot. Timeslots are on the horizontal axis (left-to-right),
                            frequency subchannels on the vertical axis (top-to-bottom)."/></h4>
                              <div className={ 'resource-grid-container' }>
                                <ResourceGrid resourceGridByUuid={ resourceGridByUuid }
                                              legNumber={ -1 }
                                              isFlightDetails={ false }
                                              resource_grid_uuid={ resourceGridUuid }
                                              toggleBlock={ a2gtoggleBlock }
                                              siteResourceElementConfig={ resourceGrida2g }
                                              is_g2a={false}
                                />
                              </div>
                            </div>
                            <div>
                              <h4>Ground to Air <HelpToolTip content="The grid below shows how each radio resource is configured for this Site.
                            Each grid represents a single 80ms frame. Each element of the grid represents a single
                            Resource Element, i.e. one subchannel for one timeslot. Timeslots are on the horizontal axis (left-to-right),
                            frequency subchannels on the vertical axis (top-to-bottom)."/></h4>
                              <div className={ 'resource-grid-container' }>
                                <ResourceGrid resourceGridByUuid={ resourceGridByUuid }
                                              legNumber={ -1 }
                                              isFlightDetails={ false }
                                              resource_grid_uuid={ resourceGridUuid }
                                              toggleBlock={ g2atoggleBlock }
                                              siteResourceElementConfig={ resourceGridg2a }
                                              is_g2a={true}
                                />
                              </div>
                            </div>
                            { resourceGridUuid &&
                              <div style={ { display: "flex", flexDirection: "column", marginTop: "10px" } }>
                                <ResourceGridLegend showFlightLegendBlock={ false }/></div> }
                          </Form.Group>
                        }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      { (siteDetails && (form.getState().dirty || haveResourceGridsChanged)) &&
                        <Alert variant="primary">
                          <Alert.Heading className="text-center">Warning</Alert.Heading>
                          <ul>
                            <li>Changes to Site RF parameters (location, Tx Power, height etc.) may invalidate existing reservations.</li>
                            <li>Changes to Site Resource Block config may impact the DFAS's ability to assign RBs to reservations.</li>
                            <li>The impact of these changes is not validated by the DFAS for MVP.</li>
                            <li>Only make changes if you are certain!</li>
                          </ul>
                        </Alert>
                      }
                      <Col md={12} className="text-end">
                        <Button
                          variant="secondary"
                          disabled={submitting}
                          onClick={() => setRedirectToSiteList(true)}
                        >
                          Cancel
                        </Button>
                        {siteDetails &&
                          <>
                            <Button
                              variant="danger"
                              className="ms-1"
                              disabled={submitting || hasValidationErrors}
                              onClick={() => setShowConfirmPopup(true)}
                            >
                              {submitting && (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Delete <i className="fas fa-trash-alt"/>
                            </Button>
                            <ConfirmationPopup
                              message="Are you sure you want to delete this Site?"
                              message_2="This cannot be undone."
                              onConfirm={handleDelete}
                              show={showConfirmPopup}
                              handleClose={handleConfirmPopupClose}
                              isLoading={submitting}
                              confirmButtonVariant={"danger"}
                            />
                          </>
                        }
                        <Button
                          variant="primary"
                          className="ms-1"
                          disabled={submitting || hasValidationErrors || (siteDetails && !form.getState().dirty && !haveResourceGridsChanged)}
                          onClick={() => handleSubmit()}
                        >
                          {submitting && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          Save <i className="fas fa-save"/>
                        </Button>
                      </Col>
                      {saveError && <p className="error-message">Error: {saveError}</p>}
                    </Row>
                  </>
                );
              }}
            </FinalForm>
          </Container>
        </div>
      </div>
    </Fragment>
  );
};

export default SiteDetails;
