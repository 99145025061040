/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import './FlightDetails.css';
import './../../../styles.css';
import Session from '../../../utils/session';
import { isAura } from '../../../utils/identity';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import { dateToString } from '../../../utils/units';
import SubwayMap from '../../../components/MapVisualization/SubwayMap';
import ResourceGrid from '../../../components/ResourceGrid/ResourceGrid';
import ResourceGridLegend from "../../../components/ResourceGrid/ResourceGridLegend";
import { Form as FinalForm } from 'react-final-form';
import DownloadLink from 'react-download-link';
import { toggleFlightListPanel } from '../store/actions/panels.action.js';
import {
  FLIGHT_PLANNING_API_URL_V2,
  RESERVATION_LIFECYCLE_API_URL,
  STATE_MACHINE_VISUALIZER
} from '../../../constants/api.endpoints.js';
import {
  setSelectedRoute,
} from "../store/actions/routes/route.plan.action";
import { convertFlightPlan, convertToRouteDataRecord, getErrorMessage } from '../store/utils/common.js';
import { FLIGHT_DETAIL_COMPONENT } from "../../../constants/constants";
import { setFlightPlans, setReservations } from '../store/actions/map/map.action.js';
import { convertToFlightOriginState } from '../store/utils/routes/route.plan.util.js';
import TimelineDiagram from '../../../components/TimelineDiagram/TimelineDiagram.js';
import ConfirmationPopup from '../../../components/ConfirmationPopup/ConfirmationPopup';
import { getFlightPathInfo, fetchFlightStateMachine, clearFlightDetails, fetchCurrentFlightMachineState } from '../store/actions/flight.details.action';
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";

const FlightDetails = () => {
  const dispatch = useDispatch();

  const identity = useSelector(({ identity }) => identity.identity);
  const { sites } = useSelector(({ sites }) => sites);
  const resourceGridByUuid = useSelector(({ resourceGrid }) => resourceGrid.resourceGridByUuid);
  const flightDetails = useSelector(({ flightDetails }) => flightDetails);
  const inventory = useSelector(({ inventory }) => inventory);
  const flightPath = useSelector(({ map }) => map.flightPath);
  const { isFetching, isFetched, flightUuid, route, flightPlan, reservation, estimatedTimeDepartureUTC, flightStateMachine, flightFrrcState } = flightDetails;
  const { aircrafts } = inventory;
  const [aircraftUuid, setAircraftUuid] = useState('');
  const [reloadFlight, setReloadFlight] = useState(false);
  const [flight, setFlight] = useState({});
  const [resourceSchedule, setResourceSchedule] = useState(null);
  const siteResourceElementConfig = _.find(sites, { uuid: resourceSchedule?.legs[0]?.site_uuid });
  const [resourceScheduleTimeRange, setResourceScheduleTimeRange] = useState([]);
  const [flightDebrief, setFlightDebrief] = useState({});
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [deleteLoadingIndicator, setDeleteLoadingIndicator] = useState(false);
  const [attachLoadingIndicator, setAttachLoadingIndicator] = useState(false);
  const [cancelLoadingIndicator, setCancelLoadingIndicator] = useState(false);
  const [overrideLoadingIndicator, setOverrideLoadingIndicator] = useState(false); // eslint-disable-line no-unused-vars
  const [preflightLoadingIndicator, setPreflightLoadingIndicator] = useState(false);
  const [abortPreflightLoadingIndicator, setAbortPreflightLoadingIndicator] = useState(false);
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState('');
  const [resourceBlockError, setResourceBlockError] = useState(false);
  const [showResourceBlockError, setShowResourceBlockError] = useState('');
  const [flightStateCssClass, setFlightStateCssClass] = useState('noHighlightState');
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const calculateGraphEdges = useCallback(() => flightPlan?.graph_edges, [flightPlan?.graph_edges]);
  const cancelToken = useMemo(() => axios.CancelToken.source(), []);

  const textAreaRef = useRef(null);
  useEffect(() => {
    setFlightStateCssClass('noHighlightState');
    axios
      .get(`${FLIGHT_PLANNING_API_URL_V2}/flights/${flightUuid}`, {
        ...Session.requestConfig(),
        cancelToken: cancelToken.token
      })
      .then(response => {
        if (response.data) {
          const new_flight = response.data;

          if (Object.keys(flight).length) {
            setFlightStateCssClass('highlightState');
          }

          setFlight(new_flight);
          setAircraftUuid(new_flight.aircraft_uuid);
          setReloadFlight(false);

          if (isAura(identity) && new_flight.reservation_uuid) {
            axios
              .get(
                `${RESERVATION_LIFECYCLE_API_URL}/resourceschedules/reservation/${new_flight.reservation_uuid}`,
                { ...Session.requestConfig(), cancelToken: cancelToken.token }
              )
              .then(response => {
                if (response.data) {
                  setResourceSchedule(response.data);
                  setResourceScheduleTimeRange(null);
                }
              })
              .catch(error => {
                setError(true);
                setShowError(error?.response?.data?.message);
              });
          }

          if (new_flight.state === 'COMPLETE' && Object.keys(flightDebrief).length === 0) {
            axios.post(
                `${RESERVATION_LIFECYCLE_API_URL}/lifecycle/debrief/${flightUuid}`,
                {},
                { ...Session.requestConfig(), cancelToken: cancelToken.token }
            ).then(response => {
              if (!response.data) {
                setError(true);
                setShowError(getErrorMessage(error));
              } else {
                setError(false);
                setFlightDebrief(response.data);
              }
            }).catch(error => {
              setError(true);
              setShowError(getErrorMessage(error));
            });
          }
        }
      })
      .catch(error => {
        setError(true);
        setShowError(getErrorMessage(error));
      });
  }, [
    error.message,
    flightDebrief,
    flightUuid,
    FLIGHT_PLANNING_API_URL_V2,
    reloadFlight,
    RESERVATION_LIFECYCLE_API_URL,
    cancelToken.token,
    identity,
    attachLoadingIndicator
  ]);

  useEffect(
    () => () => {
      cancelToken.cancel();
    },
    [cancelToken]
  );

  useEffect(() => {
    dispatch(getFlightPathInfo(flightPlan.flight_path_uuid));
    if (reservation) {
      dispatch(setReservations([reservation]));
    }
    if (flightPlan) {
      const newFlightPlan = convertFlightPlan(flightPlan);
      dispatch(setFlightPlans([convertToFlightOriginState(newFlightPlan)]));
    }
  }, []);

  useEffect(() => {
    const statesToCheck = [
      "PREFLIGHT",
      "EN_ROUTE_NOMINAL",
      "EN_ROUTE_OFF_NOMINAL",
      "EN_ROUTE_SEVERELY_OFF_NOMINAL",
      "POSTFLIGHT",
      "COMPLETE"
    ];
    if (statesToCheck.includes(flight.state)) {
      dispatch(fetchFlightStateMachine(flight.uuid));
      dispatch(fetchCurrentFlightMachineState(flight.uuid));
    }
  }, [dispatch, flight.state]);

  const formatForXStateViz = (flightStateMachine) => {
    const formattedMachine = JSON.stringify(flightStateMachine, null, 2);
    const machineCode = `import { createMachine } from 'xstate';\nconst fetchMachine = createMachine(${formattedMachine});`;
    return machineCode;
  };

  // TODO: extract into utils
  const getFlightStatePillClass = state => {
    if (state === "DRAFT") {
      return 'flight-state-pill-draft';
    } else if (state === "RESERVED" || state === "RESERVED_FOR_AIRCRAFT") {
      return 'flight-state-pill-reserved';
    } else if (state === "COMPLETE" || state === "CANCELLED") {
      return 'flight-state-pill-past';
    } else {
      return 'flight-state-pill-current';
    }
  };
  const updateAircraftRadio = isDetach => {
    if (abortPreflightLoadingIndicator) {
      return;
    }
    const val = !isDetach && aircraftUuid !== '' ? aircraftUuid : '';
    setAttachLoadingIndicator(true);
    axios.post(
        `${RESERVATION_LIFECYCLE_API_URL}/lifecycle/attach-aircraft`,
        { aircraft_uuid: val, flight_uuid: flightUuid },
        { ...Session.requestConfig(), cancelToken: cancelToken.token }
    ).then(response => {
      setError(false);
      setReloadFlight(true);
    }).catch(error => {
      setError(true);
      setShowError(getErrorMessage(error));
    }).finally(() => {
      setAttachLoadingIndicator(false);
    });
  };

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 3000);
  };

  const invokeDeleteFlight = () => {
    if (deleteLoadingIndicator) {
      return;
    }
    setDeleteLoadingIndicator(true);
    axios.delete(`${FLIGHT_PLANNING_API_URL_V2}/flights/${flightUuid}`, {
      ...Session.requestConfig(),
      cancelToken: cancelToken.token
    }).then(response => {
      setError(false);
      dispatch(toggleFlightListPanel());
    }).catch(error => {
      setError(true);
      setShowError(getErrorMessage(error));
    }).finally(() => setDeleteLoadingIndicator(false));
  };

  const invokeCancelReservation = () => {
    if (cancelLoadingIndicator) {
      return;
    }
    setCancelLoadingIndicator(true);
    axios.post(
        `${RESERVATION_LIFECYCLE_API_URL}/lifecycle/cancel-reservation/${flight.uuid}`,
        {},
        { ...Session.requestConfig(), cancelToken: cancelToken.token }
    ).then(response => {
      setError(false);
      setReloadFlight(true);
      setResourceSchedule(null);
    }).catch(error => {
      setError(true);
      setShowError(getErrorMessage(error));
    }).finally(() => setCancelLoadingIndicator(false));
  };

  const invokeMoveToPreFLight = () => {
    if (preflightLoadingIndicator) {
      return;
    }
    setPreflightLoadingIndicator(true);
    axios.post(
        `${RESERVATION_LIFECYCLE_API_URL}/lifecycle/notify-preflight/${flight.uuid}`,
        {},
        { ...Session.requestConfig(), cancelToken: cancelToken.token }
    ).then(response => {
      setError(false);
      setReloadFlight(true);
    }).catch(error => {
      setReloadFlight(true);
      setError(true);
      setShowError(getErrorMessage(error));
    }).finally(() => {
      handleConfirmPopupClose();
    });
  };

  const invokeAbort = () => {
    if (abortPreflightLoadingIndicator) {
      return;
    }
    setAbortPreflightLoadingIndicator(true);
    axios.post(
        `${RESERVATION_LIFECYCLE_API_URL}/lifecycle/abort-preflight/${flightUuid}`,
        {},
        { ...Session.requestConfig(), cancelToken: cancelToken.token }
    ).then(response => {
      setError(false);
      setReloadFlight(true);
    }).catch(error => {
      setReloadFlight(true);
      setError(true);
      setShowError(getErrorMessage(error));
    }).finally(() => {
      setAbortPreflightLoadingIndicator(false);
    });
  };

  const invokeComplete = () => {
    if (loadingIndicator) {
      return;
    }
    setLoadingIndicator(true);
    axios.post(
        `${RESERVATION_LIFECYCLE_API_URL}/lifecycle/debrief/${flightUuid}`,
        {},
        { ...Session.requestConfig(), cancelToken: cancelToken.token }
    ).then(response => {
      if (!response.data) {
        setError(true);
        setShowError(getErrorMessage(error));
      } else {
        setError(false);
        setFlightDebrief(response.data);
        setReloadFlight(true);
      }
    }).catch(error => {
      setReloadFlight(true);
      setError(true);
      setShowError(getErrorMessage(error));
    }).finally(() => setLoadingIndicator(false));
  };

  const toggleBlock = (value, legNum) => {
    // Commenting out for now..need to add updated logic later

    // if (resourceSchedule.state && resourceSchedule.state !== 'COMPLETE') {
    //   const newResourceSchedule = resourceSchedule;
    //   const newLeg = newResourceSchedule.legs[legNum];
    //   if (!checkInactiveg2a(value, newLeg.site_uuid) && !checkOtherSchedules(value, newLeg, legNum)) {
    //     const resource_block_indices_set = new Set(newLeg.resource_element_indexes);
    //     if (resource_block_indices_set.has(value)) {
    //       resource_block_indices_set.delete(value);
    //     } else {
    //       resource_block_indices_set.add(value);
    //     }
    //     newLeg.resource_element_indexes = [...resource_block_indices_set];
    //     newResourceSchedule.legs[legNum] = newLeg;
    //     setResourceSchedule({ ...newResourceSchedule });
    //   }
    // }
  };

  const getBlockIdInfo = (blockNumber, legNumber) => {
    const legs = [];
    for (const flightPlan of resourceScheduleTimeRange[legNumber]) {
      let idx = 0;
      for (const leg of flightPlan.legs) {
        if (leg.resource_element_indexes.includes(blockNumber)) {
          legs.push(leg);
        }
        idx = idx + 1;
      }
    }
    return legs;
  };

  const handleSubmit = values => {
    const resourceScheduleRequest = resourceSchedule;
    if (resourceScheduleRequest.aircraft_radio_uuid === null) {
      resourceScheduleRequest.aircraft_radio_uuid = '';
    }
    let idx = 0;
    for (const leg of resourceScheduleRequest.legs) {
      leg.resource_element_indexes = values['activeResourceGrids' + idx.toString()];
      idx += 1;
    }
    setOverrideLoadingIndicator(true);
    axios.put(
        `${FLIGHT_PLANNING_API_URL_V2}/resourceschedules/${resourceScheduleRequest.uuid}`,
        resourceScheduleRequest,
        { ...Session.requestConfig(), cancelToken: cancelToken.token }
    ).then(response => {
      if (response.data) {
        setResourceBlockError(false);
      }
    }).catch(error => {
      setResourceBlockError(true);
      setShowResourceBlockError(error?.response?.data?.message | error.message);
    }).finally(() => {
      setOverrideLoadingIndicator(false);
    });
  };

  const flightVertexToDisplayString = (name, icao_code, location) => {
    if (icao_code) {
      return `${icao_code} (${name})`;
    } else if (name) {
      return name;
    } else {
      return (
          <>
            {location.coordinates[1]}&deg;, {location.coordinates[0]}&deg;
          </>
      );
    }
  };

  const redirectToRoute = () => {
    const routeDataRecord = convertToRouteDataRecord(route);
    dispatch(setSelectedRoute({
      selectedRoute: routeDataRecord,
      parentComponent: FLIGHT_DETAIL_COMPONENT,
      disableRoute: !!routeDataRecord.flightCount
    }));
  };

  const handleClose = () => {
    dispatch(toggleFlightListPanel());
    dispatch(clearFlightDetails());
  };

  const handleConfirmPopupShow = () => setShowConfirmPopup(true);
  const handleConfirmPopupClose = () => {
    setShowConfirmPopup(false);
    setPreflightLoadingIndicator(false);
  };

  return (
      <div className="panel panel-l">
        <div className="panel-header">
          <h2>Flight Details</h2>
          <HelpToolTip content="Manage a Flight through its lifecycle before, during, and after flying. Specify which
          Aircraft will fly the Flight. Enter/leave the Safety-Critical phase and initiate pre-flight radio handshakes." />
          <a className="close-button" onClick={handleClose}><i className="fa-solid fa-circle-xmark"/></a>
        </div>
        <div className="panel-body">

            <Row>
              <h3>Flight</h3>

              <Row>
                <div className={"label-with-value"}>
                  <label>UUID</label>
                  <span>{flight.uuid}</span>
                </div>
                <div className={`label-with-value ${flightStateCssClass}`}>
                  <label>Status</label>
                  <span className={`flight-state-pill ${getFlightStatePillClass(flight.state)}`}>
                  {flight.state}
                    </span>
                  <Button
                        variant="link"
                        onClick={() => {
                          setReloadFlight(true);
                          dispatch(fetchCurrentFlightMachineState(flight.uuid));
                        }}
                    >
                      <i className="fas fa-refresh"/>
                    </Button>
                </div>

                <Form noValidate>
                  <Form.Group data-testid="aircraft-radio-id-input">
                    <Form.Label>Aircraft</Form.Label>
                    {flight.state === 'RESERVED' && (
                      <Form.Control
                        as="select"
                        value={aircraftUuid || ''}
                        className="input-sm"
                        onChange={e => setAircraftUuid(e.target.value || '')}
                      >
                        <option value="">Select</option>
                        {aircrafts?.aircrafts?.map((aircraft) => (
                            <option key={aircraft.uuid} value={aircraft.uuid}>
                              {aircraft.tailNumber}
                            </option>
                        ))}
                    </Form.Control>
                    )}
                    {flight.state !== 'RESERVED' && (
                        <div className="mw-100">{aircrafts?.aircrafts?.find(aircraft => aircraft.uuid === aircraftUuid)?.tailNumber}</div>
                    )}
                  </Form.Group>
                  {flight.aircraft_radio_state && (
                      <Form.Group as={Col} className="my-0">
                        <Form.Label className="mb-0">Aircraft Radio State</Form.Label>
                        <div className="mw-100">{flight.aircraft_radio_state}</div>
                      </Form.Group>
                  )}
                  {flight.aircraft_radio_active_site_radio_uuid && (
                      <Form.Group as={Col} className="my-0">
                        <Form.Label className="mb-0">Active Site Radio UUID</Form.Label>
                        <div className="mw-100">
                          {flight.aircraft_radio_active_site_radio_uuid}
                        </div>
                      </Form.Group>
                  )}
                </Form>
              </Row>
              <Row>
                <Col md={6} className="stacked-buttons">
                  {flight.state === 'PREFLIGHT' && (
                      <Button variant="outline-success" onClick={invokeAbort}>
                        {abortPreflightLoadingIndicator && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        &nbsp;
                        <i className="fa-solid fa-backward"/>
                        Abort Preflight
                      </Button>
                  )}
                  {flight.state === 'RESERVED_FOR_AIRCRAFT' && (
                      <Button
                          variant="outline-success"
                          className="ms-2"
                          onClick={() => updateAircraftRadio(true)}
                      >
                        {attachLoadingIndicator && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        &nbsp;
                        <i className="fa-solid fa-backward"/>
                        Detach Aircraft
                      </Button>
                  )}
                  {flight.state === 'DRAFT' &&
                      !flight.flight_plan_uuid &&
                      !flight.reservation_uuid && (
                          <Button variant="outline-danger" onClick={invokeDeleteFlight}>
                            {deleteLoadingIndicator && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            Delete Flight <i className="fa-solid fa-trash-alt"/>
                          </Button>
                  )}
                  {(flight.state === 'RESERVED') &&
                      reservation && (
                          <Button variant="outline-secondary" onClick={invokeCancelReservation}>
                            {cancelLoadingIndicator && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                            &nbsp;
                            <i className="fa-solid fa-backward"/>
                            Cancel Reservation
                          </Button>
                  )}
                </Col>
                <Col md={6} className={"stacked-buttons"}>
                  {flight.state === 'RESERVED' && (
                      <Button
                          variant="success"
                          className="ms-2"
                          onClick={() => updateAircraftRadio(false)}
                          disabled={!aircraftUuid || aircraftUuid === ''}
                      >
                        {attachLoadingIndicator && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        Attach Aircraft
                        <i className="fa-solid fa-forward"/>
                      </Button>
                  )}
                  {flight.state === 'RESERVED_FOR_AIRCRAFT' && (
                      <Button variant="current" onClick={handleConfirmPopupShow}>
                        <i className="fa-solid fa-triangle-exclamation"/>
                        Preflight
                        <i className="fa-solid fa-forward"/>
                      </Button>
                  )}
                  {flight.state === 'PREFLIGHT' && (
                      <Button variant="primary" disabled={true}>
                        {loadingIndicator && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        Awaiting ready radios
                        <i className="fa-solid fa-pause"/>
                      </Button>
                  )}
                  {flight.state === 'POSTFLIGHT' && (
                      <Button variant="primary" onClick={invokeComplete}>
                        {loadingIndicator && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                        Complete
                        <i className="fa-solid fa-stop"/>
                      </Button>
                  )}
                  {error && <p className="error-message">{showError}</p>}
                </Col>
                <ConfirmationPopup
                  message="This action will move the flight into a safety critical state."
                  message_2 = "Do you want to continue with moving the flight into preflight?"
                  onConfirm={invokeMoveToPreFLight}
                  show={showConfirmPopup}
                  handleClose={handleConfirmPopupClose}
                  isLoading={preflightLoadingIndicator}
                  confirmButtonVariant={"current"}
                />
              </Row>
            </Row>
            {flight.state === 'COMPLETE' && flightDebrief && (
                <Row>
                  <Col md={12}>
                    <h3>Flight Debrief (DO-377A)</h3>
                    <Form noValidate>
                      <Form.Group as={Col} className="my-0">
                        <Form.Label className="mb-0">Availability</Form.Label>
                        <div className="mw-100">
                          {flightDebrief.availability_metric} (
                          {flightDebrief.availability_pass ? 'PASS' : 'FAIL'})
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} className="my-0">
                        <Form.Label className="mb-0">Integrity</Form.Label>
                        <div>
                          {flightDebrief.integrity_metric} (
                          {flightDebrief.integrity_pass ? 'PASS' : 'FAIL'})
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} className="my-0">
                        <Form.Label className="mb-0">Continuity</Form.Label>
                        <div>
                          {flightDebrief.continuity_metric} (
                          {flightDebrief.continuity_pass ? 'PASS' : 'FAIL'})
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} className="my-0">
                        <Form.Label className="mb-0">Latency</Form.Label>
                        <div>
                          {flightDebrief.latency_metric} (
                          {flightDebrief.layency_pass ? 'PASS' : 'FAIL'})
                        </div>
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col md={12} className="text-end my-4">
                    <DownloadLink
                        tagName={'button'}
                        className="btn btn-outline"
                        style={{}}
                        label="Download Flight Debrief"
                        filename={flightUuid + '_flightDebrief.json'}
                        exportFile={() => JSON.stringify(flightDebrief, null, 2)}
                    />
                  </Col>
                </Row>
            )}
            {(flight.state === 'PREFLIGHT' ||
                flight.state === 'EN_ROUTE_NOMINAL' || flight.state === 'EN_ROUTE_OFF_NOMINAL' ||
                flight.state === 'EN_ROUTE_SEVERELY_OFF_NOMINAL') && Object.keys(flightFrrcState).length > 0 && (
                <div>
                <Row className="mt-3">
                  <Col md={6}>
                    <h3>En-Route Status</h3>
                  </Col>
                  <Col md={6}>
                    <Button
                          variant="link"
                          onClick={() => {
                            setReloadFlight(true);
                            dispatch(fetchCurrentFlightMachineState(flight.uuid));
                          }}
                      >
                        <i className="fas fa-refresh"/>
                      </Button>
                  </Col>
                  </Row>
                  <Row>
                  <div className={"label-with-value-frrc"}>
                    <label>Last Reported ARS State</label>
                    <span>{flightFrrcState.last_reported_state_type}</span>
                  </div>
                  <div className={"label-with-value-frrc"}>
                    <label>Reported at</label>
                    <span>{dateToString(new Date(flightFrrcState.last_reported_time_utc))}  (GMT)</span>
                  </div>
                </Row>
                </div>
            )}
            <Row>
              {!flight.reservation_uuid && <p>This flight does not have a Reservation.</p>}
              {isFetching &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
              />}
              {flight.reservation_uuid && isFetched && flightPath && (
                <>
                  <h3 className={"mb-3"}>Reservation Timeline<HelpToolTip content="This diagram shows which AURA site
             will provide radio coverage for each Leg of the Flight. The time range of each Leg includes the 'buffer' ETD
             uncertainty time specified in the Route.\n
             Expanding a Leg shows the estimated time range(s) in which each Terminus/Waypoint will be reached by the aircraft."/>
                  </h3>
                  <TimelineDiagram reservation={reservation} parentComponent={FLIGHT_DETAIL_COMPONENT}
                                   flightPath={flightPath} estimatedDepartureTime={estimatedTimeDepartureUTC}/>
                </>
              )}
            </Row>
            {isAura(identity) && resourceSchedule && (
                <Accordion flush className={'resource-schedule-accordion'}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h3>Radio Resource Schedule</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row className="mt-3">
                        <FinalForm
                            onSubmit={handleSubmit}
                            initialValues={() => {
                              const initVals = {};
                              let idx = 0;
                              for (const leg of resourceSchedule?.legs) {
                                const blockName = 'activeResourceGrids' + idx.toString();
                                initVals[blockName] = leg.resource_element_indexes;
                                idx += 1;
                              }
                              return initVals;
                            }}
                            validate={() => true}
                        >
                          {({ form, handleSubmit }) => {
                            return (
                              <>
                                <div>
                                  {resourceSchedule?.legs?.map((leg, legNumber) => {
                                    const groundToAirAllocations = leg.resource_allocations.filter(
                                      allocation => allocation.direction === "GROUND_TO_AIR"
                                    );

                                    const airToGroundAllocations = leg.resource_allocations.filter(
                                      allocation => allocation.direction === "AIR_TO_GROUND"
                                    );

                                    leg.control_channel_allocations.forEach(control_channel_allocation => {
                                      const allocation = {
                                        direction: control_channel_allocation.direction,
                                        block_indexes: [control_channel_allocation.block_index],
                                        payload_type: "CONTROL"
                                      };

                                      if (allocation.direction === "AIR_TO_GROUND") {
                                        airToGroundAllocations.push(allocation);
                                      } else {
                                        groundToAirAllocations.push(allocation);
                                      }
                                    });

                                    return (
                                        <Row key={legNumber}>
                                          <h4>
                                            Leg {legNumber + 1}: {`${reservation?.legs[legNumber].site_name}  (${reservation?.legs[legNumber].site_callsign})`}
                                            <HelpToolTip content="The grid below shows the radio resources which have been allocated to this flight,
                                            to concurrent flights, etc. Each grid represents a single 80ms frame. Each element of the grid represents a single
                                            Resource Element, i.e. one subchannel for one timeslot. Timeslots are on the horizontal axis (left-to-right),
                                            frequency subchannels on the vertical axis (top-to-bottom)." />
                                          </h4>
                                            <div className={"my-2"}>
                                              {new Date(leg.time_range_utc.start).toUTCString()}{' '}
                                              to{' '}
                                              {new Date(leg.time_range_utc.end).toUTCString()}
                                            </div>

                                          <Col md={12}>
                                            <Form.Group
                                                className="input-group input-group-100 mb-0 flight-grid"
                                            >
                                              <div>
                                                <h4>Air to Ground</h4>
                                                <div className={'resource-grid-container'}>
                                                  <ResourceGrid
                                                    resourceGridByUuid={resourceGridByUuid}
                                                    legNumber={legNumber}
                                                    isFlightDetails={true}
                                                    resource_grid_uuid={leg.resource_grid_uuid}
                                                    toggleBlock={toggleBlock}
                                                    flightResourceElementIndexes={airToGroundAllocations}
                                                    siteResourceElementConfig={siteResourceElementConfig.a2gResourceBlockConfig}
                                                    getBlockIdInfo={getBlockIdInfo}
                                                    is_g2a={false}
                                                  />
                                              </div>

                                            </div>
                                            <div>
                                              <h4>Ground to Air</h4>
                                                <div className={'resource-grid-container'}>
                                                  <ResourceGrid
                                                    resourceGridByUuid={resourceGridByUuid}
                                                    legNumber={legNumber}
                                                    isFlightDetails={true}
                                                    resource_grid_uuid={leg.resource_grid_uuid}
                                                    toggleBlock={toggleBlock}
                                                    flightResourceElementIndexes={groundToAirAllocations}
                                                    siteResourceElementConfig={siteResourceElementConfig.g2aResourceBlockConfig}
                                                    getBlockIdInfo={getBlockIdInfo}
                                                    is_g2a={true}
                                                  />
                                              </div>
                                            </div>
                                            </Form.Group>
                                          </Col>
                                          <Row>
                                            <Col md={12}>
                                            <Form.Group as={Col}>
                                              <ResourceGridLegend showFlightLegendBlock={true}/>
                                            </Form.Group>
                                            </Col>
                                          </Row>
                                        </Row>
                                    );
                                  })}

                                  {/* Commenting out the manual override button for now..will need to be restored later */}

                                  {/* {resourceSchedule.state &&
                                      resourceSchedule.state !== 'COMPLETE' && (
                                          <Col md={12} className="text-end mt-3">
                                            <Button
                                                variant="danger"
                                                className="ms-1"
                                                onClick={() => handleSubmit()}
                                            >{overrideLoadingIndicator && (
                                              <Spinner
                                                  as="span"
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                              />
                                            )}
                                              Manual Override <i className="fas fa-save"/>
                                            </Button>
                                          </Col>
                                  )} */}
                                  {resourceBlockError && (
                                      <p className="error-message">{showResourceBlockError}</p>
                                  )}
                                </div>
                              </>
                            );
                          }}
                        </FinalForm>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
            )}
            {isAura(identity) && Object.keys(flightStateMachine).length > 0 && (
                <Row className="mt-3">
                  <Accordion flush className="state-machine-schedule-accordion w-100">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-primary text-white">
                        <h3>FRRC State Machine</h3>
                      </Accordion.Header>
                      <Accordion.Body>
                        <textarea
                          ref={textAreaRef}
                          value={formatForXStateViz(flightStateMachine)}
                          readOnly
                          className="form-control mb-2 json-content"
                          style={{ height: '10rem' }}
                        />
                        <button className="btn btn-secondary" onClick={copyToClipboard}>{copySuccess ? "Copied!" : "Copy"}</button>
                        <button className="btn btn-secondary" onClick={() => window.open(STATE_MACHINE_VISUALIZER, '_blank')}>Visualize</button>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Row>
            )}

            <Row>
              <h3>Route</h3>
              <Col md={12} className={"text-end"}>
                <Button variant="icon"
                        className={'flight-list-icon'}
                        size="sm"
                        title="Flight List"
                        onClick={() => dispatch(toggleFlightListPanel())}
                />
                {flight.state === 'DRAFT' && (
                  <Button variant="icon"
                          className={'delete-icon'}
                          size="sm"
                          title="Delete Flight"
                          onClick={invokeDeleteFlight}
                  />
                )}
                {route && (
                  <Button variant="icon"
                          className={'view-route-icon'}
                          size="sm"
                          title="View Route"
                          onClick={redirectToRoute}
                  />
                )}
              </Col>
              <Col md={12}>
                {!flight.flight_plan_uuid && <p>This flight does not have a flight plan.</p>}
                {flight.flight_plan_uuid && (
                    <Form noValidate>
                      <SubwayMap
                          departure={flightPlan.departure}
                          destination={flightPlan.destination}
                          alternates={flightPlan.alternates}
                          waypoints={flightPlan.waypoints}
                          flightPath={null}
                          calculateGraphEdges={calculateGraphEdges}
                      />
                      <div className={"label-with-value"}>
                        <label>Route Name</label>
                        <div>
                          {route?.name}
                        </div>
                      </div>
                      <div className={"label-with-value"}>
                        <label>Departure</label>
                        <div>
                          {flightVertexToDisplayString(
                            flightPlan?.departure?.name,
                            flightPlan?.departure?.airport_code,
                            flightPlan.departure.location
                          )}{' '}
                            at {dateToString(new Date(flight?.estimated_time_departure_utc))} (GMT)
                        </div>
                      </div>
                      <div className={"label-with-value"}>
                        <label>Destination</label>
                        <div>
                          {flightVertexToDisplayString(
                            flightPlan?.destination?.name,
                            flightPlan?.destination?.airport_code,
                            flightPlan.destination.location
                          )}
                        </div>
                      </div>

                      {flightPlan.waypoints.map((wp, idx) => (
                        <div className={"label-with-value"}>
                          <label>Waypoint</label>
                          <div className={"mw-100"}>
                            {flightVertexToDisplayString(
                              wp.name,
                              wp.icao_description,
                              wp.location
                            )}
                          </div>
                        </div>
                      ))}

                      {flightPlan.alternates.map((alt, idx) => (
                        <div className={"label-with-value"}>
                          <label>Alternate Destination</label>
                          <div className={"mw-100"}>
                            {flightVertexToDisplayString(
                              alt.name,
                              alt.airport_code,
                              alt.location
                            )}
                          </div>
                        </div>

                      ))}
                    </Form>
                )}
              </Col>
            </Row>
        </div>
      </div>
  );
};

export default FlightDetails;
