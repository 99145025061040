import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import FlightFilters from './FlightFilters';
import { dateToString, round } from '../../../utils/units';
// import renderPrintableView from './FlightPlanPrintView';
import { useDispatch, useSelector } from 'react-redux';
import getFlights, { clearFlightPagination, setHighlightedFlightIndex } from '../store/actions/flights.action';
import { setFlightDetails } from '../store/actions/flight.details.action';
import { toggleFlightListPanel } from '../store/actions/panels.action';
import { PaginationRecord } from '../store/records/flights.record';
import './FlightList.css';
import { setHighlightedRoute } from '../store/actions/map/map.action';
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";

// const printIframe = id => {
//   const iframe = document.frames ? document.frames[id] : document.getElementById(id);
//   const iframeWindow = iframe.contentWindow || iframe;
//
//   iframe.focus();
//   iframeWindow.print();
//
//   return false;
// };

const FlightList = () => {
  const dispatch = useDispatch();

  const identity = useSelector(({ identity }) => identity.identity);
  const sitesByUuid = useSelector(({ sites }) => sites.sitesByUuid);
  const flights = useSelector(({ flights }) => flights);

  // flightPlans is part of state so it can be  added later from flights if needed
  const { isFetching, flightBundles, paginationDetails, highlightedFlightIndex, isFetched } = flights;
  const retrieveFlights = async (filter, paginationRequest = true) => {
    const newFilters = { ...filter };
    const pagination = paginationRequest ? { ...paginationDetails, ...filter } : new PaginationRecord({});
    dispatch(getFlights(sitesByUuid, newFilters, pagination));
  };
  const terminusToDisplayString = terminus => {
    if (terminus.airport_code) {
      return terminus.airport_code;
    } else if (terminus.name) {
      return terminus.name;
    } else {
      return `(${round(terminus.location.coordinates[1], 3)}${String.fromCharCode(176)},${round(
        terminus.location.coordinates[0],
        3
      )}${String.fromCharCode(176)})`;
    }
  };

  const getTerminiDisplayString = flightPlan => {
    if (!flightPlan) {
      return '-';
    } else {
      return `${terminusToDisplayString(flightPlan.departure)}${String.fromCharCode(
        8594
      )}${terminusToDisplayString(flightPlan.destination)}`;
    }
  };

  const getFlightStatePillClass = state => {
    if (state === "DRAFT") {
      return 'flight-state-pill-draft';
    } else if (state === "RESERVED" || state === "RESERVED_FOR_AIRCRAFT") {
      return 'flight-state-pill-reserved';
    } else if (state === "COMPLETE" || state === "CANCELLED") {
      return 'flight-state-pill-past';
    } else {
      return 'flight-state-pill-current';
    }
  };

  const handleClose = () => {
    dispatch(toggleFlightListPanel());
    dispatch(clearFlightPagination());
  };

  const setHighlightRow = idx => {
    dispatch(setHighlightedFlightIndex(idx));
  };

  return (
    <>
      <div className="panel panel-s">
        <div className="panel-header">
          <h2>Flights</h2>
          <HelpToolTip content="A Flight is a specific journey taking place at a specific time by a specific Aircraft along a predetermined Route." />
          <a className="close-button" onClick={handleClose}><i className="fa-solid fa-circle-xmark"/></a>
        </div>
        <div className="panel-body">
          <FlightFilters
            identity={identity}
            onChangeCallback={(filter) => retrieveFlights(filter, false)}
          />

          {isFetching && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}

          <>
            {flightBundles.map((row, index) => {
              return (
                <Row key={`flight_${index}`} className="stripe"
                  onClick={() => {
                    if (highlightedFlightIndex === index) {
                      setHighlightRow();
                    } else {
                      setHighlightRow(index);
                    }
                  }}
                  onMouseEnter={() => {
                    dispatch(setHighlightedRoute(index));
                  }}
                  onMouseLeave={() => {
                    dispatch(setHighlightedRoute());
                  }}>
                <Col xs={9}>
                  <div>
                    <h4>{getTerminiDisplayString(row.route.flight_plan)}</h4>
                  </div>
                  <div>
                    <span className="word-spacing">{dateToString(new Date(row.flight?.estimated_time_departure_utc))}</span>
                  </div>
                  <div>
                    <span>{row.route?.name}</span>
                  </div>
                  <div>
                    <span>{row?.aircraft_tail_number || "-"}</span>
                    <span>{row.flight?.organization}</span>
                  </div>
                </Col>
                <Col xs={3} className="text-end">
                  <div>
                    {/* TODO: move this into FlightDetails */}
                    {/* <iframe */}
                    {/*  id="receipt" */}
                    {/*  srcDoc={renderPrintableView(row.flight_plan, row.reservation)} */}
                    {/*  style={{ display: 'none' }} */}
                    {/*  title="Receipt" */}
                    {/* /> */}
                    {/* <Button */}
                    {/*  variant="icon" */}
                    {/*  size="sm" */}
                    {/*  className={'print-icon'} */}
                    {/*  disabled={!(row.reservation && row.flight_plan)} */}
                    {/*  onClick={() => printIframe('receipt')} */}
                    {/* > */}
                    {/* </Button> */}

                    <Button variant="icon"
                            className={'flight-details-icon'}
                            size="sm"
                            title="Flight Details"
                            onClick={(e) => {
                              e.stopPropagation();
                              const data = {};
                              data.reservation = row.reservation;
                              data.flightPlan = row.route.flight_plan;
                              data.flightUuid = row.flight.uuid;
                              data.estimatedTimeDepartureUTC = new Date(row.flight.estimated_time_departure_utc);
                              data.route = row.route;
                              data.highlightRow = index;
                              dispatch(setFlightDetails(data));
                            }}
                    >
                    </Button>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="mt-1">
                    <span className={"flight-state-pill " + getFlightStatePillClass(row.flight?.state)}>
                      {row.flight?.state}
                    </span>
                    {row.reservation &&
                      <span className={"flight-state-pill mini-site-icon " + getFlightStatePillClass(row.flight?.state)}>
                        {row.reservation.legs.map(leg => leg.site_callsign).join(', ')}
                      </span>
                    }
                  </div>
                </Col>
              </Row>
              );
            })}
          </>

          {isFetched && flightBundles?.length === 0 &&
            <strong>No Flights found</strong>
          }

          {flightBundles?.length > 0 && (paginationDetails.firstUuid || paginationDetails.lastUuid) && (
            <div className="row mb-0">
              <div className="aura-pagination mt-2">
                <Button
                  variant="primary"
                  onClick={() => retrieveFlights({ page: paginationDetails.page - 1, lastUuid: null, firstUuid: paginationDetails.firstUuid })
                  }
                  disabled={paginationDetails.firstUuid === null}
                >
                  <i className="fas fa-angle-left"/>
                </Button>
                <span>
                {paginationDetails.page * paginationDetails.perPage + 1}{' to '}
                  {flightBundles?.length + paginationDetails.perPage * paginationDetails.page}
                </span>
                <Button
                  variant="primary"
                  onClick={() => retrieveFlights({ page: paginationDetails.page + 1, lastUuid: paginationDetails.lastUuid, firstUuid: null })
                  }
                  disabled={paginationDetails.lastUuid === null}
                >
                  <i className="fas fa-angle-right"/>
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(FlightList);
