import React, { useMemo } from "react";
import './ResourceGrid.css';
import '../../styles.css';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Field } from "react-final-form";

const BlockToolTip = ({ legNumber, blockNumber, getBlockIdInfo }) => {
  const legs = useMemo(() => {
    return getBlockIdInfo(blockNumber, legNumber);
  }, [legNumber, blockNumber, getBlockIdInfo]);
  if (legs.length) {
    return legs.map((leg, idx) => (
      <div>Reserved from <strong>{new Date(leg.time_range_utc.start).toUTCString()}</strong> to&nbsp;
        <strong>{new Date(leg.time_range_utc.end).toUTCString()}</strong></div>

    ));
  }
  return null;
};

const tooltip = (legNumber, blockNumber, getBlockIdInfo) => {
  return (
    <Tooltip id={`tooltip${blockNumber}`} className={'tooltip'}>
      <BlockToolTip legNumber={legNumber} blockNumber={blockNumber} getBlockIdInfo={getBlockIdInfo} />
    </Tooltip>

  );
};

const ResourceGrid = ({
  siteResourceElementConfig,
  flightResourceElementIndexes,
  resourceGridByUuid,
  legNumber,
  toggleBlock,
  getBlockIdInfo,
  isFlightDetails,
  resource_grid_uuid,
  is_g2a
}) => {
  const resourceGrid = resourceGridByUuid[resource_grid_uuid];
  const getSubcarrierIndexes = () => {
    return [...Array(resourceGrid.num_subcarriers).keys()];
  };

  const getTimeSlotIndexes = () => {
    return [...Array(resourceGrid.num_time_slots).keys()];
  };

  const resourceBlockIndex = (numTimeSlots, subcarrier, timeslot) => {
    return (subcarrier * numTimeSlots) + timeslot;
  };

  const findPayloadTypeAndDirection = (timeslotIndex, resourceElementIndexes) => {
    const allocation = resourceElementIndexes.find(a => a.block_indexes.includes(timeslotIndex));
    return allocation ? [allocation.payload_type, allocation.direction] : [null, null];
  };

  return (
        <Table className={"resource-grid " + (isFlightDetails ? "flight-resource-grid" : "site-resource-grid")}>
            <thead>
              <tr>
                <th colSpan={ resourceGrid.num_time_slots + 2 }>Timeslot &rarr;</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                { getTimeSlotIndexes().map((slotIndex) => (
                  <th>{ slotIndex }</th>
                )) }
              </tr>
            </thead>
            <tbody>
              {resource_grid_uuid && getSubcarrierIndexes().map((subcarrierIndex) => (
                <tr key={subcarrierIndex}>
                  {subcarrierIndex === 0 &&
                    <th rowSpan={resourceGrid.num_subcarriers} className={"vertical-th"}>
                      Subcarrier (low &rarr; high frequency)
                    </th>
                  }
                  <th>{subcarrierIndex}</th>
                  {getTimeSlotIndexes().map((timeslotIndex) => {
                    return (
                          <Field
                              key={`${subcarrierIndex}-${timeslotIndex}`}
                              type="checkbox"
                              name={"activeResourceGrids" + legNumber.toString()}
                              value={resourceBlockIndex(resourceGrid.num_time_slots, subcarrierIndex, timeslotIndex)}
                              label={timeslotIndex}>
                              {({ input, label }) => {
                                let className = "";
                                const value = siteResourceElementConfig[input.value];
                                switch (value) {
                                  case 0:
                                    className = "selected-0";
                                    break;
                                  case 1:
                                    className = isFlightDetails ? "selected-1 non-highlighted" : "selected-1";
                                    break;
                                  case 2:
                                    className = isFlightDetails ? "selected-2 non-highlighted" : "selected-2";
                                    break;
                                  case 3:
                                    className = "not-selected";
                                    break;
                                  default:
                                    className = "not-selected";
                                    break;
                                }

                                // check if block is allocated
                                if (isFlightDetails) {
                                  const payloadTypeAndDirection = findPayloadTypeAndDirection(input.value, flightResourceElementIndexes);
                                  if (payloadTypeAndDirection[0] === 'VOICE') {
                                    className = payloadTypeAndDirection[1] === "GROUND_TO_AIR" ? "allocated-voice-g2a" : "allocated-voice-a2g";
                                  } else if (payloadTypeAndDirection[0] === 'C2_DATA') {
                                    className = payloadTypeAndDirection[1] === "GROUND_TO_AIR" ? "allocated-c2-data-g2a" : "allocated-c2-data-a2g";
                                  } else if (payloadTypeAndDirection[0] === 'CONTROL') {
                                    className = "selected-2";
                                  }
                                }

                                return (
                                      <OverlayTrigger placement="left" overlay={className === "blocked" ? tooltip(legNumber, input.value, getBlockIdInfo) : <></>}>
                                          <td className={className}
                                              title={`RB Index: ${input.value}, Center Freq: ${(is_g2a ? resourceGrid.g2a_min_frequency_hz : resourceGrid.a2g_min_frequency_hz) + resourceGrid.subchannel_width_hz * (subcarrierIndex + 0.5)} Hz`}
                                              onClick={() => toggleBlock(input.value, legNumber)}>
                                          </td>
                                      </OverlayTrigger>
                                );
                              }}
                          </Field>
                    );
                  })}
                </tr>
              ))}
            </tbody>
        </Table>
  );
};

export default ResourceGrid;
