import { createSlice } from "@reduxjs/toolkit";
import { ResourceScheduleRecord } from "../records/resourceschedules.record";

const initialState = new ResourceScheduleRecord({});

const siteResourceSchedules = createSlice({
  name: 'siteResourceSchedules',
  initialState,
  reducers: {
    isFetching: (siteResourceSchedulesState) => {
      return siteResourceSchedulesState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (siteResourceSchedulesState, action) => {
      return siteResourceSchedulesState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        resourceSchedules: action.payload.records
      });
    },
    isError: (siteResourceSchedulesState) => {
      return siteResourceSchedulesState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (siteResourceSchedulesState) => {
      return new ResourceScheduleRecord({});
    }
  }
});

export default siteResourceSchedules;
