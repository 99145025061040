import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Session, { AURA_ID_TOKEN, AURA_ACCESS_TOKEN, AURA_EXPIRES_AT, ORG_ID } from "../../utils/session";
import history from "../../utils/history";
import auth from "../../../src/utils/auth";

const EXPIRY_TIMEOUT_UPDATE_MS = 1000;
const EXPIRY_LOGOUT_COUNTDOWN_SEC = 60;

const SessionExpiringModal = () => {
  const [show, setShow] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [logoutCounter, setLogoutCounter] = useState(EXPIRY_LOGOUT_COUNTDOWN_SEC);

  const {
    isAuthenticated,
    getIdTokenClaims,
    getAccessTokenSilently,
    logout
  } = useAuth0();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (Session.isSessionCounterExpired()) {
        clearInterval(intervalId);
        Session.onLogout().finally(() => {
          logout({ returnTo: auth.LOGIN_URL, federated: true });
        });
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    if (isAuthenticated) {
      const lastApiCallTime = Session.getApiLastCalledWithTokenTime();
      if (lastApiCallTime) {
        if (Session.isUserRecentlyActive()) {
          Session.setApiLastCalledWithTokenTimeNow();
        } else {
          clearInterval(intervalId);
          Session.onLogout().finally(() => {
            logout({ returnTo: auth.LOGIN_URL, federated: true });
          });
        }
      }
      const sessionMonitorTimeInterval = setInterval(async () => {
        if (Session.isTokenExpiringShortly()) {
          // Always refresh the token if it's about to expire.
          try {
            const updatedAccessToken = await getAccessTokenSilently({
              cacheMode: "off",
              authorizationParams: {
                organization: localStorage.getItem(ORG_ID)
              }
            });
            const updatedIdToken = await getIdTokenClaims();
            localStorage.setItem(AURA_ID_TOKEN, updatedIdToken.__raw);
            localStorage.setItem(AURA_ACCESS_TOKEN, updatedAccessToken);
            localStorage.setItem(AURA_EXPIRES_AT, updatedIdToken.exp);
          } catch (err) {
            console.error("Failed to refresh token:", err);
          }
        }
        const lastApiCallTime = Session.getApiLastCalledWithTokenTime();
        if (!Session.isUserRecentlyActive() && lastApiCallTime) {
          setShow(true);

          setLogoutCounter(prev => {
            if (prev <= 1) {
              setShow(false);
              clearInterval(intervalId);
              history.push("/auth0-logout");
            }
            return prev - 1;
          });
        }
      }, EXPIRY_TIMEOUT_UPDATE_MS);

      setIntervalId(sessionMonitorTimeInterval);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleLogout = () => {
    setShow(false);
    clearInterval(intervalId);
    history.push("/auth0-logout");
  };

  const handleKeepAlive = () => {
    Session.setApiLastCalledWithTokenTimeNow();
    setShow(false);
    setLogoutCounter(EXPIRY_LOGOUT_COUNTDOWN_SEC);
  };

  return (
      <Modal show={show && isAuthenticated}>
        <Modal.Header>
          <Modal.Title>Session Expiring</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will be logged out in <span className="fw-bold">{logoutCounter}</span>
          {' '}seconds due to inactivity.
        </Modal.Body>
        <Modal.Footer>
        <Button
            type="button"
            variant="secondary"
            className="ms-1"
            onClick={handleLogout}
          >
            Logout
          </Button>

          <Button
            type="button"
            variant="primary"
            className="ms-1"
            onClick={handleKeepAlive}
          >
            I'm Still Here
          </Button>
        </Modal.Footer>
      </Modal>
  );
};

export default SessionExpiringModal;
