import React from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import history from "../../../utils/history";

const Logout = () => {
  const handleLogoutClick = () => {
    history.push("/");
  };

  return (
    <Container className={"mt-4"}>
      <Alert variant="primary text-center m-5">
        <Alert.Heading className="text-center">Logged out</Alert.Heading>
        <p>
        You've successfully logged out of the application. However,
        your session is still active with the identity provider,
        such as Okta.
        To log out from the Identity Provider, visit their website and perform a logout. When you're ready to log in again to the application, please click this link.{' '}
          <a href="#" onClick={handleLogoutClick}>here</a>.
        </p>
      </Alert>
    </Container>
  );
};

export default Logout;
