import { Record as ImmutableRecord } from "immutable";

export const PanelVisibilityRecord = new ImmutableRecord({
  isFlightListPanelVisible: false,
  isFlightDetailsPanelVisible: false,
  isFlightPlanPanelVisible: false,
  isSiteListPanelVisible: false,
  isSiteDetailsPanelVisible: false,
  isResourceBlockPanelVisible: false,
  isCoverageBoundsPanelVisible: false,
  isRoutePlanningPanelVisible: false,
  isRouteListPanelVisible: false,
  isResourceSchedulePanelVisible: false
});
