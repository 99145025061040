import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "./general.record";

export const ResourceScheduleDataRecord = new ImmutableRecord({
  metadata: new MetadataRecord({}),
  siteUuid: "",
  reservations: [],
  resourceSchedules: [],
  snapshots: [],
  resourceBlockAllocationLookup: []
});

export const ResourceScheduleRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  resourceSchedules: ResourceScheduleDataRecord()
});
