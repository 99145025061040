import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "../../../../admin/store/records/general.record";

export const RouteCruisingRecord = new ImmutableRecord({
  trueAirspeedMs: 0,
  altitudeM: 0,
  icaoCruiseLevel: "",
  icaoCruisingSpeed: ""
});

export const RouteDestinationRecord = new ImmutableRecord({
  name: "",
  airportCode: "",
  location: { type: "", coordinates: [] }
});

export const RouteRadioModel = new ImmutableRecord({
  name: "",
  supported_tx_power_dbm: [],
  rx_resource_requirements: { num_subcarries: "", num_time_slots: "" },
  tx_resource_requirements: { num_subcarries: "", num_time_slots: "" }
});

export const RouteUncertainityParametersRecord = new ImmutableRecord({
  departureTimeS: 0,
  horizontalM: 0,
  verticalM: 0
});

export const RoutePerformanceRecord = new ImmutableRecord({
  maxSpeedMs: 0,
  maxAltitudeM: 0,
  maxAscentRateMs: 0,
  maxDescentRateMs: 0,
  maxAscentTrueAirspeedMs: 0,
  maxDescentTrueAirspeedMs: 0
});

export const RouteAntennaMountingRecord = new ImmutableRecord({
  cableLossDb: 0,
  type: "",
});

export const RouteAircraftModel = new ImmutableRecord({
  name: "",
  performance: new RoutePerformanceRecord({}),
  antennaMountings: [new RouteAntennaMountingRecord({})]
});

export const RouteAntennaModel = new ImmutableRecord({
  name: "",
  type: "",
  pattern: { type: "", contents: "" }
});

export const RouteFlightPlanRecord = new ImmutableRecord({
  uuid: "",
  flightPathUuid: "",
  cruising: new RouteCruisingRecord({}),
  departure: new RouteDestinationRecord({}),
  destination: new RouteDestinationRecord({}),
  alternates: [],
  waypoints: [],
  graphEdges: [],
  uncertainityParameters: new RouteUncertainityParametersRecord({}),
});

export const RouteDataRecord = new ImmutableRecord({
  uuid: "",
  organization: "",
  version: "",
  name: "",
  flightPlan: new RouteFlightPlanRecord({}),
  aircraftModel: new RouteAircraftModel({}),
  antennaModel: new RouteAntennaModel({}),
  airborneRadioModel: new RouteRadioModel({}),
  metadata: new MetadataRecord({}),
  flightCount: "",
  deactivated: false
});

export const RouteRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  routes: [],
  routeList: null,
  fetchRoutesFlag: true,
  flightPlans: [],
  highlightedRouteIndex: null
});
