import coverageBounds from "./coverage.bounds.reducer";
import identity from "./identity.reducer";
import monitoring from "./monitoring.reducer";
import resourceGrid from "./resourcegrid.reducer";
import sites from "./sites.reducer";
import siteDetails from "./site.details.reducer";
import siteResourceSchedules from "./resourceschedules.reducer";

export const adminReducer = {
  identity: identity.reducer,
  coverageBounds: coverageBounds.reducer,
  monitoring: monitoring.reducer,
  resourceGrid: resourceGrid.reducer,
  sites: sites.reducer,
  siteDetails: siteDetails.reducer,
  siteResourceSchedules: siteResourceSchedules.reducer
};

export const adminActions = {
  identity: identity.actions,
  coverageBounds: coverageBounds.actions,
  monitoring: monitoring.reactionsducer,
  resourceGrid: resourceGrid.actions,
  sites: sites.actions,
  siteDetails: siteDetails.actions,
  siteResourceSchedules: siteResourceSchedules.actions
};
