// duplicate color vars from main CSS so that they can be passed to Google Maps and SVG components dynamically

export const red = '#B23430';
export const orange = '#F47037';
export const green = '#12D5AF';
export const blue = '#2FA0E5';
export const blue_darker = '#3F93C4';
export const purple = '#7044F1';
export const black = '#091B2B';
export const darker_gray = '#43444C'; // not in palette but needed so made this up
export const mid_gray = '#696B77'; // not in palette but needed so made this up
export const ecru = '#D1B372';
export const ecru_light = '#DCC593'; // not in palette but needed so made this up
export const ecru_dark = '#A98537'; // not in palette but needed so made this up
export const gray = '#9496A1';
export const gray_lighter = '#BBBDC3';
export const white = '#FFFFFF';
