import axios from "axios";
import { ANTENNA_ENDPOINT, FLIGHT_PLANNING_API_URL_V2 } from "../../../../../constants/api.endpoints";
import Session from "../../../../../utils/session";
import { flightsActions } from "../../reducers";
import { AntennaRecord } from "../../records/inventory/antenna.record";

export const getAntennas = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.antennas.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${ANTENNA_ENDPOINT}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const antennas = response?.data?.reduce((newRec, rec) => {
        const antennaRecord = new AntennaRecord({
          uuid: rec.uuid,
          antennaModelUuid: rec.antenna_model_uuid,
          organization: rec.organization
        });
        newRec.push(antennaRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.antennas.isFetched({ antennas })
      );
    } else {
      dispatch(flightsActions.inventory.antennas.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.antennas.isError());
  }
};
