import { createSlice } from "@reduxjs/toolkit";
import { AircraftModelsRecord, AircraftModelRecord } from "../../records/inventory/aircraft.model.record";

const initialState = new AircraftModelsRecord({});

const aircraftModel = createSlice({
  name: 'aircraftModel',
  initialState,
  reducers: {
    isFetching: (aircraftModelState) => {
      return aircraftModelState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (aircraftModelState, action) => {
      return aircraftModelState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        aircraftModels: action.payload.aircraftModels,
      });
    },
    isError: (aircraftModelState) => {
      return aircraftModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (aircraftModelState) => {
      return aircraftModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        aircraftModels: [new AircraftModelRecord({})]
      });
    }
  }
});

export default aircraftModel;
