import { createSlice } from "@reduxjs/toolkit";
import { ResourceGridSetsRecord } from "../records/resourcegrid.record";

const initialState = new ResourceGridSetsRecord({});

const resourceGrid = createSlice({
  name: 'resourceGrid',
  initialState,
  reducers: {
    isFetching: (resourceGridState) => {
      return resourceGridState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (resourceGridState, action) => {
      return resourceGridState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        paginationDetails: action.payload.paginationDetails,
        records: action.payload.records,
        resourceGridByUuid: action.payload.resourceGridByUuid,
        resourceGrid: action.payload.resourceGrid
      });
    },
    isError: (resourceGridState) => {
      return resourceGridState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: () => {
      return new ResourceGridSetsRecord({});
    }
  }
});

export default resourceGrid;
