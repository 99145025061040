import { Record as ImmutableRecord } from "immutable";

export const SiteDetailsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  siteDetails: null,
  parentComponent: null,
  coverageBoundsToShowBySiteId: {},
  initialSiteId: null
});
