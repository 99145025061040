import React, { useState } from 'react';
import XAxis from '../XAxis/XAxis';
import { ROUTE_PLAN_COMPONENT, FLIGHT_DETAIL_COMPONENT } from '../../constants/constants';
import { white, black } from "../MapVisualization/Colors";
import { subwayStationPath } from "../MapVisualization/SvgMarkers";
import './TimelineDiagram.css';
import { dateToString, round, sToMin } from "../../utils/units";

const TimelineDiagram = ({ reservation, flightPath, parentComponent = null, estimatedDepartureTime = null }) => {
  const groupCloseValues = (arr, uncertainty) => {
    const sortedArr = [...arr].sort((a, b) => a - b);
    const groups = [[sortedArr[0]]];
    for (let i = 1; i < sortedArr.length; i++) {
      if (sortedArr[i] - groups[groups.length - 1][0] <= uncertainty * 2) {
        groups[groups.length - 1].push(sortedArr[i]);
      } else {
        groups.push([sortedArr[i]]);
      }
    }
    return groups;
  };

  const [expandedLegs, setExpandedLegs] = useState([]);

  const toggleExpand = (index) => {
    if (expandedLegs.includes(index)) {
      setExpandedLegs(expandedLegs.filter((item) => item !== index));
    } else {
      setExpandedLegs([...expandedLegs, index]);
    }
  };

  function formatTime (date) {
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  let minStart = parentComponent === ROUTE_PLAN_COMPONENT ? Math.min(...reservation.legs.map(leg => leg.elapsed_time_range_s.start)) : Math.min(...reservation.legs.map(leg => new Date(leg.time_range_utc.start))) / 1000;
  let etd = 0;
  if (parentComponent !== ROUTE_PLAN_COMPONENT) {
    if (parentComponent === FLIGHT_DETAIL_COMPONENT && estimatedDepartureTime) {
      etd = new Date(estimatedDepartureTime) / 1000;
    } else {
      etd = new Date(reservation.estimated_departure_time_utc) / 1000;
    }
    minStart = minStart - etd;
  }
  const startValues = reservation.legs.map((leg) => {
    return parentComponent === ROUTE_PLAN_COMPONENT ? leg.elapsed_time_range_s.start : new Date(leg.time_range_utc.start) / 1000;
  });
  const endValues = reservation.legs.map((leg) => {
    return parentComponent === ROUTE_PLAN_COMPONENT ? leg.elapsed_time_range_s.end : new Date(leg.time_range_utc.end) / 1000;
  });
  const lowestStart = Math.min(...startValues);
  const highestEnd = Math.max(...endValues);
  const totalDuration = highestEnd - lowestStart;
  const axisLabels = [];
  const etdUncertainty = -minStart;
  const midpoint = (highestEnd - lowestStart) / 2.0 - etdUncertainty;

  const getAxisLabelFromEet = (eet_s, label) => ({
    position: (eet_s - lowestStart) / totalDuration * 100,
    label: label || (parentComponent === ROUTE_PLAN_COMPONENT ? Math.round(eet_s) : formatTime(new Date(eet_s * 1000)))
  });

  axisLabels.push(getAxisLabelFromEet(lowestStart));
  if (etdUncertainty > totalDuration / 7) { // 7 found by experiment to avoid label collision
    axisLabels.push(getAxisLabelFromEet(etd, "ETD"));
    axisLabels.push(getAxisLabelFromEet(highestEnd - etdUncertainty, "ETA"));
  }
  if (highestEnd - etdUncertainty > etdUncertainty) {
    axisLabels.push(getAxisLabelFromEet(midpoint));
  }
  axisLabels.push(getAxisLabelFromEet(highestEnd));

  const featureRecords = {};

  flightPath?.points_geojson.features.forEach((feature) => {
    if (feature.properties.name && feature.properties.name !== "Change of pitch" && feature.properties.name !== "Midpoint") {
      const featureUUID = feature.properties.uuid;
      reservation.legs.forEach((leg, index) => {
        const flightVertexUUIDs = leg.flight_vertex_uuids;
        if (flightVertexUUIDs.includes(featureUUID)) {
          if (!featureRecords[`leg${index}`]) {
            featureRecords[`leg${index}`] = [];
          }
          featureRecords[`leg${index}`].push(feature);
        }
      });
    }
  });

  return (
    <div>
      {reservation.legs.map((leg, index) => {
        return (
          <div key={index} className="leg-info">
            <b>Leg {index + 1}: &nbsp;</b>
            {leg.site_callsign && <b><span className={"mini-site-icon"}>{leg.site_callsign}</span></b>}
            {!leg.site_callsign && <b><span><i className="fas fa-times" />No coverage</span></b>}
            { parentComponent === ROUTE_PLAN_COMPONENT &&
              <span className={"ms-2"}>ETD + {round(sToMin(leg.elapsed_time_range_s.start), 0)}mins &rarr;
                ETD + {round(sToMin(leg.elapsed_time_range_s.end), 0)}mins</span>
            }
            { parentComponent !== ROUTE_PLAN_COMPONENT &&
              <span className={"ms-2"}>{dateToString(new Date(leg.time_range_utc.start))} &rarr; {dateToString(new Date(leg.time_range_utc.end))}</span>
            }
          </div>
        );
      })}
     <div className='timeline-graph-block'>
    <div className='timeline-graph'>
      {reservation.legs.map((leg, index) => {
        let start = 0;
        let end = 0;
        if (parentComponent === ROUTE_PLAN_COMPONENT) {
          start = leg.elapsed_time_range_s.start;
          end = leg.elapsed_time_range_s.end;
        } else {
          start = (new Date(leg.time_range_utc.start) / 1000) - etd;
          end = (new Date(leg.time_range_utc.end) / 1000) - etd;
        }
        const startPosition = ((start - minStart) / totalDuration) * 100;
        const legFeatures = featureRecords[`leg${index}`] || [];
        return (
          <div key={index} className="collapsible-wrapper">
            <input
              type="checkbox"
              id={`collapsible-${index}`}
              className="collapsible-checkbox"
              checked={expandedLegs === index}
              onChange={() => toggleExpand(index)}
            />
            <label
              htmlFor={`collapsible-${index}`}
              className={`collapsible-label ${leg.site_uuid ? 'ribbon-success' : 'ribbon-failure'}`}
              title={`Leg ${index + 1}: ${leg.site_callsign || 'No coverage'}`}
              style={{
                position: 'relative',
                left: `${startPosition}%`,
                width: `${((end - start) / totalDuration) * 100}%`,
              }}
            >
              Leg {index + 1}:&nbsp;
              {leg.site_callsign && <span className={"mini-site-icon"}>{leg.site_callsign}</span>}
              {!leg.site_callsign && <span>No coverage</span>}
              <div className={`arrow-icon ${expandedLegs.includes(index) ? 'expanded' : 'collapsed'}`}></div>
            </label>
            <div className='collapsible-content'>
              {legFeatures.map((feature, featureIndex) => {
                const isExpanded = expandedLegs.includes(index);

                const timeGroups = groupCloseValues(feature.properties.elapsed_times_s, feature.properties.uncertainty.time_s);
                return timeGroups.map((timeGroup, groupIndex) => {
                  const leftValue = (((timeGroup[0] - feature.properties.uncertainty.time_s - minStart)) / totalDuration) * 100;
                  const rightValue = (((timeGroup[timeGroup.length - 1] + feature.properties.uncertainty.time_s - minStart)) / totalDuration) * 100;
                  const widthValue = rightValue - leftValue;
                  return (
                  <div
                    key={`${featureIndex}-${groupIndex}`}
                    className={`collapsible-item ${isExpanded ? 'expanded' : 'collapsed'}`}
                    style={{
                      position: 'relative',
                      left: `${leftValue}%`,
                      width: `${widthValue}%`
                    }}
                  >
                    <div className="content-wrapper">
                      <div className='ribbon-data-name'>
                        <span>{feature.properties.name}</span>
                      </div>
                      {timeGroup.map((time, timeIndex) => {
                        const totalPos = ((time - minStart) / totalDuration) * 100;
                        const legStartPos = leftValue;
                        const legEndPos = leftValue + widthValue;
                        const legPos = ((totalPos - legStartPos) / (legEndPos - legStartPos)) * 100;
                        return (
                          <div
                            key={timeIndex}
                            className="waypoint-view"
                            style={{
                              position: 'absolute',
                              left: `calc(${legPos}% - 0.75rem)`,
                              display: 'block'
                            }}
                          >
                            <svg viewBox="0 0 17 17">
                              <path d={subwayStationPath} fill={white} stroke={black} strokeWidth={3} />
                            </svg>
                          </div>
                        );
                      })}
                    </div>
                </div>
                  );
                });
              })}
            </div>
          </div>
        );
      })}
      <XAxis values={axisLabels} parentComponent={parentComponent}/>
        </div>
    </div>

  </div>
  );
};

export default TimelineDiagram;
