/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import FormSelect from "../../../components/FormSelectV2/FormSelect";
import FormDatePicker from "../../../components/FormDatePickerV2/FormDatePicker";
import { useDispatch, useSelector } from "react-redux";
import SubwayMap from "../../../components/MapVisualization/SubwayMap";
import Reservation from "../requestform/Reservation";
import { calculateGraphEdges, convertToFlightOriginState } from "../store/utils/routes/route.plan.util";
import { convertToInitialFormValues, flightPlanValidation } from "../store/utils/flights/flight.plan.util";
import { toggleFlightPlanPanel, toggleFlightListPanel } from "../store/actions/panels.action";
import { clearRoutePlan } from "../store/actions/routes/route.plan.action";
import { setFlightPlans, setReservations } from "../store/actions/map/map.action";
import { createFlight, clearFlightPlan, cancelReservation, deleteFlight, setFlightUuid } from "../store/actions/flights/flight.plan.action";
import { FLIGHT_PLAN_COMPONENT } from "../../../constants/constants";
import { getRoutes } from "../store/actions/routes/routes.action";
import { round } from "../../../utils/units";

import "../../../styles.css";
import "../requestform/FlightPlan.css";
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";

const FlightPlan = () => {
  const dispatch = useDispatch();

  const map = useSelector(({ map }) => map);
  const flightPlan = useSelector(({ flightPlan }) => flightPlan);
  const routeList = useSelector(({ routeList }) => routeList);
  const { routes, isFetching: isFetchingRoutes } = routeList;
  const identity = useSelector(({ identity }) => identity.identity);
  const [departureTimeUncertainity, setDepartureTimeUncertainity] = useState(0);
  const [errorResponse, setErrorResponse] = useState("");
  const [currentlySelectedRoute, setCurrentlySelectedRoute] = useState(null);
  const [formPrevState, setFormState] = useState(null);
  const [isDatePickerVisible, setDatePicketVisibility] = useState(false);
  const [isFormUpdated, setFormUpdated] = useState(false);
  const { flightPlans, reservations, flightPath } = map;

  useEffect(() => {
    if (routeList.fetchRoutesFlag) {
      dispatch(getRoutes());
    }
  }, []);

  const { departure, destination, waypoints, alternates } = flightPlans?.length ? flightPlans[0] : {};

  const {
    isFetching,
    selectedRoute,
    saveButtonText,
    disableFlightPlan,
    flightUuid,
    isError
  } = flightPlan;

  useEffect(() => {
    setCurrentlySelectedRoute(selectedRoute);
    setDepartureTimeUncertainity(selectedRoute?.flightPlan?.uncertainityParameters?.departureTimeS || 0);
  }, [selectedRoute]);

  const showFlightList = () => {
    dispatch(clearRoutePlan());
    dispatch(clearFlightPlan());
    dispatch(toggleFlightListPanel());
  };

  const hideFlightPlan = () => {
    dispatch(clearRoutePlan());
    dispatch(clearFlightPlan());
    dispatch(toggleFlightPlanPanel());
  };

  const handleSave = flightPlanValue => {
    dispatch(createFlight(flightPlanValue, setErrorResponse));
  };

  const handleSaveRoute = ([routeUuid]) => {
    dispatch(setFlightUuid(null));
    const route = _.find(routes, { uuid: routeUuid?.target?.value });
    const newFlightPlan = convertToFlightOriginState(route?.flightPlan);
    setDepartureTimeUncertainity(route?.flightPlan?.uncertainityParameters?.departureTimeS || 0);
    setCurrentlySelectedRoute(route);
    dispatch(setFlightPlans([newFlightPlan]));
  };

  const handleCancelReservation = () => {
    dispatch(cancelReservation(setErrorResponse));
  };

  const handleDeleteFlight = () => {
    dispatch(deleteFlight(setErrorResponse));
    showFlightList();
  };

  return (
    <div className="panel">
      <div className="panel-header">
        <h2>Plan a Flight</h2>
        <HelpToolTip content="Create a Flight by selecting a departure time and which predetermined Route." />
        <a
          className="close-button"
          onClick={selectedRoute ? showFlightList : hideFlightPlan}
        >
          <i className="fa-solid fa-circle-xmark" />
        </a>
      </div>
      <div className="panel-body">
        {isFetchingRoutes && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {!isFetchingRoutes && (<FinalForm
          initialValues={convertToInitialFormValues(selectedRoute)}
          initialValuesEqual={() => true}
          mutators={{
            saveRoute: handleSaveRoute
          }}
          validate={flightPlanValidation}
          onSubmit={handleSave}
        >
          {({ form, handleSubmit }) => {
            const {
              mutators: {
                saveRoute
              },
            } = form;

            const {
              values,
              submitErrors,
              submitFailed,
              hasValidationErrors
            } = form.getState();

            if (!_.isEqual(formPrevState, values) && reservations) {
              dispatch(setReservations(null));
              setErrorResponse("");
              setFormUpdated(true);
            } else if (!_.isEqual(formPrevState, values) && flightUuid && !isFormUpdated) {
              dispatch(setFlightUuid(flightUuid));
              setErrorResponse("");
              setFormUpdated(true);
            } else {
              setFormState(values);
              setFormUpdated(false);
            }

            if (submitFailed && hasValidationErrors) {
              setErrorResponse("One or more fields contains invalid input.");
            } else if (errorResponse === "") {
              setErrorResponse(submitErrors);
            }

            const disableButtons = isFetching || isDatePickerVisible;

            return (
              <>
                <fieldset disabled={selectedRoute?.reservation ? "disabled" : ""}>
                  <Row className="align-items-center mt-4">
                    <Col sm={12} md={12}>
                      <Field
                        name="route"
                        label="Route *"
                        data-testid="route-name"
                        groupClassName="inline-input-group"
                        readOnly={disableFlightPlan}
                        onChange={saveRoute}
                        component={FormSelect}
                      >
                        <option value="" disabled={disableFlightPlan}>Select</option>
                        {routes?.filter(r => !r.deactivated).map((route) => (
                          <option key={route.uuid} value={route.uuid}>
                            {route.name}
                          </option>
                        ))}
                      </Field>
                      { currentlySelectedRoute &&
                        <>
                          <div className={ "label-with-value" }>
                            <label>Aircraft Model</label>
                            <span>{ currentlySelectedRoute?.aircraftModel?.name }</span>
                          </div>
                          <div className={ "label-with-value" }>
                            <label>Radio Model</label>
                            <span>{ currentlySelectedRoute?.airborneRadioModel?.name }</span>
                          </div>
                          <div className={ "label-with-value" }>
                            <label>Antenna Model</label>
                            <span>{ currentlySelectedRoute?.antennaModel?.name }</span>
                          </div>
                        </>
                      }
                    </Col>
                  </Row>
                  <Row className={"planning"}>
                    <Col md={12}>
                      {currentlySelectedRoute && <SubwayMap
                        departure={departure}
                        destination={destination}
                        alternates={alternates}
                        calculateGraphEdges={calculateGraphEdges}
                        waypoints={waypoints}
                      />}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={9}>
                      <Field
                        type="text"
                        name="departureTime"
                        label="Departure Time (GMT) *"
                        groupClassName="align-items-center"
                        labelClassName="fw-bold form-label col-md-6 col-sm-6"
                        fieldClassName="input-sm form-control col-md-4 col-sm-4"
                        readOnly={disableFlightPlan}
                        setDatePicketVisibility={setDatePicketVisibility}
                        component={FormDatePicker}
                      />
                    </Col>
                    <Col xs={3}>
                      <p className={"ms-2"}> ± {round(departureTimeUncertainity / 60, 0)} minutes</p>
                    </Col>
                  </Row>
                </fieldset>
                { !reservations &&
                  <Row>
                    <Col md={ 12 } className="text-end">
                      { (selectedRoute &&
                        !(isError || !reservations) && flightUuid && !disableButtons) && (
                        <Button
                          variant="secondary"
                          disabled={ disableButtons }
                          onClick={ showFlightList }
                          className="ms-1"
                        >
                          <i className="fas fa-angle-left"/> Route List
                        </Button>
                      ) }
                      { (isError || !reservations) && flightUuid && !disableButtons && (
                        <Button
                          variant="outline-danger"
                          disabled={ disableButtons || hasValidationErrors }
                          onClick={ handleDeleteFlight }
                          className="ms-1"
                        >
                          <i className="fas fa-trash-alt"/> Delete Flight
                        </Button>
                      ) }
                      <Button
                        variant="success"
                        className="btn-icon-after reservation-icon"
                        onClick={ handleSubmit }
                        disabled={ disableButtons || hasValidationErrors || disableFlightPlan }
                      >
                        { isFetching && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) }
                        { saveButtonText }
                      </Button>
                      { errorResponse && (
                        <div
                          className="error-message"
                          id="overall-error-message"
                        >
                          Error: { errorResponse }
                        </div>
                      ) }
                    </Col>
                  </Row>
                }
                <fieldset disabled={form.getState().dirtySinceLastSubmit}>
                  {reservations && (
                    <Reservation
                      reservation={reservations ? reservations[0] : null}
                      disabled={disableButtons || hasValidationErrors}
                      identity={identity}
                      parentComponent={FLIGHT_PLAN_COMPONENT}
                      handleCancelReservation={handleCancelReservation}
                      handleOk={showFlightList}
                      flightPath={flightPath || null}
                    />
                  )}
                </fieldset>
                { reservations && errorResponse && (
                <div
                  className="error-message"
                  id="overall-error-message"
                >
                  Error: { errorResponse }
                </div>
                ) }
              </>
            );
          }}
        </FinalForm>)}
      </div>
    </div>
  );
};

export default FlightPlan;
