import { createSlice } from '@reduxjs/toolkit';
import { PanelVisibilityRecord } from '../records/panel.record';

const initialState = new PanelVisibilityRecord({});

const panels = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    togglePanel: (panelsState, action) => {
      return new PanelVisibilityRecord({
        [action.payload.panelName]: action.payload.panelValue,
      });
    },
    clear: () => {
      return new PanelVisibilityRecord({});
    }
  }
});

export default panels;
