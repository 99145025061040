import { createSlice } from "@reduxjs/toolkit";
import { AntennaModelsRecord, AntennaModelRecord } from "../../records/inventory/antenna.model.record";

const initialState = new AntennaModelsRecord({});

const antennaModel = createSlice({
  name: 'antennaModel',
  initialState,
  reducers: {
    isFetching: (antennaModelState) => {
      return antennaModelState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (antennaModelState, action) => {
      return antennaModelState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        antennaModels: action.payload.antennaModels,
      });
    },
    isError: (antennaModelState) => {
      return antennaModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    isFetchedAll: (antennaModelState, action) => {
      return antennaModelState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        groundAntennaModels: action.payload.groundAntennaModels,
      });
    },
    clear: (antennaModelState) => {
      return antennaModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        antennaModels: [new AntennaModelRecord({})],
        groundAntennaModels: []
      });
    }
  }
});

export default antennaModel;
