import { createSlice } from "@reduxjs/toolkit";
import { GroundRadioRecord, GroundRadiosRecord } from "../../records/inventory/ground.radio.record";

const initialState = new GroundRadiosRecord({});

const groundRadio = createSlice({
  name: 'groundRadio',
  initialState,
  reducers: {
    isFetching: (groundRadioState) => {
      return groundRadioState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (groundRadioState, action) => {
      return groundRadioState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        groundRadios: action.payload.groundRadios,
      });
    },
    isError: (groundRadioState) => {
      return groundRadioState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (groundRadioState) => {
      return groundRadioState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        groundRadios: [new GroundRadioRecord({})]
      });
    }
  }
});

export default groundRadio;
