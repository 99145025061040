import axios from "axios";
import { FLIGHT_PLANNING_API_URL_V2, GROUND_RADIO_ENDPOINT } from "../../../../../constants/api.endpoints";
import Session from "../../../../../utils/session";
import { flightsActions } from "../../reducers";
import { GroundRadioRecord } from "../../records/inventory/ground.radio.record";

export const getGroundRadios = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.groundRadios.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${GROUND_RADIO_ENDPOINT}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const groundRadios = response?.data?.reduce((newRec, rec) => {
        const groundRadioRecord = new GroundRadioRecord({
          uuid: rec.uuid,
          organization: rec.organization,
          groundRadioModelUuid: rec.ground_radio_model_uuid,
        });
        newRec.push(groundRadioRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.groundRadios.isFetched({ groundRadios })
      );
    } else {
      dispatch(flightsActions.inventory.groundRadios.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.groundRadios.isError());
  }
};
