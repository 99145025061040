import { Record as ImmutableRecord } from "immutable";

export const AntennaRecord = new ImmutableRecord({
  uuid: '',
  antennaModelUuid: '',
  organization: ''
});

export const AntennasRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  antennas: [new AntennaRecord({})]
});
