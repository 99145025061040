import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import React from "react";

const AddWaypointButton = ({ label, disabled, onClick }) => (
  <div className={label ? "waypoints-list-entry text-end" : "slimline-add-waypoint"} >
    <Button className="flight-plan-add-button"
            variant={!label ? "link" : "outline-primary"}
            size="sm"
            onClick={onClick}
            disabled={disabled}>
      <span>{label}</span>
      <i className="fas fa-plus"/>
    </Button>
  </div>
);

AddWaypointButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

AddWaypointButton.defaultProps = {
  label: null,
};

export default AddWaypointButton;
