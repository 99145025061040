import React, { useEffect } from "react";

import { FormSpy } from "react-final-form";

const AutoSave = React.memo(({ values, onSave }) => {
  useEffect(() => {
    onSave(values);
  }, [values, onSave]);

  return null;
}, (prevProps, newProps) => JSON.stringify(prevProps.values) === JSON.stringify(newProps.values));

const AutoSaveHoc = (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave}/>
);

export default AutoSaveHoc;
