import { createSlice } from "@reduxjs/toolkit";
import { IdentityRecord, AccessClaimRecord } from "../records/identity.record";

const initialState = new IdentityRecord({});

const identity = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    isFetching: (identityState) => {
      return identityState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (identityState, action) => {
      return identityState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        accessClaim: new AccessClaimRecord({
          sub: action.payload.access_claim.sub,
          username: action.payload.access_claim.username,
          groups: action.payload.access_claim.groups,
          iat: action.payload.access_claim.iat,
          exp: action.payload.access_claim.exp,
          iss: action.payload.access_claim.iss,
          version: action.payload.access_claim.version
        }),
        identity: action.payload
      });
    },
    isError: (identityState) => {
      return identityState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: () => {
      return new IdentityRecord({});
    }
  }
});

export default identity;
