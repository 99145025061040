import React from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Field, Form as FinalForm } from 'react-final-form';

import FormField from "../FormField/FormField";

import { round } from "../../utils/units";
import AutoSuggestTerminus from "./AutoSuggestTerminus";

import validate from "./validate";
import FormSelect from "../FormSelect/FormSelect";
import { DEPARTURE_INDEX, displayUid, nextNodeOptions } from "../../subpages/user/requestform/util";

// Use of React.memo() and the equality function is required here to prevent the form re-rendering
// when data outside of the form such as the last map click, changes but the values important to
// this form do not.

const terminusEntryFormPropsAreEqual = (prevProps, newProps) =>
  JSON.stringify(prevProps.terminus) === JSON.stringify(newProps.terminus) &&
  JSON.stringify(prevProps.routes) === JSON.stringify(newProps.routes);

const TerminusEntryForm = React.memo(({ terminus, routes, onSave, onCancel, isDestination, waypoints = [], alternates = [], destination }) => {
  const handleSelectAirport = ([suggestion], state, utils) => {
    utils.changeValue(state, 'name', () => suggestion.name);
    utils.changeValue(state, 'airport_code', () => suggestion.icao_code);
    utils.changeValue(state, 'lng', () => round(suggestion.longitude, 6));
    utils.changeValue(state, 'lat', () => round(suggestion.latitude, 6));
  };

  const handleSelectLocation = ([location], state, utils) => {
    utils.changeValue(state, 'name', () => location.name);
    utils.changeValue(state, 'airport_code', () => location.airport_code);
    utils.changeValue(state, 'lng', () => round(location.longitude, 6));
    utils.changeValue(state, 'lat', () => round(location.latitude, 6));
  };

  return (
    <FinalForm
      initialValues={{
        name: terminus.name,
        lng: terminus.longitude,
        lat: terminus.latitude,
        airport_code: terminus.airport_code,
        // next: (terminus.next || []).map(si => parseInt(si))
        next: (terminus?.next?.map(si => parseInt(si)) || [])
      }}
      mutators={{
        selectAirport: handleSelectAirport,
        selectLocation: handleSelectLocation,
      }}
      validate={validate}
      onSubmit={onSave}
    >
      {({ form, handleSubmit }) => {
        const { hasValidationErrors } = form.getState();

        const {
          mutators: { selectAirport, selectLocation },
        } = form;

        // Need to expose the selectLocation mutator, so that the location can be updated
        // when the user clicks the map
        window.terminusEntryForm = {
          selectLocation,
        };

        return (
          <>
            <Row>
              <AutoSuggestTerminus routes={routes} onSuggestionSelected={selectAirport} />
            </Row>
            <Row className={"mb-0"}>
              <Col xs={6}>
                <Field type="text" name="name" label="Name" component={FormField} />
              </Col>
              <Col xs={3}>
                <Field type="number" name="lat" label="Latitude *" className="no-spinner" component={FormField} />
              </Col>
              <Col xs={3}>
                <Field type="number" name="lng" label="Longitude *" className="no-spinner" component={FormField} />
              </Col>
            </Row>
            {!isDestination &&
              <Row className={"mb-0"}><Field className="input-group-100 multiselect-height-7rem" multiple={true} type="text" name="next" label="Then fly to" component={FormSelect}>
                {nextNodeOptions(DEPARTURE_INDEX, destination, waypoints, alternates).map((node, index) => (
                  <option key={node.uid} value={node.uid}>{node.name || displayUid(node.uid)}</option>
                ))}
              </Field></Row>}
            <div className="text-end">
              <Button variant="secondary" className="ms-1" size="sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ms-1"
                size="sm"
                disabled={hasValidationErrors}
                onClick={handleSubmit}
              >
                Save <i className="fas fa-save"/>
              </Button>
            </div>
          </>
        );
      }}
    </FinalForm>
  );
}, terminusEntryFormPropsAreEqual);

TerminusEntryForm.propTypes = {
  terminus: PropTypes.object.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TerminusEntryForm;
