import axios from "axios";
import { FLIGHT_PLANNING_API_URL_V2 } from "../../../constants/api.endpoints";
import Session from "../../../utils/session";

const create = (siteDetails) =>
  axios.post(`${FLIGHT_PLANNING_API_URL_V2}/sites/`, siteDetails, Session.requestConfig());

const update = (id, siteDetails) => {
  return axios.put(
    `${FLIGHT_PLANNING_API_URL_V2}/sites/${id}`,
    siteDetails, Session.requestConfig()
  );
};

const delete_ = (uuid) => {
  return axios.delete(
    `${FLIGHT_PLANNING_API_URL_V2}/sites/${uuid}`,
    Session.requestConfig()
  );
};

const api = {
  create,
  update,
  delete_
};

export default api;
