import axios from 'axios';
import { FLIGHT_PLANNING_API_URL_V2, SITES_ENDPOINT } from "../../../../constants/api.endpoints";
import Session from '../../../../utils/session';
import { adminActions } from "../reducers";
import { SiteDataRecord } from '../records/site.record';
import { MetadataRecord } from '../records/general.record';
import { toggleSiteListPanel } from '../../../user/store/actions/panels.action';

const getSites = (displaySiteModal = false) => async dispatch => {
  try {
    dispatch(adminActions.sites.isFetching);
    const response = await axios.get(`${FLIGHT_PLANNING_API_URL_V2}${SITES_ENDPOINT}`, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const sitesByUuid = {};
      const records = response?.data?.records?.reduce((newRec, rec) => {
        const siteRecord = new SiteDataRecord({
          metadata: new MetadataRecord({
            created_by: rec.createdBy,
            created: rec.created,
            modifiedBy: rec.modified_by,
            modified: rec.modified,
            deletedBy: rec.deleted_by,
            deleted: rec.deleted
          }),
          uuid: rec.uuid,
          type: rec.type,
          callsign: rec.callsign,
          name: rec.name,
          groundRadioUuid: rec.ground_radio_uuid,
          antennaUuid: rec.antenna_uuid,
          location: rec.location,
          coverageBoundsUuids: rec.coverage_bounds_uuids,
          resourceGridUuid: rec.resource_grid_uuid,
          a2gResourceBlockConfig: rec.a2g_resource_block_config,
          g2aResourceBlockConfig: rec.g2a_resource_block_config,
          heightAglM: rec.height_agl_m,
          altitudeM: rec.altitude_m,
          maxEirpDbm: rec.max_eirp_dbm,
          antennaBearingDeg: rec.antenna_bearing_deg,
          antennaAzimuthDeg: rec.antenna_azimuth_deg,
          g2gOobInterferenceDbm: rec.g2g_oob_interference_dbm
        });
        sitesByUuid[siteRecord.uuid] = siteRecord;
        newRec.push(siteRecord);
        return newRec;
      }, []).sort((a, b) => a.name.localeCompare(b.name));
      dispatch(adminActions.sites.isFetched({ records, sitesByUuid }));
      if (displaySiteModal) dispatch(toggleSiteListPanel(true));
    } else {
      // todo => show generic failure message on UI, create error boundary to show error message
      dispatch(adminActions.sites.isError());
    }
  } catch (err) {
    dispatch(adminActions.sites.isError());
  }
};

export default getSites;
