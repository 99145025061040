const validate = (values) => {
  const errors = {};

  if (!values?.name) {
    errors.name = 'Required';
  }

  if (!values?.aircraftModel) {
    errors.aircraftModel = 'Required';
  }

  if (!values?.radioModel) {
    errors.radioModel = 'Required';
  }

  if (!values?.horizontal) {
    errors.horizontal = 'Required';
  }

  if (!values?.antennaModel) {
    errors.antennaModel = 'Required';
  }

  if (!values?.trueAirspeedMs) {
    errors.trueAirspeedMs = 'Required';
  }

  if (!values?.altitudeM) {
    errors.altitudeM = 'Required';
  }

  if (!values?.departure?.longitude) {
    errors.departure = 'Required';
  }

  if (!values?.destination?.latitude) {
    errors.destination = 'Required';
  }

  return errors;
};

export default validate;
