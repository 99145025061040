import React from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Field, Form as FinalForm } from 'react-final-form';

import FormField from "../FormField/FormField";

import { feetToMeters, knotsToms, metersToFeet, msToKnots, round } from "../../utils/units";
import AutoSuggestWaypoint from "./AutoSuggestWaypoint";

import validate from "./validate";
import FormSelect from "../FormSelect/FormSelect";
import { displayUid, nextNodeOptions } from "../../subpages/user/requestform/util";

// Use of React.memo() and the equality function is required here to prevent the form re-rendering
// when data outside of the form such as the last map click, changes but the values important to
// this form do not.

const waypointEntryFormPropsAreEqual = (prevProps, newProps) =>
  JSON.stringify(prevProps.waypoint) === JSON.stringify(newProps.waypoint) &&
  JSON.stringify(prevProps.routes) === JSON.stringify(newProps.routes);

const WaypointEntryForm = React.memo(({ waypoint, routes, onSave, onCancel, destination, waypoints, alternates }) => {
  const handleSelectWaypoint = ([suggestion], state, utils) => {
    utils.changeValue(state, 'name', () => suggestion.name);
    utils.changeValue(state, 'icao_description', () => suggestion.icao_code || suggestion.icao_description || '');
    utils.changeValue(state, 'lng', () => round(suggestion.location.coordinates[0], 6));
    utils.changeValue(state, 'lat', () => round(suggestion.location.coordinates[1], 6));

    utils.changeValue(
      state,
      'airspeed_kts',
      () => suggestion.true_airspeed_ms ? msToKnots(suggestion.true_airspeed_ms) : ''
    );

    utils.changeValue(
      state,
      'altitude_m',
      () => suggestion.altitude_m ? metersToFeet(suggestion.altitude_m) : ''
    );
  };

  const handleSelectLocation = ([location], state, utils) => {
    utils.changeValue(state, 'name', () => location.name);
    utils.changeValue(state, 'icao_description', () => location.icao_description);
    utils.changeValue(state, 'lng', () => round(location.lng, 6));
    utils.changeValue(state, 'lat', () => round(location.lat, 6));
  };

  const handleSave = (waypoint) => {
    const to_save = {
      name: waypoint.name,
      lat: round(waypoint.lat, 6),
      lng: round(waypoint.lng, 6),
      icao_description: waypoint.icao_description,
      true_airspeed_ms: waypoint.airspeed_kts ? knotsToms(waypoint.airspeed_kts) : undefined,
      altitude_m: waypoint.altitude_ft ? feetToMeters(waypoint.altitude_ft) : undefined,
      hold_time_s: parseInt(waypoint.hold_time_s),
      uid: waypoint.uid,
      next: (waypoint?.next?.map(si => parseInt(si)) || [])
    };
    return onSave(to_save);
  };

  return (
    <FinalForm
      initialValues={{
        name: waypoint.name,
        lat: waypoint.lat,
        lng: waypoint.lng,
        icao_description: waypoint.icao_description,
        airspeed_kts: waypoint.true_airspeed_ms ? msToKnots(waypoint.true_airspeed_ms) : '',
        altitude_ft: waypoint.altitude_m ? metersToFeet(waypoint.altitude_m) : '',
        hold_time_s: waypoint.hold_time_s,
        uid: waypoint.uid,
        next: (waypoint?.next?.map(si => parseInt(si)) || [])
      }}
      mutators={{
        selectWaypoint: handleSelectWaypoint,
        selectLocation: handleSelectLocation,
      }}
      validate={validate}
      onSubmit={handleSave}
    >
      {({ form, handleSubmit }) => {
        const { hasValidationErrors } = form.getState();

        const {
          mutators: { selectWaypoint, selectLocation }
        } = form;

        // Need to expose the selectLocation mutator, so that the location can be updted
        // when the user clicks the map
        window.waypointEntryForm = {
          selectLocation,
        };

        return (
          <div className="waypoints-list-entry edit-waypoint">
            <h4>Add Waypoint</h4>
            <Row>
              <AutoSuggestWaypoint routes={routes} onSuggestionSelected={selectWaypoint} />
            </Row>
            <Row className={"mb-0"}>
              <Col xs={6}>
                <Field type="text" name="name" label="Name" className="input-group-100" component={FormField} />
              </Col>
              <Col xs={3}>
                <Field type="number" name="lat" label="Latitude" className="no-spinner" component={FormField} />
              </Col>
              <Col xs={3}>
                <Field type="number" name="lng" label="Longitude" className="no-spinner" component={FormField} />
              </Col>
            </Row>
            <Row className={"mb-0"}>
              <Col xs={4}>
                <Field type="number" name="airspeed_kts" label="Airspeed (knots)" className="no-spinner" component={FormField} />
              </Col>
              <Col xs={4}>
                <Field type="number" name="altitude_ft" label="Altitude (ft)" className="no-spinner" component={FormField} />
              </Col>
              <Col xs={4}>
                <Field type="number" name="hold_time_s" label="Hold time (s)" className="no-spinner" component={FormField} />
              </Col>
            </Row>
            <Row className={"mb-0"}>
              <Field className="input-group-100 multiselect-height-7rem" multiple={true} size={(waypoints?.length || 0) + (alternates?.length || 0)} type="text" name="next" label="Then fly to" component={FormSelect}>
                {nextNodeOptions(waypoint.uid, destination, waypoints, alternates)?.map((node, index) => {
                  return node.uid !== waypoint.uid
                    ? <option key={node.uid || ''} value={node.uid}>{node.name || displayUid(node.uid)}</option>
                    : null;
                })}
              </Field>
            </Row>
            <div className="text-end">
              <Button variant="secondary" className="ms-1" size="sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ms-1"
                size="sm"
                disabled={hasValidationErrors}
                onClick={handleSubmit}
              >
                Save <i className="fas fa-save"/>
              </Button>
            </div>
          </div>
        );
      }}

    </FinalForm>
  );
}, waypointEntryFormPropsAreEqual);

WaypointEntryForm.propTypes = {
  waypoint: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

WaypointEntryForm.defaultProps = {
  waypoint: {},
};

export default WaypointEntryForm;
