import axios from "axios";
import { ANTENNA_MODEL_ENDPOINT, FLIGHT_PLANNING_API_URL_V2, GROUND_ANTENNA_MODEL } from "../../../../../constants/api.endpoints";
import Session from "../../../../../utils/session";
import { flightsActions } from "../../reducers";
import { AntennaModelRecord, PatternRecord } from "../../records/inventory/antenna.model.record";

export const getAntennaModels = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.antennaModels.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${ANTENNA_MODEL_ENDPOINT}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const antennaModels = response?.data?.reduce((newRec, rec) => {
        const antennaModelRecord = new AntennaModelRecord({
          uuid: rec.uuid,
          name: rec.name,
          pattern: new PatternRecord({
            type: rec.type,
            content: rec.content
          }),
          type: rec.type,
        });
        newRec.push(antennaModelRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.antennaModels.isFetched({ antennaModels })
      );
    } else {
      dispatch(flightsActions.inventory.antennaModels.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.antennaModels.isError());
  }
};

export const getGroundAntennaModels = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.antennaModels.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${GROUND_ANTENNA_MODEL}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const groundAntennaModels = response?.data?.reduce((newRec, rec) => {
        const antennaModelRecord = new AntennaModelRecord({
          uuid: rec.uuid,
          name: rec.name,
          pattern: new PatternRecord({
            type: rec.type,
            content: rec.content
          }),
          type: rec.type,
        });
        newRec.push(antennaModelRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.antennaModels.isFetchedAll({ groundAntennaModels })
      );
    } else {
      dispatch(flightsActions.inventory.antennaModels.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.antennaModels.isError());
  }
};
