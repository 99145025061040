import React from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from "prop-types";
import { withRouter } from 'react-router-dom';
import './navbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSiteCoverageMetric } from './subpages/user/store/actions/map/map.action';

const NavbarPage = () => {
  const identity = useSelector(({ identity }) => identity.identity);
  const dispatch = useDispatch();

  return (
  <Navbar collapseOnSelect
      variant="dark"
      onSelect={() => {}}
      onClick={(e) => {
        if (e.metaKey) {
          // This is cheeky ... don't want to expose this functionality to many people, or have it even be visible
          // so hide it in a semi-secret place ...
          dispatch(toggleSiteCoverageMetric());
        }
      }}
    >
      <Container>
        <Navbar.Brand>
            AURA Deterministic Frequency Allocation System
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <LinkContainer to={identity ? '/auth0-logout' : '/'}>
              <Nav.Link eventKey={2}>
                {identity ? 'Log Out' : 'Log In'}
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

NavbarPage.propTypes = {
  identity: PropTypes.object
};

export default withRouter(NavbarPage);
