import React from "react";
import PropTypes from "prop-types";

import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Field, Form as FinalForm } from 'react-final-form';

import FormField from "../FormField/FormField";

import {
  metersToFeet,
  msToKnots,
  round
} from "../../utils/units";
import AutoSuggestAlternate from "./AutoSuggestAlternate";

import validate from "./validate";

// Use of React.memo() and the equality function is required here to prevent the form re-rendering
// when data outside of the form such as the last map click, changes but the values important to
// this form do not.

const alternateEntryFormPropsAreEqual = (prevProps, newProps) =>
  JSON.stringify(prevProps.alternate) === JSON.stringify(newProps.alternate) &&
  JSON.stringify(prevProps.routes) === JSON.stringify(newProps.routes);

const AlternateEntryForm = React.memo(({ alternate, routes, onSave, onCancel, alternates }) => {
  const handleSelectAlternate = ([suggestion], state, utils) => {
    utils.changeValue(state, 'name', () => suggestion.name);
    utils.changeValue(state, 'airport_code', () => suggestion.icao_code);
    utils.changeValue(state, 'longitude', () => round(suggestion.longitude, 6));
    utils.changeValue(state, 'latitude', () => round(suggestion.latitude, 6));

    utils.changeValue(
      state,
      'airspeed_kts',
      () => suggestion.true_airspeed_ms ? msToKnots(suggestion.true_airspeed_ms) : ''
    );

    utils.changeValue(
      state,
      'altitude_m',
      () => suggestion.altitude_m ? metersToFeet(suggestion.altitude_m) : ''
    );
  };

  const handleSelectLocation = ([location], state, utils) => {
    utils.changeValue(state, 'name', () => location.name);
    utils.changeValue(state, 'airport_code', () => location.airport_code);
    utils.changeValue(state, 'latitude', () => round(location.latitude, 6));
    utils.changeValue(state, 'longitude', () => round(location.longitude, 6));
  };

  const handleSave = (alternate) => onSave({
    name: alternate.name,
    latitude: round(alternate.latitude, 6),
    longitude: round(alternate.longitude, 6),
    airport_code: alternate.airport_code,
    uid: alternate.uid
  });

  return (
    <FinalForm
      initialValues={{
        name: alternate.name,
        latitude: alternate.latitude,
        longitude: alternate.longitude,
        airport_code: alternate.airport_code,
        uid: alternate.uid
      }}
      mutators={{
        selectAlternate: handleSelectAlternate,
        selectLocation: handleSelectLocation,
      }}
      validate={validate}
      onSubmit={handleSave}
    >
      {({ form, handleSubmit }) => {
        const { hasValidationErrors } = form.getState();

        const {
          mutators: { selectAlternate, selectLocation }
        } = form;

        // Need to expose the selectLocation mutator, so that the location can be updted
        // when the user clicks the map
        window.alternateEntryForm = {
          selectLocation,
        };

        return (
          <div className="waypoints-list-entry edit-waypoint">
            <h4>Add Alternate Destination</h4>
            <Row>
              <AutoSuggestAlternate routes={routes} onSuggestionSelected={selectAlternate} />
            </Row>
            <Row className={"mb-0"}>
              <Col xs={6}>
                <Field type="text" name="name" label="Name" component={FormField} />
              </Col>
              <Col xs={3}>
                <Field type="number" name="latitude" label="Latitude" className="no-spinner" component={FormField} />
              </Col>
              <Col xs={3}>
                <Field type="number" name="longitude" label="Longitude" className="no-spinner" component={FormField} />
              </Col>
            </Row>
            <div className="text-end mt-4">
              <Button variant="secondary" className="ms-1" size="sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ms-1"
                size="sm"
                disabled={hasValidationErrors}
                onClick={handleSubmit}
              >
                Save <i className="fas fa-save"/>
              </Button>
            </div>
          </div>
        );
      }}

    </FinalForm>
  );
}, alternateEntryFormPropsAreEqual);

AlternateEntryForm.propTypes = {
  alternate: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

AlternateEntryForm.defaultProps = {
  alternate: {},
};

export default AlternateEntryForm;
