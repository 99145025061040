import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import './Toolbar.css';
import './FlightList.css';
import { clearFlightDetails } from '../store/actions/flight.details.action';
import { clearFlightPlan } from '../store/actions/flight.plan.action';
import { canUserReadSites, canUserReadFlights } from '../../../utils/identity';
import { toggleFlightListPanel, toggleFlightPlanPanel, toggleSiteListPanel, toggleRoutePlanPanel, toggleRouteListPanel, toggleResourceSchedulePanel } from '../store/actions/panels.action';
import { clearFlightRoute } from '../store/actions/routes/route.plan.action';
import { clearSuccessMessage } from '../store/actions/flights.action';

const Toolbar = () => {
  const dispatch = useDispatch();
  const identity = useSelector(({ identity }) => identity.identity);
  const [activePanel, setActivePanel] = useState(null);

  const isFlightListPanelVisible = useSelector(state => state.modals.isFlightListPanelVisible);
  const isFlightPlanPanelVisible = useSelector(state => state.modals.isFlightPlanPanelVisible);
  const isSiteListPanelVisible = useSelector(state => state.modals.isSiteListPanelVisible);
  const isRoutePlanningPanelVisible = useSelector(state => state.modals.isRoutePlanningPanelVisible);
  const isRouteListPanelVisible = useSelector(state => state.modals.isRouteListPanelVisible);
  const isResourceSchedulePanelVisible = useSelector(state => state.modals.isResourceSchedulePanelVisible);
  const isFlightDetailsPanelVisible = useSelector(state => state.modals.isFlightDetailsPanelVisible);
  const isSiteDetailsPanelVisible = useSelector(state => state.modals.isSiteDetailsPanelVisible);

  useEffect(() => {
    if (!isFlightListPanelVisible && activePanel === 'flightList') {
      setActivePanel(null);
    }
    if (!isFlightPlanPanelVisible && activePanel === 'flightPlan') {
      setActivePanel(null);
    }
    if (!isSiteListPanelVisible && activePanel === 'sites') {
      setActivePanel(null);
    }
    if (!isRoutePlanningPanelVisible && activePanel === 'routePlan') {
      setActivePanel(null);
    }
    if (!isRouteListPanelVisible && activePanel === 'routeList') {
      setActivePanel(null);
    }
    if (!isResourceSchedulePanelVisible && activePanel === 'resourceSchedules') {
      setActivePanel(null);
    }
    if ((isFlightDetailsPanelVisible) && activePanel === 'flightList') {
      setActivePanel("flightList");
    }
    if (isSiteDetailsPanelVisible && activePanel === "sites") {
      setActivePanel("sites");
    }
    if (isFlightPlanPanelVisible && activePanel === 'routeList') {
      setActivePanel('flightPlan');
    }
    if (isRouteListPanelVisible && activePanel === 'flightPlan') {
      setActivePanel('routeList');
    }
  }, [
    isFlightListPanelVisible,
    isFlightPlanPanelVisible,
    isSiteListPanelVisible,
    isRoutePlanningPanelVisible,
    isRouteListPanelVisible,
    isResourceSchedulePanelVisible,
    isFlightDetailsPanelVisible,
    isSiteDetailsPanelVisible,
    activePanel
  ]);

  const showFlightList = () => {
    setActivePanel("flightList");
    dispatch(clearFlightDetails());
    dispatch(clearFlightPlan());
    dispatch(toggleFlightListPanel());
  };

  const showFlightPlan = () => {
    setActivePanel("flightPlan");
    dispatch(clearFlightDetails());
    dispatch(clearFlightPlan());
    dispatch(toggleFlightPlanPanel());
  };

  const showSites = () => {
    setActivePanel("sites");
    dispatch(clearFlightDetails());
    dispatch(clearFlightPlan());
    dispatch(toggleSiteListPanel());
    dispatch(clearSuccessMessage());
  };

  const showRoutePlan = () => {
    setActivePanel("routePlan");
    dispatch(clearFlightRoute());
    dispatch(clearFlightDetails());
    dispatch(toggleRoutePlanPanel());
  };

  const showRouteList = () => {
    setActivePanel("routeList");
    dispatch(clearFlightDetails());
    dispatch(clearFlightPlan());
    dispatch(toggleRouteListPanel());
  };

  const showResourceSchedules = () => {
    setActivePanel("resourceSchedules");
    dispatch(clearFlightDetails());
    dispatch(clearFlightPlan());
    dispatch(toggleResourceSchedulePanel());
  };

  return (
    <ButtonToolbar className='toolbar' aria-label="Toolbar">
      <ButtonGroup vertical aria-label="Toolbar Group">
        {canUserReadFlights(identity) && (<Button variant="toolbar" title="Plan a Route" className={`routes-plus-icon ${activePanel === 'routePlan' ? 'active' : ''}`} onClick={() => showRoutePlan()}/>)}
        {canUserReadFlights(identity) && <Button variant="toolbar" title="Route List" className={`routes-icon ${activePanel === 'routeList' ? 'active' : ''}`} onClick={() => showRouteList()}/>}
        {canUserReadFlights(identity) && (<Button variant="toolbar" title="Plan a Flight" className={`flights-plus-icon ${activePanel === 'flightPlan' ? 'active' : ''}`} onClick={() => showFlightPlan()}/>)}
        {canUserReadFlights(identity) && (<Button variant="toolbar" title="Flight List" className={`flights-icon ${activePanel === 'flightList' ? 'active' : ''}`} onClick={() => showFlightList()}/>)}
        {canUserReadSites(identity) && (<Button variant="toolbar" title="Resource Schedules" onClick={() => showResourceSchedules()} className={activePanel === 'resourceSchedules' ? 'active' : ''}><i className="fa-sharp fa-solid fa-grid"></i>  </Button>)}
        {canUserReadSites(identity) && (<Button variant="toolbar" title="Site List" className={`site-icon ${activePanel === 'sites' ? 'active' : ''}`} onClick={() => showSites()}/>)}
      </ButtonGroup>
    </ButtonToolbar>
  );
};

export default Toolbar;
