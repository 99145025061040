import React from 'react';
import './MapVisualization.css';

const AircraftMarker = ({ screenX, screenY }) => {
  const aircraftPosition = {
    left: `${screenX}px`,
    top: `${screenY}px`,
  };

  return (
    <div className="aircraft-icon" style={aircraftPosition}></div>
  );
};

export default AircraftMarker;
