import { createSlice } from "@reduxjs/toolkit";
import { CoverageBoundsRecord } from "../records/coverage.bounds.record.js";

const initialState = new CoverageBoundsRecord({});

const coverageBounds = createSlice({
  name: 'coverageBounds',
  initialState,
  reducers: {
    isFetching: (coverageBoundsState) => {
      return coverageBoundsState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (coverageBoundsState, action) => {
      return coverageBoundsState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        paginationDetails: action.payload.paginationDetails,
        records: action.payload.records,
        coverageBounds: action.payload.coverageBounds
      });
    },
    isError: (coverageBoundsState) => {
      return coverageBoundsState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: () => {
      return new CoverageBoundsRecord({});
    }
  }
});

export default coverageBounds;
