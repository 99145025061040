import axios from "axios";
import { FLIGHT_PLANNING_API_URL_V2, GROUND_RADIO_MODEL_ENDPOINT } from "../../../../../constants/api.endpoints";
import Session from "../../../../../utils/session";
import { flightsActions } from "../../reducers";
import { GroundRadioModelRecord } from "../../records/inventory/ground.radio.model.record";

export const getGroundRadioModels = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.groundRadioModels.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${GROUND_RADIO_MODEL_ENDPOINT}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const groundRadioModels = response?.data?.reduce((newRec, rec) => {
        const groundRadioModelRecord = new GroundRadioModelRecord({
          uuid: rec.uuid,
          name: rec.name,
          supportedTxPowerDbm: rec.supported_tx_power_dbm
        });
        newRec.push(groundRadioModelRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.groundRadioModels.isFetched({ groundRadioModels })
      );
    } else {
      dispatch(flightsActions.inventory.groundRadioModels.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.groundRadioModels.isError());
  }
};
