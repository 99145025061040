import { Record as ImmutableRecord, List as ImmutableList } from "immutable";
import { MetadataRecord } from "./general.record";

export const CoverageBoundRecord = new ImmutableRecord({
  metadata: new MetadataRecord({}),
  geojson: { type: '', features: [] },
  power: '',
  rawCoverageBoundsUuids: [],
  siteUuids: '',
  uuid: ''
});

export const PaginationDetailRecord = new ImmutableRecord({
  page: 0,
  perPage: 0
});

export const CoverageBoundsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  paginationDetails: new PaginationDetailRecord({}),
  records: new ImmutableList(CoverageBoundRecord({})),
  coverageBounds: []
});
