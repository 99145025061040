import { Record as ImmutableRecord } from "immutable";

export const MapCoordinates = new ImmutableRecord({
  lnglat: null,
  name: null,
  icao_code: null
});

export const FlightPlanUtils = new ImmutableRecord({
  departure: null,
  waypoints: null,
  destination: null,
  alternates: null,
  initialLoad: true
});

export const FlightPlanRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  flightPlan: null,
  showPreviousButton: false,
  editingDestination: false,
  editingDeparture: false,
  addingWaypoint: false,
  addingAlternate: false,
  mapCoordinates: {
    lnglat: null,
    name: null,
    icao_code: null
  },
  flightPlanUtils: {
    departure: null,
    waypoints: null,
    destination: null,
    alternates: null,
    initialLoad: true
  },
  highlightedFlightVertexUuid: '',
  reservation: null,
  flightPath: null
});
