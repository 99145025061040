import { createSlice } from "@reduxjs/toolkit";
import { RoutePlanRecord } from "../../records/routes/route.plan.record";

const initialState = new RoutePlanRecord({});

const routePlan = createSlice({
  name: 'routePlan',
  initialState,
  reducers: {
    isFetching: (routePlanState) => {
      return routePlanState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (routePlanState, action) => {
      return routePlanState.merge({
        isFetching: false,
        isFetched: true,
        isError: false
      });
    },
    isError: (routePlanState) => {
      return routePlanState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    setFlightRoute: (routePlanState, action) => {
      return routePlanState.merge({
        route: action.payload.data,
        showPreviousButton: action.payload.showPreviousButton
      });
    },
    mapCoordinates: (routePlanState, action) => {
      return routePlanState.merge({
        mapCoordinates: action.payload.mapCoordinates
      });
    },
    isEditingDestination: (routePlanState, action) => {
      return routePlanState.merge({
        editingDestination: action.payload.editingDestination
      });
    },
    isEditingDeparture: (routePlanState, action) => {
      return routePlanState.merge({
        editingDeparture: action.payload.editingDeparture
      });
    },
    isAddingWaypoint: (routePlanState, action) => {
      return routePlanState.merge({
        addingWaypoint: action.payload.addingWaypoint
      });
    },
    isAddingAlternate: (routePlanState, action) => {
      return routePlanState.merge({
        addingAlternate: action.payload.addingAlternate
      });
    },
    updateRouteForm: (routePlanState, action) => {
      return routePlanState.merge({
        [action.payload.name]: action.payload.value
      });
    },
    setSaveButtonText: (routePlanState, action) => {
      return routePlanState.merge({
        saveButtonText: action.payload.saveButtonText
      });
    },
    setRouteUuid: (routePlanState, action) => {
      return routePlanState.merge({
        routeUuid: action.payload.routeUuid
      });
    },
    setSelectedRoute: (routePlanState, action) => {
      return routePlanState.merge({
        routeUuid: action.payload.uuid || routePlanState.routeUuid,
        selectedRoute: action.payload.selectedRoute,
        parentComponent: action.payload.parentComponent || null,
        disableRoute: !!action.payload.disableRoute
      });
    },
    setFlightOrigin: (routePlanState, action) => {
      return routePlanState.merge({
        flightOrigin: action.payload.flightOrigin
      });
    },
    setParentComponet: (routePlanState, action) => {
      return routePlanState.merge({
        parentComponent: action.payload.parentComponent
      });
    },
    setDisconnectedNodes: (routePlanState, action) => {
      return routePlanState.merge({
        disconnectedNodes: action.payload.disconnectedNodes
      });
    },
    setDisableRoute: (routePlanState, action) => {
      return routePlanState.merge({
        disableRoute: action.payload.disableRoute
      });
    },
    clear: (routePlanState) => {
      return new RoutePlanRecord({});
    }
  }
});

export const routePlanReducer = routePlan.reducer;
export const routePlanActions = routePlan.actions;
