import flights from "./flights.reducer";
import flightDetails from "./flight.details.reducer";
import flightPlan from "./flight.plan.reducer";
import panels from "./panel.reducer";
import { inventoryActions, inventoryReducers } from "./inventory";

export const flightsReducer = {
  flights: flights.reducer,
  flightDetails: flightDetails.reducer,
  flightPlan: flightPlan.reducer,
  panels: panels.reducer,
  inventory: inventoryReducers,
};

export const flightsActions = {
  flights: flights.actions,
  flightDetails: flightDetails.actions,
  flightPlan: flightPlan.actions,
  panels: panels.actions,
  inventory: inventoryActions,
};
