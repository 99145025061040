import { getAirborneRadioModels } from "./airborne.radio.model.action";
import { getAirborneRadios } from "./airborne.radio.action";
import { getAircraftModels } from "./aircraft.model.action";
import { getAircrafts } from "./aircraft.action";
import { getAntennaModels } from "./antenna.model.action";
import { getAntennas } from "./antenna.action";
import { getGroundRadios } from "./ground.radio.action";
import { getGroundRadioModels } from "./ground.radio.model.action";

export {
  getAirborneRadioModels,
  getAirborneRadios,
  getAircraftModels,
  getAircrafts,
  getAntennaModels,
  getAntennas,
  getGroundRadioModels,
  getGroundRadios
};

export const getInventory = () => dispatch => {
  dispatch(getAirborneRadioModels());
  dispatch(getAirborneRadios());
  dispatch(getAircraftModels());
  dispatch(getAntennaModels());
  dispatch(getAntennas());
  dispatch(getGroundRadioModels());
  dispatch(getGroundRadios());
  dispatch(getAircrafts());
};
