import {
  RouteAircraftModel, RouteAntennaModel, RouteAntennaMountingRecord,
  RouteCruisingRecord,
  RouteDataRecord,
  RouteDestinationRecord,
  RouteFlightPlanRecord, RoutePerformanceRecord, RouteRadioModel, RouteUncertainityParametersRecord
} from "../records/routes/routes.record";
import { MetadataRecord } from "../../../admin/store/records/general.record";

export const getErrorMessage = err => {
  const errMessage = [];
  if (err?.response?.data?.errors) {
    const errObj = err?.response?.data?.errors;
    for (const error in errObj) {
      errMessage.push(`${error} = ${errObj[error]}`);
    }
    return errMessage.join(", ");
  }
  if (err?.response?.data?.message) {
    return err?.response?.data?.message;
  } else {
    return err.message;
  }
};

export const convertFlightPlan = flightPlan => new RouteFlightPlanRecord({
  uuid: flightPlan.uuid,
  flightPathUuid: flightPlan.flight_path_uuid,
  cruising: new RouteCruisingRecord({
    trueAirspeedMs: flightPlan.cruising.true_airspeed_ms,
    altitudeM: flightPlan.cruising.altitude_m,
    icaoCruiseLevel: flightPlan.cruising.icao_cruising_level,
    icaoCruisingSpeed: flightPlan.cruising.icao_cruising_speed
  }),
  departure: new RouteDestinationRecord({
    name: flightPlan.departure.name,
    airportCode: flightPlan.departure.airport_code,
    location: flightPlan.departure.location
  }),
  destination: new RouteDestinationRecord({
    name: flightPlan.destination.name,
    airportCode: flightPlan.destination.airport_code,
    location: flightPlan.destination.location
  }),
  alternates: flightPlan.alternates,
  waypoints: flightPlan.waypoints,
  graphEdges: flightPlan.graph_edges,
  uncertainityParameters: new RouteUncertainityParametersRecord({
    departureTimeS: flightPlan.uncertainty_parameters.departure_time_s,
    horizontalM: flightPlan.uncertainty_parameters.horizontal_m,
    verticalM: flightPlan.uncertainty_parameters.vertical_m
  })
});

export const convertToMetadataRecord = metadata => new MetadataRecord({
  created_by: metadata?.createdBy,
  created: metadata?.created,
  modifiedBy: metadata?.modified_by,
  modified: metadata?.modified,
  deletedBy: metadata?.deleted_by,
  deleted: metadata?.deleted
});

export const convertToPerformanceRecord = performance => new RoutePerformanceRecord({
  maxSpeedMs: performance?.max_speed_ms,
  maxAltitudeM: performance?.max_altitude_m,
  maxAscentRateMs: performance?.max_ascent_rate_ms,
  maxDescentRateMs: performance?.max_descent_rate_ms,
  maxAscentTrueAirspeedMs: performance?.max_ascent_true_airspeed_ms,
  maxDescentTrueAirspeedMs: performance?.max_descent_true_airspeed_ms
});

export const convertToAntennaMountingRecord = antennaMounting => new RouteAntennaMountingRecord({
  cableLossDb: antennaMounting?.cable_loss_db,
  type: antennaMounting?.type
});

export const convertToAircraftModelRecord = aircraftModel => new RouteAircraftModel({
  name: aircraftModel?.name,
  performance: convertToPerformanceRecord(aircraftModel?.performance),
  antennaMountings: aircraftModel?.antenna_mountings?.map(convertToAntennaMountingRecord)
});

export const convertToAntennaModelRecord = antennaModel => new RouteAntennaModel({
  name: antennaModel.name,
  type: antennaModel.type,
  pattern: antennaModel.pattern
});

export const convertToAirborneRadioModelRecord = airborneRadioModel => new RouteRadioModel({
  name: airborneRadioModel?.name,
  supportedTxPowerDbm: airborneRadioModel?.supported_tx_power_dbm,
  rxResourceRequirements: airborneRadioModel?.rx_resource_requirements,
  txResourceRequirements: airborneRadioModel?.tx_resource_requirements
});

export const convertToRouteDataRecord = displayRoute => new RouteDataRecord({
  metadata: convertToMetadataRecord(displayRoute?.metadata),
  uuid: displayRoute.uuid,
  organization: displayRoute.organization,
  version: displayRoute.version,
  name: displayRoute.name,
  flightCount: displayRoute.flight_count,
  deactivated: displayRoute.deactivated,
  flightPlan: convertFlightPlan(displayRoute?.flight_plan),
  aircraftModel: convertToAircraftModelRecord(displayRoute?.aircraft_model),
  antennaModel: convertToAntennaModelRecord(displayRoute?.antenna_model),
  airborneRadioModel: convertToAirborneRadioModelRecord(displayRoute?.radio_model)
});
