import React from "react";
import PropTypes from "prop-types";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Accordion, Col } from "react-bootstrap";

import { Field, Form as FinalForm } from 'react-final-form';

import AutoSave from "../../../components/AutoSave/AutoSave";
import FormField from "../../../components/FormField/FormField";
import FormHidden from "../../../components/FormHidden/FormHidden";

import {
  coerceDateIntoUtc,
  getDatePlusDays,
  roundDateBackToMidnight,
  roundDateForwardToMidnight
} from "../../../utils/units";

import "./Datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "./../../../styles.css";

const FlightFilters = ({ identity, onChangeCallback }) => {
  const handleDepartDatesChange = ([[startDate, endDate]], state, utils) => {
    utils.changeValue(state, 'departAfter', () => startDate);
    utils.changeValue(state, 'departBefore', () => endDate);
  };

  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filter By</Accordion.Header>
        <Accordion.Body>
          <FinalForm
            initialValues={{
              departBefore: coerceDateIntoUtc(roundDateBackToMidnight(getDatePlusDays(28))),
              departAfter: coerceDateIntoUtc(roundDateForwardToMidnight(getDatePlusDays(-7))),
            }}
            initialValuesEqual={() => true}
            mutators={{
              selectDepartDates: handleDepartDatesChange,
            }}
            onSubmit={onChangeCallback /* NOT USED, but required */}
            subscription={{} /* No need to subscribe to anything */}
          >
            {({ form }) => {
              const { mutators: { selectDepartDates } } = form;
              const { values: { departBefore, departAfter } } = form.getState();

              return (
                <>
                  <Row>
                    <AutoSave onSave={onChangeCallback}/>
                    <Col xs={6}>
                      <Field type="text" name="aircraftUuid" label="Aircraft UUID"
                        component={FormField}/>
                    </Col>
                    <Col xs={6}>
                      <Field type="text" name="organization" label="Organization"
                        component={FormField}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Field type="text" name="departureAirport" label="Departs Airport"
                        component={FormField}/>
                    </Col>
                    <Col xs={6}>
                      <div className="input-group">
                        <Form.Label>Departs at (GMT)</Form.Label>
                        {/* NOTE: DatePicker outputs in user's LOCAL TIME ZONE, this.state.startDate/endDate will be in user's timezone, we will coerce this into UTC at point of submitting to backend */}
                        <DatePicker
                          className="form-control"
                          selected={departBefore}
                          startDate={departAfter}
                          endDate={departBefore}
                          onChange={selectDepartDates}
                          dateFormat="MM/dd/yyyy"
                          selectsRange
                        />
                        <Field type="hidden" name="departBefore" component={FormHidden}/>
                        <Field type="hidden" name="departAfter" component={FormHidden}/>
                      </div>
                    </Col>
                  </Row>
                </>
              );
            }}
          </FinalForm>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

FlightFilters.propTypes = {
  usersById: PropTypes.object,
  organizations: PropTypes.array,
  identity: PropTypes.object.isRequired,
  onChangeCallback: PropTypes.func,
};

export default FlightFilters;
