import { MapCoordinates } from '../records/flight.plan.record';
import { flightsActions } from '../reducers';
import { toggleFlightPlanPanel } from './panels.action';

export const setFlightPlan = (data, showPreviousButton = false) => dispatch => {
  dispatch(flightsActions.flightPlan.setFlightPlan({ data, showPreviousButton }));
  dispatch(toggleFlightPlanPanel(true));
};

export const clearFlightPlan = () => dispatch => {
  dispatch(flightsActions.flightPlan.clear());
};

export const setMapboxCoordinates = mapCoordinates => (dispatch, getState) => {
  const flightPlan = getState().flightPlan;
  const { editingDeparture, editingDestination, addingWaypoint, addingAlternate } = flightPlan;
  if (editingDeparture || editingDestination || addingWaypoint || addingAlternate) {
    dispatch(flightsActions.flightPlan.mapCoordinates({ mapCoordinates }));
  };
};

export const clearMapboxCoordinates = () => dispatch => {
  dispatch(flightsActions.flightPlan.mapCoordinates({ mapCoordinates: new MapCoordinates({}) }));
};

export const isEditingDestination = editingDestination => (dispatch) => {
  dispatch(flightsActions.flightPlan.isEditingDestination({ editingDestination }));
};

export const isEditingDeparture = editingDeparture => (dispatch) => {
  dispatch(flightsActions.flightPlan.isEditingDeparture({ editingDeparture }));
};

export const isAddingWaypoint = addingWaypoint => (dispatch) => {
  dispatch(flightsActions.flightPlan.isAddingWaypoint({ addingWaypoint }));
};

export const isAddingAlternate = addingAlternate => (dispatch) => {
  dispatch(flightsActions.flightPlan.isAddingAlternate({ addingAlternate }));
};

export const setFlightPlanUtils = flightPlanUtils => (dispatch) => {
  dispatch(flightsActions.flightPlan.setFlightPlanUtils({ flightPlanUtils }));
};

export const setHighlightedFlightVertexUuid = highlightedFlightVertexUuid => dispatch => {
  dispatch(flightsActions.flightPlan.setHighlightedFlightVertexUuid({ highlightedFlightVertexUuid }));
};
