import {
  feetToMeters,
  knotsToms,
  round,
  metersToFeet,
  msToKnots
} from "../../../../../utils/units";
import _ from 'lodash';
export const DEPARTURE_INDEX = 0;
export const DESTINATION_INDEX = 1;
export const WAYPOINT_START_ID = 100;
export const ALTERNATE_START_ID = 10000;

const MAX_NEXT_PREVIEW = 7;

export const calculateNextNodes = (selectedRoute) => {
  const { graphEdges: edges = [], waypoints = [], alternates = [] } = (selectedRoute || {});
  const uid_map = [
    DEPARTURE_INDEX,
    DESTINATION_INDEX,
    ...(alternates || []).map((alt, i) => i + ALTERNATE_START_ID),
    ...(waypoints || []).map((wp, i) => i + WAYPOINT_START_ID)
  ].reduce((obj, uuid, vidx) => ({ ...obj, [vidx]: uuid }), {});
  return edges.reduce((obj, [from_vert, to_vert]) => ({ ...obj, [uid_map[from_vert]]: [...(obj[uid_map[from_vert]] || []), uid_map[to_vert]] }), {});
};

export const calculateGraphEdges = (departure, waypoints, alternates) => {
  if (!waypoints?.length && !alternates.length) { return [[0, 1]]; }

  const toIndex = [...alternates, ...waypoints].reduce((obj, node, idx) => ({ ...obj, [node.uid]: idx + 2 }), { 0: 0, 1: 1 });
  const uidEdges = departure.next.map(n => [DEPARTURE_INDEX, n])
    .concat(waypoints.reduce((acc, wp) => [...acc, ...wp.next.map(n => [wp.uid, n])], []));
  return uidEdges.map(([from, to]) => [toIndex[from], toIndex[to]]);
};

export const displayUid = (uid) =>
  uid >= ALTERNATE_START_ID
    ? `Alternate ${uid - ALTERNATE_START_ID}`
    : (
        uid >= WAYPOINT_START_ID ? `Waypoint ${uid + 1 - WAYPOINT_START_ID}` : 'Destination');

export const displayNextNodes = (next_uids, destination, alternates, waypoints) => {
  const nodes_by_uid = new Map();

  nodes_by_uid.set(DESTINATION_INDEX, destination);
  waypoints.forEach(waypoint => { nodes_by_uid.set(waypoint.uid, waypoint); });
  alternates.forEach(alternate => { nodes_by_uid.set(alternate.uid, alternate); });

  return next_uids?.slice(0, MAX_NEXT_PREVIEW)
    ?.map(uid => nodes_by_uid.get(uid))
    .map(node => node ? (node.airport_code || node.icao_description || displayUid(node.uid || DESTINATION_INDEX)) : '')
    .join(", ");
};

export const nextNodeOptions = (uid, destination, waypoints, alternates) => {
  const result = [...(waypoints || []), { uid: DESTINATION_INDEX, ...destination }, ...(alternates || [])];
  return result;
};

const convertToTerminusState = (terminus) => {
  if (
    terminus &&
    'location' in terminus &&
    'type' in terminus.location &&
    'coordinates' in terminus.location
  ) {
    return {
      name: terminus.name,
      airport_code: terminus.airportCode,
      longitude: round(terminus.location.coordinates[0], 6),
      latitude: round(terminus.location.coordinates[1], 6)
    };
  }
  return {};
};

export const convertToDepartureState = (plan, next_node_map = {}) => (
  { ...convertToTerminusState(plan?.departure), uid: DEPARTURE_INDEX, next: next_node_map[DEPARTURE_INDEX] || [] });
export const convertToDestinationState = (plan) => (
  { ...convertToTerminusState(plan?.destination), uid: DESTINATION_INDEX });
export const convertToAlternateState = (plan, next_node_map) => (plan?.alternates || []).map((alt, idx) => (
  { ...convertToTerminusState(alt), uid: ALTERNATE_START_ID + idx, next: next_node_map[ALTERNATE_START_ID + idx] || [] }
));

export const convertToWaypointsState = (plan, next_node_map) => (plan?.waypoints || []).map((wp, idx) => ({
  lat: round(wp.location.coordinates[1], 6),
  lng: round(wp.location.coordinates[0], 6),
  name: wp.name || "",
  icao_description: wp.icao_description || "",
  altitude_m: wp.altitude_m,
  true_airspeed_ms: wp.true_airspeed_ms,
  hold_time_s: wp.hold_time_s,
  uid: WAYPOINT_START_ID + idx,
  next: next_node_map[WAYPOINT_START_ID + idx] || []
}));

export const convertToInitialFormValues = (initialRoutePlan, aircraftModels, antennaModels, airborneRadioModels) => {
  const next_node_map = calculateNextNodes(initialRoutePlan?.flightPlan);
  if (initialRoutePlan) {
    const aircraftModel = _.find(aircraftModels.aircraftModels, { name: initialRoutePlan?.aircraftModel?.name });
    initialRoutePlan.aircraftModel.uuid = aircraftModel.uuid;

    const antennaModel = _.find(antennaModels.antennaModels, { name: initialRoutePlan?.antennaModel?.name });
    initialRoutePlan.antennaModel.uuid = antennaModel.uuid;

    const airborneRadioModel = _.find(airborneRadioModels.airborneRadioModels, { name: initialRoutePlan?.airborneRadioModel?.name });
    initialRoutePlan.airborneRadioModel.uuid = airborneRadioModel.uuid;
  }
  return {
    name: initialRoutePlan?.name || '',
    aircraftModel: initialRoutePlan?.aircraftModel?.uuid || '',
    radioModel: initialRoutePlan?.airborneRadioModel?.uuid || '',
    antennaModel: initialRoutePlan?.antennaModel?.uuid || '',
    trueAirspeedMs: round(msToKnots(initialRoutePlan?.flightPlan?.cruising?.trueAirspeedMs), 0) || 0,
    altitudeM: round(metersToFeet(initialRoutePlan?.flightPlan?.cruising?.altitudeM), 0) || 0,
    departureTimeUncertainty: (initialRoutePlan?.flightPlan?.uncertainityParameters?.departureTimeS) / 60 || 30,
    horizontal: initialRoutePlan?.flightPlan?.uncertainityParameters?.horizontalM || 100,
    departure: convertToDepartureState(initialRoutePlan?.flightPlan, next_node_map),
    waypoints: convertToWaypointsState(initialRoutePlan?.flightPlan, next_node_map),
    destination: convertToDestinationState(initialRoutePlan?.flightPlan),
    alternates: convertToAlternateState(initialRoutePlan?.flightPlan, next_node_map)
  };
};

export const convertToCruisingDto = (values) => {
  return {
    true_airspeed_ms: knotsToms(round(values.trueAirspeedMs, 1)),
    altitude_m: feetToMeters(round(values.altitudeM, 0)),
  };
};

export const convertToTerminusDto = (terminusState) => {
  const terminus = {
    airport_code: terminusState.airport_code
  };

  if (!terminusState.icao_code) {
    terminus.name = terminusState.name;
    terminus.location = {
      type: 'Point',
      coordinates: [terminusState.longitude, terminusState.latitude]
    };
  }
  return terminus;
};

export const convertToWaypointDtos = (waypoints) => {
  return waypoints.map(x => {
    return {
      name: x.name,
      location: {
        type: "Point",
        coordinates: [round(x.lng, 6), round(x.lat, 6)]
      },
      icao_description: x.icao_description || undefined,
      altitude_m: x.altitude_m || undefined,
      true_airspeed_ms: x.true_airspeed_ms || undefined,
      hold_time_s: x.hold_time_s || undefined
    };
  });
};

export const convertToAlternateDtos = alternates => alternates.map(convertToTerminusDto);

export const convertToRouteDtos = route => {
  return {
    name: route.name,
    antenna_model_uuid: route.antennaModel,
    aircraft_model_uuid: route.aircraftModel,
    airborne_radio_model_uuid: route.radioModel,
    cruising: convertToCruisingDto(route),
    waypoints: route.waypoints.map(wp => {
      return {
        name: wp.name,
        location: {
          type: "Point",
          coordinates: [wp.lng, wp.lat]
        },
        icao_description: wp.icao_description || "",
        altitude_m: wp.altitude_m,
        height_agl_m: wp.height_agl_m,
        true_airspeed_ms: wp.true_airspeed_ms,
        hold_time_s: wp.hold_time_s
      };
    }),
    departure: {
      name: route.departure.name,
      airport_code: route.departure.airport_code,
      location: {
        type: "Point",
        coordinates: [route.departure.longitude, route.departure.latitude]
      }
    },
    destination: {
      name: route.destination.name,
      airport_code: route.destination.airport_code,
      location: {
        type: "Point",
        coordinates: [route.destination.longitude, route.destination.latitude]
      }
    },
    alternates: route.alternates.map(alt => {
      return {
        name: alt.name,
        airport_code: alt.airport_code,
        location: {
          type: "Point",
          coordinates: [alt.longitude, alt.latitude]
        }
      };
    }),
    graph_edges: calculateGraphEdges(route.departure, route.waypoints, route.alternates),
    uncertainty_parameters: {
      horizontal_m: parseInt(route.horizontal),
      vertical_m: parseInt(route.horizontal),
      departure_time_s: parseInt(route.departureTimeUncertainty * 60)
    }
  };
};

export const convertToFlightOriginState = flightPlan => {
  const nextNodeMap = calculateNextNodes(flightPlan);
  const departure = convertToDepartureState(flightPlan, nextNodeMap);
  const waypoints = convertToWaypointsState(flightPlan, nextNodeMap);
  const destination = convertToDestinationState(flightPlan);
  const alternates = convertToAlternateState(flightPlan, nextNodeMap);
  const flightPathUuid = flightPlan?.flightPathUuid;
  return { departure, waypoints, destination, alternates, flightPathUuid };
};
