import { createSlice } from "@reduxjs/toolkit";
import { GroundRadioModelsRecord, GroundRadioModelRecord } from "../../records/inventory/ground.radio.model.record";

const initialState = new GroundRadioModelsRecord({});

const groundRadioModel = createSlice({
  name: 'groundRadioModel',
  initialState,
  reducers: {
    isFetching: (groundRadioModelState) => {
      return groundRadioModelState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (groundRadioModelState, action) => {
      return groundRadioModelState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        groundRadioModels: action.payload.groundRadioModels,
      });
    },
    isError: (groundRadioModelState) => {
      return groundRadioModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (groundRadioModelState) => {
      return groundRadioModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        groundRadioModels: [new GroundRadioModelRecord({})]
      });
    }
  }
});

export default groundRadioModel;
