import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import React from "react";
import "./Datepicker.css";
import "react-datepicker/dist/react-datepicker.css";

const FormDatePicker = ({
  input,
  label,
  groupClassName,
  labelClassName,
  fieldClassName,
  readOnly,
  setDatePicketVisibility,
  'data-testid': dataTestId,
  meta: { touched, error },
}) => {
  const handleChange = (date) => {
    input.onChange(date);
  };

  return (
    <Form.Group controlId={input.name}
      data-testid={dataTestId}
      className={groupClassName}>
      {label && <Form.Label className={labelClassName}>{label}</Form.Label>}
      <DatePicker
        className={fieldClassName}
        wrapperClassName="w-50"
        showTimeSelect
        selected={input.value}
        onChange={handleChange}
        dateFormat="MM/dd/yyyy HH:mm"
        timeFormat="HH:mm"
        timeIntervals={15}
        disabled={readOnly}
        onCalendarClose={() => setDatePicketVisibility && setDatePicketVisibility(false)}
        onCalendarOpen={() => setDatePicketVisibility && setDatePicketVisibility(true)}
      />
      {touched && error && (
        <div className="error-message">{error}</div>
      )}
    </Form.Group>
  );
};

FormDatePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  label: PropTypes.string,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  'data-testid': PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
};

FormDatePicker.defaultProps = {
  label: null,
  meta: {
    error: '',
  },
  groupClassName: null,
  labelClassName: null,
  fieldClassName: null,
  'data-testid': null,
};

export default FormDatePicker;
