const validate = (values) => {
  const errors = {};

  if (!values.lng && values.lng !== 0) {
    errors.lng = 'Required';
  }

  if (!values.lat && values.lat !== 0) {
    errors.lat = 'Required';
  }
  return errors;
};

export default validate;
