import { createSlice } from "@reduxjs/toolkit";
import { AirborneRadioRecord, AirborneRadiosRecord } from "../../records/inventory/airborne.radio.record";

const initialState = new AirborneRadiosRecord({});

const airborneRadio = createSlice({
  name: 'airborneRadio',
  initialState,
  reducers: {
    isFetching: (airborneRadioState) => {
      return airborneRadioState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (airborneRadioState, action) => {
      return airborneRadioState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        airborneRadios: action.payload.airborneRadios,
      });
    },
    isError: (airborneRadioState) => {
      return airborneRadioState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (airborneRadioState) => {
      return airborneRadioState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        airborneRadios: [new AirborneRadioRecord({})]
      });
    }
  }
});

export default airborneRadio;
