import axios from 'axios';
import { RESERVATION_LIFECYCLE_API_URL, SITE_RESOURCE_SCHEDULES_API } from "../../../../constants/api.endpoints";
import Session from '../../../../utils/session';
import { adminActions } from '../reducers';
import { ResourceScheduleDataRecord } from '../records/resourceschedules.record';

const getSiteResourceSchedules = (site_uuid, start, end, displaySiteResourceScheduleModal = false) => async dispatch => {
  try {
    dispatch(adminActions.siteResourceSchedules.isFetching);
    const urlParams = [];
    urlParams.push(`site_uuid=${site_uuid}`);
    urlParams.push(`start=${start}`);
    urlParams.push(`end=${end}`);
    const response = await axios.get(`${RESERVATION_LIFECYCLE_API_URL}${SITE_RESOURCE_SCHEDULES_API}?${urlParams.join("&")}`, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const { site_uuid, reservations, resource_schedules, snapshots, resource_block_allocation_lookup } = response.data;
      const records = new ResourceScheduleDataRecord({
        siteUuid: site_uuid,
        reservations,
        resourceSchedules: resource_schedules,
        snapshots,
        resourceBlockAllocationLookup: resource_block_allocation_lookup
      });
      dispatch(adminActions.siteResourceSchedules.isFetched({ records }));
    } else {
      dispatch(adminActions.siteResourceSchedules.isError());
    }
  } catch (err) {
    dispatch(adminActions.siteResourceSchedules.isError());
  }
};

export default getSiteResourceSchedules;
