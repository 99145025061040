import axios from "axios";
import { AIRBORNER_RADIO_MODEL_ENDPOINT, FLIGHT_PLANNING_API_URL_V2 } from "../../../../../constants/api.endpoints";
import Session from "../../../../../utils/session";
import { flightsActions } from "../../reducers";
import { ResourceRequirementRecord, AirborneRadioModelRecord } from "../../records/inventory/airborne.radio.model.record";

export const getAirborneRadioModels = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.airborneRadioModels.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${AIRBORNER_RADIO_MODEL_ENDPOINT}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const airborneRadioModels = response?.data?.reduce((newRec, rec) => {
        const airborneRadioModelRecord = new AirborneRadioModelRecord({
          uuid: rec.uuid,
          name: rec.name,
          rxResourceRequirements: new ResourceRequirementRecord({
            numSubCarriers: rec.rx_resource_requirements.num_subcarriers,
            numTimeSlots: rec.rx_resource_requirements.num_time_slots
          }),
          supported_tx_power_dbm: [],
          txResourceRequirements: new ResourceRequirementRecord({
            numSubCarriers: rec.tx_resource_requirements.num_subcarriers,
            numTimeSlots: rec.tx_resource_requirements.num_time_slots
          })
        });
        newRec.push(airborneRadioModelRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.airborneRadioModels.isFetched({ airborneRadioModels })
      );
    } else {
      dispatch(flightsActions.inventory.airborneRadioModels.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.airborneRadioModels.isError());
  }
};
