import MapboxDraw from "@mapbox/mapbox-gl-draw";

const DirectSelect = MapboxDraw.modes.direct_select;

DirectSelect.toDisplayFeatures = function (state, geojson, push) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = MapboxDraw.constants.activeStates.ACTIVE;
    push(geojson);
    if (MapboxDraw.createSupplementaryPoints) {
      MapboxDraw.createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: false,
        selectedPaths: state.selectedCoordPaths
      }).forEach(push);
    }
  } else {
    geojson.properties.active = MapboxDraw.constants.activeStates.INACTIVE;
    push(geojson);
  }
  this.fireActionable(state);
};

export default DirectSelect;
