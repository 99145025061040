import { Record as ImmutableRecord } from "immutable";

export const FlightPlanRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  reservation: null,
  flightPath: null,
  selectedRoute: null,
  flightUuid: null,
  saveButtonText: "Make a Reservation",
  disableFlightPlan: false
});
