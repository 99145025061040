import { configureStore } from '@reduxjs/toolkit';
import { adminReducer } from './subpages/admin/store/reducers';
import { flightsReducer } from './subpages/user/store/reducers';
import { routePlanReducer } from './subpages/user/store/reducers/routes/route.plan.reducer';
import { routeListReducer } from './subpages/user/store/reducers/routes/routes.reducer';
import { flightPlanReducer } from './subpages/user/store/reducers/flights/flight.plan.reducer';
import { mapReducer } from './subpages/user/store/reducers/map/map.reducer';

const store = configureStore({
  reducer: {
    coverageBounds: adminReducer.coverageBounds,
    identity: adminReducer.identity,
    inventory: flightsReducer.inventory,
    flights: flightsReducer.flights,
    flightDetails: flightsReducer.flightDetails,
    flightPlan: flightPlanReducer,
    map: mapReducer,
    modals: flightsReducer.panels,
    resourceGrid: adminReducer.resourceGrid,
    routePlan: routePlanReducer,
    routeList: routeListReducer,
    sites: adminReducer.sites,
    siteDetails: adminReducer.siteDetails,
    siteResourceSchedules: adminReducer.siteResourceSchedules

  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
});

export default store;
