import axios from 'axios';
import Session from '../../../../../utils/session';
import { MapCoordinates } from '../../records/routes/route.plan.record';
import { routePlanActions } from '../../reducers/routes/route.plan.reducer';
import { convertToFlightOriginState, convertToRouteDtos } from '../../utils/routes/route.plan.util';
import { FLIGHT_PLANNING_API_URL_V2, ROUTES_ENDPOINT, LIFECYCLE_TEMPLATE_RESERVATION_ENDPOINT, RESERVATION_LIFECYCLE_API_URL, FLIGHT_PATH_ENDPOINT, DEACTIVATE_ROUTE, TEMPLATE_SIGNALS_ENDPOINT } from '../../../../../constants/api.endpoints';
import { toggleRouteListPanel, toggleRoutePlanPanel } from '../panels.action';
import { setFlightPath, setFlightPlans, setReservations, setTemplateSignals } from '../map/map.action';
import { convertFlightPlan, getErrorMessage } from '../../utils/common';
import { setFetchRoutesFlag } from './routes.action';

export const setFlightRoute = (data, showPreviousButton = false) => dispatch => {
  dispatch(routePlanActions.setFlightRoute({ data, showPreviousButton }));
};

export const createTemplateReservation = (flightPathUuid, deactivated, setErrorMessage) => async (dispatch, getState) => {
  try {
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Checking radio coverage" }));
    const { sitesByUuid } = getState().sites;
    dispatch(routePlanActions.isFetching());

    const response = await axios.post(
      `${RESERVATION_LIFECYCLE_API_URL}${LIFECYCLE_TEMPLATE_RESERVATION_ENDPOINT}${flightPathUuid}`,
      {},
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      const reservation = response.data;

      for (const leg of reservation?.legs) {
        if (leg?.site_uuid && leg?.site_uuid !== '') {
          leg.site_name = sitesByUuid[leg.site_uuid]?.name;
          leg.site_callsign = sitesByUuid[leg.site_uuid]?.callsign;
        }
      }
      if (!deactivated) {
        dispatch(routePlanActions.clear());
        dispatch(toggleRoutePlanPanel());
      } else {
        dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Check Radio Coverage" }));
        dispatch(setReservations([reservation]));
        dispatch(routePlanActions.isFetched());
      }

      const template_signals = await axios.get(
        `${RESERVATION_LIFECYCLE_API_URL}${TEMPLATE_SIGNALS_ENDPOINT}${flightPathUuid}`,
        Session.requestConfig()
      );
      if (Session.validationResponse(template_signals)) {
        const records = template_signals.data.records;
        const recordsDictionary = {};
        records.forEach(record => {
          const flightVertexUuid = record.flight_vertex_uuid;
          if (!recordsDictionary[flightVertexUuid]) {
            recordsDictionary[flightVertexUuid] = [record];
          } else {
            recordsDictionary[flightVertexUuid].push(record);
          }
        });

        dispatch(setTemplateSignals(recordsDictionary));
      } else {
        throw new Error();
      }
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Check Radio Coverage" }));
    dispatch(routePlanActions.isError());
  }
};

export const getFlightPath = (flightPathUuid, deactivated, setErrorMessage, flightPlan) => async dispatch => {
  try {
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Fetching Flight Path" }));
    dispatch(routePlanActions.isFetching());

    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${FLIGHT_PATH_ENDPOINT}${flightPathUuid}`,
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      const flightPath = response.data;
      dispatch(setFlightPath(flightPath));
      const newFlightPlan = convertFlightPlan(flightPlan);
      dispatch(setFlightPlans([convertToFlightOriginState(newFlightPlan)]));
      dispatch(createTemplateReservation(flightPathUuid, deactivated, setErrorMessage));
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Check Radio Coverage" }));
    dispatch(routePlanActions.isError());
  }
};

export const createRoute = (routeValues, deactivated, setErrorMessage, setWarnings) => async (dispatch, getState) => {
  try {
    setErrorMessage(null);
    setWarnings([]);
    dispatch(setReservations(null));
    dispatch(routePlanActions.isFetching());
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Creating Route" }));
    const routeRequestBody = convertToRouteDtos(routeValues);
    routeRequestBody.deactivated = deactivated;
    const { routeUuid } = getState().routePlan;
    let response = null;
    if (routeUuid) {
      response = await axios.put(
        `${FLIGHT_PLANNING_API_URL_V2}${ROUTES_ENDPOINT}${routeUuid}`,
        { ...routeRequestBody },
        Session.requestConfig()
      );
    } else {
      response = await axios.post(
        `${FLIGHT_PLANNING_API_URL_V2}${ROUTES_ENDPOINT}`,
        { ...routeRequestBody },
        Session.requestConfig()
      );
    }
    if (Session.validationResponse(response)) {
      dispatch(setFetchRoutesFlag(true));
      const flightPathUuid = response.data.flight_plan?.flight_path_uuid;
      const routeUuid = response.data.uuid;
      const flightPlan = response.data.flight_plan;
      setWarnings(response.data.warnings);
      dispatch(getFlightPath(flightPathUuid, deactivated, setErrorMessage, flightPlan));
      dispatch(setFlightRoute(response.data.flight_plan));
      dispatch(setRouteUuid(routeUuid));
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Check Radio Coverage" }));
    dispatch(routePlanActions.isError());
  }
};

export const deleteRoute = () => async (dispatch, getState) => {
  try {
    dispatch(routePlanActions.isFetching());
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Deleting Route" }));
    const { routeUuid } = getState().routePlan;
    const response = await axios.delete(
      `${FLIGHT_PLANNING_API_URL_V2}${ROUTES_ENDPOINT}${routeUuid}`,
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      dispatch(routePlanActions.clear());
      dispatch(toggleRouteListPanel());
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Check Radio Coverage" }));
    dispatch(routePlanActions.isError());
  }
};

export const deactivateRoute = () => async (dispatch, getState) => {
  try {
    dispatch(routePlanActions.isFetching());
    const { routeUuid } = getState().routePlan;
    const response = await axios.put(
      `${FLIGHT_PLANNING_API_URL_V2}${DEACTIVATE_ROUTE}${routeUuid}`,
      {},
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      dispatch(routePlanActions.clear());
      dispatch(toggleRouteListPanel());
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(routePlanActions.setSaveButtonText({ saveButtonText: "Check Radio Coverage" }));
    dispatch(routePlanActions.isError());
  }
};

export const clearFlightRoute = () => dispatch => {
  dispatch(routePlanActions.clear());
};

export const setMapboxCoordinates = mapCoordinates => (dispatch, getState) => {
  const { editingDeparture, editingDestination, addingWaypoint, addingAlternate } = getState().routePlan;
  if (editingDeparture || editingDestination || addingWaypoint || addingAlternate) {
    dispatch(routePlanActions.mapCoordinates({ mapCoordinates }));
  };
};

export const clearMapboxCoordinates = () => dispatch => {
  dispatch(routePlanActions.mapCoordinates({ mapCoordinates: new MapCoordinates({}) }));
};

export const isEditingDestination = editingDestination => (dispatch) => {
  dispatch(routePlanActions.isEditingDestination({ editingDestination }));
};

export const isEditingDeparture = editingDeparture => (dispatch) => {
  dispatch(routePlanActions.isEditingDeparture({ editingDeparture }));
};

export const isAddingWaypoint = addingWaypoint => (dispatch) => {
  dispatch(routePlanActions.isAddingWaypoint({ addingWaypoint }));
};

export const isAddingAlternate = addingAlternate => (dispatch) => {
  dispatch(routePlanActions.isAddingAlternate({ addingAlternate }));
};

export const setRouteUuid = routeUuid => dispatch => {
  dispatch(routePlanActions.setRouteUuid({ routeUuid }));
};

export const setSelectedRoute = ({ selectedRoute, parentComponent = null, flightPath = null, disableRoute = false, editRoute = false }) => dispatch => {
  const { uuid } = editRoute ? selectedRoute : {};
  dispatch(routePlanActions.setSelectedRoute({ selectedRoute, parentComponent, disableRoute, uuid }));
  dispatch(toggleRoutePlanPanel());
  if (flightPath) {
    dispatch(setFlightPath(flightPath));
  } else if (selectedRoute.flightPlan) {
    const newFlightOrigin = convertToFlightOriginState(selectedRoute?.flightPlan);
    dispatch(setFlightPlans([newFlightOrigin]));
  };
};

export const setDisconnectedNodes = disconnectedNodes => dispatch => {
  dispatch(routePlanActions.setDisconnectedNodes({ disconnectedNodes }));
};

export const setDisableRoute = disableRoute => dispatch => {
  dispatch(routePlanActions.setDisableRoute({ disableRoute }));
};

export const clearRoutePlan = () => dispatch => {
  dispatch(routePlanActions.clear());
};
