/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { Fragment, useState } from "react";
import { Form, Button, Col, Row, Spinner } from "react-bootstrap";
import { Field, Form as FinalForm } from "react-final-form";
import _ from "lodash";
import FormField from "../../../components/FormFieldV2/FormField";
import FormSelect from "../../../components/FormSelectV2/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import TerminusEntry from "../../../components/TerminusEntry/TerminusEntry";
import WaypointsEntry from "../../../components/WaypointsEntry/WaypointsEntry";
import AlternatesEntry from "../../../components/AlternatesEntry/AlternatesEntry";
import SubwayMap from "../../../components/MapVisualization/SubwayMap";
import Reservation from "../requestform/Reservation";
import {
  convertToInitialFormValues,
  calculateGraphEdges,
} from "../store/utils/routes/route.plan.util";
import validate from "../store/utils/routes/validate";
import {
  toggleFlightDetailsPanel,
  toggleRouteListPanel,
  toggleRoutePlanPanel,
} from "../store/actions/panels.action";
import {
  clearRoutePlan,
  createRoute,
  deactivateRoute,
  deleteRoute,
  isAddingAlternate,
  isAddingWaypoint,
  isEditingDeparture,
  isEditingDestination,
  setDisconnectedNodes
} from "../store/actions/routes/route.plan.action";
import { setFlightOrigin, setFlightPath, setReservations, setIsdrawingEnabled } from "../store/actions/map/map.action";
import "../requestform/FlightPlan.css";
import { FLIGHT_DETAIL_COMPONENT, ROUTE_LIST_COMPONENT, ROUTE_PLAN_COMPONENT } from "../../../constants/constants";
import "../../../styles.css";
import { displayUid } from "../requestform/util";
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";

const RoutePlan = () => {
  const dispatch = useDispatch();

  const routePlan = useSelector(({ routePlan }) => routePlan);
  const identity = useSelector(({ identity }) => identity.identity);
  const inventory = useSelector(({ inventory }) => inventory);
  const map = useSelector(({ map }) => map);

  const { flightOrigin, flightPath, reservations, isDrawingEnabled } = map;
  const { aircraftModels, antennaModels, airborneRadioModels } = inventory;
  const {
    isFetching,
    editingDeparture,
    editingDestination,
    addingWaypoint,
    addingAlternate,
    mapCoordinates,
    selectedRoute,
    saveButtonText,
    parentComponent,
    disableRoute,
    disconnectedNodes
  } = routePlan;

  const [warningMessages, setWarningMessages] = useState([]);
  const [errorResponse, setErrorResponse] = useState("");
  const [formPrevState, setFormState] = useState(null);

  const setDisconnectedGraph = (nodes, departure, waypoints, alternates) => {
    if (departure?.next && !departure.next.length && (waypoints?.length || alternates?.length)) {
      nodes.push({
        label: departure.name, uid: departure.uid
      });
    };
    waypoints?.forEach(wp => {
      if (wp?.next && !wp?.next?.length && !_.find({ label: wp.icao_description || wp.name || displayUid(wp.uid) }, nodes)) {
        nodes.push({
          label: wp.icao_description || wp.name || displayUid(wp.uid), uid: wp.uid
        });
      }
    });
    dispatch(setDisconnectedNodes(nodes));
  };

  const handleSave = routeValues => {
    dispatch(createRoute(routeValues, true, setErrorResponse, setWarningMessages));
  };

  const handleCreateRoute = (deactivated = false) => {
    dispatch(deactivateRoute(deactivated));
  };

  const handleSaveDeparture = ([departure], state, utils) => {
    utils.changeValue(state, "departure", () => departure);
    dispatch(setFlightOrigin({ ...flightOrigin, departure }));
  };

  const handleSaveWaypoints = ([waypoints, departure = null], state, utils) => {
    utils.changeValue(state, "waypoints", () => waypoints);
    if (departure) {
      utils.changeValue(state, "departure", () => departure);
      dispatch(setFlightOrigin({ ...flightOrigin, waypoints, departure }));
    } else {
      dispatch(setFlightOrigin({ ...flightOrigin, waypoints }));
    }
  };

  const handleSaveDestination = ([destination], state, utils) => {
    const {
      formState: {
        values: { departure, waypoints, alternates },
      },
    } = state;
    if (!waypoints?.length && !alternates.length && !departure?.next?.length) {
      departure.next.push(1);
    }
    utils.changeValue(state, "destination", () => destination);
    dispatch(setFlightOrigin({ ...flightOrigin, destination, departure }));
  };

  const handleSaveAlternates = ([alternates, waypoints = null, departure = null], state, utils) => {
    utils.changeValue(state, "alternates", () => alternates);
    if (departure || waypoints) {
      utils.changeValue(state, "departure", () => departure);
      dispatch(setFlightOrigin({ ...flightOrigin, alternates, waypoints, departure }));
    } else {
      dispatch(setFlightOrigin({ ...flightOrigin, alternates }));
    }
  };

  const handleDeleteRoute = () => {
    dispatch(deleteRoute());
  };

  const handleUpdateDestinationDate = ([index], state, utils) => {
    const {
      formState: {
        values: { destination },
      },
    } = state;

    utils.changeValue(state, "destination", () => ({
      ...destination
    }));

    const remainingWarningMessages = [...warningMessages];
    remainingWarningMessages.splice(index, 1);

    setWarningMessages(remainingWarningMessages);
    dispatch(
      setFlightOrigin({
        ...flightOrigin,
        destination: { ...destination },
      })
    );
  };

  const handleClose = () => {
    dispatch(clearRoutePlan());
    switch (parentComponent) {
      case ROUTE_LIST_COMPONENT:
        dispatch(toggleRouteListPanel());
        dispatch(setIsdrawingEnabled(false));
        break;
      case FLIGHT_DETAIL_COMPONENT:
        dispatch(toggleFlightDetailsPanel());
        break;
      default:
        dispatch(toggleRoutePlanPanel());
        break;
    }
  };

  return (
    <Fragment>
      <div className="panel">
        <div className="panel-header">
          <h2>{disableRoute ? "Route Details" : "Plan a Route"}</h2>
          <HelpToolTip content="Input a Flight Plan, determine whether the flight path (or paths) are within AURA's radio
          network coverage, and create a Route as a 'template' for future Flights." />
          <a
            className="close-button"
            onClick={handleClose}
          >
            <i className="fa-solid fa-circle-xmark" />
          </a>
        </div>
        <div className="panel-body">
          <FinalForm
            initialValues={convertToInitialFormValues(selectedRoute, aircraftModels, antennaModels, airborneRadioModels)}
            initialValuesEqual={() => true}
            mutators={{
              saveDeparture: handleSaveDeparture,
              saveWaypoints: handleSaveWaypoints,
              saveDestination: handleSaveDestination,
              updateDestinationDate: handleUpdateDestinationDate,
              saveAlternates: handleSaveAlternates,
            }}
            validate={validate}
            onSubmit={handleSave}
          >
            {({ form, handleSubmit }) => {
              const {
                mutators: {
                  saveDeparture,
                  saveWaypoints,
                  saveDestination,
                  updateDestinationDate,
                  saveAlternates,
                },
              } = form;

              const {
                values,
                submitErrors,
                submitFailed,
                hasValidationErrors
              } = form.getState();

              if (!_.isEqual(formPrevState, values) && reservations) {
                dispatch(setReservations(null));
                dispatch(setFlightPath(null));
              } else {
                setFormState(values);
              }

              window.flightPlanMapInteration = {
                saveDeparture,
                saveWaypoints
              };

              const { departure, waypoints, destination, alternates } = values;

              if (flightOrigin.initialLoad && departure) {
                dispatch(
                  setFlightOrigin({
                    departure,
                    waypoints,
                    destination,
                    alternates,
                    initialLoad: false,
                    flightPathUuid: selectedRoute?.flightPlan?.flightPathUuid || null
                  })
                );
              }

              if (submitFailed && hasValidationErrors) {
                setErrorResponse("One or more fields contains invalid input.");
              } else if (errorResponse === "") {
                setErrorResponse(submitErrors);
              }

              const disableButtons =
                editingDeparture ||
                editingDestination ||
                addingWaypoint ||
                addingAlternate ||
                isDrawingEnabled ||
                isFetching;

              const readOnly = isDrawingEnabled || disableRoute;

              return (
                <>
                  <fieldset disabled={selectedRoute?.disableRoute ? "disabled" : ""}>
                    <h3 className="mb-3">Overview <HelpToolTip content="Routes must define which models of aircraft, and
                    which models of radio hardware, which will be used, to make realistic assumptions when assessing
                    radio coverage and signal strength. After creating a Flight from this Route, prior to takeoff,
                    you will be required to select a particular aircraft of the correct model to fly that particular Flight." /></h3>
                    <Row>
                      <Col xs={8}>
                        <Field
                          type="text"
                          name="name"
                          label="Name *"
                          readOnly={readOnly}
                          groupClassName="align-items-center d-flex"
                          labelClassName="fw-bold form-label col-md-3 col-sm-3"
                          fieldClassName="input-sm form-control"
                          component={FormField}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={4}>
                        <Field
                          name="aircraftModel"
                          label="Aircraft Model *"
                          data-testid="aircraft-model"
                          readOnly={readOnly}
                          component={FormSelect}
                        >
                          <option disabled={readOnly} value="">Select</option>
                          {aircraftModels?.aircraftModels?.map((aircraftModel) => (
                            <option key={aircraftModel.uuid} value={aircraftModel.uuid}>
                              {aircraftModel.name}
                            </option>
                          ))}
                        </Field>
                      </Col>
                      <Col xs={4}>
                        <Field
                          name="radioModel"
                          label="Radio Model *"
                          data-testid="radio-model"
                          readOnly={readOnly}
                          component={FormSelect}
                        >
                          <option disabled={readOnly} value="">Select</option>
                          {airborneRadioModels?.airborneRadioModels?.map((airborneRadioModel) => (
                            <option key={airborneRadioModel.uuid} value={airborneRadioModel.uuid}>
                              {airborneRadioModel.name}
                            </option>
                          ))}
                        </Field>
                      </Col>
                      <Col xs={4}>
                        <Field
                          name="antennaModel"
                          label="Antenna Model *"
                          data-testid="antenna-model"
                          readOnly={readOnly}
                          component={FormSelect}
                        >
                          <option disabled={readOnly} value="">Select</option>
                          {antennaModels?.antennaModels?.map((antennaModel) => (
                            <option key={antennaModel.uuid} value={antennaModel.uuid}>
                              {antennaModel.name}
                            </option>
                          ))}
                        </Field>
                      </Col>
                    </Row>
                    <h3 className="my-3">Nominal Flight Plan <HelpToolTip content="Specify a Flight Plan for nominal scenarios,
                    and check whether it is within the AURA network's radio coverage.\n
                    Unlike conventional Flight Plans, multiple 'branches' are supported, allowing several different
                    sequences of Waypoints to be provided. This allows all likely ATC procedures - e.g., SIDs and STARS -
                    to be included in the consideration of radio coverage.\n
                    A future version of DFAS will consider off-nominal scenarios, and 'smart' addition of
                    charted procedures." /></h3>
                    <Row className="align-items-center">
                      <Form.Label as={Col} xs={2} className="fw-bold">Cruising *</Form.Label>
                      <Col xs={2}>
                        <Field
                          type="number"
                          name="altitudeM"
                          readOnly={readOnly}
                          fieldClassName="input-sm form-control mx-1"
                          component={FormField}
                        />
                      </Col>
                      <Form.Label className="fw-normal mx-2 w-12">ft
                        <HelpToolTip content="Cruising altitude in feet Above Mean Sea Level, as found by a GPS altimeter (i.e., height above the smooth WGS84 ellipsoid).
                        Note this differs from altitude from a barometric pressure altimeter calibrated to airport elevations (i.e., height above the lumpy geoid).
                        By this definition, much of the US has a negative altitude at ground level." /></Form.Label>
                      <Col xs={2}>
                        <Field
                          type="number"
                          name="trueAirspeedMs"
                          readOnly={readOnly}
                          fieldClassName="input-sm form-control mx-1"
                          component={FormField}
                        />
                      </Col>
                      <Form.Label as={Col} xs={2} className="fw-normal mx-2">knots</Form.Label>
                    </Row>
                    <Row className="align-items-center">
                    <Form.Label as={Col} xs={5} className="fw-bold">Estimated Time of Departure (ETD) Uncertainty</Form.Label>
                      <Col xs={2}>
                        <Field
                          type="number"
                          name="departureTimeUncertainty"
                          readOnly={readOnly}
                          groupClassName="align-items-center d-flex"
                          fieldClassName="input-sm form-control mx-1"
                          component={FormField}
                        />
                      </Col>
                      <Form.Label as={Col} xs={2} className="fw-normal">
                        minutes
                        <HelpToolTip content="A Reservation for a Flight to use AURA's Radio Network includes this 'buffer'
                        time to account for small deviations from the Estimated Time of Departure (ETD)." />
                      </Form.Label>
                    </Row>
                    <Row className="align-items-center">
                      <Form.Label as={Col} xs={4} className="fw-bold">Position Uncertainty *</Form.Label>
                      <Col xs={2}>
                        <Field
                          type="number"
                          name="horizontal"
                          readOnly={readOnly}
                          groupClassName="align-items-center d-flex"
                          fieldClassName="input-sm form-control"
                          component={FormField}
                        />
                      </Col>
                      <Form.Label className="fw-normal m-1 w-20">
                        m
                        <HelpToolTip content="A Flight can deviate from its Nominal Flight Path by this distance and
                        remain in coverage of AURA's radio network." />
                      </Form.Label>
                    </Row>
                    <Row className={"planning"}>
                      <Col md={12}>
                        <div className="waypoints-list">
                          <TerminusEntry
                            terminus={departure}
                            terminusName="Departure"
                            onSave={saveDeparture}
                            onToggleEditing={(b) =>
                              dispatch(isEditingDeparture(b))
                            }
                            latestMapClick={mapCoordinates}
                            disabled={
                              editingDestination ||
                              addingWaypoint ||
                              addingAlternate ||
                              readOnly
                            }
                            editing={editingDeparture}
                            routes={[]}
                            destination={destination}
                            waypoints={waypoints}
                            alternates={alternates}
                          />
                          {departure?.latitude && destination?.latitude && (
                            <WaypointsEntry
                              waypoints={waypoints}
                              departure={departure}
                              destination={destination}
                              alternates={alternates}
                              onSave={saveWaypoints}
                              mutateDeparture={saveDeparture}
                              onToggleEditing={(b) =>
                                dispatch(isAddingWaypoint(b))
                              }
                              latestMapClick={mapCoordinates}
                              disabled={
                                editingDeparture ||
                                editingDestination ||
                                addingAlternate ||
                                readOnly
                              }
                              editing={!!addingWaypoint}
                              routes={[]}
                            />
                          )}
                          <TerminusEntry
                            terminus={destination}
                            terminusName="Destination"
                            onSave={saveDestination}
                            onToggleEditing={(b) =>
                              dispatch(isEditingDestination(b))
                            }
                            latestMapClick={mapCoordinates}
                            disabled={
                              editingDeparture ||
                              addingWaypoint ||
                              addingAlternate ||
                              readOnly
                            }
                            editing={editingDestination}
                            routes={[]}
                            isDestination
                          />
                          {departure?.latitude && destination?.latitude && (
                            <AlternatesEntry
                              alternates={alternates}
                              editing={!!addingAlternate}
                              disabled={
                                editingDeparture ||
                                editingDestination ||
                                addingWaypoint ||
                                addingAlternate ||
                                readOnly
                              }
                              routes={[]}
                              onSave={saveAlternates}
                              onToggleEditing={(b) =>
                                dispatch(isAddingAlternate(b))
                              }
                              latestMapClick={mapCoordinates}
                              departure={departure}
                              waypoints={waypoints}
                              mutateDeparture={saveDeparture}
                              mutateWaypoints={saveWaypoints}
                            />
                          )}
                        </div>
                        <SubwayMap
                          departure={departure}
                          destination={destination}
                          alternates={alternates}
                          calculateGraphEdges={calculateGraphEdges}
                          waypoints={waypoints}
                          setDisconnectedGraph={setDisconnectedGraph}
                        />
                      </Col>
                    </Row>
                  </fieldset>
                  <Row>
                    <Col md={12} className="text-end">
                      {selectedRoute && (
                        <Button
                          variant="secondary"
                          disabled={disableButtons}
                          onClick={handleClose}
                          className="ms-1"
                        >
                          <i className="fas fa-angle-left" /> {parentComponent}
                        </Button>
                      )}
                        {!disableRoute && (<Button
                          variant="primary"
                          className="btn-icon-after reservation-icon"
                          onClick={handleSubmit}
                          disabled={disableButtons || hasValidationErrors || disconnectedNodes?.length}
                        >
                          {isFetching && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          {saveButtonText}
                        </Button>)}
                      {(errorResponse || disconnectedNodes?.length > 0) && (
                        <div
                          className="error-message"
                          id="overall-error-message"
                        >
                          Error: { errorResponse ||
                                  `${Array.from(new Set(disconnectedNodes.filter(obj => obj.cyclic).map(obj => obj.label))).join(', ')}` ||
                                  `${Array.from(new Set(disconnectedNodes.map(obj => obj.label))).join(', ')} 
                                  ${disconnectedNodes.length === 1 ? 'is' : 'are'} not connected to the route (connect waypoints and airports by clicking them on the map).`
                                  }
                        </div>
                      )}
                      {warningMessages &&
                        warningMessages.map((w, i) => (
                          <div className="warning-message mt-2" key={i}>
                            Warning: {w}
                            {w.includes("ETA") && (
                              <Button
                                variant="outline-primary"
                                size="sm"
                                className="ms-2"
                                disabled={disableButtons}
                                onClick={() => updateDestinationDate(i)}
                              >
                                Use calculated ETA
                              </Button>
                            )}
                          </div>
                        ))}
                    </Col>
                  </Row>
                  <fieldset disabled={form.getState().dirtySinceLastSubmit}>
                    {reservations && !hasValidationErrors && (
                      <Reservation
                        reservation={reservations[0]}
                        disabled={disableButtons}
                        identity={identity}
                        parentComponent={ ROUTE_PLAN_COMPONENT }
                        handleRouteSubmit={() => handleCreateRoute()}
                        handleDeleteRoute={handleDeleteRoute}
                        flightPath={ flightPath || null}
                      />
                    )}
                  </fieldset>
                </>
              );
            }}
          </FinalForm>
        </div>
      </div>
    </Fragment>
  );
};

export default RoutePlan;
