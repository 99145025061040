import { adminActions } from "../subpages/admin/store/reducers";
import { flightsActions } from "../subpages/user/store/reducers";

const clearReduxState = () => dispatch => {
  if (dispatch) {
    dispatch(adminActions.coverageBounds.clear());
    dispatch(adminActions.identity.clear());
    dispatch(adminActions.resourceGrid.clear());
    dispatch(adminActions.siteDetails.clear());
    dispatch(adminActions.sites.clear());
    dispatch(flightsActions.flightDetails.clear());
    dispatch(flightsActions.flightPlan.clear());
    dispatch(flightsActions.flights.clear());
    dispatch(flightsActions.panels.clear());
  }
};

export default clearReduxState();
