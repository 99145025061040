import axios from 'axios';
import { List as ImmutableList } from 'immutable';
import { FLIGHT_PLANNING_API_URL_V2, COVERAGE_BOUNDS_ENDPOINT } from "../../../../constants/api.endpoints";
import Session from '../../../../utils/session';
import { adminActions } from "../reducers";
import { CoverageBoundRecord, PaginationDetailRecord } from '../records/coverage.bounds.record.js';
import { MetadataRecord } from '../records/general.record';

const getCoverageBounds = () => async dispatch => {
  try {
    dispatch(adminActions.coverageBounds.isFetching);
    const response = await axios.get(`${FLIGHT_PLANNING_API_URL_V2}${COVERAGE_BOUNDS_ENDPOINT}`, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const coverageBounds = response.data.records;
      const paginationDetails = new PaginationDetailRecord({
        page: response.data.pagination_details.page,
        perPage: response.data.pagination_details.per_page,
      });
      const records = response?.data.records?.reduce((newRec, rec) => {
        return newRec.push(
          new CoverageBoundRecord({
            metadata: new MetadataRecord({
              created: rec.metadata.created,
              createdBy: rec.metadata.created_by
            }),
            geojson: rec.geojson,
            power: rec.power,
            rawCoverageBoundsUuids: rec.raw_coverage_bounds_uuids,
            siteUuids: rec.site_uuids,
            uuid: rec.uuid
          })
        );
      }, new ImmutableList());
      dispatch(adminActions.coverageBounds.isFetched({ paginationDetails, records, coverageBounds }));
    } else {
      // todo => show generic failure message on UI, create error boundary to show error message
      dispatch(adminActions.coverageBounds.isError);
    }
  } catch (err) {
    dispatch(adminActions.coverageBounds.isError);
  }
};

export default getCoverageBounds;
