import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { tubeMap } from "d3-tube-map";
import _ from 'lodash';
import { gray, green, red, black } from './Colors';
import { v4 as uuidv4 } from "uuid";
import { round } from "../../utils/units";
import { displayUid } from "../../subpages/user/requestform/util";
import './SubwayMap.css';

const d3 = require('d3');
const MAX_NODES = 30; // maximum points allowed in large subway map
const X_SCALE_FACTOR = 6; // don't set this <=3
const Y_SCALE_FACTOR_SMALL = 4; // don't set this <=3
const Y_SCALE_FACTOR_LARGE = 6; // don't set this <=3
const MARGIN = { top: 10, right: 20, bottom: 10, left: 10 };
const MARKER_RADIUS = 10;

const traverseGraphForLoops = (graphEdges, currentVertex, visitedVertices, errors) => {
  if (errors.length) return;
  const nextVertices = [];

  for (const [start, end] of graphEdges) {
    if (start === currentVertex) {
      nextVertices.push(end);

      if (visitedVertices.includes(end)) {
        errors.push(end);
        return;
      }
    }
  }

  const updatedVisitedVertices = [...visitedVertices, currentVertex];

  for (const nextVertex of nextVertices) {
    traverseGraphForLoops(graphEdges, nextVertex, updatedVisitedVertices, errors);
  }
};

const getSubwayLabel = (label) => {
  return label.length > 5 ? label.substring(0, 4) + '…' : label;
};

const flightPlanToGraph = (departure, destination, alternates, waypoints, calculateGraphEdges) => {
  const graphEdges = calculateGraphEdges(departure, waypoints, alternates);
  const nodes = [];

  nodes.push({
    index: 0,
    name: "0",
    label: getSubwayLabel(departure.airport_code || departure.name || 'Dept.'),
    type: "departure",
    isMajor: true,
    ancestorNodeIndexes: new Set(),
    previousNodeIndexes: [],
    nextNodeIndexes: [],
    distances: [],
    heights: [],
    reachable: true,
    uid: departure.uid || 0
  });
  nodes.push({
    index: 1,
    name: "1",
    label: getSubwayLabel(destination.airport_code || destination.name || 'Dest.'),
    type: "destination",
    isMajor: true,
    ancestorNodeIndexes: new Set(),
    previousNodeIndexes: [],
    nextNodeIndexes: [],
    distances: [],
    heights: [],
    reachable: false,
    uid: destination.uid || 1
  });
  nodes.push(...alternates.map((alternate, index) => ({
    index: 2 + index,
    name: `${2 + index}`,
    label: getSubwayLabel(alternate.airport_code || alternate.name || (alternate.uid ? displayUid(alternate.uid) : `Alternate ${index + 1}`)),
    type: "alternate",
    isMajor: true,
    ancestorNodeIndexes: new Set(),
    previousNodeIndexes: [],
    nextNodeIndexes: [],
    distances: [],
    heights: [],
    reachable: false,
    uid: alternate.uid
  })));
  nodes.push(...waypoints.sort((wp1, wp2) => wp1.uid - wp2.uid).map((waypoint, index) => ({
    index: 2 + alternates.length + index,
    name: `${2 + alternates.length + index}`,
    label: getSubwayLabel(waypoint.icao_description || waypoint.name || waypoint.name || (waypoint.uid ? displayUid(waypoint.uid) : `Waypoint ${index + 1}`)),
    type: "waypoint",
    isMajor: true,
    ancestorNodeIndexes: new Set(),
    previousNodeIndexes: [],
    nextNodeIndexes: [],
    distances: [],
    heights: [],
    reachable: false,
    uid: waypoint.uid
  })));

  graphEdges?.forEach(graphEdge => {
    nodes[graphEdge[0]].nextNodeIndexes.push(graphEdge[1]);
    nodes[graphEdge[1]].previousNodeIndexes.push(graphEdge[0]);
  });

  return {
    nodes,
    edges: graphEdges
  };
};

const flightPathToNodes = (vertices_geojson, originalGraphEdges, reservation) => {
  const verticesGeojson = _.cloneDeep(vertices_geojson);
  const graphEdges = _.cloneDeep(originalGraphEdges);
  const nodesInCoverage = {};
  const coveringSitesByNode = {};

  if (reservation) {
    reservation.legs.forEach(leg => leg.flight_vertex_uuids
      .forEach(uuid => {
        if (leg.site_uuid === null || leg.site_uuid === "") {
          nodesInCoverage[uuid] = false;
        } else {
          nodesInCoverage[uuid] = true;
          if (!coveringSitesByNode[uuid]) { coveringSitesByNode[uuid] = []; }
          coveringSitesByNode[uuid].push(leg.site_uuid);
        }
      }));
  }

  const totalNodes = verticesGeojson.features.length;
  let nodeRemovalPercentage = 0;
  if (totalNodes > MAX_NODES) {
    nodeRemovalPercentage = MAX_NODES / totalNodes;
  }
  const totalNodesBetweenAllVertices = [];
  let pointsBetweenVertices = [];
  let startVertex = '';
  let totalNodesBetweenTwoVertices = 0;
  let remainingNodes = [];
  let isCoverageSwitched = false;
  graphEdges.forEach((graphEdge, graphIdx) => {
    const startEdge = graphEdge[0];
    const endEdge = graphEdge[1];
    const vertexOne = verticesGeojson.features[startEdge];
    const vertexTwo = verticesGeojson.features[endEdge];
    const isVertexOneMajor = vertexOne.properties.name && !(['Change of pitch', 'Midpoint', ''].includes(vertexOne.properties.name));
    const isVertexTwoMajor = vertexTwo.properties.name && !(['Change of pitch', 'Midpoint', ''].includes(vertexTwo.properties.name));
    const nodeOneHasCoverage = reservation ? nodesInCoverage[vertexOne.properties.uuid] : undefined;
    const nodeTwoHasCoverage = reservation ? nodesInCoverage[vertexOne.properties.uuid] : undefined;

    if (reservation && (nodeOneHasCoverage !== nodeTwoHasCoverage || nodeOneHasCoverage !== isCoverageSwitched || nodeTwoHasCoverage !== isCoverageSwitched)) {
      remainingNodes.push(startEdge);
      remainingNodes.push(endEdge);
      isCoverageSwitched = !isCoverageSwitched;
    }
    if (nodeRemovalPercentage === 0) {
      remainingNodes.push(startEdge);
      if (isVertexTwoMajor) {
        remainingNodes.push(endEdge);
      }
    } else if (isVertexOneMajor) {
      pointsBetweenVertices.push(startEdge);
      totalNodesBetweenTwoVertices++;
      startVertex = vertexOne.properties.name;
    } else if (isVertexTwoMajor) {
      const maxAllowedNodes = Math.ceil(totalNodesBetweenTwoVertices / (totalNodesBetweenTwoVertices * nodeRemovalPercentage));
      const allowedNodes = pointsBetweenVertices.filter((nodeIdx, idx) => {
        if ([0, 1, pointsBetweenVertices.length - 2, pointsBetweenVertices.length - 1].includes(idx)) return true;
        return nodeIdx % maxAllowedNodes === 0;
      });
      allowedNodes.push(startEdge);
      allowedNodes.push(endEdge);
      totalNodesBetweenAllVertices.push({
        startVertex,
        end: vertexTwo.properties.name,
        totalNodesBetweenTwoVertices,
        allowedNodes
      });
      pointsBetweenVertices = [];
      totalNodesBetweenTwoVertices = 0;
      remainingNodes.push(...allowedNodes);
      startVertex = '';
    } else {
      pointsBetweenVertices.push(graphIdx);
      totalNodesBetweenTwoVertices++;
    }
  });
  remainingNodes = Array.from(new Set([...remainingNodes])).sort((a, b) => a - b);
  const nodes = verticesGeojson.features.filter((f, i) => remainingNodes.indexOf(i) >= 0).map((f, i) => {
    const isMajor = f.properties.name && !(['Change of pitch', 'Midpoint', ''].includes(f.properties.name));

    return {
      index: i,
      name: f.properties.uuid,
      label: isMajor ? getSubwayLabel(f.properties.name) : " ",
      type: '',
      isMajor,
      ancestorNodeIndexes: new Set(),
      previousNodeIndexes: [],
      nextNodeIndexes: [],
      distances: [],
      heights: [],
      reachable: i === 0,
      hasCoverage: reservation ? nodesInCoverage[f.properties.uuid] : undefined,
      coveringSites: reservation ? coveringSitesByNode[f.properties.uuid] || [] : []
    };
  });

  const newGraphEdges = graphEdges?.filter((f, i) => remainingNodes.indexOf(f[0]) >= 0);
  newGraphEdges.forEach((graphEdge, graphIdx) => {
    if (remainingNodes.indexOf(graphEdge[1]) === -1 && graphIdx < newGraphEdges.length) {
      if (graphIdx === newGraphEdges.length - 1) {
        graphEdge[1] = remainingNodes.length - 1;
      } else if (newGraphEdges[graphIdx + 1][0] < graphEdge[1]) {
        graphEdge[1] = remainingNodes.indexOf(graphEdge[0]) + 1;
      } else {
        graphEdge[1] = remainingNodes.indexOf(newGraphEdges[graphIdx + 1][0]);
      }
    } else {
      graphEdge[1] = remainingNodes.indexOf(graphEdge[1]);
    }
    graphEdge[0] = remainingNodes.indexOf(graphEdge[0]);
    nodes[graphEdge[0]].nextNodeIndexes.push(graphEdge[1]);
    nodes[graphEdge[1]].previousNodeIndexes.push(graphEdge[0]);
  });

  return {
    nodes,
    edges: newGraphEdges
  };
};

const traverseNodesAndPopulateDistanceAndAncestors = (nodes, currentNodeIndex = 0, currentNodeDistance = 0, ancestorNodeIndexes = []) => {
  const node = nodes[currentNodeIndex];

  node.distances.push(currentNodeDistance);
  ancestorNodeIndexes.forEach(i => node.ancestorNodeIndexes.add(i));
  const nextNodes = node.nextNodeIndexes.map(i => nodes[i]);
  nextNodes.forEach((nn, i) =>
    traverseNodesAndPopulateDistanceAndAncestors(
      nodes,
      nn.index,
      currentNodeDistance + 1,
      [...node.ancestorNodeIndexes, currentNodeIndex])
  );
};

const traverseNodesAndPopulateHeightForMapSubway = (nodes, currentNodeIndex = 0, currentNodeHeight = 0) => {
  const node = nodes[currentNodeIndex];
  node.heights.push(currentNodeHeight);
  const nextNodes = node.nextNodeIndexes.map(i => nodes[i]);
  if (nextNodes.length > 1) {
    const nextNodeSiblingsAlsoAncestorsCount = {};
    nextNodes.forEach(nn => { nextNodeSiblingsAlsoAncestorsCount[nn.index] = nextNodes.filter(nnn => nn.ancestorNodeIndexes.has(nnn.index)).length; });
    nextNodes.sort((a, b) => (nextNodeSiblingsAlsoAncestorsCount[b.index] - nextNodeSiblingsAlsoAncestorsCount[a.index]) || (a.index - b.index));
  }

  nextNodes.forEach((nn, i) => {
    currentNodeHeight = currentNodeHeight + i;
    if (i > 0) {
      const siblingNode = nextNodes[i - 1];
      currentNodeHeight = siblingNode.maxHeightAtCurrentLevel ? siblingNode.maxHeightAtCurrentLevel + currentNodeHeight : currentNodeHeight;
    }
    if (!nn.traversed) {
      traverseNodesAndPopulateHeightForMapSubway(
        nodes,
        nn.index,
        currentNodeHeight);
    }
    if (!nn.nextNodeIndexes.length && currentNodeHeight > 0) {
      nn.ancestorNodeIndexes.forEach(n => {
        const node = nodes[n];
        node.maxHeightAtCurrentLevel = node.maxHeightAtCurrentLevel ? Math.max(node.maxHeightAtCurrentLevel, currentNodeHeight) : currentNodeHeight;
      });
    }
  });
  // node.traversed = true; // this does not provide accurate result in case of graph edges coming from API as each node ancentors are not actual waypoints but only plain edges too.
};

const traverseNodesAndPopulateHeight = (nodes, currentNodeIndex = 0, currentNodeHeight = 0) => {
  const node = nodes[currentNodeIndex];
  node.heights.push(currentNodeHeight);
  const nextNodes = node.nextNodeIndexes.map(i => nodes[i]);
  if (nextNodes.length > 1) {
    const nextNodeSiblingsAlsoAncestorsCount = {};
    nextNodes.forEach(nn => { nextNodeSiblingsAlsoAncestorsCount[nn.index] = nextNodes.filter(nnn => nn.ancestorNodeIndexes.has(nnn.index)).length; });
    nextNodes.sort((a, b) => (nextNodeSiblingsAlsoAncestorsCount[b.index] - nextNodeSiblingsAlsoAncestorsCount[a.index]) || (a.index - b.index));
  }

  nextNodes.forEach((nn, i) => {
    currentNodeHeight = i > 0 ? currentNodeHeight + 1 : currentNodeHeight;
    if (!nn.traversed) {
      traverseNodesAndPopulateHeight(
        nodes,
        nn.index,
        currentNodeHeight);
    }
  });
  node.traversed = true;
};

const nodesToLines = (nodeFrom, nodeTo, xScaleFactor, yScaleFactor) => {
  const displayNodes = [{
    coords: [nodeFrom.x * xScaleFactor, nodeFrom.y * yScaleFactor],
    name: nodeFrom.name,
    label: nodeFrom.isMajor ? nodeFrom.name : "",
    labelPos: nodeFrom.isMajor ? "S" : "N",
    marker: nodeFrom.isMajor ? "interchange" : undefined,
  }];

  if (nodeFrom.y !== nodeTo.y) {
    const sign = nodeFrom.y < nodeTo.y ? 1 : -1;
    displayNodes.push({ coords: [nodeTo.x * xScaleFactor - 4, nodeFrom.y * yScaleFactor] });
    displayNodes.push({ coords: [nodeTo.x * xScaleFactor - 3, nodeFrom.y * yScaleFactor + sign] });
    displayNodes.push({ coords: [nodeTo.x * xScaleFactor - 3, nodeTo.y * yScaleFactor - sign] });
    displayNodes.push({ coords: [nodeTo.x * xScaleFactor - 2, nodeTo.y * yScaleFactor] });
  }

  displayNodes.push({
    coords: [nodeTo.x * xScaleFactor, nodeTo.y * yScaleFactor],
    name: nodeTo.name,
    label: nodeTo.isMajor ? nodeTo.name : "",
    labelPos: nodeTo.isMajor ? "S" : "N",
    marker: nodeTo.isMajor ? "interchange" : undefined,
  });

  let color;

  if (nodeFrom.hasCoverage && nodeTo.hasCoverage) { color = green; } else if (nodeFrom.hasCoverage === false || nodeTo.hasCoverage === false) { color = red; } else {
    return [{
      name: nodeTo.name,
      color: gray,
      shiftCoords: [0, 0],
      nodes: displayNodes
    }];
  }

  const result = [{
    name: nodeTo.name,
    color,
    shiftCoords: [0, 0],
    nodes: displayNodes
  }];

  // If this node is within a handover zone, render an additional black line
  // Note this is not the obvious approach of having one distinct, contiguous line per site coverage
  // it is simply decorating a single (global) line with a 'this is also a handover' indication.
  const commonSites = nodeFrom.coveringSites.filter(x => nodeTo.coveringSites.includes(x));

  if (commonSites.length > 1) {
    result.push({
      name: 'handover',
      color: black,
      shiftCoords: [0, -1],
      nodes: displayNodes
    });
  }

  return result;
};

const isGraphDisconnected = graph => {
  const disconnectedNodes = [];
  for (const node of graph.nodes) {
    if (!node.reachable && !_.find({ label: node.label }, disconnectedNodes)) {
      disconnectedNodes.push({ label: node.label, uid: node.uid });
    }
  }
  return disconnectedNodes;
};

const graphToTubeMapJson = (graph, flightPathSubwayMap) => {
  graph.nodes.forEach(n => n.nextNodeIndexes.forEach(nni => { graph.nodes[nni].reachable = true; }));
  traverseNodesAndPopulateDistanceAndAncestors(graph.nodes);
  flightPathSubwayMap ? traverseNodesAndPopulateHeightForMapSubway(graph.nodes) : traverseNodesAndPopulateHeight(graph.nodes);
  let nodeHeights = [];
  graph.nodes.forEach(n => {
    nodeHeights.push(...n.heights);
  });
  nodeHeights = Array.from(new Set([...nodeHeights])).sort((a, b) => a - b);
  graph.nodes.forEach(n => {
    n.x = Math.max(...n.distances);
    n.y = flightPathSubwayMap ? nodeHeights.indexOf(Math.min(...n.heights)) : Math.min(...n.heights);
  });

  const xScaleFactor = X_SCALE_FACTOR;
  const yScaleFactor = graph.nodes.length > 10 ? Y_SCALE_FACTOR_LARGE : Y_SCALE_FACTOR_SMALL;

  const lines = graph.edges.flatMap(edge => nodesToLines(graph.nodes[edge[0]], graph.nodes[edge[1]], xScaleFactor, yScaleFactor));

  const transparentLines = _.cloneDeep(lines);
  transparentLines.forEach(l => {
    l.name = `${l.name}-transparent`;
  });
  const stations = {};
  graph.nodes.filter(n => n.reachable).forEach((n, i) => {
    stations[n.name || i.toString()] = {
      label: n.label || n.name || i.toString()
    };
  });

  const tubeMapJson = { stations, lines: [...lines, ...transparentLines] };

  return tubeMapJson;
};

const SubwayMap = ({ departure, destination, alternates, waypoints, calculateGraphEdges, flightPath, reservation, highlightFlightVertexUuidCallback, setDisconnectedGraph = null, flightPathSubwayMap = false }) => {
  const [dragEnabled, setIsDragEnabled] = useState(false);
  const subwayMapRef = useRef();
  const subwayMap = tubeMap().margin(MARGIN);
  const componentUuid = uuidv4();

  const displayAircraftIcon = (d, container, type) => {
    let name = d.name;
    if (name?.includes('transparent')) {
      name = name.split('-transparent')[0];
    }
    let coords = null;
    const closestStationCoordinates = container.selectAll("g.stations g").nodes().filter((n) => {
      return n.id === name;
    }).map(n => {
      return { x: n.getBoundingClientRect().x, y: n.getBoundingClientRect().y + 8.5 };
    });
    coords = closestStationCoordinates.length ? closestStationCoordinates : coords;
    const closestInterchangeCoordinates = container.selectAll("g.interchanges g").nodes().filter((n) => {
      return n.id === name;
    }).map(n => {
      return { x: n.getBoundingClientRect().x + 8.5, y: n.getBoundingClientRect().y + 8.5 };
    });
    coords = closestInterchangeCoordinates.length ? closestInterchangeCoordinates : coords;
    highlightFlightVertexUuidCallback(name, coords?.length ? coords[0] : null);
  };

  useEffect(() => {
    if ((!(departure?.latitude || departure?.location) || !(destination?.latitude || destination?.location)) && !flightPath) { return; }

    const graph = flightPath
      ? flightPathToNodes(flightPath.points_geojson, flightPath.graph_edges, reservation)
      : flightPlanToGraph(departure, destination, alternates, waypoints, calculateGraphEdges);
    if (graph?.nodes && !graph?.nodes?.length) return;
    const graphLoopNodes = [];
    traverseGraphForLoops(graph.edges, 0, [], graphLoopNodes);
    if (graphLoopNodes.length) {
      const node = graph?.nodes[graphLoopNodes];
      const disconnectedNodes = { label: `Loop around ${node?.label}`, uid: node?.uid, cyclic: true };
      setDisconnectedGraph && setDisconnectedGraph([disconnectedNodes], departure, waypoints, alternates);
      return;
    }
    const tubeMapJson = graphToTubeMapJson(graph, flightPathSubwayMap);
    const container = d3.select(`#tube-map-${componentUuid}`);
    container.selectAll('svg').remove();

    const disconnectedNodes = isGraphDisconnected(graph);

    const maxY = Math.max(...graph.nodes.filter(n => n.reachable).map(n => n.y));
    // const maxX = Math.max(...graph.nodes.map(n => n.x))
    subwayMap.height((maxY + 1) * Y_SCALE_FACTOR_SMALL * 10);
    subwayMapRef.current.style.height = `${(maxY + 1) * Y_SCALE_FACTOR_SMALL * 10 + 20}px`;
    subwayMapRef.current.style.maxHeight = disconnectedNodes?.length > 0 ? '0px' : flightPathSubwayMap ? '100px' : '';

    setDisconnectedGraph && setDisconnectedGraph(disconnectedNodes, departure, waypoints, alternates);
    if (disconnectedNodes?.length) return;

    container.datum(tubeMapJson).call(subwayMap);

    // tube-map does clever things to map the data to svg coords, which vary according to aspect ratio
    // sadly, it doesn't put this into a viewBox
    // a-posteriori extract the bounds of the elements it produces
    // then retroactively add a viewBox

    const interchangePathTransforms = container.selectAll('g.interchanges g path').nodes()
      .map(e => e.getAttribute('transform'));

    const maxSvgX = Math.max(...interchangePathTransforms
      .map(s => s.slice(s.indexOf('(') + 1, s.indexOf(','))) // extract x part of "transform(xxxx, yyyy)"
      .map(xs => +xs) // string to number
      .map(x => 10 * round(Math.ceil(x) / 10, 0))); // round up to nearest 10

    const maxSvgY = Math.max(...interchangePathTransforms
      .map(s => s.slice(s.indexOf(',') + 1, s.indexOf(')'))) // extract y part of "transform(xxxx, yyyy)"
      .map(ys => ys) // string to number
      .map(y => 10 * round(Math.ceil(y) / 10, 0))); // round up to nearest 10

    if (subwayMapRef.current.children && subwayMapRef.current.children.length > 0) {
      subwayMapRef.current.children[0].setAttribute('viewBox', `-${MARGIN.left} 0 ${maxSvgX + MARKER_RADIUS + MARGIN.right} ${maxSvgY + MARKER_RADIUS + 2 * MARGIN.bottom}`);
    }

    // enforce a minimum font size of 10px
    container.selectAll('g.labels g text').nodes()
      .filter(n => +n.style.fontSize.slice(0, -2) < 10)
      .forEach(n => { n.style.fontSize = '10px'; });

    container.selectAll('g.lines path').nodes().filter(n => n.id.includes('transparent')).forEach(e => {
      e.style.strokeWidth = e.getBoundingClientRect()?.height ? 10 : 20;
      e.style.stroke = "transparent";
    });

    container.selectAll('g.stations g path').nodes().forEach(node => {
      let d = node.getAttribute("d");
      const regex = /(\d+(\.\d+)?)/g;
      const matches = d.match(regex);
      if (matches && matches.length >= 2) {
        const currentYValue = parseFloat(matches[1]);
        const updatedYValue = currentYValue + 2.5;
        d = d.replace(currentYValue, updatedYValue);
      }
      node.setAttribute("d", d);
      node.style.strokeWidth = 2;
    });

    if (highlightFlightVertexUuidCallback) {
      container.selectAll("path.interchange").on("mousedown", (e, d) => {
        displayAircraftIcon(d, container, "interchange");
        setIsDragEnabled(true);
      }).on("mousemove", (e, d) => {
        if (dragEnabled) displayAircraftIcon(d, container, "interchange");
      });

      container.selectAll("path.station").on("mousedown", (e, d) => {
        displayAircraftIcon(d, container, "station");
        setIsDragEnabled(true);
      }).on("mousemove", (e, d) => {
        if (dragEnabled) displayAircraftIcon(d, container, "station");
      });

      container.selectAll("path.line").on("mousedown", (e, d) => {
        displayAircraftIcon(d, container, "line");
        setIsDragEnabled(true);
      }).on("mousemove", (e, d) => {
        if (dragEnabled) {
          displayAircraftIcon(d, container, "line");
        };
      });
      container.on("mouseup", () => {
        setIsDragEnabled(false);
      });
      container.selectAll("path.station").style("cursor", "pointer");
      document.addEventListener('mouseup', () => {
        if (dragEnabled) {
          setIsDragEnabled(false);
          document.removeEventListener('mouseup', () => {});
        };
      });
    }
    return () => {
      document.removeEventListener('mouseup', () => {});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departure, destination, alternates, waypoints, flightPath, reservation, highlightFlightVertexUuidCallback, flightPathSubwayMap, dragEnabled]);

  return <div id={`tube-map-${componentUuid}`} className={'subway-map'} ref={subwayMapRef}></div>;
};

SubwayMap.propTypes = {
  departure: PropTypes.object,
  destination: PropTypes.object,
  alternates: PropTypes.array,
  waypoints: PropTypes.array,
  calculateGraphEdges: PropTypes.func,
  flightPath: PropTypes.object,
  reservation: PropTypes.object,
  highlightFlightVertexUuidCallback: PropTypes.func
};

export default SubwayMap;
