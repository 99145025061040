import axios from 'axios';
import { FLIGHT_PLANNING_API_URL_V2, IDENTITY_ENDPOINT } from "../../../../constants/api.endpoints";
import Session from '../../../../utils/session';
import { adminActions } from "../reducers";
import getCoverageBounds from './coverage.bounds.action';
import getResourceGridSets from './resourcegrid.action';
import getSites from './sites.action';
import clearReduxState from '../../../../utils/clear.redux.state';
import { getInventory } from '../../../user/store/actions/inventory';

const getIdentity = () => async dispatch => {
  try {
    dispatch(adminActions.identity.isFetching());
    const response = await axios.get(`${FLIGHT_PLANNING_API_URL_V2}${IDENTITY_ENDPOINT}`, Session.requestConfig());
    if (Session.validationResponse(response)) {
      dispatch(adminActions.identity.isFetched({ ...response.data }));
      dispatch(getCoverageBounds());
      dispatch(getResourceGridSets());
      dispatch(getSites());
      dispatch(getInventory());
    } else {
      dispatch(clearReduxState);
      dispatch(adminActions.identity.isError());
    }
  } catch (err) {
    // This print statement is included to assist developers in troubleshooting login issues resulting from failures in any of the API calls mentioned above.
    console.log('Error While loading application', err);
    dispatch(clearReduxState);
    dispatch(adminActions.identity.isError());
  }
};

export default getIdentity;
