import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import WaypointDisplay from "./WaypointDisplay";
import WaypointEntryForm from "./WaypointEntryForm";
import AddWaypointButton from "./AddWaypointButton";
import { DESTINATION_INDEX, WAYPOINT_START_ID } from "../../subpages/user/requestform/util";

const WaypointsEntry = ({
  waypoints,
  latestMapClick,
  editing,
  disabled,
  routes,
  onSave,
  mutateDeparture,
  onToggleEditing,
  alternates,
  departure,
  destination
}) => {
  const [newWaypointIndex, setNewWaypointIndex] = useState(-1);
  const [editWaypointIndex, setEditWaypointIndex] = useState(-1);

  const nextWaypointUID = useMemo(() => {
    return waypoints.reduce((nextId, waypoint, i) => Math.max(nextId, waypoint.uid), WAYPOINT_START_ID - 1) + 1;
  }, [waypoints]);

  useEffect(() => {
    // Only want hook to fire if latestMapClick changes, not if editing changes,
    // therefore only latestMapClick is in the list of dependencies
    if (editing && latestMapClick?.lngLat) {
      window.waypointEntryForm.selectLocation({
        name: latestMapClick.name || '',
        icao_description: latestMapClick.icao_code,
        lng: latestMapClick.lngLat.lng,
        lat: latestMapClick.lngLat.lat,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestMapClick]);

  const handleAdd = (index) => {
    setNewWaypointIndex(index);
    onToggleEditing(true);
  };

  const handleEdit = (index) => {
    setEditWaypointIndex(index);
    onToggleEditing(true);
  };

  const handleDelete = (index) => {
    const uid_to_delete = waypoints[index].uid;
    waypoints.forEach(node => {
      if (node.next.length === 1 && node.next[0] === uid_to_delete) { node.next = [...waypoints[index].next]; } else { node.next = node.next.filter(x => x !== uid_to_delete); }
    });
    const wps = [...waypoints];
    wps.splice(index, 1);

    setNewWaypointIndex(newWaypointIndex > index ? newWaypointIndex - 1 : newWaypointIndex);
    setEditWaypointIndex(editWaypointIndex > index ? editWaypointIndex - 1 : editWaypointIndex);

    if (departure.next.includes(uid_to_delete)) {
      if (departure.next.length === 1) {
        departure.next = [...waypoints[index].next];
      } else {
        departure.next = Array.from(new Set([...departure.next.filter(x => x !== uid_to_delete), ...waypoints[index].next]));
      }
    }
    onSave(wps, departure);
  };

  const handleSave = (waypoint) => {
    const waypointsToSave = [...waypoints];

    if (newWaypointIndex >= 0) {
      waypointsToSave.splice(newWaypointIndex, 0, {});
      waypoint.uid = nextWaypointUID;
      const prevWaypoint = waypointsToSave[newWaypointIndex - 1];
      if (prevWaypoint) {
        if (prevWaypoint.next.length === 1 && waypoint.next.length === 0) {
          waypoint.next = prevWaypoint.next;
          prevWaypoint.next = [waypoint.uid];
        } else {
          prevWaypoint.next = prevWaypoint.next.concat(waypoint.uid);
          if (waypoint.next.length === 0) { waypoint.next = [waypointsToSave[newWaypointIndex + 1] ? waypointsToSave[newWaypointIndex + 1].uid : DESTINATION_INDEX]; }
        }
      } else {
        if (departure.next.length === 1 && waypoint.next.length === 0) {
          waypoint.next = departure.next;
          departure.next = [waypoint.uid];
        } else {
          departure.next = (departure?.next || []).concat(waypoint.uid);
          if (waypoint.next.length === 0) { waypoint.next = [waypointsToSave[newWaypointIndex + 1] ? waypointsToSave[newWaypointIndex + 1].uid : DESTINATION_INDEX]; }
        }
      }
    }

    waypointsToSave[newWaypointIndex >= 0 ? newWaypointIndex : editWaypointIndex] = waypoint;

    setNewWaypointIndex(-1);
    setEditWaypointIndex(-1);

    onSave(waypointsToSave);
    onToggleEditing(false);
  };

  const handleCancelEdit = () => {
    setNewWaypointIndex(-1);
    setEditWaypointIndex(-1);

    onToggleEditing(false);
  };

  const isNewWaypointBeingCreated = newWaypointIndex >= 0;

  return (
    <>
      {
        waypoints.map((wp, i) => {
          return (
            <React.Fragment key={i}>
              {(newWaypointIndex === i || editWaypointIndex === i) && (
                <WaypointEntryForm
                  waypoint={isNewWaypointBeingCreated ? {} : wp}
                  routes={routes}
                  onSave={handleSave}
                  onCancel={handleCancelEdit}
                  destination={destination}
                  waypoints={waypoints}
                  alternates={alternates}
                />
              )}
              {(editWaypointIndex !== i || isNewWaypointBeingCreated) && (
                <>
                  <AddWaypointButton onClick={() => handleAdd(i)} disabled={disabled || editing} />
                  <WaypointDisplay
                    index={i}
                    waypoint={wp}
                    disabled={disabled}
                    onEdit={() => handleEdit(i)}
                    onDelete={() => handleDelete(i)}
                    destination={destination}
                    waypoints={waypoints}
                    alternates={alternates}
                  />
                </>
              )}
            </React.Fragment>
          );
        })
      }
      {newWaypointIndex === waypoints.length && (
        <WaypointEntryForm
          routes={routes}
          onSave={handleSave}
          onCancel={handleCancelEdit}
          destination={destination}
          waypoints={waypoints}
          alternates={alternates}/>
      )}
      {newWaypointIndex !== waypoints.length && (
        <AddWaypointButton
          label={waypoints.length === 0 ? 'Add Waypoint ' : ''}
          onClick={() => handleAdd(waypoints.length)}
          disabled={disabled || editing}
        />
      )}
    </>
  );
};

WaypointsEntry.propTypes = {
  waypoints: PropTypes.array.isRequired,
  latestMapClick: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  disabled: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onToggleEditing: PropTypes.func.isRequired,
};

export default WaypointsEntry;
