import React, { useEffect } from "react";
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Spinner } from "react-bootstrap";
import { round } from "../../../utils/units";
import { toggleRouteListPanel } from "../store/actions/panels.action";
import {
  deactivateRoute,
  deleteRoute,
  getRoutes, setFetchRoutesFlag,
  setHighlightedRouteIndex
} from "../store/actions/routes/routes.action";
import { setSelectedRoute } from "../store/actions/routes/route.plan.action";
import { setSelectedRoute as setFlightPlanRoute } from "../store/actions/flights/flight.plan.action";
import "../listrequests/FlightList.css";
import { ROUTE_LIST_COMPONENT } from "../../../constants/constants";
import { setFlightPlans, setHighlightedRoute } from "../store/actions/map/map.action";

const RouteList = () => {
  const dispatch = useDispatch();
  const routeObject = useSelector(({ routeList }) => routeList);
  const { routes, isFetching, isFetched, fetchRoutesFlag, flightPlans, highlightedRouteIndex } = routeObject;

  useEffect(() => {
    if (fetchRoutesFlag) {
      dispatch(getRoutes());
    } else {
      dispatch(setFlightPlans(flightPlans));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoutesFlag]);

  const getTerminiDisplayString = flightPlan => {
    if (!flightPlan) {
      return '-';
    } else {
      return `${terminusToDisplayString(flightPlan.departure)}${String.fromCharCode(
        8594
        )}${terminusToDisplayString(flightPlan.destination)}`;
    }
  };

  const terminusToDisplayString = (terminus) => {
    if (terminus.airportCode) {
      return terminus.airportCode;
    } else if (terminus.name) {
      return terminus.name;
    } else {
      return `(${round(terminus.location.coordinates[1], 3)}${String.fromCharCode(176)},${round(
        terminus.location.coordinates[0],
        3
        )}${String.fromCharCode(176)})`;
    }
  };

  const handleClose = () => {
    dispatch(toggleRouteListPanel());
  };

  const handleRefresh = () => {
    dispatch(setFetchRoutesFlag(true));
  };

  const handleRouteDelete = (uuid) => {
    dispatch(deleteRoute(uuid));
  };

  const handleRouteDeprecate = (row) => {
    dispatch(deactivateRoute(row.uuid, row));
  };

  const handleCloneRoute = (row) => {
    dispatch(setSelectedRoute({ selectedRoute: row, parentComponent: ROUTE_LIST_COMPONENT }));
  };

  const handleViewRoute = (row) => {
    dispatch(setSelectedRoute({ selectedRoute: row, parentComponent: ROUTE_LIST_COMPONENT, disableRoute: row.flightCount, editRoute: !row.flightCount }));
  };

  const handleFlightPlan = (row) => {
    dispatch(setFlightPlanRoute(row));
  };

  const setHighlightRow = idx => {
    dispatch(setHighlightedRouteIndex(idx));
  };

  return (
    <div className="panel panel-s">
      <div className="panel-header">
        <h2>Routes</h2>
        <a className="refresh-button" onClick={ handleRefresh }>
          <i className="fas fa-refresh"/>
        </a>
        <HelpToolTip content="A Route is a predetermined path flight (or paths) which has been verified
        to be within AURA's radio network coverage for any future departure time. Routes serve as 'templates' for future
        Flights with specified departure times." />
        <a className="close-button" onClick={handleClose}>
          <i className="fa-solid fa-circle-xmark" />
        </a>
      </div>
      <div className="panel-body">
        {isFetching && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        <>
          {routes?.map((row, index) => {
            return (
                <Row key={index}
                     className="stripe"
                     onClick={() => {
                       if (highlightedRouteIndex === index) {
                         setHighlightRow();
                       } else {
                         setHighlightRow(index);
                       }
                     }}
                      onMouseEnter={() => {
                        dispatch(setHighlightedRoute(index));
                      }}
                      onMouseLeave={() => {
                        dispatch(setHighlightedRoute());
                      }}>
                  <Col xs={12}>
                      <h4>
                        {row?.name}
                        {row?.deactivated ? " (inactive)" : ""}
                      </h4>
                  </Col>
                  <Col xs={12}>
                    {row?.organization} | <strong>{getTerminiDisplayString(row?.flightPlan)}</strong>
                  </Col>
                  <Col xs={12}>
                    <span>{row?.aircraftModel?.name}</span>
                  </Col>
                  <Col xs={12} className="text-end pt-2">
                    <Button variant="icon"
                            className={ "clone-icon" }
                            size="xs"
                            title="Clone Route"
                            onClick={ () => handleCloneRoute(row) }
                    />
                    <Button variant="icon"
                            className={ "deprecate-icon" }
                            size="xs"
                            title="Deactivate Route"
                            onClick={ () => handleRouteDeprecate(row) }
                    />
                    <Button variant="icon"
                            className={ 'view-route-icon' }
                            size="xs"
                            title={row?.flightCount === 0 ? "Edit Route" : "View Route"}
                            onClick={ () => handleViewRoute(row) }
                    />
                    { row?.flightCount === 0 &&
                      <Button variant="icon"
                              className={ "delete-icon" }
                              size="xs"
                              title="Delete Route"
                              onClick={ () => handleRouteDelete(row.uuid) }
                      />
                    }
                    { !row?.deactivated && (
                      <Button variant="icon"
                              className={ "plan-flight-icon" }
                              title="Plan a Flight"
                              onClick={ () => handleFlightPlan(row) }
                      />
                    ) }
                  </Col>
                </Row>
            );
          })}
        </>
        {isFetched && routes?.length === 0 &&
          <strong>No Routes found</strong>
        }
      </div>
    </div>
  );
};

export default RouteList;
