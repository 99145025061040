import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import { subwayStationPath } from "../MapVisualization/SvgMarkers";
import { black, white } from "../MapVisualization/Colors";

import { displayUid } from "../../subpages/user/requestform/util";

const AlternateDisplay = ({ alternate, onEdit, onDelete, disabled }) => {
  const uid = alternate?.uid;
  const alternateDisplay = useMemo(() => displayUid(uid, '', 'Alternate '), [uid]);

  return (<div className="waypoints-list-entry">
    <svg viewBox="0 0 17 17">
      <path d={subwayStationPath} fill={white} stroke={black} strokeWidth={3}/>
    </svg>
    <h4>{alternateDisplay}{alternate.airport_code ? `: ${alternate.airport_code} (${alternate.name})` : `: ${alternate.name || ''}`}</h4>
    <div>
      ({alternate.latitude}&deg;, {alternate.longitude}&deg;)
    </div>
    <div className="text-end btns-inline-alt-dest">
      <Button variant="danger" size="sm" onClick={onDelete} disabled={disabled}>
        Delete <i className="fas fa-trash-alt"/>
      </Button>
      <Button variant="outline-primary" size="sm" className="ms-1" onClick={onEdit} disabled={disabled}>
        Edit <i className="fas fa-edit"/>
      </Button>
    </div>
  </div>);
};

AlternateDisplay.propTypes = {
  index: PropTypes.number.isRequired,
  alternate: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AlternateDisplay;
