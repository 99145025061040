import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import App from "./app.js";
import Session from "./utils/session";
import store from './store';
import history from "./utils/history";
import './styles.css';

import axios from "axios";

axios.interceptors.response.use(
  (response) => Session.onRequestFulfilled(response),
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      // do nothing
    }
    return Session.onRequestRejected(error);
  }
);

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

export const config = {
  domain: window._env_.AUTH0_DOMAIN,
  clientId: window._env_.AUTH0_CLIENT_ID
};
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: window._env_.AUTH0_API_AUDIENCE,
    scope: window._env_.AUTH0_REQUIRED_SCOPE
  },
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Auth0Provider
        {...providerConfig}
      >
        <App/>
      </Auth0Provider>

  </Provider>
);
