import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "../../../../admin/store/records/general.record";

export const GroundRadioModelRecord = new ImmutableRecord({
  name: '',
  supportedTxPowerDbm: [],
  uuid: '',
  metadata: new MetadataRecord({})
});

export const GroundRadioModelsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  groundRadioModels: [new GroundRadioModelRecord({})]
});
