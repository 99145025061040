import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isAura } from '../../../utils/identity';
import { Button } from 'react-bootstrap';
import NewResourceGrid from './NewResourceGrid';
import ReactTable from "../../../components/Table/ReactTable.js";
import { toggleSiteListPanel } from "../../user/store/actions/panels.action.js";
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";

const ResourceGrid = () => {
  const dispatch = useDispatch();
  const resourceGrid = useSelector(({ resourceGrid }) => resourceGrid.resourceGrid);
  const identity = useSelector(({ identity }) => identity.identity);

  const [showNewResourceGridModal, setShowNewResourceGridModal] = useState(false);

  if (!isAura(identity)) {
    return;
  }

  const handleClose = () => {
    dispatch(toggleSiteListPanel());
  };

  return (
    <div className="panel">
      <div className="panel-header">
        <h2>Resource Grids</h2>
        <HelpToolTip content="Manage & configure Resource Grids, which define in general terms which radio resources can be assigned to Flights. Visible to AURA only." />
        <a className="close-button" onClick={ handleClose }><i className="fa-solid fa-circle-xmark"/></a>
      </div>
      <div className="panel-body">
        <div className="container" data-testid="resource-block-sets" key="resource-block-set-container">
          {showNewResourceGridModal &&
            <NewResourceGrid showModal={ true }
                             hideModal={ (() => setShowNewResourceGridModal(false)) }/>
          }
          <ReactTable
            data={ resourceGrid }
            columns={ [
              {
                Header: "Name",
                accessor: "name",
                id: "name"
              },
              {
                Header: "Number of Subcarriers",
                accessor: "num_subcarriers",
                id: "subcarriers"
              },
              {
                Header: "Number of Timeslots",
                accessor: "num_time_slots",
                id: "time_slots"
              },
              {
                Header: "Min Frequency (hz)",
                accessor: "min_frequency_hz",
                id: "frequency"
              },
              {
                Header: "Sub Channel Width (hz)",
                accessor: "subchannel_width_hz",
                id: "subchannel_width"
              }
            ] }
            showPagination={ false }
            minRows={ 1 }
            className="-striped -highlight"
          />
          <div className="row">
            <div className="col-md-12 text-end mt-4">
              <Button variant="primary" data-testid="add-resource-block-set"
                      onClick={ () => setShowNewResourceGridModal(true) }>
                Add Resource Grid <i className="fas fa-plus"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-footer">
        <Button onClick={ handleClose } className="btn-primary">Close</Button>
      </div>
    </div>);
};

export default ResourceGrid;
