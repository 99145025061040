import { combineReducers } from "@reduxjs/toolkit";
import aircraft from "./aircraft.reducer";
import aircraftModel from "./aircraft.model.reducer";
import airborneRadio from "./airborne.radio.reducer";
import airborneRadioModel from "./airborne.radio.model.reducer";
import antenna from "./antenna.reducer";
import antennaModel from "./antenna.model.reducer";
import groundRadio from "./ground.radio.reducer";
import groundRadioModel from "./ground.radio.model.reducer";

export const inventoryReducers = combineReducers({
  aircrafts: aircraft.reducer,
  aircraftModels: aircraftModel.reducer,
  airborneRadios: airborneRadio.reducer,
  airborneRadioModels: airborneRadioModel.reducer,
  antennas: antenna.reducer,
  antennaModels: antennaModel.reducer,
  groundRadios: groundRadio.reducer,
  groundRadioModels: groundRadioModel.reducer
});

export const inventoryActions = {
  aircrafts: aircraft.actions,
  aircraftModels: aircraftModel.actions,
  airborneRadios: airborneRadio.actions,
  airborneRadioModels: airborneRadioModel.actions,
  antennas: antenna.actions,
  antennaModels: antennaModel.actions,
  groundRadios: groundRadio.actions,
  groundRadioModels: groundRadioModel.actions
};
