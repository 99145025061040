import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './HelpToolTip.css';

const HelpToolTip = ({ content, placement = 'bottom' }) => {
  const renderTooltip = (props) => (
    <Tooltip {...props}>
      <span className="custom-tooltip-content">{content.replaceAll("\\n", "\n\n")}</span>
    </Tooltip>
  );

  return (
    <OverlayTrigger overlay={renderTooltip} placement={placement}>
      <span className="help-tooltip-icon">
        <span><i className="fa-solid fa-circle-question"></i></span>
      </span>
    </OverlayTrigger>
  );
};

export default HelpToolTip;
