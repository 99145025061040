import React from "react";
import { useTable, useSortBy } from "react-table";

const ReactTable = (props) => {
  const { data, columns, rowProps, rowIdx } = props;
  const tableInfo = useTable({ columns, data }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInfo;

  return (
    <div className="container">
      <table {...getTableProps()} className={"ReactTable"}>
        <thead>
          {
            headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {
                        column.render('Header')}
                      {<span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' \u25BC'
                            : ' \u25B2'
                          : ''}
                      </span>}
                    </th>
                  ))}
              </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(rowProps && rowProps(row, rowIdx))}>
                  {
                    row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps({ className: cell.column.className })}>
                          {
                            cell.render('Cell')}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ReactTable;
