const validate = (values) => {
  const errors = {};

  if (!values.longitude && values.longitude !== 0) {
    errors.longitude = 'Required';
  }

  if (!values.latitude && values.latitude !== 0) {
    errors.latitude = 'Required';
  }
  return errors;
};

export default validate;
