import { createSlice } from "@reduxjs/toolkit";
import { AirborneRadioModelRecord, AirborneRadioModelsRecord } from "../../records/inventory/airborne.radio.model.record";

const initialState = new AirborneRadioModelsRecord({});

const airborneRadioModel = createSlice({
  name: 'airborneRadioModel',
  initialState,
  reducers: {
    isFetching: (airborneRadioModelState) => {
      return airborneRadioModelState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (airborneRadioModelState, action) => {
      return airborneRadioModelState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        airborneRadioModels: action.payload.airborneRadioModels,
      });
    },
    isError: (airborneRadioModelState) => {
      return airborneRadioModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (airborneRadioModelState) => {
      return airborneRadioModelState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        airborneRadioModels: [new AirborneRadioModelRecord({})]
      });
    }
  }
});

export default airborneRadioModel;
