import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const FormSelect = ({
  input,
  label,
  readOnly,
  groupClassName,
  labelClassName,
  fieldClassName,
  'data-testid': dataTestId,
  meta: { touched, error },
  onChange,
  children,
}) => (
  <Form.Group
    controlId={input.name}
    data-testid="route-name"
    className={groupClassName}
  >
    <Form.Label className={labelClassName}>{label}</Form.Label>
    <select
      {...input}
      className={`form-select${fieldClassName ? ` ${fieldClassName}` : ''}`}
      readOnly={readOnly}
      disabled={readOnly}
      data-testid={dataTestId}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        input.onChange(event);
      }}
    >
      {children}
    </select>
    {
      !readOnly && touched && error && (
        <div className="error-message  m-0">{error}</div>
      )
    }
  </Form.Group >
);

FormSelect.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  fieldClassName: PropTypes.string,
  'data-testid': PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
};

FormSelect.defaultProps = {
  label: null,
  readOnly: false,
  'data-testid': null,
  groupClassName: null,
  labelClassName: null,
  fieldClassName: null,
  meta: {
    error: '',
  },
  onChange: undefined,
};

export default FormSelect;
