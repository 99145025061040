import axios from 'axios';
import {
  FLIGHT_PLANNING_API_URL_V2,
  ROUTES_ENDPOINT, DEACTIVATE_ROUTE
} from '../../../../../constants/api.endpoints';
import Session from '../../../../../utils/session';
import { routeListActions } from '../../reducers/routes/routes.reducer';
import { toggleRouteListPanel } from "../panels.action";
import { convertToFlightOriginState } from '../../utils/routes/route.plan.util';
import { setFlightPlans } from '../map/map.action';
import { convertToRouteDataRecord } from '../../utils/common';

export const getRoutes = (displayRouteModal = false) => async (dispatch, getState) => {
  try {
    dispatch(routeListActions.isFetching());
    const { isRouteListPanelVisible } = getState().modals;
    const response = await axios.get(`${FLIGHT_PLANNING_API_URL_V2}${ROUTES_ENDPOINT}`, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const flightPlans = [];
      const records = response?.data?.records?.reduce((newRec, rec) => {
        const routeRecord = convertToRouteDataRecord(rec);
        flightPlans.push(convertToFlightOriginState(routeRecord?.flightPlan));
        newRec.push(routeRecord);
        return newRec;
      }, []);
      dispatch(routeListActions.isFetched({ records, flightPlans }));
      isRouteListPanelVisible && dispatch(setFlightPlans(flightPlans));
      if (displayRouteModal) dispatch(toggleRouteListPanel(true));
    } else {
      dispatch(routeListActions.isError());
    }
  } catch (err) {
    console.log('err', err);
    dispatch(routeListActions.isError());
  }
};

export const deleteRoute = (uuid) => async (dispatch, getState) => {
  try {
    dispatch(routeListActions.isFetching());
    const response = await axios.delete(`${FLIGHT_PLANNING_API_URL_V2}${ROUTES_ENDPOINT}${uuid}`, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const { routes } = getState().routeList;
      const filteredRoutes = routes.filter((route) => route.uuid !== uuid);
      const flightPlans = [];
      flightPlans.push(convertToFlightOriginState(filteredRoutes?.flightPlan));
      dispatch(routeListActions.deleteRoute({ filteredRoutes, flightPlans }));
      dispatch(setFlightPlans(flightPlans));
    } else {
      dispatch(routeListActions.isError());
    }
  } catch (err) {
    dispatch(routeListActions.isError());
  }
};

export const deactivateRoute = (uuid, routeDetails) => async (dispatch, getState) => {
  try {
    dispatch(routeListActions.isFetching());
    const response = await axios.put(`${FLIGHT_PLANNING_API_URL_V2}${DEACTIVATE_ROUTE}${uuid}`, routeDetails, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const { routes } = getState().routeList;
      const filteredRoutes = routes.map((route) => {
        if (route.uuid === uuid) {
          if (!route.deactivated) {
            return route.set("deactivated", true);
          } else {
            return route.set("deactivated", false);
          }
        } else {
          return route;
        }
      });
      dispatch(routeListActions.deactivateRoute(filteredRoutes));
    } else {
      dispatch(routeListActions.isError());
    }
  } catch (err) {
    dispatch(routeListActions.isError());
  }
};

export const setHighlightedRouteIndex = (idx = null) => dispatch => {
  dispatch(routeListActions.setHighlightedRouteIndex({ idx }));
};

export const setFetchRoutesFlag = (fetchRoutesFlag = false) => dispatch => {
  dispatch(routeListActions.setFetchRoutesFlag({ fetchRoutesFlag }));
};

export const clearRouteState = () => dispatch => {
  dispatch(routeListActions.clear());
};
