import {
  black,
  gray,
  green,
  red,
  white,
  darker_gray,
  blue,
  orange,
  ecru_dark
} from '../../Colors';

export const AIRPORT_LAYER = {
  id: 'airports-symbol',
  type: 'symbol',
  source: 'airports',
  'source-layer': 'airports',
  layout: {
    'icon-image': 'airport-lg',
    'icon-size': 0.5,
    'icon-allow-overlap': true,
    'text-allow-overlap': false,
    'icon-ignore-placement': true,
    'text-ignore-placement': false,
    'text-field': ['get', 'display_name'],
    'text-font': [
      'URW DIN Regular'
    ],
    'text-offset': [0, 1],
    'text-anchor': 'top',
    'text-size': 12
  },
  paint: {
    'text-color': ecru_dark,
    'text-halo-color': white,
    'text-halo-width': 1,
  }
};

export const NAVAID_LAYER = {
  id: 'navaids-symbol',
  type: 'symbol',
  source: 'navaid',
  'source-layer': 'navaid',
  layout: {
    'icon-image': ['case',
      ['==', ["get", "type"], 'RNAV'], 'rnav',
      ['==', ["get", "type"], 'RNAV-COMPULSORY'], 'rnav-compulsory',
      ['==', ["get", "type"], 'REP-PT'], 'rnav',
      ['==', ["get", "type"], 'REP-PT-COMPULSORY'], 'rnav-compulsory',
      ['==', ["get", "type"], 'NDB/DME'], 'ndb-dme',
      ['==', ["get", "type"], 'NDB'], 'ndb',
      ['==', ["get", "type"], 'DME'], 'dme',
      ['==', ["get", "type"], 'VOR/DME'], 'vor-dme',
      ['==', ["get", "type"], 'VORTAC'], 'vortac',
      ['==', ["get", "type"], 'VOR'], 'vor',
      ['==', ["get", "type"], 'TACAN'], 'tacan',
      ['==', ["get", "type"], 'VFR'], 'vfr',
      'rocket'],
    'icon-size': ['case',
      ['==', ["get", "type"], 'NDB/DME'], 1.0,
      ['==', ["get", "type"], 'NDB'], 1.0,
      ['==', ["get", "type"], 'VOR/DME'], 0.6,
      ['==', ["get", "type"], 'VORTAC'], 0.6,
      ['==', ["get", "type"], 'VOR'], 0.6,
      ['==', ["get", "type"], 'TACAN'], 0.6,
      ['==', ["get", "type"], 'VFR'], 0.6,
      0.4
    ],
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-ignore-placement': true,
    'text-field': ['get', 'display_name'],
    'text-font': [
      'URW DIN Regular'
    ],
    'text-offset': [0, 1],
    'text-anchor': 'top',
    "text-size": {
      stops: [
        [0, 0],
        [9.9, 0],
        [10, 10],
        [12, 13],
      ]
    }
  },
  paint: {
    'text-color': darker_gray
  }
};

export const SITES_LAYER = {
  id: 'sites-symbol',
  type: 'symbol',
  source: 'sites',
  layout: {
    'icon-image': 'site',
    'icon-size': 0.75,
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
    'text-field': ['get', 'callsign'],
    'text-font': [
      'URW DIN Demi'
    ],
    'text-offset': [0, 1.33],
    'text-anchor': 'top',
    "text-size": {
      stops: [
        [0, 0],
        [3.9, 0],
        [4, 13],
        [6, 13],
        [7, 16]
      ]
    },
    'text-allow-overlap': true,
    'text-ignore-placement': true,
  },
  paint: {
    'text-halo-color': white,
    'text-halo-width': 1,
    'text-color': black
  }
};

export const AIRCRAFT_MARKERS = {
  id: 'aircraftmarkers-symbol',
  type: 'symbol',
  source: 'aircraftmarkers',
  layout: {
    'icon-image': 'aircraft-marker',
    'icon-offset': [0, -17],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'text-field': ['get', 'display_name'],
    'text-font': [
      'URW DIN Regular'
    ],
    'text-offset': [0, 0.75],
    'text-anchor': 'top',
    'text-size': 12
  },
  paint: {
    'icon-opacity': [
      'case',
      ['get', 'is_in_flight'],
      1,
      0.5
    ]
  }
};

export const FLIGHT_PATH_DISPLAY_LINES = {
  id: 'flightpathdisplay-line',
  type: 'line',
  source: 'flightpathdisplay',
  filter: ['any', ['==', ['geometry-type'], 'LineString'], ['==', ['geometry-type'], 'Polygon']],
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-width': ['case', ['==', ['geometry-type'], 'Polygon'], 0.3, 2],
    'line-color': ['case', ['get', 'is_dummy'], gray, ['get', 'has_coverage'], green, red],
  }
};

export const FLIGHT_PATH_DISPLAY_DIRECTIONS = {
  id: 'flightpathdisplay-direction',
  type: 'symbol',
  source: 'flightpathdisplay',
  filter: ['==', ['geometry-type'], 'LineString'],
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 20,
    'icon-image': ['case', ['get', 'is_dummy'], 'gray-arrow', ['get', 'has_coverage'], 'green-arrow', 'red-cross'],
    'icon-rotate': ['case', ['get', 'is_dummy'], 0, ['get', 'has_coverage'], 0, 90],
    'icon-rotation-alignment': 'map',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-size': 1,
  }
};

export const FLIGHT_PATH_UNCERTAINTY_DISPLAY_FILL = sourceLayer => {
  return {
    id: `flightpathuncertaintydisplay-fill-${sourceLayer}`,
    type: 'fill',
    source: sourceLayer,
    'source-layer': 'Volume',
    filter: ['==', ['geometry-type'], 'Polygon'],
    layout: {},
    paint: {
      'fill-color': ['case', ['==', ['get', 'site_uuid'], ''], red, green],
      'fill-opacity': {
        stops: [
          [0, 0.75],
          [10, 0.75],
          [13, 0.5]
        ]
      }
    }
  };
};

export const FLIGHT_PATH_UNCERTAINTY_DISPLAY_STROKE = sourceLayer => {
  return {
    id: `flightpathuncertaintydisplay-stroke-${sourceLayer}`,
    type: 'line',
    source: sourceLayer,
    'source-layer': 'Volume',
    filter: ['==', ['geometry-type'], 'Polygon'],
    layout: {},
    paint: {
      // 'line-color': ['case', ['==', ['get', 'site_uuid'], ''], red, green],
      'line-color': '#ffffff',
      // 'line-width': 1,
      'line-width': {
        stops: [
          [0, 0],
          [9.9, 0],
          [10, 1],
          [13, 2]
        ]
      },
      'line-opacity': 0.5
    }
  };
};

export const FLIGHT_PATH_OUTLINE_LINE = {
  id: 'flightpathoutline-line',
  type: 'line',
  source: 'flightpathoutline',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': gray,
  }
};

export const FLIGHT_PATH_OUTLINE_DIRECTION = {
  id: 'flightpathoutline-direction',
  type: 'symbol',
  source: 'flightpathoutline',
  paint: {},
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 50,
    'icon-image': 'gray-arrow',
    'icon-rotate': 0,
    'icon-rotation-alignment': 'map',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true
  }
};

export const FLIGHT_PATH_HIGHLIGHT_LINE = {
  id: 'highlight-flightpathoutline-line',
  type: 'line',
  source: 'flightpathoutlinehighlight',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': blue,
    'line-width': 3
  }
};

export const FLIGHT_PATH_HIGHLIGHT_DIRECTION = {
  id: 'highlight-flightpathoutline-direction',
  type: 'symbol',
  source: 'flightpathoutlinehighlight',
  paint: {},
  layout: {
    'symbol-placement': 'line',
    'symbol-spacing': 50,
    'icon-image': 'blue-arrow', // this arrow can be changed to some other color, like blue outline and white fill
    'icon-rotate': 0,
    'icon-rotation-alignment': 'map',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true
  }
};

export const CLICK_MARKER_SYMBOLS = {
  id: 'clickmarker-symbol',
  type: 'symbol',
  source: 'clickmarker',
  layout: {
    'icon-image': 'pin-black',
    'icon-offset': [0, -8],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true
  }
};

export const ROUTE_MARKER_SYMBOLS = {
  id: 'routemarkers-symbol',
  type: 'symbol',
  source: 'routemarkers',
  layout: {
    'icon-image': ['case', ['==', ['get', 'reachable'], true], 'subway-station', 'subway-station-error'],
    'icon-offset': [0, -0],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
    'text-field': ['get', 'display_name'],
    'text-font': ['URW DIN Demi'],
    'text-offset': [0, 1],
    'text-anchor': 'top',
    'text-size': 14
  },
  paint: {
    'text-color': ['case', ['==', ['get', 'reachable'], true], black, red],
    'text-halo-color': white,
    'text-halo-width': 1.5
  }
};

export const HIGHTLIGHT_ROUTE_MARKER_SYMBOLS = {
  id: 'highlight-routemarkers-symbol',
  type: 'symbol',
  source: 'highlightroutemarkers',
  layout: {
    'icon-image': 'subway-station-highlight',
    'icon-offset': [0, -0],
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'text-ignore-placement': true,
    'text-field': ['get', 'display_name'],
    'text-font': ['URW DIN Demi'],
    'text-offset': [0, 1],
    'text-anchor': 'top',
    'text-size': 14
  },
  paint: {
    'text-color': blue,
    'text-halo-color': white,
    'text-halo-width': 1.5
  }
};

export const HANDOVER_LINE = {
  id: 'handovers-line',
  type: 'line',
  source: 'handovers',
  layout: {},
  paint: {
    'line-width': 2,
    'line-color': black,
    'line-dasharray': [2, 1]
  }
};

export const GEOJSON_INITIAL_OBJECT = {
  type: 'geojson',
  data: {
    type: 'FeatureCollection',
    features: []
  }
};

export const MAPBOX_DRAW_LINE_STYLE = {
  id: "gl-draw-line",
  type: "line",
  filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "draw_line_string"]],
  layout: {
    "line-cap": "round",
    "line-join": "round"
  },
  paint: {
    "line-color": orange,
    "line-dasharray": [0.2, 2],
    "line-width": 2
  }
};
