// new user groups
export const AURA_SUPERUSER = "AuraSuperuser";
export const AURA_USER_ADMIN = "AuraUserAdmin";
export const AURA_ENGINEERING_ADMIN = "AuraEngineeringAdmin";
export const AURA_ENGINEERING_SUPPORT = "AuraEngineeringSupport";
export const AURA_STANDARD_USER = "AuraStandardUser";
export const CUSTOMER_SUPERUSER = "CustomerSuperuser";
export const CUSTOMER_USER_ADMIN = "CustomerUserAdmin";
export const CUSTOMER_MISSION_MANAGER = "CustomerMissionManager";
export const CUSTOMER_FLEET_MANAGER = "CustomerFleetManager";
export const CUSTOMER_STANDARD_USER = "CustomerStandardUser";

export const claimsGroup = (identity, groupName) =>
  identity &&
  identity.access_claim &&
  identity.access_claim.groups &&
  identity.access_claim.groups.includes(groupName);

export const isAura = identity => isAuraSuperUser(identity) || isAuraUserAdmin(identity) ||
                                  isAuraEngineeringAdmin(identity) || isAuraEngineeringSupport(identity) || isAuraStandardUser(identity);

export const canUserReadAllUsers = identity => isAuraSuperUser(identity) || isAuraUserAdmin(identity) ||
                                               isCustomerSuperUser(identity) || isCustomerUserAdmin(identity);

export const canUserReadSites = identity => isAuraSuperUser(identity) || isAuraEngineeringAdmin(identity) || isAuraEngineeringSupport(identity) ||
                                            isAuraStandardUser(identity);

export const canUserReadOrgUsers = identity => isCustomerSuperUser(identity) || isCustomerUserAdmin(identity);

export const canUserReadFlights = identity => isAuraSuperUser(identity) || isAuraEngineeringAdmin(identity) || isAuraEngineeringSupport(identity) ||
                                              isAuraStandardUser(identity) || isCustomerSuperUser(identity) || isCustomerMissionManager(identity) ||
                                              isCustomerFleetManager(identity) || isCustomerStandardUser(identity);

export const isAuraSuperUser = identity => claimsGroup(identity, AURA_SUPERUSER);
export const isAuraUserAdmin = identity => claimsGroup(identity, AURA_USER_ADMIN);
export const isAuraEngineeringAdmin = identity => claimsGroup(identity, AURA_ENGINEERING_ADMIN);
export const isAuraEngineeringSupport = identity => claimsGroup(identity, AURA_ENGINEERING_SUPPORT);
export const isAuraStandardUser = identity => claimsGroup(identity, AURA_STANDARD_USER);
export const isCustomerSuperUser = identity => claimsGroup(identity, CUSTOMER_SUPERUSER);
export const isCustomerMissionManager = identity => claimsGroup(identity, CUSTOMER_MISSION_MANAGER);
export const isCustomerFleetManager = identity => claimsGroup(identity, CUSTOMER_FLEET_MANAGER);
export const isCustomerStandardUser = identity => claimsGroup(identity, CUSTOMER_STANDARD_USER);
export const isCustomerUserAdmin = identity => claimsGroup(identity, CUSTOMER_USER_ADMIN);
