/* global google */

import { Buffer } from 'buffer';

export const pinIconPath = "M3.75,9.25 C3.75,4.693 7.444,1 12,1 C16.556,1 20.25,4.693 20.25,9.25 C20.25,12.576 19.091,13.505 12.85,22.557 C12.657,22.835 12.341,23 12.002,23 C11.664,23 11.348,22.835 11.155,22.557 C4.909,13.499 3.75,12.575 3.75,9.25";

export const pinIcon = (fillColor) => {
  return {
    path: pinIconPath,
    fillColor,
    fillOpacity: 1,
    anchor: new google.maps.Point(12, 22),
    strokeWeight: 1,
    strokeColor: '#343A40',
    scale: 1,
    labelOrigin: new google.maps.Point(12, 32)
  };
};

export const airportSvgString = (color) => (
  '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="16" height="16" viewBox="0 0 450 450">\n' +
  '  <g>\n' +
  '    <path d="M390.511,442.548 C419.259,442.548 442.548,419.259 442.548,390.555 L442.548,59.401 C442.548,30.697 419.259,7.452 390.511,7.452 L59.401,7.452 C30.697,7.452 7.452,30.697 7.452,59.401 L7.452,390.555 C7.452,419.259 30.697,442.548 59.401,442.548 z" fill="' + color + '" id="path8066"/>\n' +
  '    <path d="M59.401,16.257 C35.627,16.257 16.257,35.628 16.257,59.401 L16.257,390.555 C16.257,414.372 35.627,433.743 59.401,433.743 L390.511,433.743 C414.372,433.743 433.743,414.372 433.743,390.555 L433.743,59.401 C433.743,35.628 414.372,16.257 390.511,16.257 z" fill="#FFFFFF" id="path8068"/>\n' +
  '    <path d="M206.003,68.69 C206.091,43.156 244.613,43.156 244.613,69.439 L244.613,176.683 L394.737,266.978 L394.737,306.6 L245.317,257.424 L245.317,337.593 L279.877,364.669 L279.877,395.97 L226.563,379.417 L173.249,395.97 L173.249,364.669 L207.456,337.593 L207.456,257.424 L57.948,306.6 L57.948,266.978 L206.003,176.683 L206.003,68.69 z" fill="' + color + '" id="path8070"/>\n' +
  '  </g>\n' +
  '</svg>'
);

export const airportSvgAsUrl = (color) => {
  return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(airportSvgString(color));
};

export const airportIcon = (color) => {
  return {
    url: airportSvgAsUrl(color),
    anchor: new google.maps.Point(8, 8),
    labelOrigin: new google.maps.Point(8, 24)
  };
};

export const towerMacroSvgString = () =>
  '<svg id="icon_Tower_Macro" data-name="icon/Tower/Macro" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\n' +
  '  <rect id="Rectangle_124" data-name="Rectangle 124" width="24" height="24" rx="12" fill="#fff"/>\n' +
  '  <path id="Path_3266" data-name="Path 3266" d="M4.245,5.4h.949a.443.443,0,0,0,.418-.6,3.521,3.521,0,0,1,0-2.409.443.443,0,0,0-.418-.6H4.245a.449.449,0,0,0-.433.316,5.3,5.3,0,0,0,0,2.968A.449.449,0,0,0,4.245,5.4ZM2.529.656A.445.445,0,0,0,2.136,0H1.143A.455.455,0,0,0,.728.262,8.034,8.034,0,0,0,0,3.6,7.838,7.838,0,0,0,.751,6.943a.453.453,0,0,0,.411.257h.98a.445.445,0,0,0,.392-.657,6.272,6.272,0,0,1,0-5.886M17.27.261A.455.455,0,0,0,16.856,0h-1a.445.445,0,0,0-.394.654,6.307,6.307,0,0,1,.008,5.885.446.446,0,0,0,.391.661h.991a.455.455,0,0,0,.414-.261,8,8,0,0,0,0-6.677M13.755,1.8h-.949a.443.443,0,0,0-.418.6,3.521,3.521,0,0,1,0,2.409.443.443,0,0,0,.418.6h.949a.449.449,0,0,0,.433-.316,5.3,5.3,0,0,0,0-2.968.448.448,0,0,0-.433-.316M10.484,4.616a1.8,1.8,0,1,0-2.968,0l-3.67,8.815a.45.45,0,0,0,.242.588l.83.346a.45.45,0,0,0,.588-.242L6.89,10.8H11.11l1.384,3.323a.449.449,0,0,0,.588.242l.83-.346a.45.45,0,0,0,.242-.588ZM7.639,9,9,5.732,10.361,9Z" transform="translate(3 5.2)" fill="#343a40"/>\n' +
  '</svg>\n';

export const towerMacroSvgStringAsUrl = () => {
  return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(towerMacroSvgString());
};

export const towerMacroIcon = () => {
  return {
    url: towerMacroSvgStringAsUrl(),
    anchor: new google.maps.Point(12, 12),
    labelOrigin: new google.maps.Point(12, 34),
  };
};

export const towerMicroSvgString = () =>
  '<svg id="icon_Tower_Micro" data-name="icon/Tower/Micro" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\n' +
  '  <rect id="Rectangle_126" data-name="Rectangle 126" width="24" height="24" rx="12" fill="#fff"/>\n' +
  '  <path id="Path_3268" data-name="Path 3268" d="M4.469,1.639a.665.665,0,0,0-.906.261,3.617,3.617,0,0,0-.12,3.268.666.666,0,1,0,1.209-.559,2.263,2.263,0,0,1-.208-.958,2.29,2.29,0,0,1,.285-1.106.665.665,0,0,0-.26-.905M1.739.066A.888.888,0,0,0,.582.556a8.022,8.022,0,0,0,0,6A.889.889,0,1,0,2.23,5.889a6.249,6.249,0,0,1,0-4.666A.888.888,0,0,0,1.739.066m13.678.49a.889.889,0,0,0-1.648.666,6.18,6.18,0,0,1,.453,2.333,5.971,5.971,0,0,1-.452,2.308.909.909,0,0,0,.491,1.183.877.877,0,0,0,.333.064.889.889,0,0,0,.824-.556A8.484,8.484,0,0,0,16,3.531,8.388,8.388,0,0,0,15.417.556M11.672,1.618a.666.666,0,0,0-.326.884,2.261,2.261,0,0,1,.209.959,2.289,2.289,0,0,1-.285,1.106.667.667,0,0,0,1.167.646,3.629,3.629,0,0,0,.451-1.752,3.591,3.591,0,0,0-.333-1.517.666.666,0,0,0-.884-.326m-3.289.2A1.774,1.774,0,0,0,7.111,5.087v8.247a.889.889,0,1,0,1.778,0V5.086a1.772,1.772,0,0,0-.506-3.268" transform="translate(4 5)" fill="#343a40"/>\n' +
  '</svg>\n';

export const towerMicroSvgStringAsUrl = () => {
  return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(towerMicroSvgString());
};

export const towerMicroIcon = () => {
  return {
    url: towerMicroSvgStringAsUrl(),
    anchor: new google.maps.Point(12, 12),
    labelOrigin: new google.maps.Point(12, 34),
  };
};

export const uavSvgString =
  '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="50" height="60" viewBox="0 0 50 60">' +
  '  <g>' +
  '    <path d="M25.003,0.048 Q33.721,0.048 39.838,6.165 Q45.955,12.282 45.955,21 Q45.955,25.359 43.774,30.984 Q41.593,36.609 38.5,41.532 Q35.407,46.455 32.383,50.742 Q29.359,55.029 27.25,57.561 L25,59.952 Q24.157,58.968 22.75,57.351 Q21.343,55.734 17.686,50.883 Q14.029,46.032 11.287,41.46 Q8.545,36.888 6.295,31.125 Q4.045,25.362 4.045,21 Q4.045,12.282 10.162,6.165 Q16.279,0.048 24.997,0.048 z" fill="#343A40"/>' +
  '    <path d="M7,21 C7,11.059 15.059,3 25,3 C34.941,3 43,11.059 43,21 C43,30.941 34.941,39 25,39 C15.059,39 7,30.941 7,21" fill="#FFFFFF"/>' +
  '    <path d="M35.115,17.635 L29.094,17.635 L23.557,7.944 C23.407,7.681 23.127,7.519 22.825,7.519 L19.374,7.519 C18.814,7.519 18.41,8.055 18.563,8.594 L21.147,17.635 L15.727,17.635 L13.451,14.6 C13.292,14.388 13.042,14.263 12.777,14.263 L10.67,14.263 C10.122,14.263 9.719,14.778 9.852,15.31 L11.513,21.007 L9.852,26.703 C9.719,27.235 10.122,27.75 10.67,27.75 L12.777,27.75 C13.043,27.75 13.292,27.626 13.451,27.413 L15.727,24.379 L21.147,24.379 L18.563,33.419 C18.41,33.958 18.814,34.494 19.374,34.494 L22.825,34.494 C23.127,34.494 23.406,34.332 23.556,34.07 L29.094,24.379 L35.115,24.379 C36.978,24.379 40.173,22.869 40.173,21.007 C40.173,19.144 36.978,17.635 35.115,17.635 z" fill="#343A40"/>' +
  '  </g>' +
  '</svg>';

export const uavSvgStringAsUrl = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(uavSvgString);

export const uavSvgStringAsBase64Url = 'data:image/svg+xml;base64,' + Buffer.from(uavSvgString).toString('base64');

export const uavIcon = () => {
  return {
    url: uavSvgStringAsUrl,
    anchor: new google.maps.Point(25, 60),
    labelOrigin: new google.maps.Point(24, 72)
  };
};

export const subwayStationPath = "M8.5,15 C4.91,15 2,12.09 2,8.5 C2,4.91 4.91,2 8.5,2 C12.09,2 15,4.91 15,8.5 C15,12.09 12.09,15 8.5,15 z";

export const subwayStationSvgString =
  '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" width="16" height="16" viewBox="0, 0, 16, 16">' +
  '  <g id="Layer_1">' +
  '    <g>' +
  '      <path d="M8.5,15 C4.91,15 2,12.09 2,8.5 C2,4.91 4.91,2 8.5,2 C12.09,2 15,4.91 15,8.5 C15,12.09 12.09,15 8.5,15 z" fill="#FFFFFF"/>' +
  '      <path d="M8.5,15 C4.91,15 2,12.09 2,8.5 C2,4.91 4.91,2 8.5,2 C12.09,2 15,4.91 15,8.5 C15,12.09 12.09,15 8.5,15 z" fill-opacity="0" stroke="#000000" stroke-width="3"/>' +
  '    </g>' +
  '  </g>' +
  '</svg>';
