import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { ROUTE_PLAN_COMPONENT } from "../../../constants/constants";
import '../../../styles.css';
import TimelineDiagram from "../../../components/TimelineDiagram/TimelineDiagram";
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";

const Reservation = ({
  identity,
  reservation,
  disabled,
  parentComponent = null,
  handleRouteSubmit,
  handleDeleteRoute,
  handleCancelReservation,
  handleOk,
  flightPath
}) => {
  useEffect(() => {
    window.scroll({
      top: document.body.scrollHeight, // or document.scrollingElement || document.body
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <Row>
      <>
        <div>
          {!reservation.is_fully_covered && (
            <div className={"alert alert-danger"}>
              Parts of the Route are outside radio coverage
              <i className="fas fa-times" />
            </div>
          )}
          {reservation.is_fully_covered && !reservation.are_resources_available && parentComponent !== ROUTE_PLAN_COMPONENT && (
            <div className={"alert alert-danger"}>
              No availability at this time
              <i className="fas fa-times" />
            </div>
          )}
          {reservation.is_fully_covered && (reservation.are_resources_available || parentComponent === ROUTE_PLAN_COMPONENT) && (
            <div className={"alert alert-success"}>
              {parentComponent === ROUTE_PLAN_COMPONENT ? 'Route is within radio coverage' : 'A Reservation has been made for this Flight'}
              <i className="fa-solid fa-circle-check"></i>
            </div>
          )}
          {parentComponent === ROUTE_PLAN_COMPONENT &&
            <h3 className={"mb-3"}>Coverage Timeline<HelpToolTip content="This diagram shows which AURA site (if any)
             will provide radio coverage for each Leg of the Route. The time range of each Leg is shown as Estimated Elapsed Time
             (EET), including the 'buffer' ETD uncertainty time specified above. (The ETD itself will be specified when each Flight
             is created from this Route).\n
             Expanding a Leg shows the estimated time range(s) in which each Terminus/Waypoint will be reached by the aircraft." /></h3>
          }
          {parentComponent !== ROUTE_PLAN_COMPONENT &&
            <h3 className={"mb-3"}>Reservation Timeline<HelpToolTip content="This diagram shows which AURA site
             will provide radio coverage for each Leg of the Flight. The time range of each Leg includes the 'buffer' ETD
             uncertainty time specified in the Route.\n
             Expanding a Leg shows the estimated time range(s) in which each Terminus/Waypoint will be reached by the aircraft." /></h3>
          }
          <TimelineDiagram reservation={reservation} parentComponent={parentComponent} flightPath={flightPath}/>
        </div>
        <div className="col-md-12 text-end mt-2">
          {reservation.is_fully_covered && parentComponent === ROUTE_PLAN_COMPONENT && (
            <>
              <Button
                variant="outline-danger"
                onClick={handleDeleteRoute}
                className="ms-1"
              >
                Discard
                {' '}
                <i className="fas fa-trash" />
              </Button>
              <Button
                variant={'success'}
                className="ms-1 btn-icon-after reservation-icon"
                disabled={disabled}
                onClick={handleRouteSubmit}
              >
                {disabled && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Create Route
              </Button>
            </>
          )}
          {reservation.is_fully_covered && parentComponent !== ROUTE_PLAN_COMPONENT && (
            <>
              <Button
                variant={'outline-danger'}
                className="ms-1 btn-icon-after reservation-icon"
                disabled={disabled}
                onClick={handleCancelReservation}
              >
                {disabled && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Cancel Reservation
              </Button>
              <Button
                variant={'success'}
                className="ms-1 btn-icon-after"
                disabled={disabled}
                onClick={handleOk}
              >
                OK <i className="fa-solid fa-circle-check"></i>
              </Button>
            </>
          )}
        </div>
      </>
    </Row>
  );
};

Reservation.propTypes = {
  reservation: PropTypes.object.isRequired,
  identity: PropTypes.object.isRequired,
};

export default Reservation;
