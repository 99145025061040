import { createSlice } from "@reduxjs/toolkit";
import { RouteRecord } from "../../records/routes/routes.record";

const initialState = new RouteRecord({});

const routeList = createSlice({
  name: 'routeList',
  initialState,
  reducers: {
    isFetching: (routesState) => {
      return routesState.merge({
        isFetching: true,
        isFetched: false,
        isError: false,
        highlightedRouteIndex: null
      });
    },
    isFetched: (routesState, action) => {
      return routesState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        routes: action.payload.records,
        flightPlans: action.payload.flightPlans,
        fetchRoutesFlag: false
      });
    },

    isError: (routesState) => {
      return routesState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    setHighlightedRouteIndex: (routesState, action) => {
      return routesState.merge({
        highlightedRouteIndex: action.payload.idx
      });
    },
    deleteRoute: (routesState, action) => {
      return routesState.merge({
        routes: action.payload.filteredRoutes,
        flightPlans: action.payload.flightPlans,
        isFetching: false, // Update the isFetching state to false
        isFetched: true // Set isFetched to true since the routes are fetched
      });
    },
    deactivateRoute: (routesState, action) => {
      return routesState.merge({
        routes: action.payload,
        isFetching: false,
        isFetched: true,
      });
    },
    setFetchRoutesFlag: (routesState, action) => {
      return routesState.merge({
        fetchRoutesFlag: action.payload.fetchRoutesFlag
      });
    },
    clear: (routesState) => {
      return routesState.merge({
        routeList: null
      });
    }
  }
});

export const routeListReducer = routeList.reducer;
export const routeListActions = routeList.actions;
