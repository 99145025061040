import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import Session, { AURA_ID_TOKEN, AURA_ACCESS_TOKEN } from "../../../utils/session";
import history from "../../../utils/history";
import { getIdentity } from "../../admin/store/actions";

const LoginButton = () => {
  const dispatch = useDispatch();
  const { loginWithRedirect, getIdTokenClaims, isAuthenticated, getAccessTokenSilently, error, isLoading } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        prompt: "login",
      });
    }

    const fetchTokenClaims = async () => {
      if (isAuthenticated) {
        const existingIdToken = localStorage.getItem(AURA_ID_TOKEN);
        const existingAccessToken = localStorage.getItem(AURA_ACCESS_TOKEN);
        const shouldFetchNewTokens = !existingIdToken || !existingAccessToken || Session.isTokenExpiringShortly();

        if (shouldFetchNewTokens) {
          try {
            const id_token = await getIdTokenClaims();
            const access_token = await getAccessTokenSilently();

            Session.onLoginSuccess({
              id_token: id_token.__raw,
              access_token,
              expires_in: id_token.exp - id_token.iat,
              token_type: "Bearer",
              expires_at: id_token.exp,
              org_id: id_token.org_id
            });
            Session.setApiLastCalledWithTokenTimeNow();
          } catch (error) {
            console.error("Error fetching ID token claims:", error);
          }
        }
        history.push("/auranetwork");
        dispatch(getIdentity());
      }
    };

    fetchTokenClaims();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return null;
};

export default LoginButton;
