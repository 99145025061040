import { Record as ImmutableRecord } from "immutable";

export const MapCoordinates = new ImmutableRecord({
  lnglat: null,
  name: null,
  icao_code: null
});

export const RoutePlanRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  disableRoute: false,
  saveButtonText: "Check Radio Coverage",
  parentComponent: null,
  selectedRoute: null,
  flightPath: null,
  reservation: null,
  routeUuid: null,
  disconnectedNodes: [],
  name: '',
  aircraftModel: '',
  radioModel: '',
  antennaModel: '',
  cruising: {
    trueAirspeedMs: 0.0,
    altitudeM: 0.0,
    icaoCruisingLevel: '',
    icaoCruisingSpeed: ''
  },
  departureTimeUncertainty: 0,
  positionUncertainty: {
    horizontal: 0,
    vertical: 0
  },
  editingDestination: false,
  editingDeparture: false,
  addingWaypoint: false,
  addingAlternate: false,
  mapCoordinates: {
    lnglat: null,
    name: null,
    icao_code: null
  },
  flightOrigin: {
    departure: null,
    waypoints: null,
    destination: null,
    alternates: null,
    initialLoad: true
  }
});
