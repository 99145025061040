import { Record as ImmutableRecord } from "immutable";

export const AircraftRecord = new ImmutableRecord({
  uuid: '',
  aircraftModelUuid: '',
  organization: '',
  tailNumber: ''
});

export const AircraftsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  aircrafts: [new AircraftRecord({})]
});
