import { createSlice } from "@reduxjs/toolkit";
import { MonitoringRecord } from "../records/monitoring.record";

const initialState = new MonitoringRecord({});

const monitoring = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    isFetching: (monitoringState) => {
      return monitoringState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (monitoringState, action) => {
      return monitoringState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        time: action.payload.time
      });
    },
    isError: (monitoringState) => {
      return monitoringState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: () => {
      return new MonitoringRecord({});
    }
  }
});

export default monitoring;
