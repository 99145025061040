import axios from "axios";
import _ from "lodash";
import Session from "../../../../../utils/session";
import { flightPlanActions } from "../../reducers/flights/flight.plan.reducer";
import { convertToFlightPlanDto } from "../../utils/flights/flight.plan.util";
import { convertToFlightOriginState } from "../../utils/routes/route.plan.util";
import {
  FLIGHT_PLANNING_API_URL_V2,
  FLIGHTS_ENDPOINT,
  TEMPLATE_RESERVATION_ENDPOINT,
  RESERVATION_LIFECYCLE_API_URL,
  RESERVATION_ENDPOINT,
  CANCEL_RESERVATION_ENDPOINT,
  FLIGHT_PATH_ENDPOINT
} from "../../../../../constants/api.endpoints";
import { toggleFlightPlanPanel } from "../panels.action";
import { setReservations, setFlightPath, setFlightPlans } from "../map/map.action";
import { getErrorMessage } from "../../utils/common";
import { setFetchRoutesFlag } from "../routes/routes.action";
import { coerceDateIntoUtc } from "../../../../../utils/units";

export const cancelReservation = (setErrorMessage) => async (dispatch, getState) => {
  try {
    setErrorMessage(null);
    const { flightUuid } = getState().flightPlan;
    dispatch(flightPlanActions.isFetching());
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Canceling Reservation" }));
    const response = await axios.post(`${RESERVATION_LIFECYCLE_API_URL}${CANCEL_RESERVATION_ENDPOINT}${flightUuid}`, {}, Session.requestConfig());
    if (Session.validationResponse(response)) {
      dispatch(setReservations(null));
      dispatch(flightPlanActions.isFetched({ disableFlightPlan: false }));
      dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    dispatch(flightPlanActions.isError());
  }
};

export const createReservation = (flightUuid, setErrorMessage) => async (dispatch, getState) => {
  try {
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Making a Reservation" }));
    const response = await axios.post(`${RESERVATION_LIFECYCLE_API_URL}${RESERVATION_ENDPOINT}`, {
      flight_uuid: flightUuid,
    }, Session.requestConfig());
    if (Session.validationResponse(response)) {
      const reservation = response.data;
      const { sitesByUuid } = getState().sites;
      for (const leg of reservation?.legs) {
        if (leg?.site_uuid && leg?.site_uuid !== "") {
          leg.site_name = sitesByUuid[leg.site_uuid]?.name;
          leg.site_callsign = sitesByUuid[leg.site_uuid]?.callsign;
        }
      }
      dispatch(setReservations([reservation]));
      dispatch(flightPlanActions.isFetched({ disableFlightPlan: true }));
      dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(flightPlanActions.setFlightUuid({ flightUuid }));
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    dispatch(flightPlanActions.isError());
  }
};

export const getTemplateReservation = (route, flightUuid, setErrorMessage) => async (dispatch, getState) => {
  try {
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Checking Template Reservation" }));
    const urlParams = [];
    urlParams.push(`route_uuid=${route.uuid}`);
    urlParams.push(`flight_plan_uuid=${route.flightPlan.uuid}`);
    urlParams.push(`organization=${route.organization}`);
    const response = await axios.get(
        `${RESERVATION_LIFECYCLE_API_URL}${TEMPLATE_RESERVATION_ENDPOINT}?${urlParams.join("&")}`,
        Session.requestConfig());
    if (Session.validationResponse(response)) {
      const templateReservation = response.data.records[0];
      const templateReservationUuid = templateReservation?.uuid;
      dispatch(createReservation(templateReservationUuid, flightUuid, setErrorMessage));
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    dispatch(flightPlanActions.isError());
  }
};

export const deleteFlight = (setErrorMessage) => async (dispatch, getState) => {
  try {
    setErrorMessage(null);
    const { flightUuid } = getState().flightPlan;
    dispatch(flightPlanActions.isFetching());
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Deleting Flight" }));
    const response = await axios.delete(
      `${FLIGHT_PLANNING_API_URL_V2}${FLIGHTS_ENDPOINT}${flightUuid}`,
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      dispatch(setFetchRoutesFlag(true));
      dispatch(setReservations(null));
      dispatch(flightPlanActions.isFetched({ disableFlightPlan: false }));
      dispatch(flightPlanActions.setFlightUuid({ flightUuid: null }));
      dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" })
    );
    dispatch(flightPlanActions.isError());
  }
};

export const getFlightPathInfo = (flightPathUuid, setErrorMessage) => async dispatch => {
  try {
    dispatch(flightPlanActions.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${FLIGHT_PATH_ENDPOINT}${flightPathUuid}`,
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      const flightPath = response.data;
      dispatch(setFlightPath(flightPath));
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    dispatch(flightPlanActions.isError());
  }
};

export const createFlight = (flightData, setErrorMessage) => async (dispatch, getState) => {
  try {
    setErrorMessage(null);
    const flight_uuid = getState().flightPlan?.flightUuid;
    dispatch(flightPlanActions.isFetching());
    dispatch(setReservations(null));
    const route = _.find(getState().routeList.routes, { uuid: flightData?.route });
    const inventory = getState().inventory;
    const departureTime = coerceDateIntoUtc(flightData?.departureTime);
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Creating Flight" }));
    const flightRequestBody = convertToFlightPlanDto(route, departureTime, inventory);
    let response = null;
    if (flight_uuid) {
      response = await axios.put(
        `${FLIGHT_PLANNING_API_URL_V2}${FLIGHTS_ENDPOINT}${flight_uuid}`,
        { ...flightRequestBody },
        Session.requestConfig());
    } else {
      response = await axios.post(
        `${FLIGHT_PLANNING_API_URL_V2}${FLIGHTS_ENDPOINT}`,
        { ...flightRequestBody },
        Session.requestConfig());
    }
    if (Session.validationResponse(response)) {
      dispatch(setFetchRoutesFlag(true));
      const flightUuid = response?.data?.uuid;
      dispatch(flightPlanActions.setFlightUuid({ flightUuid }));
      dispatch(getFlightPathInfo(route.flightPlan.flightPathUuid, setErrorMessage));
      dispatch(createReservation(flightUuid, setErrorMessage));
    } else {
      throw new Error();
    }
  } catch (err) {
    setErrorMessage(getErrorMessage(err) || err.message);
    dispatch(flightPlanActions.setSaveButtonText({ saveButtonText: "Make a Reservation" }));
    dispatch(flightPlanActions.isError());
  }
};

export const setFlightUuid = (flightUuid) => (dispatch) => {
  dispatch(flightPlanActions.setFlightUuid({ flightUuid }));
};

export const setSelectedRoute = (selectedRoute) => (dispatch) => {
  dispatch(flightPlanActions.setSelectedRoute({ selectedRoute }));
  const newFlightOrigin = convertToFlightOriginState(selectedRoute?.flightPlan);
  dispatch(toggleFlightPlanPanel());
  dispatch(setFlightPlans([newFlightOrigin]));
};

export const clearFlightPlan = () => (dispatch) => {
  dispatch(flightPlanActions.clear());
};
