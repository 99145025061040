import {
  coerceDateIntoUtc,
  feetToMeters, ftminTokmh,
  kmhToftmin,
  kmhToknots,
  knotsTokmh,
  metersToFeet,
  round,
} from "../../../utils/units";

const aircraftSubfields = [
  "name",
  "aircraft_tail_number",
  "aircraft_type",
  "no_aircraft",
  "flight_type",
  "flight_rule",
  "wake_turbulence",
  "aircraft_equipment",
  "pilot_contact_information",
  "pilot_in_command",
  "surveillance_equipment",
  "aircraft_color_and_markings",
  "other_information",
  "supplemental_remarks",
  "emergency_radios",
  "survival_equipment",
  "jackets",
  "dinghies",
  "fuel_endurance",
  "uav_type"
];

export const DEPARTURE_INDEX = 0;
export const DESTINATION_INDEX = 1;
export const WAYPOINT_START_ID = 100;
export const ALTERNATE_START_ID = 10000;

const MAX_NEXT_PREVIEW = 7;

export const calculateNextNodes = (selectedRoute) => {
  const { graph_edges: edges = [], waypoints = [], alternates = [] } = (selectedRoute || {});
  const uid_map = [
    DEPARTURE_INDEX,
    DESTINATION_INDEX,
    ...(alternates || []).map((alt, i) => i + ALTERNATE_START_ID),
    ...(waypoints || []).map((wp, i) => i + WAYPOINT_START_ID)
  ].reduce((obj, uuid, vidx) => ({ ...obj, [vidx]: uuid }), {});
  return edges.reduce((obj, [from_vert, to_vert]) => ({ ...obj, [uid_map[from_vert]]: [...(obj[uid_map[from_vert]] || []), uid_map[to_vert]] }), {});
};

export const calculateGraphEdges = (departure, waypoints, alternates) => {
  if (!waypoints?.length && !alternates.length) { return [[0, 1]]; }

  const toIndex = [...alternates, ...waypoints].reduce((obj, node, idx) => ({ ...obj, [node.uid]: idx + 2 }), { 0: 0, 1: 1 });
  const uidEdges = departure.next.map(n => [DEPARTURE_INDEX, n])
    .concat(waypoints.reduce((acc, wp) => [...acc, ...wp.next.map(n => [wp.uid, n])], []));
  return uidEdges.map(([from, to]) => [toIndex[from], toIndex[to]]);
};

export const displayUid = (uid) =>
  uid >= ALTERNATE_START_ID
    ? `Alternate ${uid - ALTERNATE_START_ID}`
    : (
        uid >= WAYPOINT_START_ID ? `Waypoint ${uid + 1 - WAYPOINT_START_ID}` : 'Destination');

export const displayNextNodes = (next_uids, destination, alternates, waypoints) => {
  const nodes_by_uid = new Map();

  nodes_by_uid.set(DESTINATION_INDEX, destination);
  waypoints.forEach(waypoint => { nodes_by_uid.set(waypoint.uid, waypoint); });
  alternates.forEach(alternate => { nodes_by_uid.set(alternate.uid, alternate); });

  return next_uids?.slice(0, MAX_NEXT_PREVIEW)
    ?.map(uid => nodes_by_uid.get(uid))
    .map(node => node ? (node.airport_code || node.icao_description || displayUid(node.uid || DESTINATION_INDEX)) : '')
    .join(", ");
};

export const nextNodeOptions = (uid, destination, waypoints, alternates) => {
  const result = [...(waypoints || []), { uid: DESTINATION_INDEX, ...destination }, ...(alternates || [])];
  return result;
};

const convertToTerminusState = (terminus) => {
  if (
    terminus &&
    Object.hasOwn(terminus, 'location') &&
    Object.hasOwn(terminus.location, 'type') &&
    Object.hasOwn(terminus.location, 'coordinates')
  ) {
    return {
      name: terminus.name,
      airport_code: terminus.airport_code,
      longitude: round(terminus.location.coordinates[0], 6),
      latitude: round(terminus.location.coordinates[1], 6),
      date: coerceDateIntoUtc(new Date(terminus.estimated_time))
    };
  }
  return {};
};

export const convertToDepartureState = (plan, next_node_map = {}) => (
  { ...convertToTerminusState(plan?.departure), uid: DEPARTURE_INDEX, next: next_node_map[DEPARTURE_INDEX] || [] });
export const convertToDestinationState = (plan) => (
  { ...convertToTerminusState(plan?.destination), uid: DESTINATION_INDEX });
export const convertToAlternateState = (plan, next_node_map) => (plan?.alternates || []).map((alt, idx) => (
  { ...convertToTerminusState(alt), uid: ALTERNATE_START_ID + idx, next: next_node_map[ALTERNATE_START_ID + idx] || [] }
));

export const convertToWaypointsState = (plan, next_node_map) => (plan?.waypoints || []).map((wp, idx) => ({
  lat: round(wp.location.coordinates[1], 6),
  lng: round(wp.location.coordinates[0], 6),
  name: wp.name || "",
  icao_description: wp.icao_description || "",
  altitude_m: wp.altitude_m,
  true_airspeed_ms: wp.true_airspeed_ms,
  hold_time_s: wp.hold_time_s,
  uid: WAYPOINT_START_ID + idx,
  next: next_node_map[WAYPOINT_START_ID + idx] || []
}));

export const convertToInitialFormValues = (initialFlightPlan, userData) => {
  const flightPlanDraft = (() => {
    if (userData?.flightPlanDraft) {
      const draft = JSON.parse(userData.flightPlanDraft);

      if (draft.departure?.date) {
        draft.departure.date = coerceDateIntoUtc(draft.departure.date);
      }

      if (draft.destination?.date) {
        draft.destination.date = coerceDateIntoUtc(draft.destination.date);
      }

      if (draft.alternates) {
        for (const alternate of draft.alternates) {
          if (alternate.date) {
            alternate.date = coerceDateIntoUtc(alternate.date);
          }
        }
      }
      return draft;
    }
    return null;
  })();

  const next_node_map = calculateNextNodes(initialFlightPlan);

  return !initialFlightPlan && flightPlanDraft
    ? flightPlanDraft
    : {
        name: initialFlightPlan?.aircraft?.name || '',
        aircraft_tail_number: initialFlightPlan?.aircraft?.aircraft_tail_number || '',
        aircraft_type: initialFlightPlan?.aircraft?.aircraft_type || '',
        no_aircraft: initialFlightPlan?.aircraft?.no_aircraft || 1,
        flight_type: initialFlightPlan?.aircraft?.flight_type || '',
        flight_rule: initialFlightPlan?.aircraft?.flight_rule || '',
        wake_turbulence: initialFlightPlan?.aircraft?.wake_turbulence || '',
        aircraft_equipment: initialFlightPlan?.aircraft?.aircraft_equipment || '',
        pilot_contact_information: initialFlightPlan?.aircraft?.pilot_contact_information || '',
        pilot_in_command: initialFlightPlan?.aircraft?.pilot_in_command || '',
        surveillance_equipment: initialFlightPlan?.aircraft?.surveillance_equipment || '',
        aircraft_color_and_markings: initialFlightPlan?.aircraft?.aircraft_color_and_markings || '',
        other_information: initialFlightPlan?.aircraft?.other_information || '',
        supplemental_remarks: initialFlightPlan?.aircraft?.supplemental_remarks || '',
        emergency_radios: initialFlightPlan?.aircraft?.emergency_radios || '',
        survival_equipment: initialFlightPlan?.aircraft?.survival_equipment || '',
        jackets: initialFlightPlan?.aircraft?.jackets || '',
        persons_on_board: initialFlightPlan?.aircraft?.persons_on_board || 0,
        dinghies: initialFlightPlan?.aircraft?.dinghies || 0,
        fuel_endurance: initialFlightPlan?.aircraft?.fuel_endurance || '',
        uav_type: initialFlightPlan?.aircraft?.uav_type || '',
        ascent_rate: initialFlightPlan?.aircraft ? round(kmhToftmin(initialFlightPlan.aircraft.ascent_rate_kmh), 1) : '',
        descent_rate: initialFlightPlan?.aircraft ? round(kmhToftmin(initialFlightPlan.aircraft.descent_rate_kmh), 1) : '',
        ascent_true_airspeed: initialFlightPlan?.aircraft ? round(kmhToknots(initialFlightPlan.aircraft.ascent_true_airspeed_kmh), 1) : '',
        descent_true_airspeed: initialFlightPlan?.aircraft ? round(kmhToknots(initialFlightPlan.aircraft.descent_true_airspeed_kmh), 1) : '',
        true_airspeed: initialFlightPlan?.cruising ? round(kmhToknots(initialFlightPlan.cruising.true_airspeed_ms), 1) : '',
        altitude: initialFlightPlan?.cruising ? round(metersToFeet(initialFlightPlan.cruising.altitude_m), 0) : '',
        bandwidth_subcarriers: initialFlightPlan?.resource_block_requirements?.num_subcarriers ?? 1,
        bandwidth_time_slots: initialFlightPlan?.resource_block_requirements?.num_time_slots ?? 1,
        departure: convertToDepartureState(initialFlightPlan, next_node_map),
        waypoints: convertToWaypointsState(initialFlightPlan, next_node_map),
        destination: convertToDestinationState(initialFlightPlan),
        alternates: convertToAlternateState(initialFlightPlan, next_node_map) // TODO: Pull from real state
      };
};

export const convertToCruisingDto = (values) => {
  return {
    true_airspeed_ms: knotsTokmh(round(values.true_airspeed, 1)),
    altitude_m: feetToMeters(round(values.altitude, 0)),
  };
};

export const convertToTerminusDto = (terminusState) => {
  const terminus = {
    estimated_time: terminusState.date,
    airport_code: terminusState.airport_code
  };

  if (!terminusState.icao_code) {
    terminus.name = terminusState.name;
    terminus.location = {
      type: 'Point',
      coordinates: [terminusState.longitude, terminusState.latitude]
    };
  }
  return terminus;
};

export const convertToWaypointDtos = (waypoints) => {
  return waypoints.map(x => {
    return {
      name: x.name,
      location: {
        type: "Point",
        coordinates: [round(x.lng, 6), round(x.lat, 6)]
      },
      icao_description: x.icao_description || undefined,
      altitude_m: x.altitude_m || undefined,
      true_airspeed_ms: x.true_airspeed_ms || undefined,
      hold_time_s: x.hold_time_s || undefined
    };
  });
};

export const convertToAlternateDtos = alternates => alternates.map(convertToTerminusDto);

export const convertToFlightPlanDto = (values) => {
  const request = {
    aircraft: {},
    cruising: {},
    departure: {},
    destination: {},
    alternates: [],
    waypoints: [],
    graph_edges: [],
    resource_block_requirements: {}
  };

  // Aircraft details
  for (const aircraftSubfield of aircraftSubfields) {
    request.aircraft[aircraftSubfield] = values[aircraftSubfield];
  }
  request.aircraft.persons_on_board = parseInt(values.persons_on_board);
  request.aircraft.dinghies = parseInt(values.dinghies) || 0;
  request.aircraft.no_aircraft = parseInt(values.no_aircraft);
  request.aircraft.ascent_rate_kmh = ftminTokmh(round(values.ascent_rate, 1));
  request.aircraft.descent_rate_kmh = ftminTokmh(round(values.descent_rate, 1));
  request.aircraft.ascent_true_airspeed_kmh = knotsTokmh(round(values.ascent_true_airspeed, 1));
  request.aircraft.descent_true_airspeed_kmh = knotsTokmh(round(values.descent_true_airspeed, 1));

  request.cruising = convertToCruisingDto(values);
  request.departure = convertToTerminusDto(values.departure);
  request.waypoints = convertToWaypointDtos(values.waypoints);
  request.destination = convertToTerminusDto(values.destination);
  request.alternates = convertToAlternateDtos(values.alternates);

  request.graph_edges = calculateGraphEdges(values.departure, values.waypoints, values.alternates);

  request.resource_block_requirements.num_subcarriers = parseInt(values.bandwidth_subcarriers);
  request.resource_block_requirements.num_time_slots = parseInt(values.bandwidth_time_slots);

  return request;
};
