import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "../../../../admin/store/records/general.record";

export const ResourceRequirementRecord = ImmutableRecord({
  numSubCarriers: 0,
  numTimeSlots: 0
});

export const AirborneRadioModelRecord = new ImmutableRecord({
  uuid: '',
  name: '',
  rxResourceRequirements: new ResourceRequirementRecord({}),
  supported_tx_power_dbm: [],
  txResourceRequirements: new ResourceRequirementRecord({}),
  metadata: new MetadataRecord({})
});

export const AirborneRadioModelsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  airborneRadioModels: [new AirborneRadioModelRecord({})]
});
