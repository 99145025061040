import { createSlice } from "@reduxjs/toolkit";
import { AircraftRecord, AircraftsRecord } from "../../records/inventory/aircraft.record";

const initialState = new AircraftsRecord({});

const aircraft = createSlice({
  name: 'aircraft',
  initialState,
  reducers: {
    isFetching: (aircraftState) => {
      return aircraftState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (aircraftState, action) => {
      return aircraftState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        aircrafts: action.payload.aircrafts,
      });
    },
    isError: (aircraftState) => {
      return aircraftState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    clear: (aircraftState) => {
      return aircraftState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        aircrafts: [new AircraftRecord({})]
      });
    }
  }
});

export default aircraft;
