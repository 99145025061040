import { flightsActions } from "../reducers";
import { clearMap } from "./map/map.action";

export const toggleFlightListPanel = (showPanel = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isFlightListPanelVisible } = showPanel === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isFlightListPanelVisible', panelValue: !isFlightListPanelVisible }));
};

export const toggleFlightDetailsPanel = (showPanel = null) => (dispatch, getState) => {
  const { isFlightDetailsPanelVisible } = showPanel === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isFlightDetailsPanelVisible', panelValue: !isFlightDetailsPanelVisible }));
};

export const toggleFlightPlanPanel = (showPanel = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isFlightPlanPanelVisible } = showPanel === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isFlightPlanPanelVisible', panelValue: !isFlightPlanPanelVisible }));
};

export const toggleSiteListPanel = (showModal = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isSiteListPanelVisible } = showModal === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isSiteListPanelVisible', panelValue: !isSiteListPanelVisible }));
};

export const toggleSiteDetailsModal = (showModal = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isSiteDetailsPanelVisible } = showModal === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isSiteDetailsPanelVisible', panelValue: !isSiteDetailsPanelVisible }));
};

export const toggleResourceBlockPanel = (showModal = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isResourceBlockPanelVisible } = showModal === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isResourceBlockPanelVisible', panelValue: !isResourceBlockPanelVisible }));
};

export const toggleCoverageBoundsPanel = (showModal = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isCoverageBoundsPanelVisible } = showModal === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isCoverageBoundsPanelVisible', panelValue: !isCoverageBoundsPanelVisible }));
};

export const toggleRoutePlanPanel = (showModal = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isRoutePlanningPanelVisible } = showModal === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isRoutePlanningPanelVisible', panelValue: !isRoutePlanningPanelVisible }));
};

export const toggleRouteListPanel = (showModal = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isRouteListPanelVisible } = showModal === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isRouteListPanelVisible', panelValue: !isRouteListPanelVisible }));
};

export const toggleResourceSchedulePanel = (showModal = null) => (dispatch, getState) => {
  dispatch(clearMap());
  const { isResourceSchedulePanelVisible } = showModal === null ? getState().modals : false;
  dispatch(flightsActions.panels.togglePanel({ panelName: 'isResourceSchedulePanelVisible', panelValue: !isResourceSchedulePanelVisible }));
};
