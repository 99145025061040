import { Record as ImmutableRecord } from "immutable";

export const MapRecord = new ImmutableRecord({
  flightOrigin: {
    departure: null,
    waypoints: null,
    destination: null,
    alternates: null,
    initialLoad: true
  },
  flightPath: null,
  reservations: null,
  flightPlans: [],
  isDrawingEnabled: false,
  highlightedRoute: null,
  templateSignals: null,
  siteUuidsToShowCoverage: [],
  siteCoverageMetric: 0,
});
