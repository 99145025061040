import { Record as ImmutableRecord, List as ImmutableList } from "immutable";
import { MetadataRecord } from "./general.record";

export const ResourceGridRecord = new ImmutableRecord({
  metadata: new MetadataRecord({}),
  name: '',
  numSubCarriers: 0,
  numTimeSlots: '',
  minFrequencyHz: '',
  subChannelWidthHz: '',
  siteUuids: '',
  uuid: ''
});

export const PaginationDetailRecord = new ImmutableRecord({
  page: 0,
  perPage: 0
});

export const ResourceGridSetsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  paginationDetails: new PaginationDetailRecord({}),
  records: new ImmutableList(ResourceGridRecord({})),
  resourceGridByUuid: {},
  resourceGrid: []
});
