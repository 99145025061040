import axios from 'axios';
import { FLIGHT_PATH_ENDPOINT, FLIGHT_PLANNING_API_URL_V2, RESERVATION_LIFECYCLE_API_URL, FLIGHT_STATE_MACHINE_ENDPOINT, CURRENT_STATE_MACHINE_STATE } from '../../../../constants/api.endpoints';
import { flightsActions } from '../reducers';
import { setFlightPath } from './map/map.action';
import { toggleFlightDetailsPanel } from './panels.action';
import Session from "../../../../utils/session";

export const getFlightPathInfo = flightPathUuid => async dispatch => {
  try {
    dispatch(flightsActions.flightDetails.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${FLIGHT_PATH_ENDPOINT}${flightPathUuid}`,
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      const flightPath = response.data;
      dispatch(setFlightPath(flightPath));
      dispatch(flightsActions.flightDetails.isFetched());
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(flightsActions.flightDetails.isError());
  }
};

export const setFlightDetails = data => dispatch => {
  dispatch(flightsActions.flightDetails.setFlightDetails({ ...data }));
  dispatch(toggleFlightDetailsPanel(true));
};

export const showFlightDetailsModal = () => dispatch => {
  dispatch(toggleFlightDetailsPanel(true));
};

export const clearFlightDetails = () => dispatch => {
  dispatch(flightsActions.flightDetails.clear());
};

export const fetchFlightStateMachine = (flight_uuid) => async dispatch => {
  try {
    dispatch(flightsActions.flightDetails.isFetching());
    const response = await axios.get(
    `${RESERVATION_LIFECYCLE_API_URL}${FLIGHT_STATE_MACHINE_ENDPOINT}${flight_uuid}`,
    Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      dispatch(flightsActions.flightDetails.setStateMachineViz(response.data));
      dispatch(flightsActions.flightDetails.isFetched());
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(flightsActions.flightDetails.isError());
  }
};

export const fetchCurrentFlightMachineState = (flight_uuid) => async dispatch => {
  try {
    dispatch(flightsActions.flightDetails.isFetching());
    const response = await axios.get(
      `${RESERVATION_LIFECYCLE_API_URL}${CURRENT_STATE_MACHINE_STATE}${flight_uuid}`,
      Session.requestConfig()
    );
    if (Session.validationResponse(response)) {
      dispatch(flightsActions.flightDetails.setFlightFrrcState(response.data));
      dispatch(flightsActions.flightDetails.isFetched());
    } else {
      throw new Error();
    }
  } catch (err) {
    dispatch(flightsActions.flightDetails.isError());
  }
};
