import React from 'react';
import { Table } from 'react-bootstrap';
import './ResourceScheduleVizGrid.css';
import '../../styles.css';
import '../../subpages/user/requestform/FlightDetails.css';

export const ResourceScheduleVizGrid = ({ data, allocationLookup, isHovered, hoveredFlightUuid, resourceGrid, is_g2a }) => {
  const gridData = [];
  while (data.length) gridData.push(data.splice(0, 16));
  const num_time_slots = gridData.length ? gridData[0].length : 0;
  const num_subcarriers = gridData.length;

  const getTimeSlotIndexes = () => [...Array(num_time_slots).keys()];

  const getAllocationAndStyle = (cellValue) => {
    const allocation = allocationLookup[cellValue];
    let className = "";
    if (allocation.payload_type === 'VOICE') {
      className = allocation.direction === "GROUND_TO_AIR" ? "allocated-voice-g2a" : "allocated-voice-a2g";
    } else if (allocation.payload_type === 'C2_DATA') {
      className = allocation.direction === "GROUND_TO_AIR" ? "allocated-c2-data-g2a" : "allocated-c2-data-a2g";
    } else if (allocation.payload_type === 'CONTROL') {
      className = 'selected-2';
    }
    return { className, allocation };
  };

  return (gridData.length &&
    <Table className={ "resource-grid" }>
      <thead>
        <tr>
          <th colSpan={ num_time_slots + 2 }>Timeslot &rarr;</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          { getTimeSlotIndexes().map((slotIndex) => (
            <th>{ slotIndex }</th>
          )) }
        </tr>
      </thead>
      <tbody>
      { gridData.map((row, rowIndex) => (
        <tr key={ rowIndex }>
          {rowIndex === 0 &&
            <th rowSpan={num_subcarriers} className={"vertical-th"}>
              Subcarrier (low &rarr; high frequency)
            </th>
          }
          <th>{rowIndex}</th>
          { row.map((cellValue, cellIndex) => {
            let allocationAndStyle;
            let isHighlighted = '';
            switch (cellValue) {
              case 0:
                allocationAndStyle = { className: "selected-0" };
                break;
              case 1:
                allocationAndStyle = { className: "selected-1" };
                break;
              case 2:
                allocationAndStyle = { className: "selected-2" };
                break;
              case 3:
                allocationAndStyle = { className: "not-selected" };
                break;
              default:
                allocationAndStyle = getAllocationAndStyle(cellValue);
                isHighlighted = hoveredFlightUuid === allocationAndStyle.allocation.flight_uuid;
                break;
            }
            return (
              <td
                key={ cellIndex }
                className={ `cell ${allocationAndStyle.className} square-block ${isHovered ? isHighlighted ? 'highlighted' : 'non-highlighted' : ''}` }
                title={ `RB Index: ${rowIndex * 16 + cellIndex}, ` +
                  `Center Freq: ${(is_g2a ? resourceGrid.g2a_min_frequency_hz : resourceGrid.a2g_min_frequency_hz) + resourceGrid.subchannel_width_hz * (rowIndex + 0.5)} Hz` +
                  (allocationAndStyle.allocation ? `, Allocation: ${JSON.stringify(allocationAndStyle.allocation)}` : "") }
              >

              </td>
            );
          }) }
        </tr>
      )) }
      </tbody>
    </Table>
  );
};
