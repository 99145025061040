/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRoot } from 'react-dom/client';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import arc from 'arc';
import Slider from 'rc-slider';
import axios from "axios";
import _ from "lodash";
import './MapVisualization.css';
import 'rc-slider/assets/index.css';
import { feetToMeters, metersToFeet, round } from '../../utils/units';
import Session from "../../utils/session";
import { calculateGraphEdges, displayUid } from "../../subpages/user/requestform/util";
import { ACCESS_TOKEN, LOGO_POSITION, STYLE, MAP_MARKER_POPUP_TEXT } from './store/utils/map.constants';
import { FLIGHT_PLANNING_API_URL_V2 } from '../../constants/api.endpoints';
import {
  AIRCRAFT_MARKERS,
  AIRPORT_LAYER,
  CLICK_MARKER_SYMBOLS,
  FLIGHT_PATH_OUTLINE_DIRECTION,
  FLIGHT_PATH_OUTLINE_LINE,
  GEOJSON_INITIAL_OBJECT,
  HANDOVER_LINE,
  ROUTE_MARKER_SYMBOLS,
  SITES_LAYER,
  FLIGHT_PATH_UNCERTAINTY_DISPLAY_FILL,
  FLIGHT_PATH_UNCERTAINTY_DISPLAY_STROKE,
  FLIGHT_PATH_DISPLAY_LINES,
  FLIGHT_PATH_DISPLAY_DIRECTIONS,
  NAVAID_LAYER,
  HIGHTLIGHT_ROUTE_MARKER_SYMBOLS,
  MAPBOX_DRAW_LINE_STYLE,
  FLIGHT_PATH_HIGHLIGHT_LINE,
  FLIGHT_PATH_HIGHLIGHT_DIRECTION,
} from './store/utils/mapbox.layers';
import DirectSelect from './mapbox-draw/DirectSelect';
import CustomDrawLineString from './mapbox-draw/CustomDrawLineString';
import MapPopup from '../MapPopup/MapPopup';
import { setIsdrawingEnabled, toggleCoverageForSiteUuid } from '../../subpages/user/store/actions/map/map.action';
import SubwayMap from './SubwayMap';
import AircraftMarker from './AircraftMarker';

mapboxgl.accessToken = ACCESS_TOKEN;

const SliderTooltip = ({ children }) => {
  return (
    <div style={
      {
        position: "relative",
        bottom: "6px",
        left: "-7px",
        background: "transparent",
        width: "73px",
        padding: "0px 0px 0px 20px",
        border: "2px solid black",
        userSelect: "none",
        fontWeight: 700,
        fontSize: "12px",
      }
    }>
      {children}
    </div>
  );
};

// export default SliderTooltip;

const MapVisualization = props => {
  const dispatch = useDispatch();
  const sites = useSelector(({ sites }) => sites.sites);
  const sitesByUuid = useSelector(({ sites }) => sites.sitesByUuid);
  const flights = useSelector(({ flights }) => flights);
  const flightDetails = useSelector(({ flightDetails }) => flightDetails);
  // const flightDetailsFlightPlan = useSelector(({ flightDetails }) => flightDetails.flightPlan);
  const routePlan = useSelector(({ routePlan }) => routePlan);
  const routeList = useSelector(({ routeList }) => routeList);
  const modals = useSelector(({ modals }) => modals);
  const map = useSelector(({ map }) => map);

  const { highlightedFlightIndex } = flights;
  const { highlightedRouteIndex } = routeList;
  const { editingDeparture, editingDestination, addingWaypoint, addingAlternate, disableRoute, disconnectedNodes } = routePlan;
  const { isRoutePlanningPanelVisible, isFlightPlanPanelVisible, isFlightListPanelVisible, isFlightDetailsPanelVisible, isRouteListPanelVisible } = modals;
  const { flightOrigin, reservations, flightPath, flightPlans, highlightedRoute, templateSignals, siteUuidsToShowCoverage } = map;
  const sitesByUuidRef = useRef(sitesByUuid);
  const drawingEnabledOrDisabled = map.isDrawingEnabled;

  const [flightPathLayers, setFlightPathLayers] = useState(new Set([]));
  const [subwayAircraftMarkerCoods, setSubwayAircraftMarkerCoods] = useState({ screenX: 0, screenY: 0 });
  const displayNameRef = useRef("");
  const clickedMarkerUid = useRef("");
  const [error, setError] = useState(null);
  const [displayedSiteCoverageMetric, setDisplayedSiteCoverageMetric] = useState(0);

  const [sitesGeoJson, setSitesGeoJson] = React.useState({ // eslint-disable-line no-unused-vars
    type: "FeatureCollection",
    features: []
  });

  useEffect(() => {
    setIsDrawingEnabled(drawingEnabledOrDisabled);
    if (!drawingEnabledOrDisabled && markerPopup) {
      markerPopup.remove();
    }
    if (!drawingEnabledOrDisabled && edgePopup) {
      edgePopup.remove();
    }
  }, [drawingEnabledOrDisabled]);

  useEffect(() => {
    console.log(map.siteCoverageMetric);
  }, [map.siteCoverageMetric]);

  const [isDrawingEnabled, _setIsDrawingEnabled] = useState(false);
  // using draw mode reference for mapbox
  const isDrawingEnabledRef = useRef(isDrawingEnabled);
  const setIsDrawingEnabled = data => {
    isDrawingEnabledRef.current = data;
    _setIsDrawingEnabled(data);
  };

  // Draw start point ref for mapbox
  const [startPoint, _setStartPoint] = useState(null);
  const startPointRef = useRef(startPoint);
  const setStartPoint = data => {
    startPointRef.current = data;
    _setStartPoint(data);
  };

  // Draw end point ref for mapbox
  const [endPoint, _setEndPoint] = useState(null);
  const endPointRef = useRef(endPoint);
  const setEndPoint = data => {
    endPointRef.current = data;
    _setEndPoint(data);
  };

  // Marker Pop up Text
  const [markerPopUpContent, _setMarkerPopUpContent] = useState(MAP_MARKER_POPUP_TEXT);
  const markerPopUpContentRef = useRef(markerPopUpContent);
  const setMarkerPopUpContent = data => {
    markerPopUpContentRef.current = data;
    _setMarkerPopUpContent(data);
  };

  // Delete Edge ref for mapbox
  const [deleteEdge, _setDeleteEdge] = useState(null);
  const deleteEdgeRef = useRef(deleteEdge);
  const setDeleteEdge = data => {
    deleteEdgeRef.current = data;
    _setDeleteEdge(data);
  };

  const [showClicks, _setShowClick] = useState(false);
  // using reference for mapbox
  const showClicksRef = useRef(showClicks);
  const setShowClick = clickValue => {
    showClicksRef.current = clickValue;
    _setShowClick(clickValue);
  };

  const addingWaypointRef = useRef(addingWaypoint);

  const [showRouteMarkerClick, _setRouteMarkerClick] = useState(false);
  // using reference for mapbox
  const showRouteMarkerClickRef = useRef(showRouteMarkerClick);
  const setRouteMarkerClick = data => {
    showRouteMarkerClickRef.current = data;
    _setRouteMarkerClick(data);
  };

  const [altitudeSliderValue, setAltitudeSliderValue] = useState(-1);
  const [mapContainer,] = useState(React.createRef());
  const [mapboxMap, setMapboxMap] = useState(null);
  const [mapboxDraw, setMapboxDraw] = useState(null);
  const [edgePopup, setEdgePopup] = useState(null);
  const [markerPopup, setMarkerPopup] = useState(null);
  const [isMapLoaded, setMapLoaded] = useState(false);
  const [isMapConfigured, setMapConfigured] = useState(false);

  const [cancelToken,] = useState(axios.CancelToken.source());

  useEffect(() => {
    sitesByUuidRef.current = sitesByUuid;
  }, [sitesByUuid]);

  useEffect(() => {
    addingWaypointRef.current = addingWaypoint;
  }, [addingWaypoint]);

  useEffect(() => {
    if (!isMapConfigured) {
      return;
    }

    if (isMapConfigured && sites && sites.length > 0) {
      const features = sites.map(site => ({
        type: "Feature",
        properties: {
          uuid: site.uuid,
          callsign: site.callsign
        },
        geometry: {
          type: "Point",
          coordinates: site.location.coordinates
        }
      }));

      setSitesGeoJson({
        type: "FeatureCollection",
        features
      });
      mapboxMap?.getSource('sites').setData({
        type: "FeatureCollection",
        features
      });
      setError(null);
    } else {
      setError("Unable to load data. Please try again later");
    }
  }, [sites, isMapConfigured]);

  const setClickMarker = lngLat => {
    const isClickMarkerLoaded = isMapConfigured && !!mapboxMap?.getSource('clickmarker');
    if (showClicksRef.current && isClickMarkerLoaded) {
      mapboxMap.getSource('clickmarker').setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [lngLat.lng, lngLat.lat]
            }
          }
        ]
      });
    }
  };

  const removeClickMarker = () => {
    const isClickMarkerLoaded = isMapConfigured && !!mapboxMap?.getSource('clickmarker');
    if (isClickMarkerLoaded) {
      mapboxMap.getSource('clickmarker').setData({
        type: 'FeatureCollection',
        features: []
      });
    }
  };

  const airportClick = event => {
    const lngLat = { lng: event.features[0].geometry.coordinates[0], lat: event.features[0].geometry.coordinates[1] };
    const name = event.features[0].properties.name;
    const icao_code = event.features[0].properties.icao_code;
    setClickMarker(lngLat);
    event.originalEvent.dontPropagate = true;
    if (showClicksRef.current) props.mapClickCallback(lngLat, name, icao_code);
  };

  const navaidClick = event => {
    if (addingWaypointRef.current) {
      const lngLat = { lng: event.features[0].geometry.coordinates[0], lat: event.features[0].geometry.coordinates[1] };
      const name = event.features[0].properties.name;
      const icao_code = event.features[0].properties.icao_code;
      setClickMarker(lngLat);
      event.originalEvent.dontPropagate = true;
      if (showClicksRef.current) props.mapClickCallback(lngLat, name, icao_code);
    }
  };

  const routeMarkerClick = event => {
    if (showRouteMarkerClickRef.current) {
      event.originalEvent.dontPropagate = true;
      const lngLat = { lng: event.features[0].geometry.coordinates[0], lat: event.features[0].geometry.coordinates[1] };
      const { uid, type, display_name } = event.features[0].properties;
      clickedMarkerUid.current = uid;
      displayNameRef.current = display_name;
      const isDrawingEnabled = !isDrawingEnabledRef.current;
      if (isDrawingEnabled && (type === "Destination" || type === "Alternate")) return;
      setIsDrawingEnabled(isDrawingEnabled);
      if (isDrawingEnabled) {
        const point = { type: 'Point', coordinates: [lngLat.lng, lngLat.lat] };
        const lineStringData = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [[lngLat.lng, lngLat.lat]],
          },
        };
        const startingPointFeature = mapboxDraw.add(lineStringData);
        setStartPoint({ startPointInfo: { startPointUid: uid, startPointType: type }, drawStartPoint: { featureId: startingPointFeature[0], from: point } });
        const markerTooltip = <MapPopup content={markerPopUpContentRef.current} onclick={cancelClick} buttonText="cancel" variant="danger"/>;
        const markerPopupNode = document.createElement('div');
        createRoot(markerPopupNode).render(markerTooltip);
        edgePopup.setLngLat([lngLat.lng, lngLat.lat])
          .setDOMContent(markerPopupNode)
          .addTo(mapboxMap);

        dispatch(setIsdrawingEnabled(true));
      } else {
        setEndPoint({ endPointUid: uid, endPointType: type, deleteOtherPaths: event.originalEvent.shiftKey });
        dispatch(setIsdrawingEnabled(false));
      }
    }
  };

  const flightPathOutlineClick = event => {
    // check if click is on marker, prioritize marker click
    const routeMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['routemarkers-symbol'] });
    const highlightRouteMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['highlight-routemarkers-symbol'] });
    if (routeMarkers.length || highlightRouteMarkers.length || isDrawingEnabledRef.current) return;
    event.originalEvent.dontPropagate = true;
    if (showRouteMarkerClickRef.current) {
      const fromVertex = JSON.parse(event.features[0].properties.fromVertex);
      const toVertex = JSON.parse(event.features[0].properties.toVertex);
      const popupLabel = `Delete Connection from ${fromVertex.display_name} to ${toVertex.display_name}`;
      const coordinates = event.lngLat;
      const handleButtonClick = () => {
        const deleteEdgeInfo = {
          uid: fromVertex.uid,
          type: fromVertex.type,
          deleteUid: toVertex.uid
        };
        setDeleteEdge(deleteEdgeInfo);
      };
      const edgeTooltip = <MapPopup content={popupLabel} onclick={handleButtonClick} buttonText="Remove" variant="danger"/>;
      const edgePopupNode = document.createElement('div');
      createRoot(edgePopupNode).render(edgeTooltip);
      edgePopup.setLngLat(coordinates)
        .setDOMContent(edgePopupNode)
        .addTo(mapboxMap);
    }
  };

  const mapClick = event => {
    if (event.originalEvent.dontPropagate) { return; }
    setClickMarker(event.lngLat);
    if (showClicksRef.current) props.mapClickCallback(event.lngLat);
  };

  const sitePointer = event => {
    const sites = mapboxMap.queryRenderedFeatures(event.point, { layers: ['sites-symbol'] });
    if (sites.length) {
      mapboxMap.getCanvas().style.cursor = 'pointer';
      return;
    }
    mapboxMap.getCanvas().style.cursor = '';
  };

  const airportPointer = event => {
    const navaids = mapboxMap.queryRenderedFeatures(event.point, { layers: ['navaids-symbol'] });
    const routeMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['routemarkers-symbol'] });
    const highlightRouteMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['highlight-routemarkers-symbol'] });
    if (!showClicksRef.current || navaids.length || routeMarkers.length || highlightRouteMarkers.length) return;
    const airports = mapboxMap.queryRenderedFeatures(event.point, { layers: ['airports-symbol'] });
    if (airports.length) {
      mapboxMap.getCanvas().style.cursor = 'pointer';
      return;
    }
    mapboxMap.getCanvas().style.cursor = '';
  };

  const navaidPointer = event => {
    const routeMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['routemarkers-symbol'] });
    const highlightRouteMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['highlight-routemarkers-symbol'] });
    if (!showClicksRef.current || routeMarkers.length || highlightRouteMarkers.length) return;
    if (!showClicksRef.current) return;
    const navaids = mapboxMap.queryRenderedFeatures(event.point, { layers: ['navaids-symbol'] });
    if (navaids.length && addingWaypointRef.current) {
      mapboxMap.getCanvas().style.cursor = 'pointer';
      return;
    }
    mapboxMap.getCanvas().style.cursor = '';
  };

  const cancelClick = () => {
    dispatch(setIsdrawingEnabled(false));
    displayNameRef.current = "";
    clickedMarkerUid.current = "";
    edgePopup.remove();
  };

  const routeMarkerPointer = event => {
    if (!showRouteMarkerClickRef.current) return;
    const routeMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['routemarkers-symbol'] });
    const highlightRouteMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['highlight-routemarkers-symbol'] });
    if (routeMarkers.length || highlightRouteMarkers.length) {
      const hoverUid = routeMarkers[0].id;
      if (hoverUid === clickedMarkerUid.current) return;
      mapboxMap.getCanvas().style.cursor = isDrawingEnabledRef.current ? event.originalEvent.shiftKey ? 'copy' : 'default' : 'pointer';
      if (highlightRouteMarkers.length > 0) return;
      const coordinates = { lng: routeMarkers[0].geometry.coordinates[0], lat: routeMarkers[0].geometry.coordinates[1] };
      const { type } = routeMarkers[0].properties;
      const displayPopup = isDrawingEnabledRef.current || type === "Departure" || type === "Waypoint";
      let markerTooltip = "";
      if (!markerPopup.isOpen() && displayPopup) {
        if (!isDrawingEnabledRef.current) {
          markerTooltip = <MapPopup content={markerPopUpContentRef.current} />;
        } else {
          markerTooltip = <MapPopup content={`Click to connect ${displayNameRef.current} to here, shift-click to add connection`}/>;
        }
        const markerPopupNode = document.createElement('div');
        createRoot(markerPopupNode).render(markerTooltip);
        markerPopup.setLngLat(coordinates)
          .setDOMContent(markerPopupNode)
          .addTo(mapboxMap);
      }
      const geojson = {
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: routeMarkers[0].geometry.coordinates
          },
          properties: routeMarkers[0].properties
        }]
      };
      mapboxMap?.getSource('highlightroutemarkers').setData(geojson);
      return;
    }
    mapboxMap.getCanvas().style.cursor = '';
    markerPopup.remove();
    mapboxMap?.getSource('highlightroutemarkers')?.setData({
      type: 'FeatureCollection',
      features: []
    });
  };

  const flightPathOutlinePointer = event => {
    if (!showRouteMarkerClickRef.current || isDrawingEnabledRef.current) return;
    const routeMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['routemarkers-symbol'] });
    const highlightRouteMarkers = mapboxMap.queryRenderedFeatures(event.point, { layers: ['highlight-routemarkers-symbol'] });
    if (routeMarkers.length || highlightRouteMarkers.length) return;
    const flightPathOutlineLine = mapboxMap.queryRenderedFeatures(event.point, { layers: ['flightpathoutline-line'] });
    const flightPathOutlineDirection = mapboxMap.queryRenderedFeatures(event.point, { layers: ['flightpathoutline-direction'] });
    const highlightFlightOutlineLine = mapboxMap.queryRenderedFeatures(event.point, { layers: ['highlight-flightpathoutline-line'] });
    const highlightFlightOutlineDirection = mapboxMap.queryRenderedFeatures(event.point, { layers: ['highlight-flightpathoutline-direction'] });
    if (flightPathOutlineLine.length || flightPathOutlineDirection?.length || highlightFlightOutlineLine.length || highlightFlightOutlineDirection.length) {
      mapboxMap.getCanvas().style.cursor = 'pointer';
      if (highlightFlightOutlineLine.length || highlightFlightOutlineDirection.length) return;
      const getHighlightedCoordinates = [...flightPathOutlineLine, ...flightPathOutlineDirection];
      const fromVertex = JSON.parse(getHighlightedCoordinates[0].properties.fromVertex);
      const toVertex = JSON.parse(getHighlightedCoordinates[0].properties.toVertex);
      const isLong = Math.abs(toVertex.coords[0] - fromVertex.coords[0]) > 1 || Math.abs(toVertex.coords[1] - fromVertex.coords[1]) > 1;
      const geodesic = new arc.GreatCircle(
        { x: fromVertex.coords[0], y: fromVertex.coords[1] },
        { x: toVertex.coords[0], y: toVertex.coords[1] }, {}
      ).Arc(isLong ? 200 : 20).json();
      const geojson = {
        type: 'FeatureCollection',
        features: []
      };
      geojson.features.push(geodesic);
      mapboxMap?.getSource('flightpathoutlinehighlight').setData(geojson);
      return;
    }
    mapboxMap.getCanvas().style.cursor = '';
    mapboxMap?.getSource('flightpathoutlinehighlight')?.setData({
      type: 'FeatureCollection',
      features: []
    });
  };

  useEffect(() => {
    if (mapboxDraw && isDrawingEnabled) {
      setEndPoint(null);
      mapboxDraw.changeMode('draw_line_string', startPoint.drawStartPoint);
    } else if (mapboxDraw && !isDrawingEnabled) {
      mapboxDraw.changeMode('simple_select');
    }
  }, [mapboxDraw, isDrawingEnabled]);

  useEffect(() => {
    if (startPoint && endPoint) {
      const { startPointUid, startPointType } = startPoint.startPointInfo;
      const { endPointUid, endPointType, deleteOtherPaths } = endPoint;
      if (startPointUid > -1 && endPointUid > -1 && startPointUid !== endPointUid && (startPointType === "Departure" || startPointType === "Waypoint") && endPointType !== "Departure") {
        if (startPointType === "Departure") {
          !deleteOtherPaths && flightOrigin?.departure?.next ? flightOrigin.departure.next = [endPointUid] : flightOrigin?.departure?.next?.push(endPointUid);
          window.flightPlanMapInteration.saveDeparture({ ...flightOrigin?.departure });
        } else if (startPointType === "Waypoint") {
          flightOrigin?.waypoints?.forEach(wp => {
            if (wp.uid === startPointUid) {
              !deleteOtherPaths && wp?.next ? wp.next = [endPointUid] : wp?.next?.push(endPointUid);
              window.flightPlanMapInteration.saveWaypoints([...flightOrigin?.waypoints]);
            }
          });
        }
      };
      setStartPoint(null);
      setEndPoint(null);
      setMarkerPopUpContent(MAP_MARKER_POPUP_TEXT);
    }
  }, [startPoint, endPoint]);

  useEffect(() => {
    if (deleteEdge) {
      const { uid, type, deleteUid } = deleteEdge;
      if (type === "Departure") {
        const deleteEdgeIndex = flightOrigin?.departure?.next?.indexOf(deleteUid);
        if (deleteEdgeIndex > -1) {
          flightOrigin?.departure?.next?.splice(deleteEdgeIndex, 1);
          window.flightPlanMapInteration.saveDeparture({ ...flightOrigin?.departure });
        }
      } else if (type === "Waypoint") {
        flightOrigin?.waypoints?.forEach(wp => {
          const deleteEdgeIndex = wp?.next?.indexOf(deleteUid);
          if (wp.uid === uid && deleteEdgeIndex > -1) {
            wp?.next?.splice(deleteEdgeIndex, 1);
          }
        });
        window.flightPlanMapInteration.saveWaypoints([...flightOrigin?.waypoints]);
      }
      setDeleteEdge(null);
      edgePopup.remove();
    }
  }, [deleteEdge]);

  // Aircraft marker moves when clicked on subway map.
  const setAircraftMarkers = (highlightedFlightVertexUuid, event) => {
    const geojson = {
      type: 'FeatureCollection',
      features: []
    };

    if (!flightPath) {
      mapboxMap?.getSource('aircraftmarkers')?.setData(geojson);
      return;
    }

    event?.x && event?.y && setSubwayAircraftMarkerCoods({ screenX: event?.x - 6, screenY: event?.y - 20 });
    const highlightedVertexFeature = _.find(flightPath?.points_geojson?.features, { properties: { uuid: highlightedFlightVertexUuid } });

    if (highlightedVertexFeature) {
      // Show informative tooltip
      const reservation = reservations ? reservations[highlightedFlightIndex || 0] : null;
      const covering_sites = [];
      if (reservation) {
        reservation.legs.forEach((leg, i) => {
          if (leg.flight_vertex_uuids.includes(highlightedFlightVertexUuid) && leg.site_name) {
            covering_sites.push(`Leg ${i + 1}: ${leg.site_callsign}`);
          }
        });
      }

      const altitude_ft = Math.round(metersToFeet(highlightedVertexFeature?.properties?.altitude_m));
      console.log(altitude_ft);
      const newAltitudeSliderValue = altitudeFtToAltitudeSliderValue(altitude_ft - 100);
      console.log(newAltitudeSliderValue);
      setAltitudeSliderValue(newAltitudeSliderValue);
      // showCoverageAtAltitude(Math.floor(highlightedVertexFeature?.properties?.altitude_m));

      let rss_dbm = null;
      if (highlightedFlightVertexUuid in templateSignals) {
        const records = templateSignals[highlightedFlightVertexUuid];
        const rss_dbms = records.map(record => round(record.rss_dbm, 1));
        if (rss_dbms.length > 0) {
          rss_dbm = rss_dbms.join(", ");
        }
      }

      const height_agl_ft = Math.round(metersToFeet(highlightedVertexFeature?.properties.height_agl_m));
      // flight path does not have start time and end time, have to change this based on elapsed time
      let timeString;
      if (flightDetails?.estimatedTimeDepartureUTC) {
        // we have an absolute departure time
        const dates = highlightedVertexFeature?.properties.elapsed_times_s.map(
          elapsed_time_s => new Date(flightDetails.estimatedTimeDepartureUTC + elapsed_time_s * 1000)
        );

        timeString = dates.join();
      } else {
        // we have relative elapsed time
        timeString = highlightedVertexFeature?.properties.elapsed_times_s.join() + "s EET";
      }

      const labelText = altitude_ft + " ft AMSL (GPS)\n" +
                        height_agl_ft + " ft AGL\n" +
                        (covering_sites.length > 0 ? covering_sites.join() + "\n" : "Outside radio coverage\n") +
                        "@ " + timeString + "\n" +
                        (rss_dbm ? "RSS: " + rss_dbm + "dBm" : "");

      // Move pin on map to highlighted vertex
      const markerFeature = {
        type: "Feature",
        properties: {
          display_name: labelText,
          is_in_flight: true
        },
        geometry: {
          type: "Point",
          coordinates: highlightedVertexFeature.geometry.coordinates
        }
      };
      geojson.features.push(markerFeature);
    }
    mapboxMap?.getSource('aircraftmarkers')?.setData(geojson);
  };

  useEffect(() => {
    setAircraftMarkers(null);
  }, [flightPath]);

  useEffect(() => {
    setShowClick(isRoutePlanningPanelVisible && (editingDeparture || editingDestination || addingWaypoint || addingAlternate));
    setRouteMarkerClick(isRoutePlanningPanelVisible && !(editingDeparture || editingDestination || addingWaypoint || addingAlternate) && !disableRoute);
  }, [editingDeparture, editingDestination, addingWaypoint, addingAlternate, isRoutePlanningPanelVisible]);

  useEffect(() => {
    if (isMapConfigured && mapboxDraw) {
      mapboxMap.on('click', 'airports-symbol', airportClick);
      mapboxMap.on('click', 'navaids-symbol', navaidClick);
      mapboxMap.on('click', mapClick);
      mapboxMap.on('click', 'highlight-routemarkers-symbol', routeMarkerClick);
      mapboxMap.on('click', 'flightpathoutline-line', flightPathOutlineClick);
      mapboxMap.on('click', 'flightpathoutline-direction', flightPathOutlineClick);
    }
    if (isMapConfigured) {
      mapboxMap?.on('mousemove', sitePointer);
      mapboxMap?.on('mousemove', navaidPointer);
      mapboxMap?.on('mousemove', airportPointer);
      mapboxMap?.on('mousemove', routeMarkerPointer);
      mapboxMap?.on('mousemove', flightPathOutlinePointer);
    }
  }, [isMapConfigured, mapboxDraw]);

  useEffect(() => {
    if (!showClicks) {
      removeClickMarker();
    }
  }, [showClicks]);

  const handleDrawModeChange = event => {
    const newMode = event.mode;
    if (newMode === 'simple_select') {
      setStartPoint(null);
      setIsDrawingEnabled(false);
      setEndPoint(null);
      setMarkerPopUpContent(MAP_MARKER_POPUP_TEXT);
      dispatch(setIsdrawingEnabled(false));
    }
  };

  const configureMapboxMap = () => {
    const map = mapboxMap;
    if (!map || isMapConfigured) {
      return;
    }

    /*
    Index of sources: layers we will add to mapbox
      airports: airports-symbol
      sites: sites-symbol
      flightpathoutline: flightpathoutline-line
      flightpathdisplay: flightpathdisplay-line
      clickmarker: clickmarker-symbol
      routemarkers: routemarkers-symbol
      aircraftmarkers: aircraftmarkers-symbol
    */

    const mapboxDraw = new MapboxDraw({
      modes: Object.assign({
        direct_select: DirectSelect,
        draw_line_string: CustomDrawLineString
      }, MapboxDraw.modes),
      displayControlsDefault: false,
      styles: [MAPBOX_DRAW_LINE_STYLE]
    });

    const markerPopup = new mapboxgl.Popup({ closeButton: false, offset: [0, -10] });
    const edgePopup = new mapboxgl.Popup();

    map.addControl(new mapboxgl.ScaleControl());
    map.addControl(mapboxDraw);
    setMapboxDraw(mapboxDraw);
    setMarkerPopup(markerPopup);
    setEdgePopup(edgePopup);
    mapboxMap.on('draw.modechange', handleDrawModeChange);

    // ************ NAVAIDS *************
    map.addSource('navaid', {
      type: 'vector',
      tiles: [FLIGHT_PLANNING_API_URL_V2 + '/lookup/navaid/tile/{z}/{x}/{y}.mvt'],
      minzoom: 7
    });
    map.addLayer(NAVAID_LAYER);

    // ************ AIRPORTS *************
    map.addSource('airports', {
      type: 'vector',
      tiles: [FLIGHT_PLANNING_API_URL_V2 + '/lookup/airport/tile/{z}/{x}/{y}.mvt'],
      minzoom: 5
    });

    map.addLayer(AIRPORT_LAYER);

    map.addSource('sites', GEOJSON_INITIAL_OBJECT);

    map.addLayer(SITES_LAYER);
    map.addSource('clickmarker', GEOJSON_INITIAL_OBJECT);
    map.addLayer(CLICK_MARKER_SYMBOLS);
    map.addSource('highlightroutemarkers', GEOJSON_INITIAL_OBJECT);
    map.addLayer(HIGHTLIGHT_ROUTE_MARKER_SYMBOLS);
    map.addSource('routemarkers', GEOJSON_INITIAL_OBJECT);
    map.addLayer(ROUTE_MARKER_SYMBOLS, 'highlight-routemarkers-symbol');
    map.addSource('flightpathoutlinehighlight', GEOJSON_INITIAL_OBJECT);
    map.addLayer(FLIGHT_PATH_HIGHLIGHT_LINE, 'routemarkers-symbol', 'highlight-routemarkers-symbol');
    map.addLayer(FLIGHT_PATH_HIGHLIGHT_DIRECTION, 'routemarkers-symbol', 'highlight-routemarkers-symbol');
    map.addSource('flightpathoutline', GEOJSON_INITIAL_OBJECT);
    map.addLayer(FLIGHT_PATH_OUTLINE_LINE, 'highlight-flightpathoutline-line', 'highlight-flightpathoutline-direction', 'routemarkers-symbol', 'highlight-routemarkers-symbol');
    map.addLayer(FLIGHT_PATH_OUTLINE_DIRECTION, 'highlight-flightpathoutline-line', 'highlight-flightpathoutline-direction', 'routemarkers-symbol', 'highlight-routemarkers-symbol');
    map.addSource('handovers', GEOJSON_INITIAL_OBJECT);
    map.addLayer(HANDOVER_LINE, 'airports-symbol');
    map.addSource('flightpathdisplay', GEOJSON_INITIAL_OBJECT);
    map.addLayer(FLIGHT_PATH_DISPLAY_LINES, 'routemarkers-symbol', 'highlight-routemarkers-symbol');
    map.addLayer(FLIGHT_PATH_DISPLAY_DIRECTIONS, 'flightpathdisplay-line');
    map.addSource('aircraftmarkers', GEOJSON_INITIAL_OBJECT);
    map.addLayer(AIRCRAFT_MARKERS);

    map.debouncedSetSourceTiles = _.debounce((sourceId, urls) => map.getSource(sourceId).setTiles(urls), 2000, { leading: true, trailing: true });

    setMapConfigured(true);
  };

  // this useEffect is triggered only once during the loading of component.
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: STYLE,
      bounds: [
        [-125.073, 24.2535], // Contiguous US
        [-66.8854, 49.3845]
      ],
      logoPosition: LOGO_POSITION,
      boxZoom: false,
      transformRequest: (url, resourceType) => {
        if (resourceType === 'Tile' && url.startsWith(FLIGHT_PLANNING_API_URL_V2)) {
          return {
            url,
            headers: Session.requestConfig().headers
          };
        }
      }
    });

    setAltitudeSliderValue(70);

    // add a useful method to a mapbox primitive
    mapboxgl.LngLatBounds.prototype.extendOrPopulate = function (lngLat) {
      if (this.isEmpty()) {
        this.setNorthEast(lngLat);
        this.setSouthWest(lngLat);
      } else {
        this.extend(lngLat);
      }
    };
    setMapboxMap(map);
    setMapLoaded(true);
    return () => {
      cancelToken.cancel("Component unmounting");
    };
  }, []);

  useEffect(() => {
    if (isMapLoaded && !isMapConfigured) {
      mapboxMap.on('load', configureMapboxMap);
    }
  }, [isMapLoaded, isMapConfigured]);

  const removeFlightPathOutline = () => {
    if (isMapConfigured && mapboxMap?.getSource('flightpathoutline')) {
      mapboxMap?.getSource('flightpathoutline').setData({
        type: 'FeatureCollection',
        features: []
      });
    }
  };

  // this method create a flight plan or route plan based on flightOrigin or flightPlan object
  const addFlightPathOutline = (displayAllFlightOutlines = false, routes = null, features = [], idx = 0) => {
    if (isRoutePlanningPanelVisible && reservations) return;
    const routePlan = displayAllFlightOutlines && routes ? routes : flightOrigin;
    const graphEdges = calculateGraphEdges(routePlan?.departure, routePlan?.waypoints || [], routePlan?.alternates || []);
    if (!graphEdges) { return; }

    const coordinates = [];
    if (routePlan?.departure?.latitude) {
      coordinates.push({
        coords: [routePlan?.departure.longitude, routePlan?.departure.latitude],
        display_name: routePlan?.departure.name || 'Departure',
        uid: routePlan?.departure?.uid || 0,
        type: 'Departure'
      });
    }

    if (routePlan?.destination?.latitude) {
      coordinates.push({
        coords: [routePlan?.destination.longitude, routePlan?.destination.latitude],
        display_name: routePlan?.destination.name || 'Destination',
        uid: routePlan?.destination?.uid || 1,
        type: 'Destination'
      });
    }

    routePlan?.alternates?.forEach(alternate => {
      coordinates.push({
        coords: [alternate.longitude, alternate.latitude],
        display_name: alternate.name || (alternate.uid ? displayUid(alternate.uid) : `Alternate`),
        uid: alternate.uid,
        type: 'Alternate'
      });
    });

    routePlan?.waypoints?.forEach(waypoint => {
      coordinates.push({
        coords: [waypoint.lng, waypoint.lat],
        display_name: waypoint.icao_description || waypoint.name || (waypoint.uid ? displayUid(waypoint.uid) : `Waypoint`),
        uid: waypoint.uid,
        type: 'Waypoint'
      });
    });

    if (coordinates.length >= 2) {
      const geojson = {
        type: "FeatureCollection",
        features: []
      };

      // Mapbox doesn't natively plot geodesics, so interpolate
      for (let i = 0; i < graphEdges.length; ++i) {
        if (graphEdges[i][0] >= coordinates.length || graphEdges[i][1] >= coordinates.length) { continue; }

        const fromVertex = coordinates[graphEdges[i][0]];
        const toVertex = coordinates[graphEdges[i][1]];

        // Crude attempt at scaling point resolution according to physical distance
        const isLong = Math.abs(toVertex.coords[0] - fromVertex.coords[0]) > 1 || Math.abs(toVertex.coords[1] - fromVertex.coords[1]) > 1;

        const geodesic = new arc.GreatCircle(
          { x: fromVertex.coords[0], y: fromVertex.coords[1] },
          { x: toVertex.coords[0], y: toVertex.coords[1] }, {}
        ).Arc(isLong ? 200 : 20).json();
        geodesic.properties.fromVertex = fromVertex;
        geodesic.properties.toVertex = toVertex;
        displayAllFlightOutlines ? features.push(geodesic) : geojson.features.push(geodesic);
      }
      if (idx === highlightedRoute && mapboxMap?.getSource('flightpathoutlinehighlight')) {
        geojson.features = features;
        mapboxMap?.getSource('flightpathoutlinehighlight').setData(geojson);
      } else if (isMapConfigured && mapboxMap?.getSource('flightpathoutline')) {
        if (displayAllFlightOutlines && idx === flightPlans.length - 1) {
          geojson.features = features;
        }
        mapboxMap?.getSource('flightpathoutline').setData(geojson);
      }
    }
  };

  // this method is used by fit bounds
  const extendAndPopulateLatLng = (flightPlan, lngLatBounds) => {
    if (flightPlan?.departure?.latitude) { lngLatBounds.extendOrPopulate([flightPlan?.departure.longitude, flightPlan?.departure.latitude]); }

    if (flightPlan?.waypoints) {
      for (const i in flightPlan?.waypoints) { lngLatBounds.extendOrPopulate([flightPlan?.waypoints[i].lng, flightPlan?.waypoints[i].lat]); }
    }

    if (flightPlan?.alternates) {
      for (const i in flightPlan?.alternates) { lngLatBounds.extendOrPopulate([flightPlan?.alternates[i]?.longitude, flightPlan?.alternates[i]?.latitude]); }
    }

    if (flightPlan?.destination?.latitude) { lngLatBounds.extendOrPopulate([flightPlan?.destination.longitude, flightPlan?.destination.latitude]); }
  };

  // fit bounds use to zoom in and zoom out depends on flightPlan object and panel currently open.
  const fitBounds = (bounds = null, showFullMap = false) => {
    if (!isMapConfigured) return;
    if (bounds) {
      const lngLatBounds = new mapboxgl.LngLatBounds(bounds);
      mapboxMap.fitBounds(lngLatBounds, {
        padding: {
          top: 100,
          right: 150,
          bottom: 100,
          left: 600
        }
      });
      return;
    }

    const lngLatBounds = new mapboxgl.LngLatBounds();

    extendAndPopulateLatLng(flightOrigin, lngLatBounds);

    if (flightPlans) {
      if (highlightedFlightIndex !== null && isFlightListPanelVisible) {
        extendAndPopulateLatLng(flightPlans[highlightedFlightIndex], lngLatBounds);
      } else if (highlightedRouteIndex !== null && isRouteListPanelVisible) {
        extendAndPopulateLatLng(flightPlans[highlightedRouteIndex], lngLatBounds);
      } else {
        flightPlans.forEach((fp, i) => {
          extendAndPopulateLatLng(fp, lngLatBounds);
        });
      }
    };

    if (lngLatBounds.isEmpty() || showFullMap) {
      // fit contiguous 48 states
      lngLatBounds.extendOrPopulate([-125.073, 24.2535]);
      lngLatBounds.extendOrPopulate([-66.8854, 49.3845]);
    }
    if (!isRoutePlanningPanelVisible || flightOrigin?.departure?.longitude || flightOrigin?.waypoints?.length > 0 || flightOrigin?.destination?.longitude || flightOrigin?.alternates?.length > 0) {
      mapboxMap.fitBounds(lngLatBounds,
        {
          maxZoom: 12,
          padding: {
            top: 100,
            right: 150,
            bottom: 100,
            left: 600
          },
        });
    }
  };

  // remove flight path display
  const removeFlightPathDisplay = () => {
    if (!isMapConfigured) return;
    if (mapboxMap.getSource('flightpathdisplay')) {
      mapboxMap.getSource('flightpathdisplay').setData({
        type: 'FeatureCollection',
        features: []
      });
    }
  };

  // Add flight path display with vertex and points. flight_path object is needed for this function
  const addFlightPathDisplay = () => {
    if (!isMapConfigured || !mapboxMap.getSource('flightpathdisplay')) {
      return;
    }

    const flightPathGeojson = {
      type: "FeatureCollection",
      features: []
    };

    const handoversGeojson = {
      type: "FeatureCollection",
      features: []
    };

    if (!flightPath) { return; }

    flightPathGeojson.features = flightPathGeojson.features.concat(flightPath.points_geojson.features);

    const vertices_in_coverage = {};

    const reservation = reservations[0] || null;
    if (reservation) {
      reservation?.legs?.forEach((leg, i) => {
        const hasCoverage = leg.site_uuid != null && leg.site_uuid !== "";
        for (const j of leg.flight_vertex_uuids) {
          vertices_in_coverage[j] = hasCoverage;
        }
      });

      reservation?.handovers?.forEach((handover, i) => {
        handoversGeojson.features.push({ type: "feature", geometry: handover.bounds });
      });
    } else {
      for (const f of flightPath.points_geojson.features) {
        vertices_in_coverage[f.properties.uuid] = false;
      }
    }

    const lines = flightPath.lines_geojson.features;
    for (const line of lines) {
      const startInCoverage = vertices_in_coverage[line.properties.vertex_uuids[0]];
      const endInCoverage = vertices_in_coverage[line.properties.vertex_uuids[1]];

      if (startInCoverage === endInCoverage) {
        line.properties.has_coverage = startInCoverage && endInCoverage;
        line.properties.is_dummy = reservation == null;

        flightPathGeojson.features.push(line);
      } else {
        // has_coverage flips midway along the line; split into two lines
        const startCoords = line.geometry.coordinates[0];
        const endCoords = line.geometry.coordinates[1];
        const midpointCoords = [(endCoords[0] + startCoords[0]) / 2, (endCoords[1] + startCoords[1]) / 2];

        const features = [
          {
            type: "Feature",
            properties: {
              ...line.properties,
              has_coverage: startInCoverage,
              is_dummy: reservation == null
            },
            geometry: {
              type: "LineString",
              coordinates: [startCoords, midpointCoords]
            }
          },
          {
            type: "Feature",
            properties: {
              ...line.properties,
              has_coverage: endInCoverage,
              is_dummy: reservation == null
            },
            geometry: {
              type: "LineString",
              coordinates: [midpointCoords, endCoords]
            }
          },
        ];
        flightPathGeojson.features.push(...features);
      }
    }

    mapboxMap.getSource('flightpathdisplay').setData(flightPathGeojson);
    mapboxMap.getSource('handovers').setData(handoversGeojson);
    removeFlightPathOutline();
  };

  // setting up flight plan for different panels
  useEffect(() => {
    const features = [];
    const flightPlan = flightPlans.length > 0 ? flightPlans : [flightOrigin];
    flightPlan.map((fp, idx) => addFlightPathOutline(true, fp, features, idx));
    if (flightPlans?.length > 0) {
      fitBounds();
    }
    if (flightPlan?.length === 0 || !flightPlan[0]?.departure?.longitude) {
      removeFlightPathOutline();
    }
    removeFlightPathUncertaintyDisplay();
    if (isFlightPlanPanelVisible || isFlightDetailsPanelVisible || (isRoutePlanningPanelVisible && (reservations || disableRoute))) {
      addFlightPathsUncertaintyDisplay();
    }
    if (!reservations) {
      removeFlightPathDisplay();
    }
  }, [flightPlans, flightOrigin, reservations]);

  useEffect(() => {
    if (highlightedRoute !== null && highlightedRoute >= 0 && highlightedRoute < flightPlans.length) {
      const fp = flightPlans[highlightedRoute];
      addFlightPathOutline(true, fp, [], highlightedRoute);
    } else {
      mapboxMap?.getSource('flightpathoutlinehighlight')?.setData({
        type: 'FeatureCollection',
        features: []
      });
    };
  }, [highlightedRoute]);

  // setting up route markers and flight plan outline for flightplan component
  useEffect(() => {
    const disconnectedNodesUid = Array.from(new Set(disconnectedNodes.map(obj => obj.uid)));
    const geojson = {
      type: 'FeatureCollection',
      features: []
    };
    const flightPlan = flightPlans.length > 0 ? flightPlans : [flightOrigin];
    flightPlan.forEach((fp, idx) => {
      if (fp?.departure) {
        geojson.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [fp?.departure.longitude, fp?.departure.latitude]
          },
          properties: {
            id: `${idx}-${fp?.departure?.uid || 0}`,
            is_terminus: true,
            is_alternate: false,
            display_name: fp?.departure.name || 'Departure',
            uid: fp?.departure?.uid || 0,
            type: 'Departure',
            reachable: disconnectedNodesUid.indexOf(fp?.departure?.uid) < 0
          },
          id: parseInt(`${idx}${fp?.departure?.uid || 0}`)
        });
      }

      if (fp?.destination) {
        geojson.features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [fp?.destination.longitude, fp?.destination.latitude]
          },
          properties: {
            id: `${idx}-${fp?.destination?.uid || 1}`,
            is_terminus: true,
            is_alternate: false,
            display_name: fp?.destination.name || 'Destination',
            uid: fp?.destination?.uid || 1,
            type: 'Destination',
            reachable: disconnectedNodesUid.indexOf(1) < 0
          },
          id: parseInt(`${idx}${fp?.destination?.uid || 1}`)
        });
      }

      if (fp?.alternates) {
        fp?.alternates.forEach((alternate, i) => {
          geojson.features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [alternate.longitude, alternate.latitude]
            },
            properties: {
              id: `${idx}-${i}-${alternate.uid}`,
              is_terminus: false,
              is_alternate: true,
              display_name: alternate.name || (alternate.uid ? displayUid(alternate.uid) : `Alternate`),
              uid: alternate.uid,
              type: 'Alternate',
              reachable: disconnectedNodesUid.indexOf(alternate?.uid) < 0
            },
            id: parseInt(`${idx}${i}${alternate.uid}`)
          });
        });
      }

      if (fp?.waypoints) {
        fp?.waypoints.forEach((waypoint, i) => {
          geojson.features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [waypoint.lng, waypoint.lat]
            },
            properties: {
              id: `${idx}-${i}-${waypoint.uid}`,
              is_terminus: false,
              is_alternate: false,
              display_name: waypoint.icao_description || waypoint.name || (waypoint.uid ? displayUid(waypoint.uid) : `Waypoint`),
              uid: waypoint.uid,
              type: 'Waypoint',
              reachable: disconnectedNodesUid.indexOf(waypoint?.uid) < 0
            },
            id: parseInt(`${idx}${i}${waypoint.uid}`)
          });
        });
      }
    });

    if (isMapConfigured && mapboxMap?.getSource('routemarkers')) {
      mapboxMap?.getSource('routemarkers').setData(geojson);
    }
    if (isRoutePlanningPanelVisible || isFlightPlanPanelVisible) {
      addFlightPathOutline();
      fitBounds();
    }
  }, [flightOrigin?.alternates, flightOrigin?.departure, flightOrigin?.destination, flightOrigin?.waypoints, flightPlans, disconnectedNodes]);

  const showCoverageAtAltitude = (altitude_m) => {
    console.log("showCoverageAtAltitude", altitude_m);
    if (!isMapConfigured) return;

    Object.keys(mapboxMap.getStyle().sources)
      .filter((sourceId) => sourceId.startsWith('coverageraster'))
      .forEach((sourceId) => {
        const siteUuid = sourceId.substring(15);
        mapboxMap.debouncedSetSourceTiles(sourceId, [`${FLIGHT_PLANNING_API_URL_V2}/sites/coverage/tile/${siteUuid}/${altitude_m}/{z}/{x}/{y}.png?metric=${map.siteCoverageMetric}`]);
      });
  };

  const altitudeSliderValueToAltitude = (v) => {
    let altitude_ft;

    if (v < 30) {
      altitude_ft = ((30.0 - v) / 30.0) * -150.0;
    } else if (v < 50) {
      altitude_ft = ((v - 30.0) / 20.0) * 100.0;
    } else if (v < 70) {
      altitude_ft = ((v - 50.0) / 20.0) * 900.0 + 100.0;
    } else if (v < 90) {
      altitude_ft = ((v - 70.0) / 20.0) * 9000.0 + 1000.0;
    } else {
      altitude_ft = ((v - 90.0) / 10.0) * 15000.0 + 10000.0;
    }

    const altitude_m = Math.floor(feetToMeters(altitude_ft));

    return [altitude_m, altitude_ft];
  };

  const altitudeFtToAltitudeSliderValue = (altitude_ft) => {
    if (altitude_ft >= 10000.0) {
      return (altitude_ft - 10000.0) / 15000.0 * 10.0 + 90.0;
    } else if (altitude_ft >= 1000.0) {
      return (altitude_ft - 1000.0) / 9000.0 * 20.0 + 70.0;
    } else if (altitude_ft >= 100.0) {
      return (altitude_ft - 100.0) / 900.0 * 20.0 + 50.0;
    } else if (altitude_ft >= 0.0) {
      return altitude_ft / 100.0 * 20.0 + 30.0;
    } else if (altitude_ft >= -150.0) {
      return 30.0 - altitude_ft / -150.0 * 30.0;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const altitude_m = altitudeSliderValueToAltitude(altitudeSliderValue)[0];
    showCoverageAtAltitude(altitude_m);
  }, [altitudeSliderValue]);

  useEffect(() => {
    if (!isMapConfigured) return;

    const layerPrefix = 'coverageraster';

    const currentlyVisibleCoverageSiteUuids = mapboxMap
      .getStyle()
      .layers.map((l) => l.id)
      .filter((id) => id.startsWith(layerPrefix))
      .map((layerId) => layerId.substring(15, 51));

    const reloadAll = map.siteCoverageMetric !== displayedSiteCoverageMetric;

    const sitesToHide = currentlyVisibleCoverageSiteUuids.filter((x) => !siteUuidsToShowCoverage.includes(x) || reloadAll);
    const sitesToShow = siteUuidsToShowCoverage.filter((x) => !currentlyVisibleCoverageSiteUuids.includes(x) || reloadAll);

    for (const siteUuid of sitesToHide) {
      mapboxMap.removeLayer(`${layerPrefix}-${siteUuid}-raster`);
      mapboxMap.removeSource(`${layerPrefix}-${siteUuid}`);
    }

    const altitude_m = altitudeSliderValueToAltitude(altitudeSliderValue)[0];

    for (const siteUuid of sitesToShow) {
      mapboxMap.addSource(`${layerPrefix}-${siteUuid}`, {
        type: 'raster',
        tiles: [`${FLIGHT_PLANNING_API_URL_V2}/sites/coverage/tile/${siteUuid}/${altitude_m}/{z}/{x}/{y}.png?metric=${map.siteCoverageMetric}`],
        tileSize: 128,
      });

      mapboxMap.addLayer({
        id: `${layerPrefix}-${siteUuid}-raster`,
        type: 'raster',
        source: `${layerPrefix}-${siteUuid}`,
        minzoom: 4,
        paint: {
          'raster-resampling': 'nearest',
        }
      }, 'sites-symbol');
    }

    if (reloadAll) {
      setDisplayedSiteCoverageMetric(map.siteCoverageMetric);
    }
  }, [siteUuidsToShowCoverage, map.siteCoverageMetric]);

  const removeFlightPathUncertaintyDisplay = () => {
    if (!isMapConfigured) return;
    flightPathLayers.forEach((tileSource) => {
      if (tileSource && mapboxMap.getSource(tileSource)) {
        try {
          mapboxMap.removeLayer(`flightpathuncertaintydisplay-fill-${tileSource}`);
          mapboxMap.removeLayer(`flightpathuncertaintydisplay-stroke-${tileSource}`);
          flightPathLayers.delete(tileSource);
        } catch (err) {
          // safeguard in case tile is not present on map.
        };
      }
    });
    setFlightPathLayers(new Set([]));
  };

  const addFlightPathsUncertaintyDisplay = () => {
    if (!isMapConfigured) {
      return;
    }
    const flightPlan = flightPlans.length > 0 && !disableRoute ? flightPlans : [flightOrigin];
    const flightPathUuids = new Set([]);
    flightPlan?.forEach((fp) => {
      const tileSource = fp.flightPathUuid;
      if (!flightPathUuids.has(tileSource)) {
        if (!mapboxMap.getSource(tileSource)) {
          mapboxMap.addSource(tileSource, {
            type: 'vector',
            tiles: [`${FLIGHT_PLANNING_API_URL_V2}/flightpaths/tile/${fp.flightPathUuid}/{z}/{x}/{y}.mvt`],
            minzoom: 5
          });
        }
        tileSource && mapboxMap.addLayer(FLIGHT_PATH_UNCERTAINTY_DISPLAY_FILL(tileSource), 'routemarkers-symbol');
        tileSource && mapboxMap.addLayer(FLIGHT_PATH_UNCERTAINTY_DISPLAY_STROKE(tileSource), 'routemarkers-symbol');
        flightPathUuids.add(tileSource);
      }
    });
    setFlightPathLayers(new Set([...flightPathUuids]));
    if (isRoutePlanningPanelVisible && reservations) {
      addFlightPathDisplay();
    } else {
      removeFlightPathDisplay();
    }
  };

  // show reservations
  useEffect(() => {
    if ((reservations && reservations.length > 0)) {
      fitBounds();
    }
  }, [reservations]);

  useEffect(() => {
    if (isMapConfigured) {
      mapboxMap.on('click', 'sites-symbol', event => {
        dispatch(toggleCoverageForSiteUuid(event.features[0].properties.uuid));
        event.originalEvent.dontPropagate = true;
      });
      fitBounds();
    }
  }, [isMapConfigured]);

  useEffect(() => {
    fitBounds();
  }, [highlightedFlightIndex, highlightedRouteIndex]);

  return (
    <>
      <div className={ "map-wrapper" + (showClicks ? " show-clicks" : "") }>
        <div ref={ mapContainer } className="map-container"/>
      </div>
      {siteUuidsToShowCoverage.length > 0 &&
      <div className="vertical-slider-container">
        { altitudeSliderValue >= -1 &&
          <Slider min={ 0 }
                  marks={{
                    10: "-100 ft",
                    30: "0 ft",
                    50: "100 ft",
                    70: "1000 ft",
                    90: "10000 ft",
                  }}
                  value={ altitudeSliderValue }
                  // step={ null }
                  handleRender={(renderProps) => {
                    return (
                      <div {...renderProps.props}>
                        <SliderTooltip>
                          <div style={{ backgroundColor: "white", width: "49px" }}>{round(altitudeSliderValueToAltitude(altitudeSliderValue)[1], 0)} ft</div>
                        </SliderTooltip>
                      </div>
                    );
                  }}
                  onChange={ setAltitudeSliderValue }
                  vertical={ true }/>

        }
        <p>Altitude (GPS)</p>
      </div>}
      {flightPath && reservations && isRoutePlanningPanelVisible && (
        <div className={"subway-map-controller-container"}>
          <div className={"subway-map-controller"}>
            <SubwayMap
              flightPath={flightPath}
              reservation={reservations ? reservations[0] : null}
              highlightFlightVertexUuidCallback={setAircraftMarkers}
              flightPathSubwayMap={true}
            />
          </div>
        </div>
      )}
      {flightPath && reservations && isRoutePlanningPanelVisible && subwayAircraftMarkerCoods.screenX && subwayAircraftMarkerCoods.screenY && (
      <AircraftMarker screenX={subwayAircraftMarkerCoods.screenX} screenY={subwayAircraftMarkerCoods.screenY} />
      )}
      {error && <div className="map-error-message">{error}</div>}
    </>
  );
};

export default MapVisualization;
