import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const FormHidden = ({ input, 'data-testid': dataTestId }) => (
  <Form.Control
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...input}
    type="hidden"
    data-testid={dataTestId}
  />
);

FormHidden.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  'data-testid': PropTypes.string,
};

FormHidden.defaultProps = {
  'data-testid': null,
};

export default FormHidden;
