import { createSlice } from "@reduxjs/toolkit";
import { SiteRecord } from "../records/site.record";

const initialState = new SiteRecord({});

const sites = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    isFetching: (sitesState) => {
      return sitesState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (sitesState, action) => {
      return sitesState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        sitesByUuid: action.payload.sitesByUuid,
        sites: action.payload.records
      });
    },

    isError: (sitesState) => {
      return sitesState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    setSiteDetails: (sitesState, action) => {
      return sitesState.merge({
        siteDetails: action.payload.siteDetails
      });
    },
    clear: (sitesState) => {
      return new SiteRecord({});
    }
  }
});

export default sites;
