import React from "react";
import { Redirect, Route } from "react-router-dom";

import Session from "../../utils/session";

const PrivateRoute = ({ component: MyComponent, ...others }) => {
  return (
    <Route
      {...others}
      render={(p) => (
        Session.isAuthenticated() ? <MyComponent {...p} /> : <Redirect to="/auth0-logout" />
      )}
    />
  );
};

export default PrivateRoute;
