import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "../../../../admin/store/records/general.record";

export const AirborneRadioRecord = new ImmutableRecord({
  uuid: '',
  organization: '',
  aircraftRadioModelUuid: '',
  metadata: new MetadataRecord({})
});

export const AirborneRadiosRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  airborneRadios: [new AirborneRadioRecord({})]
});
