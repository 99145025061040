import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import { displayNextNodes } from "../../subpages/user/requestform/util";

const TerminusDisplay = ({ terminus, onEdit, disabled, isDestination, alternates, waypoints }) => {
  const next = terminus?.next;
  const waypointsDisplay = useMemo(() => isDestination ? '' : displayNextNodes(next, terminus, alternates, waypoints), [next, terminus, alternates, waypoints, isDestination]);
  return (<>
    {terminus.longitude && (
      <>
        <div>
          ({terminus.latitude}&deg;, {terminus.longitude}&deg;)
        </div>
        {!isDestination && <div>&#8594; {waypointsDisplay}</div>}
      </>
    )}
    <div className={"text-end" + (terminus.longitude && !isDestination ? " btns-inline" : " btns-inline-alt-dest")}>
      <Button variant="outline-primary" size="sm" onClick={onEdit} disabled={disabled}>
        Edit <i className="fas fa-edit"/>
      </Button>
    </div>
  </>);
};

TerminusDisplay.propTypes = {
  terminus: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default TerminusDisplay;
