import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { white, black } from "../MapVisualization/Colors";
import { subwayStationPath } from "../MapVisualization/SvgMarkers";

import TerminusDisplay from "./TerminusDisplay";
import TerminusEntryForm from "./TerminusEntryForm";

import { round } from "../../utils/units";

const TerminusEntry = ({
  terminus,
  terminusName,
  latestMapClick,
  routes,
  editing,
  disabled,
  onSave,
  onToggleEditing,
  isDestination = false,
  waypoints = [],
  alternates = [],
  destination
}) => {
  useEffect(() => {
    // Only want hook to fire if latestMapClick changes, not if editing changes,
    // therefore only latestMapClick is in the list of dependencies
    if (editing && latestMapClick?.lngLat) {
      window.terminusEntryForm.selectLocation({
        name: latestMapClick.name || '',
        airport_code: latestMapClick.icao_code || '',
        longitude: round(latestMapClick.lngLat.lng, 6),
        latitude: round(latestMapClick.lngLat.lat, 6),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestMapClick]);

  const handleSave = ({ name, lng, lat, airport_code, next }) => {
    // Check if lat or lng fields have been edited
    const isLatEdited = terminus.latitude !== round(lat, 6);
    const isLngEdited = terminus.longitude !== round(lng, 6);

    let updatedAirportCode = airport_code;
    if (airport_code === terminus.airport_code && ((terminus.latitude && isLatEdited) || (terminus.longitude && isLngEdited))) {
      updatedAirportCode = '';
    }
    onSave({
      name,
      longitude: round(lng, 6),
      latitude: round(lat, 6),
      airport_code: updatedAirportCode,
      next: (next?.map(si => parseInt(si)) || [])
    });

    onToggleEditing(false);
  };

  return (
    <div className="waypoints-list-entry" id={`terminus-list-entry-${terminusName.toLowerCase()}`}>
      <svg viewBox="0 0 17 17">
        <path d={subwayStationPath} fill={white} stroke={black} strokeWidth={3}/>
      </svg>
      <h4>{terminusName}{terminus.airport_code ? `: ${terminus.airport_code} (${terminus.name})` : `: ${terminus.name || ''}`}</h4>
      {editing && (
        <TerminusEntryForm
          terminus={terminus}
          routes={routes}
          onSave={handleSave}
          onCancel={() => onToggleEditing(false)}
          isDestination={isDestination}
          destination={destination}
          waypoints={waypoints}
          alternates={alternates}
        />
      )}
      {!editing && (
        <TerminusDisplay
          terminus={terminus}
          disabled={disabled}
          onEdit={() => onToggleEditing(true)}
          isDestination={isDestination}
          destination={destination}
          alternates={alternates}
          waypoints={waypoints}
        />
      )}
    </div>
  );
};

TerminusEntry.propTypes = {
  terminus: PropTypes.object.isRequired,
  terminusName: PropTypes.string.isRequired,
  latestMapClick: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  disabled: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onToggleEditing: PropTypes.func.isRequired,
};

export default TerminusEntry;
