const validate = (values) => {
  const errors = {
    location: {
      coordinates: [undefined, undefined],
    }
  };

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.callsign) {
    errors.callsign = 'Required';
  }

  if (
    !values.location?.coordinates?.length ||
    (!values.location.coordinates[0] && values.location.coordinates[0] !== 0)
  ) {
    errors.location.coordinates[0] = 'Required';
  }

  if (
    !values.location?.coordinates?.length ||
    values.location.coordinates.length < 2 ||
    (!values.location.coordinates[1] && values.location.coordinates[1] !== 0)
  ) {
    errors.location.coordinates[1] = 'Required';
  }

  if (values.type === undefined || values.type === null) {
    errors.type = 'Required';
  }

  if (!values.antennaUuid) {
    errors.antennaUuid = 'Required';
  }

  if (!values.groundRadioUuid) {
    errors.groundRadioUuid = 'Required';
  }

  if (!values.maxEirpDbm) {
    errors.maxEirpDbm = 'Required';
  }

  if (!values.resourceGridUuid) {
    errors.resourceGridUuid = 'Required';
  }

  return errors;
};

export default validate;
