import { Record as ImmutableRecord } from "immutable";

export const PaginationRecord = new ImmutableRecord({
  firstUuid: null,
  lastUuid: null,
  page: 0,
  perPage: 10
});

export const FlightsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  flightBundles: [],
  flightPath: {},
  flightPlans: [],
  reservations: [],
  paginationDetails: new PaginationRecord({}),
  highlightedFlightIndex: null,
  successMessage: ''
});
