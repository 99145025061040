import axios from "axios";
import { AIRBORBNE_RADIO_ENDPOINT, FLIGHT_PLANNING_API_URL_V2 } from "../../../../../constants/api.endpoints";
import Session from "../../../../../utils/session";
import { flightsActions } from "../../reducers";
import { AirborneRadioRecord } from "../../records/inventory/airborne.radio.record";

export const getAirborneRadios = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.airborneRadios.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${AIRBORBNE_RADIO_ENDPOINT}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const airborneRadios = response?.data?.reduce((newRec, rec) => {
        const airborneRadioRecord = new AirborneRadioRecord({
          uuid: rec.uuid,
          organization: rec.organization,
          aircraftRadioModelUuid: rec.aircraft_radio_model_uuid
        });
        newRec.push(airborneRadioRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.airborneRadios.isFetched({ airborneRadios })
      );
    } else {
      dispatch(flightsActions.inventory.airborneRadios.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.airborneRadios.isError());
  }
};
