export const FLIGHT_PLANNING_API_URL_V2 = window._env_.REACT_APP_FLIGHT_PLANNING_API_URL_V2;
export const RESERVATION_LIFECYCLE_API_URL = window._env_.REACT_APP_RESERVATION_LIFECYCLE_API_URL_V2 || "http://localhost:15104/v2.0";
// IDENTITY API
export const IDENTITY_ENDPOINT = "/identity/";

// MONITORING API
export const MONITORING_ENDPOINT = "/monitoring/time/";

// USER ADMIN API
export const USERS_ENDPOINT = "/users/admin/";
export const USERS_LOGIN_ENDPOINT = "/users/admin/login/";
export const USERS_SUMMARY_ENDPOINT = "/users/admin/summary";
export const USERS_TOTP_ENDPOINT = "/users/admin/totp";
export const USERS_TOTP_SECRET_ENDPOINT = "/users/admin/totp_secret/";

// USERS DATA API
export const USERS_DATA_ENDPOINT = "/users/data/";

// RESOURCE BLOCK SET API
export const RESOURCE_BLOCK_SETS_ENDPOINT = "/resourceblocksets/";
export const RESOURCE_GRIDS_ENDPOINT = "/resourcegrids/";
export const RESOURCE_RESERVATION_ENDPOINT = "/resourceSchedules/reservation/";
export const GET_RESOURCE_SITE_ENDPOINT = "/resourceSchedules/site/";
export const RESOURCE_SCHEDULE_ENDPOINT = "/resourceSchedules/";

// COVERAGE BOUNDS API
export const COVERAGE_BOUNDS_ENDPOINT = "/coveragebounds/";

// SITE API
export const SITES_ENDPOINT = "/sites/";
export const SITES_COVERAGE_ENDPOINT = "/sites/coverage/";
export const SITES_RESERVATION_ENDPOINT = "/sites/reservations/";
export const SITES_TILES_ENDPOINT = "/sites/tile/"; // "/sites/tile/{z}/{x}/{y}.mvt"

// FLIGHT_CONTROLLER API
export const FLIGHTS_ENDPOINT = "/flights/";
export const FLIGHTS_CLEANUP = "/flights/cleanup";
export const ORPHANED_FLIGHT_CLEANUP = "/flights/discard";

// FLIGHT PATH API
export const FLIGHT_PATH_ENDPOINT = "/flightpaths/";

// FLIGHT PLAN API
export const FLIGHT_PLAN_ENDPOINT = "/flightplans/";

// ROUTE API
export const ROUTES_ENDPOINT = "/routes/";
export const LIFECYCLE_TEMPLATE_RESERVATION_ENDPOINT = "/lifecycle/template-reservation/";
export const TEMPLATE_RESERVATION_ENDPOINT = "/template-reservation/";
export const RESERVATION_ENDPOINT = "/lifecycle/reserve";
export const CANCEL_RESERVATION_ENDPOINT = "/lifecycle/cancel-reservation/";
export const DEACTIVATE_ROUTE = "/routes/deactivate/";

// AIRPORT API
export const AIRPORT_ENDPOINT = "/lookup/airport/";
export const AIRPORT_AUTOCOMPLETE_ENDPOINT = "/lookup/airport/autocomplete";
export const AIRPORT_TILE_ENDPOINT = "/lookup/airport/tile"; // "/lookup/airport/tile/{z}/{x}/{y}.mvt"

// NAVAID API
export const NAVAID_ENDPOINT = "/lookup/navaid";
export const NAVAID_AUTOCOMPLETE_ENDPOINT = "/lookup/navaid/autocomplete/";
export const NAVAID_PARSE_ENDPOINT = "/lookup/navaid/parse/";
export const NAVAID_TILE_ENDPOINT = "/lookup/navaid/tile/"; // "/lookup/navaid/tile/{z}/{x}/{y}.mvt"

// SESSION API
export const SESSION_INVALIDATE_ENDPOINT = "/session/invalidate/";
export const SESSION_KEEP_ALIVE_ENDPOINT = "/session/keep-alive/";

// COMPONENTS
export const SITE_COMPONENT = "sites";
export const COVERAGE_BOUNDS_COMPONENT = "coverageBounds";

// INVENTORY API
export const AIRCRAFT_ENDPOINT = "/inventory/aircraft/";
export const AIRCRAFT_MODEL_ENDPOINT = "/inventory/aircraft-model/";
export const AIRBORBNE_RADIO_ENDPOINT = "/inventory/airborne-radio/";
export const AIRBORNER_RADIO_MODEL_ENDPOINT = "/inventory/airborne-radio-model/";
export const ANTENNA_ENDPOINT = "/inventory/antenna/";
export const ANTENNA_MODEL_ENDPOINT = "/inventory/antenna-model/";
export const GROUND_ANTENNA_MODEL = "/inventory/antenna-model/ground-antenna-models";
export const GROUND_RADIO_ENDPOINT = "/inventory/ground-radio/";
export const GROUND_RADIO_MODEL_ENDPOINT = "/inventory/ground-radio-model/";

// SITE RESOURCE SCHEDULES API
export const SITE_RESOURCE_SCHEDULES_API = "/siteresourceschedules/";

// TEMPLATE SIGNALS API
export const TEMPLATE_SIGNALS_ENDPOINT = "/template-signal/";

// state machine API
export const FLIGHT_STATE_MACHINE_ENDPOINT = "/state-viz/";
export const STATE_MACHINE_VISUALIZER = "https://stately.ai/viz";
export const CURRENT_STATE_MACHINE_STATE = "/state-viz/current-state/";
