import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Router, Route } from "react-router-dom";
import Session from "./utils/session";
import NavbarPage from "./navbar";
import Logout from "./subpages/common/logout/Logout";
import Auth0Logout from "./subpages/common/logout/Auth0Logout";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import SessionExpiringModal from "./components/SessionExpiring/SessionExpiringModal";
import history from "./utils/history";
import { getIdentity } from "./subpages/admin/store/actions";
import MapView from "./subpages/user/MapView";
import LoginButton from "./subpages/common/login/LoginButton";

import './styles.css';

const flightPlanningApiUrl = window._env_.REACT_APP_FLIGHT_PLANNING_API_URL_V2;

const App = () => {
  const dispatch = useDispatch();

  const navbarPage = () => {
    return (
      <NavbarPage />);
  };

  const MapViewComp = () => {
    return (
      <div>
        {navbarPage()}
         <MapView />
      </div>
    );
  };

  const populateData = () => {
    // Prepopulate various data which the child pages will share
    if (Session.isAuthenticated()) {
      dispatch(getIdentity());
    };
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router history={history}>
        <Route exact path="/" component={LoginButton} />
        <PrivateRoute exact path="/auranetwork" component={MapViewComp} />
        <Route exact path="/auth0-logout" component={Auth0Logout} />
        <Route exact path="/logout" component={Logout} />
        <SessionExpiringModal apiUrl={flightPlanningApiUrl} />
      </Router>
    </>
  );
};

export default App;
