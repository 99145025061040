import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import auth from "../../../utils/auth";
import Session from "../../../utils/session";

const Auth0Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    Session.onLogout().finally(() => {
      logout({ returnTo: auth.LOGGED_OUT_URL, federated: true });
    });
  }, [logout]);

  return (
    <Container className={"mt-4"}>
      <Alert variant="primary text-center m-5">
        <Alert.Heading className="text-center">Please wait. Logging out from application...</Alert.Heading>
        <p>
          After logging out from the application, you will be redirected to the logout page shortly.{' '}
        </p>
      </Alert>
    </Container>
  );
};

export default Auth0Logout;
