import { Record as ImmutableRecord } from "immutable";

export const AccessClaimRecord = new ImmutableRecord({
  sub: "",
  username: "",
  groups: [],
  iat: 0,
  exp: 0,
  iss: "",
  version: ""
});

export const IdentityRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  accessClaim: new AccessClaimRecord({}),
  identity: {}
});
