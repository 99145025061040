import React from 'react';
import { Button, Container } from 'react-bootstrap';

const MapPopup = ({ content, onclick = null, buttonText, variant }) => {
  return (
    <Container>
      <div><span>{content}</span></div>
      {!!onclick && <Button variant={variant} style={{ float: 'right' }} onClick={onclick}>{buttonText}</Button>}
    </Container>
  );
};

export default MapPopup;
