import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import { subwayStationPath } from "../MapVisualization/SvgMarkers";
import { black, white } from "../MapVisualization/Colors";

import { metersToFeet, msToKnots, round } from "../../utils/units";
import {
  displayNextNodes,
  displayUid,
} from "../../subpages/user/requestform/util";

const WaypointDisplay = ({ index, destination, waypoint, alternates, onEdit, onDelete, disabled, waypoints }) => {
  const uid = waypoints[index]?.uid;
  const next = waypoints[index]?.next;

  const waypointDisplay = useMemo(() => displayUid(uid), [uid]);
  const waypointsDisplay = useMemo(() => displayNextNodes(next, destination, alternates, waypoints), [next, destination, alternates, waypoints]);

  return (<div className="waypoints-list-entry">
    <svg viewBox="0 0 17 17">
      <path d={subwayStationPath} fill={white} stroke={black} strokeWidth={3}/>
    </svg>
    <h4>{waypointDisplay}{waypoint.icao_description ? `: ${waypoint.icao_description}` : `: ${waypoint.name || ''}`}</h4>
    <div>
      ({waypoint.lat}&deg;, {waypoint.lng}&deg;)
    </div>
    <div>
      &#8594; {waypointsDisplay}
    </div>
    <div>
      {
        [
          waypoint.true_airspeed_ms ? `${round(msToKnots(waypoint.true_airspeed_ms), 0)} knots` : '',
          waypoint.altitude_m ? `${round(metersToFeet(waypoint.altitude_m), 0)} ft` : '',
          waypoint.hold_time_s ? `hold for ${waypoint.hold_time_s} s` : ''
        ].filter(Boolean).join(', ')
      }
    </div>
    <div className="text-end btns-inline">
      <Button variant="danger" size="sm" onClick={onDelete} disabled={disabled}>
        Delete <i className="fas fa-trash-alt"/>
      </Button>
      <Button variant="outline-primary" size="sm" className="ms-1" onClick={onEdit} disabled={disabled}>
        Edit <i className="fas fa-edit"/>
      </Button>
    </div>
  </div>);
};

WaypointDisplay.propTypes = {
  index: PropTypes.number.isRequired,
  waypoint: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default WaypointDisplay;
