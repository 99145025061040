import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import AlternateDisplay from "./AlternateDisplay";
import AlternateEntryForm from "./AlternateEntryForm";
import AddAlternateButton from "./AddAlternateButton";
import { ALTERNATE_START_ID } from "../../subpages/user/requestform/util";
import { round } from "../../utils/units";

const AlternatesEntry = ({
  alternates,
  editing,
  disabled,
  routes,
  onSave,
  onToggleEditing,
  latestMapClick,
  waypoints,
  departure,
  mutateWaypoints,
  mutateDeparture
}) => {
  const [newAlternateIndex, setNewAlternateIndex] = useState(-1);
  const [editAlternateIndex, setEditAlternateIndex] = useState(-1);

  const nextAlternateUID = useMemo(() => {
    return alternates.reduce((nextId, alternate, i) => Math.max(nextId, alternate.uid), ALTERNATE_START_ID) + 1;
  }, [alternates]);

  const handleAdd = (index) => {
    setNewAlternateIndex(index);
    onToggleEditing(true);
  };

  const handleEdit = (index) => {
    setEditAlternateIndex(index);
    onToggleEditing(true);
  };

  const handleDelete = (index) => {
    const wps = [...alternates];
    wps.splice(index, 1);

    setNewAlternateIndex(newAlternateIndex > index ? newAlternateIndex - 1 : newAlternateIndex);
    setEditAlternateIndex(editAlternateIndex > index ? editAlternateIndex - 1 : editAlternateIndex);

    const uid_to_delete = alternates[index].uid;
    waypoints.forEach(node => { node.next = node.next.filter(x => x !== uid_to_delete); });

    if (departure.next.includes(uid_to_delete)) {
      departure.next = departure.next.filter(x => x !== uid_to_delete);
    }
    onSave(wps, waypoints, departure);
  };

  const handleSave = (alternate) => {
    const alternatesToSave = [...alternates];

    if (newAlternateIndex >= 0) {
      alternatesToSave.splice(newAlternateIndex, 0, {});
      alternate.uid = nextAlternateUID;
    }

    alternatesToSave[newAlternateIndex >= 0 ? newAlternateIndex : editAlternateIndex] = alternate;

    setNewAlternateIndex(-1);
    setEditAlternateIndex(-1);

    onSave(alternatesToSave);
    onToggleEditing(false);
  };

  const handleCancelEdit = () => {
    setNewAlternateIndex(-1);
    setEditAlternateIndex(-1);

    onToggleEditing(false);
  };

  useEffect(() => {
    // Only want hook to fire if latestMapClick changes, not if editing changes,
    // therefore only latestMapClick is in the list of dependencies
    if (editing && latestMapClick?.lngLat) {
      window.alternateEntryForm.selectLocation({
        name: latestMapClick.name || '',
        airport_code: latestMapClick.icao_code || '',
        longitude: round(latestMapClick.lngLat.lng, 6),
        latitude: round(latestMapClick.lngLat.lat, 6),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestMapClick]);

  const isNewAlternateBeingCreated = newAlternateIndex >= 0;

  return (
    <>
      {
        alternates.map((wp, i) => {
          return (
            <React.Fragment key={i}>
              {(newAlternateIndex === i || editAlternateIndex === i) && (
                <AlternateEntryForm
                  alternate={isNewAlternateBeingCreated ? {} : wp}
                  routes={routes}
                  onSave={handleSave}
                  onCancel={handleCancelEdit}
                  alternates={alternates}
                />
              )}
              {(editAlternateIndex !== i || isNewAlternateBeingCreated) && (
                <>
                  <AddAlternateButton onClick={() => handleAdd(i)} disabled={disabled || editing} />
                  <AlternateDisplay
                    index={i}
                    alternate={wp}
                    disabled={disabled}
                    onEdit={() => handleEdit(i)}
                    onDelete={() => handleDelete(i)}
                  />
                </>
              )}
            </React.Fragment>
          );
        })
      }
      {newAlternateIndex === alternates.length && (
        <AlternateEntryForm routes={routes} onSave={handleSave} onCancel={handleCancelEdit} />
      )}
      {newAlternateIndex !== alternates.length && (
        <AddAlternateButton
          label={alternates.length === 0 ? 'Add Alternate ' : ''}
          onClick={() => handleAdd(alternates.length)}
          disabled={disabled || editing}
        />
      )}
    </>
  );
};

AlternatesEntry.propTypes = {
  alternates: PropTypes.array.isRequired,
  editing: PropTypes.bool,
  disabled: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  onToggleEditing: PropTypes.func.isRequired,
  latestMapClick: PropTypes.object.isRequired,
};

export default AlternatesEntry;
