import { createSlice } from "@reduxjs/toolkit";
import { FlightsRecord, PaginationRecord } from "../records/flights.record";

const initialState = new FlightsRecord({});

const flights = createSlice({
  name: 'flights',
  initialState,
  reducers: {
    isFetching: (flightsState) => {
      return flightsState.merge({
        isFetching: true,
        isFetched: false,
        isError: false,
        highlightedFlightIndex: null
      });
    },
    isFetched: (flightsState, action) => {
      return flightsState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        flightBundles: action.payload.flightBundles,
        paginationDetails: action.payload.paginationDetails
      });
    },
    isError: (flightsState) => {
      return flightsState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    setHighlightedFlightIndex: (flightState, action) => {
      return flightState.merge({
        highlightedFlightIndex: action.payload.idx
      });
    },
    setSuccessMessage: (flightsState, action) => {
      return flightsState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        successMessage: action.payload.successMessage
      });
    },
    clearSuccessMessage: (flightsState) => {
      return flightsState.merge({
        successMessage: ''
      });
    },
    clear: (flightsState) => {
      return flightsState.merge({
        isFetching: false,
        isFetched: false,
        isError: false,
        flightBundles: [],
        flightPlans: [],
        reservations: [],
        successMessage: '',
        paginationDetails: new PaginationRecord({}),
        highlightedFlightIndex: null
      });
    },
    clearFlightPagination: (flightsState) => {
      return flightsState.merge({
        paginationDetails: new PaginationRecord({}),
      });
    }
  }
});

export default flights;
