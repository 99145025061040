import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';

const FormField = ({
  input,
  type,
  label,
  placeholder,
  readOnly,
  className,
  'data-testid': dataTestId,
  meta: { touched, error },
}) => (
  <Form.Group controlId={input.name} className={`input-group${!readOnly && touched && error ? ' has-error' : ''}${className ? ` ${className}` : ''}`}>
    {label && <Form.Label>{label}</Form.Label>}
    <Form.Control
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...input}
      type={input.type || type}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={readOnly}
      data-testid={dataTestId}
    />
    {!readOnly && touched && error && (
      <div className="error-message">{error}</div>
    )}
  </Form.Group>
);

FormField.propTypes = {
  input: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }),
};

FormField.defaultProps = {
  type: 'text',
  label: null,
  placeholder: null,
  readOnly: false,
  className: null,
  'data-testid': null,
  meta: {
    error: '',
  },
};

export default FormField;
