import { createSlice } from "@reduxjs/toolkit";
import { FlightPlanRecord } from "../records/flight.plan.record";

const initialState = new FlightPlanRecord({});

const flightPlan = createSlice({
  name: 'flightPlan',
  initialState,
  reducers: {
    isFetching: (flightPlanState) => {
      return flightPlanState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (flightPlanState, action) => {
      return flightPlanState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        flightBundles: action.payload.flightBundles,
        flightPath: action.payload.flightPath,
        flightPlans: action.payload.flightPlans,
        reservations: action.payload.reservations
      });
    },
    isError: (flightPlanState) => {
      return flightPlanState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    setFlightPlan: (flightPlanState, action) => {
      return flightPlanState.merge({
        flightPlan: action.payload.data,
        showPreviousButton: action.payload.showPreviousButton
      });
    },
    mapCoordinates: (mapboxState, action) => {
      return mapboxState.merge({
        mapCoordinates: action.payload.mapCoordinates
      });
    },
    isEditingDestination: (mapboxState, action) => {
      return mapboxState.merge({
        editingDestination: action.payload.editingDestination
      });
    },
    isEditingDeparture: (mapboxState, action) => {
      return mapboxState.merge({
        editingDeparture: action.payload.editingDeparture
      });
    },
    isAddingWaypoint: (mapboxState, action) => {
      return mapboxState.merge({
        addingWaypoint: action.payload.addingWaypoint
      });
    },
    isAddingAlternate: (mapboxState, action) => {
      return mapboxState.merge({
        addingAlternate: action.payload.addingAlternate
      });
    },
    setFlightPlanUtils: (mapboxState, action) => {
      return mapboxState.merge({
        flightPlanUtils: action.payload.flightPlanUtils
      });
    },
    setHighlightedFlightVertexUuid: (mapboxState, action) => {
      return mapboxState.merge({
        highlightedFlightVertexUuid: action.payload.highlightedFlightVertexUuid
      });
    },
    clear: (flightPlanState) => {
      return flightPlanState.merge({
        flightPlan: null,
        showPreviousButton: false,
        editingDestination: false,
        editingDeparture: false,
        addingWaypoint: false,
        addingAlternate: false,
        mapCoordinates: {
          lnglat: null,
          name: null,
          icao_code: null
        },
        flightPlanUtils: {
          departure: null,
          waypoints: null,
          destination: null,
          alternates: null,
          initialLoad: true
        },
        highlightedFlightVertexUuid: '',
        reservation: null,
        flightPath: null
      });
    }
  }
});

export default flightPlan;
