import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { isAura } from "../../../utils/identity";
import { Button, Container, Row } from "react-bootstrap";
import ReactTable from "../../../components/Table/ReactTable.js";
import { useDispatch, useSelector } from 'react-redux';
import { setSiteDetails } from '../store/actions/site.details.action';
import { toggleSiteListPanel, toggleResourceBlockPanel } from '../../user/store/actions/panels.action';
import { SITE_COMPONENT } from '../../../constants/api.endpoints';
import { getAntennas, getGroundRadios } from '../../user/store/actions/inventory';
import HelpToolTip from "../../../components/HelpToolTip/HelpToolTip";
import { cleanUpOldFlights, clearSuccessMessage, cleanUpOrphanedFlightData } from '../../user/store/actions/flights.action';

const SiteList = () => {
  const dispatch = useDispatch();
  const sites = useSelector(({ sites }) => sites.sites);
  const identity = useSelector(({ identity }) => identity.identity);
  const successMessage = useSelector(({ flights }) => flights.successMessage);

  const [isCleaningOldFlights, setIsCleaningOldFlights] = useState(false);
  const [isDiscardingOrphanedData, setIsDiscardingOrphanedData] = useState(false);
  useEffect(() => {
    dispatch(getGroundRadios());
    dispatch(getAntennas());
  });

  if (!isAura(identity)) {
    return;
  }

  const redirectToSiteDetails = (siteDetails = null) => {
    dispatch(setSiteDetails(siteDetails, SITE_COMPONENT));
  };

  const redirectToResourceGrids = () => {
    dispatch(toggleResourceBlockPanel());
  };

  const handleClose = () => {
    dispatch(clearSuccessMessage());
    dispatch(toggleSiteListPanel());
  };

  const cleanOldFlights = () => {
    setIsCleaningOldFlights(true);
    dispatch(cleanUpOldFlights()).finally(() => setIsCleaningOldFlights(false));
  };

  const discardOrphanedFlightData = () => {
    setIsDiscardingOrphanedData(true);
    dispatch(cleanUpOrphanedFlightData()).finally(() => setIsDiscardingOrphanedData(false));
  };

  return (
    <div className="panel panel-s">
      <div className="panel-header">
        <h2>Sites</h2>
        <HelpToolTip content="Manage & configure AURA's GRS radio Sites. Visible to AURA only." />
        <a className="close-button" onClick={handleClose}><i className="fa-solid fa-circle-xmark"/></a>
      </div>
      <div className="panel-body">
        <Container>
          <ReactTable
            data={sites || []}
            columns={[
              {
                Header: "Name",
                accessor: "name"
              },
              {
                Header: "Callsign",
                accessor: "callsign"
              }
            ]}
            showPagination={false}
            defaultPageSize={100}
            minRows={1}
            className="-striped -highlight"
            rowProps={(row) => {
              return {
                onClick: e => {
                  redirectToSiteDetails(row.original);
                }
              };
            }}

          />
          <Row>
            <div className='col-md-12 mt-4 text-end'>
              <Button
                variant="primary"
                onClick={() => {
                  redirectToSiteDetails();
                }}
              >
                Add Site <i className="fas fa-plus"/>
              </Button>
            </div>
            <div className='col-md-12 mt-4 text-end'>
              <Button
                variant="primary"
                onClick={() => {
                  redirectToResourceGrids();
                }}
              >
                Resource Grids
              </Button>
            </div>

            <div className='col-md-12 mt-4 text-end'>
              <Button
                variant="primary"
                onClick={() => {
                  cleanOldFlights();
                }}
              >
                {isCleaningOldFlights && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Clean up old Flights
              </Button>
            </div>

            <div className='col-md-12 mt-4 text-end'>
              <Button
                variant="primary"
                onClick={() => {
                  discardOrphanedFlightData();
                }}
              >
                {isDiscardingOrphanedData && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Clean up orphaned Flight data
              </Button>
            </div>
            {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}
          </Row>
        </Container>
        <div className={"text-end"}>v.{window._env_.VERSION || "(Unspecified)"}</div>
      </div>
    </div>
  );
};

export default SiteList;
