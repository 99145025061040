import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import PropType from 'prop-types';
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import Session from "../../../utils/session";
import "./resourcegrid.css";
import { FLIGHT_PLANNING_API_URL_V2 } from '../../../constants/api.endpoints';
import { useDispatch } from 'react-redux';
import { getResourceGridSets } from '../store/actions';
import { getErrorMessage } from '../../user/store/utils/common';

const NewResourceGrid = ({ showModal, hideModal }) => {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);

  const [newResourceGrid, setNewResourceGrid] = useState({
    uuid: uuidv4(),
    name: '',
    num_subcarriers: 1,
    num_time_slots: 1,
    min_frequency_hz: 1,
    subchannel_width_hz: 1
  });

  const [errors, setErrors] = useState({});
  const [saveError, setSaveError] = useState("");

  const isResourceGridValid = () => {
    return Object.keys(errors).length === 0;
  };

  // Returns true if the data is valid
  const validate = (resourceGrid) => {
    const newErrors = {};
    if (!resourceGrid.name || resourceGrid.name.trim().length === 0) {
      newErrors.name = 'Required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateState = () => {
    return validate(newResourceGrid);
  };

  const updateValue = (name, value) => {
    const updatedState = {
      ...newResourceGrid
    };
    updatedState[name] = value;
    setNewResourceGrid(updatedState);
    validate(updatedState);
  };

  const submitNewResourceGrid = () => {
    if (!validateState()) {
      return;
    }
    setSaveError("");
    setShowSpinner(true);
    axios
      .post(FLIGHT_PLANNING_API_URL_V2 + '/resourcegrids/', newResourceGrid, Session.requestConfig())
      .then((response) => {
        hideModal();
        dispatch(getResourceGridSets());
      })
      .catch((error) => {
        console.log(error);
        setSaveError(getErrorMessage(error));
      })
      .finally(() => setShowSpinner(false));
  };

  const updateNumTimeslots = (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 1) {
      newValue = 1;
    }
    updateValue("num_time_slots", newValue);
  };

  const updateNumSubcarriers = (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 1) {
      newValue = 1;
    }
    updateValue("num_subcarriers", newValue);
  };

  const updateMinFrequency = (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 1) {
      newValue = 1;
    }
    updateValue("min_frequency_hz", newValue);
  };

  const updateSubChannelWidth = (event) => {
    let newValue = parseInt(event.target.value);
    if (newValue < 1) {
      newValue = 1;
    }
    updateValue("subchannel_width_hz", newValue);
  };

  const updateName = (event) => {
    updateValue("name", event.target.value);
  };

  return (
    <Modal
      className="resource-block-modal"
      data-testid="new-resource-block-modal"
      show={ showModal }
      onHide={ hideModal }>
      <Modal.Header closeButton>
        <Modal.Title>Add Resource Grid</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>
          <Row>
            <Form.Group as={ Col } controlId='resourceGrid.name' data-testid="name-input">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" autoFocus onChange={ updateName } isInvalid={ !!errors.name }/>
              { errors.name && <Form.Control.Feedback type="invalid" tooltip>{ errors.name }</Form.Control.Feedback> }
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={ Col } controlId='resourceGrid.num_subcarriers' data-testid="num-subcarriers-input">
              <Form.Label>Number of Subcarriers</Form.Label>
              <Form.Control type="number" value={ newResourceGrid.num_subcarriers } onChange={ updateNumSubcarriers }/>
            </Form.Group>
            <Form.Group as={ Col } controlId='resourceGrid.num_time_slots' data-testid="num-time-slots-input">
              <Form.Label>Number of Time Slots</Form.Label>
              <Form.Control type="number" value={ newResourceGrid.num_time_slots } onChange={ updateNumTimeslots }/>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={ Col } controlId='resourceGrid.min_frequency_hz' data-testid="min-frequency-input">
              <Form.Label>Min Frequency (hz)</Form.Label>
              <Form.Control type="number" value={ newResourceGrid.min_frequency_hz } onChange={ updateMinFrequency }/>
            </Form.Group>
            <Form.Group as={ Col } controlId='resourceGrid.subchannel_width_hz' data-testid="sub-channel-width-input">
              <Form.Label>Sub Channel Width (hz)</Form.Label>
              <Form.Control type="number" value={ newResourceGrid.subchannel_width_hz }
                            onChange={ updateSubChannelWidth }/>
            </Form.Group>
          </Row>

        </Form>
        { saveError && <p className="text-danger">{ saveError }</p> }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          data-testid="new-resource-block-modal-cancel-button"
          disabled={ showSpinner }
          onClick={ () => hideModal() }>
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={ showSpinner || !isResourceGridValid() }
          onClick={ submitNewResourceGrid }>
          Save <i className="fas fa-save"/>
        </Button>
        { showSpinner && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            data-testid="new-resource-block-spinner"
          />
        ) }
      </Modal.Footer>
    </Modal>
  );
};

NewResourceGrid.propTypes = {
  showModal: PropType.bool,
  hideModal: PropType.func
};

export default NewResourceGrid;
