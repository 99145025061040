import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MapVisualization from '../../components/MapVisualization/MapVisualization';
import Toolbar from './listrequests/Toolbar';
import FlightDetails from './requestform/FlightDetails';
import RoutePlan from './routes/RoutePlan';
import FlightPlan from './flights/FlightPlan';

import SiteList from '../admin/sites/SiteList';
import SiteDetails from '../admin/sites/SiteDetails';
import ResourceGrid from '../admin/resourcegrid/ResourceGrid.js';
import ResourceScheduleViz from '../admin/resourceschedules/ResourceScheduleViz';
import FlightList from './listrequests/FlightList';
import RouteList from './routes/RouteList';
import { setMapboxCoordinates } from './store/actions/routes/route.plan.action';
import './listrequests/FlightList.css';

const MapView = () => {
  const dispatch = useDispatch();
  const modals = useSelector(({ modals }) => modals);
  const { isFlightListPanelVisible, isFlightDetailsPanelVisible, isFlightPlanPanelVisible, isSiteListPanelVisible, isSiteDetailsPanelVisible, isResourceBlockPanelVisible, isRoutePlanningPanelVisible, isRouteListPanelVisible, isResourceSchedulePanelVisible } = modals;

  const handleMapClick = (lngLat, name = null, icao_code = null) => {
    dispatch(setMapboxCoordinates({ lngLat, name, icao_code }));
  };

  return (
    <>
      <Toolbar />
      { isRouteListPanelVisible && <RouteList />}
      { isFlightListPanelVisible && <FlightList /> }
      { isFlightDetailsPanelVisible && <FlightDetails /> }
      { isRoutePlanningPanelVisible && <RoutePlan /> }
      { isFlightPlanPanelVisible && <FlightPlan /> }
      { isSiteListPanelVisible && <SiteList /> }
      { isSiteDetailsPanelVisible && <SiteDetails /> }
      { isResourceBlockPanelVisible && <ResourceGrid /> }
      { isResourceSchedulePanelVisible && <ResourceScheduleViz />}
      {useMemo(() => (<MapVisualization
        mapClickCallback={handleMapClick}
      // eslint-disable-next-line react-hooks/exhaustive-deps
      />), [dispatch])}
    </>
  );
};

export default withRouter(MapView);
