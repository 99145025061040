import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "../../../../admin/store/records/general.record";

export const PatternRecord = new ImmutableRecord({
  content: '',
  type: ''
});

export const AntennaModelRecord = new ImmutableRecord({
  name: '',
  pattern: new PatternRecord({}),
  type: '',
  uuid: '',
  metadata: new MetadataRecord({})
});

export const AntennaModelsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  antennaModels: [new AntennaModelRecord({})],
  groundAntennaModels: [new AntennaModelRecord({})],
});
