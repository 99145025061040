import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "./general.record";

export const CoverageBoundsUuidsRecord = new ImmutableRecord({
  power: "",
  coverageBoundsUuid: ""
});

export const SiteDataRecord = new ImmutableRecord({
  uuid: "",
  type: "",
  callsign: "",
  name: "",
  groundRadioUuid: "",
  antennaUuid: "",
  location: { type: "", coordinates: [] },
  coverageBoundsUuids: [],
  resourceGridUuid: "",
  a2gResourceBlockConfig: [],
  g2aResourceBlockConfig: [],
  heightAglM: 0,
  altitudeM: 0,
  maxEirpDbm: 0,
  antennaBearingDeg: 0,
  antennaAzimuthDeg: 0,
  g2gOobInterferenceDbm: 0,
  metadata: new MetadataRecord({})
});

export const SiteRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  sitesByUuid: {},
  sites: [SiteDataRecord({})],
  siteDetails: null
});
