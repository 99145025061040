import { Record as ImmutableRecord } from "immutable";

export const FlightDetailsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  flightUuid: "",
  estimatedTimeDepartureUTC: '',
  flightPlan: [],
  flightPath: [],
  route: [],
  reservation: [],
  flightStateMachine: {},
  flightFrrcState: {}
});
