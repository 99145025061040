import { createSlice } from "@reduxjs/toolkit";
import { FlightPlanRecord } from "../../records/flights/flight.plan.records";

const initialState = new FlightPlanRecord({});

const flightPlan = createSlice({
  name: 'flightPlan',
  initialState,
  reducers: {
    isFetching: (flightPlanState) => {
      return flightPlanState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (flightPlanState, action) => {
      return flightPlanState.merge({
        isFetching: false,
        isFetched: true,
        isError: false,
        disableFlightPlan: action.payload.disableFlightPlan
      });
    },
    isError: (flightPlanState) => {
      return flightPlanState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    setSaveButtonText: (flightPlanState, action) => {
      return flightPlanState.merge({
        saveButtonText: action.payload.saveButtonText
      });
    },
    setSelectedRoute: (flightPlanState, action) => {
      return flightPlanState.merge({
        selectedRoute: action.payload.selectedRoute
      });
    },
    setFlightUuid: (flightPlanState, action) => {
      return flightPlanState.merge({
        flightUuid: action.payload.flightUuid
      });
    },
    clear: (flightPlanState) => {
      return new FlightPlanRecord({});
    }
  }
});

export const flightPlanReducer = flightPlan.reducer;
export const flightPlanActions = flightPlan.actions;
