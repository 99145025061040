import axios from "axios";
import { AIRCRAFT_MODEL_ENDPOINT, FLIGHT_PLANNING_API_URL_V2 } from "../../../../../constants/api.endpoints";
import Session from "../../../../../utils/session";
import { flightsActions } from "../../reducers";
import { AircraftModelRecord, AntennaMountingRecord, PerformanceRecord } from "../../records/inventory/aircraft.model.record";

export const getAircraftModels = () => async dispatch => {
  try {
    dispatch(flightsActions.inventory.aircraftModels.isFetching());
    const response = await axios.get(
      `${FLIGHT_PLANNING_API_URL_V2}${AIRCRAFT_MODEL_ENDPOINT}`,
      Session.requestConfig()
    );

    if (Session.validationResponse(response)) {
      const aircraftModels = response?.data?.reduce((newRec, rec) => {
        const aircraftModelRecord = new AircraftModelRecord({
          uuid: rec.uuid,
          antennaMountings: rec.antenna_mountings.map(mounting => new AntennaMountingRecord({
            cableLossDb: mounting.cable_loss_db,
            type: mounting.type
          })),
          performance: new PerformanceRecord({
            maxAltitudeM: rec.performance.max_altitude_m,
            maxAscentRateMs: rec.performance.max_ascent_rate_ms,
            maxAscentTrueAirspeedMs: rec.performance.max_ascent_true_airspeed_ms,
            maxDescentRateMs: rec.performance.max_descent_rate_ms,
            maxDescentTrueAirspeedMs: rec.performance.max_descent_true_airspeed_ms,
            maxSpeedMs: rec.performance.max_speed_ms,
          }),
          name: rec.name
        });
        newRec.push(aircraftModelRecord);
        return newRec;
      }, []);
      dispatch(
        flightsActions.inventory.aircraftModels.isFetched({ aircraftModels })
      );
    } else {
      dispatch(flightsActions.inventory.aircraftModels.isError());
    }
  } catch (err) {
    dispatch(flightsActions.inventory.aircraftModels.isError());
  }
};
