import React from 'react';
import '../../styles.css';
import './ResourceGridLegend.css';

const ResourceGridLegend = ({ showFlightLegendBlock }) => {
  const legendBlocks = [
    {
      description: 'Allocated to Voice (PDSCH & PUSCH)',
      className: 'allocated-voice-g2a',
      showInFlightLegend: true,
      showInSiteLegend: false
    },
    {
      description: 'Allocated to C2/Data (PDSCH & PUSCH)',
      className: 'allocated-c2-data-g2a',
      showInFlightLegend: true,
      showInSiteLegend: false
    },
    {
      description: 'Available (PDSCH & PUSCH)',
      className: 'not-selected',
      showInFlightLegend: false,
      showInSiteLegend: true
    },
    {
      description: 'Unallocated',
      className: 'not-selected',
      showInFlightLegend: true,
      showInSiteLegend: false
    },
    {
      description: 'Initialization (PACH/PBCH & PRACH)',
      className: 'selected-1',
      showInFlightLegend: true,
      showInSiteLegend: true
    },
    {
      description: 'Control (PDCCH & PUCCH)',
      className: 'selected-2',
      showInFlightLegend: true,
      showInSiteLegend: true
    },
    {
      description: 'Not in use',
      className: 'selected-0',
      showInFlightLegend: true,
      showInSiteLegend: true
    }
  ];

  return (
    <div className='rb-legend'>
      {legendBlocks
        .filter((block, index) => (showFlightLegendBlock ? block.showInFlightLegend : block.showInSiteLegend))
        .map((block, index) => (
          <div
            key={index}
          >
            <div
              className={`square-block ${block.className}`}
            ></div>
            <span>{block.description}</span>
          </div>
        ))}
    </div>
  );
};

export default ResourceGridLegend;
