import { createSlice } from "@reduxjs/toolkit";
import { SiteDetailsRecord } from "../records/site.details.record";

const initialState = new SiteDetailsRecord({});

const siteDetails = createSlice({
  name: 'siteDetails',
  initialState,
  reducers: {
    setSiteDetails: (sitesDetailsState, action) => {
      return sitesDetailsState.merge({
        siteDetails: action.payload.siteDetails,
        parentComponent: action.payload.parentComponent,
        coverageBoundsToShowBySiteId: action.payload.coverageBoundsToShowBySiteId,
        initialSiteId: action.payload.initialSiteId
      });
    },
    setInitialSiteId: (sitesDetailsState, action) => {
      return sitesDetailsState.merge({
        initialSiteId: action.payload.initialSiteId
      });
    },
    setCoverageBoundsToShowBySiteId: (sitesDetailsState, action) => {
      return sitesDetailsState.merge({
        coverageBoundsToShowBySiteId: action.payload.coverageBoundsToShowBySiteId
      });
    },
    clear: (sitesDetailsState) => {
      return new SiteDetailsRecord({});
    }
  }
});

export default siteDetails;
