import { createSlice } from "@reduxjs/toolkit";
import { MapRecord } from "../../records/map/map.record";

const initialState = new MapRecord({});

const map = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setReservations: (mapState, action) => {
      return mapState.merge({
        reservations: action.payload.reservations
      });
    },
    setFlightPath: (mapState, action) => {
      return mapState.merge({
        flightPath: action.payload.flightPath
      });
    },
    setFlightPlans: (mapState, action) => {
      return mapState.merge({
        flightPlans: action.payload.flightPlans
      });
    },
    setFlightOrigin: (mapState, action) => {
      return mapState.merge({
        flightOrigin: action.payload.flightOrigin,
        flightPlans: []
      });
    },
    setFlightAndReservation: (mapState, action) => {
      return mapState.merge({
        flightPlans: action.payload.flightPlans,
        flightPath: action.payload.flightPath,
        reservations: action.payload.reservations
      });
    },
    setIsdrawingEnabled: (mapState, action) => {
      return mapState.merge({
        isDrawingEnabled: action.payload.isDrawingEnabled
      });
    },
    setHighlightedRoute: (mapState, action) => {
      return mapState.merge({
        highlightedRoute: action.payload.highlightedRoute
      });
    },
    setTemplateSignals: (mapState, action) => {
      return mapState.merge({
        templateSignals: action.payload.templateSignals
      });
    },
    showCoverageForSiteUuid: (mapState, action) => {
      if (mapState.siteUuidsToShowCoverage.includes(action.payload.siteUuid)) {
        //   noting to be done
        return mapState;
      } else {
        const updatedSiteUuidsToShowCoverage = [...mapState.siteUuidsToShowCoverage, action.payload.siteUuid];
        return mapState.merge({
          siteUuidsToShowCoverage: updatedSiteUuidsToShowCoverage
        });
      }
    },
    hideCoverageForSiteUuid: (mapState, action) => {
      return mapState.merge({
        siteUuidsToShowCoverage: mapState.siteUuidsToShowCoverage.filter((x) => x !== action.payload.siteUuid)
      });
    },
    toggleCoverageForSiteUuid: (mapState, action) => {
      if (mapState.siteUuidsToShowCoverage.includes(action.payload.siteUuid)) {
        return mapState.merge({
          siteUuidsToShowCoverage: mapState.siteUuidsToShowCoverage.filter((x) => x !== action.payload.siteUuid)
        });
      } else {
        const updatedSiteUuidsToShowCoverage = [...mapState.siteUuidsToShowCoverage, action.payload.siteUuid];
        return mapState.merge({
          siteUuidsToShowCoverage: updatedSiteUuidsToShowCoverage
        });
      }
    },
    toggleSiteCoverageMetric: (mapState) => {
      return mapState.merge({
        siteCoverageMetric: 1 - mapState.siteCoverageMetric
      });
    },
    clear: (mapState) => {
      return new MapRecord({});
    }
  }
});

export const mapReducer = map.reducer;
export const mapActions = map.actions;
