import { Record as ImmutableRecord } from "immutable";
import { MetadataRecord } from "../../../../admin/store/records/general.record";

export const AntennaMountingRecord = new ImmutableRecord({
  cableLossDb: '',
  type: '',
});

export const PerformanceRecord = new ImmutableRecord({
  maxAltitudeM: 0,
  maxAscentRateMs: 0,
  maxAscentTrueAirspeedMs: 0,
  maxDescentRateMs: 0,
  maxDescentTrueAirspeedMs: 0,
  maxSpeedMs: 0,
});

export const AircraftModelRecord = new ImmutableRecord({
  antennaMountings: [new AntennaMountingRecord({})],
  metadata: new MetadataRecord({}),
  name: '',
  performance: new PerformanceRecord({}),
  uuid: ""
});

export const AircraftModelsRecord = new ImmutableRecord({
  isFetching: false,
  isFetched: false,
  isError: false,
  aircraftModels: [new AircraftModelRecord({})]
});
