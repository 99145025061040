import { createSlice } from "@reduxjs/toolkit";
import { FlightDetailsRecord } from "../records/flight.details.record";

const initialState = new FlightDetailsRecord({});

const flightDetails = createSlice({
  name: 'flightDetails',
  initialState,
  reducers: {
    isFetching: (flightDetailsState) => {
      return flightDetailsState.merge({
        isFetching: true,
        isFetched: false,
        isError: false
      });
    },
    isFetched: (flightDetailsState, action) => {
      return flightDetailsState.merge({
        isFetching: false,
        isFetched: true,
        isError: false
      });
    },
    isError: (flightDetailsState) => {
      return flightDetailsState.merge({
        isFetching: false,
        isFetched: false,
        isError: true
      });
    },
    setFlightDetails: (flightDetailsState, action) => {
      return flightDetailsState.merge({
        flightUuid: action.payload.flightUuid,
        flightPlan: action.payload.flightPlan,
        estimatedTimeDepartureUTC: action.payload.estimatedTimeDepartureUTC,
        route: action.payload.route,
        reservation: action.payload.reservation
      });
    },
    setStateMachineViz: (flightDetailsState, action) => {
      return flightDetailsState.merge({
        flightStateMachine: action.payload
      });
    },
    setFlightFrrcState: (flightDetailsState, action) => {
      return flightDetailsState.merge({
        flightFrrcState: action.payload
      });
    },
    clear: (flightDetailsState) => {
      return flightDetailsState.merge({
        flightUuid: "",
        flightPlan: [],
        flightPath: [],
        reservation: [],
        estimatedTimeDepartureUTC: "",
        flightStateMachine: {},
        flightFrrcState: {}
      });
    }
  }
});

export default flightDetails;
